import React from "react";
import NoAccessPage from "../Pages/Auth/NoAccessPage";
import RedirectElement from "../Pages/Auth/RedirectElement";

const AuthenticationMainRouter = [
  {
    path: '/login',
    element: <RedirectElement />,
  },

  {
    path: '/no-access',
    element: <NoAccessPage />,
  },


];

export default AuthenticationMainRouter;
