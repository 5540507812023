import {
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Spacer,
    useDisclosure,
    Heading,
    useToast,
    Avatar,
    Stack,
    Text,
  } from "@chakra-ui/react";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { useLocation } from "react-router-dom";
  import { clientTypessense } from "../../Api/Typesense";
  import BackButtons from "../../Components/Buttons/BackButtons";
  import { db } from "../../Config/firebase";
  import useUserStore from "../../Hooks/Zustand/Store";
  import HrisRoleAddPage from "./HrisRoleAddPage";
  import DynamicTable from "../../Components/Table/DynamicTable";
  import AlertDialogV2 from "../../Components/AlertDialog/AlertDialogV2";
  import {
    deleteDocumentFirebase,
    getSingleDocumentFirebase,
    updateDocumentFirebase,
  } from "../../Api/firebaseApi";
  import DynamicButton from "../../Components/Buttons/DynamicButton";
  
  function RolesPage() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const alertDelete = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [roleId, setRoleId] = useState("");
    const [deleteRole, setDeleteRole] = useState({});
    const globalState = useUserStore();
    const toast = useToast();
    const [type, setType] = useState("");
    const modalEditRole = useDisclosure();
  
    const tableHeader = ["title", "id", "createdAt"];
  
    const getData = async (q = "") => {
      try {
        const searchParameters = {
          q: q,
          query_by: "title",
          filter_by: `companyId:'${globalState.currentCompany}' && category:'hris' `,
          sort_by: "_text_match:desc",
        };
        clientTypessense
          .collections("files")
          .documents()
          .search(searchParameters)
          .then((x) => {
            console.log(x,"xxxxxxxxxxx")
            const newData = x?.hits?.map((y) => {
              return { ...y.document };
            });
  
            setData(newData);
            console.log(newData,"new")
          });

      } catch (error) {
        console.log(error);
      }
    };
  
    let tableData = "";
    tableData = data?.map((data, index) => {
      const id = data?.id || "";
      const title = data?.title || "";
      const createdAt = data?.createdAt || "No Date";
  
      return {
        data,
        title: (
          <HStack>
            <Avatar size={"md"} name={title} />
            <Stack spacing={1}>
              <Text fontWeight={500} textTransform={"capitalize"}>
                {title}
              </Text>
            </Stack>
          </HStack>
        ),
        id: id,
        createdAt: createdAt,
      };
    });
  
    const openModalEdit = (x) => {
      setRoleId(x.id);
      setType("edit");
      modalEditRole?.onOpen();
    };
  console.log(data,"data")
    const openModalDelete = (x) => {
      setDeleteRole(x?.data);
      alertDelete.onOpen();
    };
  console.log(globalState,"hyo")
    const handleDelete = async () => {
      setIsLoading(true);
      try {
        const res = await getSingleDocumentFirebase(
          `companies/${globalState.currentCompany}/roles`,
          deleteRole?.id
        );
  
        console.log(res);
  
        if (res && "users" in res) {
          res?.users?.forEach(async (x) => {
            const updateRoleUser = await updateDocumentFirebase("users", x, {
              role: "",
            });
  
            console.log(updateRoleUser, "updated");
          });
        }
  
        await deleteDocumentFirebase(
          `companies/${globalState.currentCompany}/roles`,
          deleteRole?.id
        );
  
        await deleteDocumentFirebase("files", deleteRole?.id);
  
        await getData("");
  
        toast({
          status: "success",
          title: "Success",
          description: "Role deleted!",
          duration: 3000,
        });
        alertDelete.onClose();
      } catch (error) {
        console.log(error);
  
        toast({
          status: "error",
          title: "Error",
          description: `${error}`,
          duration: 3000,
        });
      } finally {
        setIsLoading(false);
        alertDelete.onOpen();
        window.location.reload();
      }
    };
  
    useEffect(() => {
      getData();
  
      return () => {
        setData([]);
      };
    }, []);
  
    const openModalAdd = () => {
      setRoleId("");
  
      onOpen();
    };
  
    const closeModal = () => {
      onClose();
      setRoleId("");
    };
  
    const closeEditModal = () => {
      modalEditRole?.onClose();
      setRoleId("");
    };
  
    return (
      <Box p={[0, 1, 5]}>
        <HStack pb={3}>
          <BackButtons />
          <Spacer />
          <Heading size={"lg"}>Role</Heading>
        </HStack>
  
        <Stack bg={"white"} p="5" borderRadius="md" shadow="base">
          <HStack>
            <Spacer />
            <DynamicButton
              action={"create"}
              title={"Role"}
              onClick={openModalAdd}
            />
          </HStack>
  
          <Box>
            <DynamicTable
              header={tableHeader}
              data={tableData}
              onDelete={openModalDelete}
              onEdit={openModalEdit}
            />
          </Box>
        </Stack>
  
        <Modal isOpen={isOpen} onClose={closeModal} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Role</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HrisRoleAddPage
                getData={getData}
                onClose={closeModal}
                roleId={roleId}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
  
        {/* <Modal isOpen={modalEditRole?.isOpen} onClose={closeEditModal} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Role</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HrisRoleAddPage
                getData={getData}
                onClose={closeEditModal}
                roleId={roleId}
                type={type}
              />
            </ModalBody>
          </ModalContent>
        </Modal> */}
  
        <AlertDialogV2
          isLoading={isLoading}
          isOpen={alertDelete.isOpen}
          onClose={alertDelete.onClose}
          onAction={handleDelete}
          title={`Delete Role ${deleteRole?.title}`}
        />
      </Box>
    );
  }
  
  export default RolesPage;
  