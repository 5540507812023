import _axios from './AxiosBarrier';


export const addActivity = async (data) => {
  try {
    const res = await _axios.post(`/kanban-addActivity`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// Tambahkan fungsi-fungsi lain untuk API activity lainnya jika diperlukan
