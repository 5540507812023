import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tooltip,
  VStack,
  Select,
  Tr,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { MdOutlinePermMedia } from "react-icons/md";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, serverTimestamp, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../Config/firebase";

const DoctorCreatePage = () => {
  const navigate = useNavigate();
  const [newTreatment, setNewTreatment] = useState("");
  const [content, setContent] = useState("");
  const [treatment, setTreatment] = useState([]);
  const [dataInput, setDataInput] = useState({
    treatment: [],
  });
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const toast = useToast()

  const getTreatment = async () => {
    const collectionRef = collection(db, "treatment-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTreatment(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const contentChange = (value) => {
    setContent(value);
  };

  const generateSchedule = () => {
    const formattedDay =
      selectedDay ||
      selectedDay.toLocaleDateString("id-ID", { weekday: "long" });

    const startHour = parseInt(selectedTime.slice(0, 2));
    const endHour = startHour + 1;

    const formattedSchedule = `${formattedDay} ${selectedTime}-${endHour
      .toString()
      .padStart(2, "0")}:00 WIB`;

    setAvailableSlots([...availableSlots, formattedSchedule]);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...availableSlots];
    updatedSlots.splice(index, 1);
    setAvailableSlots(updatedSlots);
  };

  const handleSave = async () => {
    try {
      let data = {
        ...dataInput,
        thumbnail: imageUrl,
        avaible: availableSlots,
        createdAt: serverTimestamp(),
        type: "doctor",
      };

      const docRef = await addDoc(collection(db, "doctor-deoapp-clinic"), data);

      if (
        Array.isArray(dataInput.treatment) &&
        dataInput.treatment.length > 0
      ) {
        for (const treatment of dataInput.treatment) {
          const treatmentDocRef = doc(
            db,
            "treatment-deoapp-clinic",
            treatment.id
          );

          const treatmentDocSnapshot = await getDoc(treatmentDocRef);

          if (treatmentDocSnapshot.exists()) {
            const existingData = treatmentDocSnapshot.data();

            const updatedData = {
              ...existingData,
              doctor: [
                ...(existingData.doctor || []),
                {
                  id: docRef.id,
                  name: dataInput.name,
                },
              ],
            };

            await updateDoc(treatmentDocRef, updatedData);
          }
        }
      }

      console.log("Data berhasil ditambahkan dengan ID:", docRef.id);
      toast({
        title: "Deoapp Clinic",
        description: "Docter Added!",
        status: "success",
        duration: 1000,
      });

      setTimeout(() => {
        navigate("/booking/list-doctor");
      }, 1000);
    } catch (error) {
      console.error(error);

      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTag = () => {
    let arr = [...dataInput.treatment];
    const selectedTreatment = treatment.find((t) => t.id === newTreatment);

    if (selectedTreatment) {
      arr.push({
        id: selectedTreatment.id,
        title: selectedTreatment.title,
      });
      setDataInput({
        ...dataInput,
        treatment: arr,
      });
    }
  };

  const handleDeleteTag = (index) => {
    let updatedCategories = [...dataInput.treatment];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      treatment: updatedCategories,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    if (newFiles.length) {
      const reader = new FileReader();
      reader.readAsDataURL(newFiles[0]);
      reader.onload = () => {
        setImageUrl(reader.result);
      };
    }
  };

  useEffect(() => {
    getTreatment();
  }, []);

  return (
    <Flex direction="column" mx={5} my={10}>
      {/* Bagian Atas */}
      <Box mb={5}>
        <BackButtons />
        <Heading fontSize="2xl" mt={5} mb={3}>
          Add Doctor
        </Heading>

        <Flex>
          {/* Bagian Kiri */}
          <Box flex={1} mr={5}>
            <FormControl mt={5}>
              <FormLabel>Name</FormLabel>
              <Input
                bg="white"
                onChange={(e) => setDataInput({ ...dataInput, name: e.target.value })}
                placeholder="Enter Name"
              />
            </FormControl>

            <FormControl mt={5}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                bg="white"
                onChange={(e) => setDataInput({ ...dataInput, hp: e.target.value })}
                placeholder="Enter Phone Number"
              />
            </FormControl>

            <Box mt={8}>
              <Tooltip label="Thumbnail image for your articles">
                <Text fontWeight={600} color="gray.600">
                  Thumbnail
                </Text>
              </Tooltip>
              {imageUrl ? (
                <>
                  <Image src={imageUrl} boxSize="full" objectFit="cover" />
                  <Flex justify={"center"} mt={2}>
                    <Input
                      type="file"
                      onChange={handleFileInputChange}
                      display="none"
                      id="fileInput"
                    />
                    <label htmlFor="fileInput">
                      <Flex cursor="pointer">
                        <Box>
                          <MdOutlinePermMedia />
                        </Box>
                        <Text fontSize="sm" color="blue.600" fontStyle="italic">
                          Change Image thumbnail
                        </Text>
                      </Flex>
                    </label>
                  </Flex>
                </>
              ) : (
                <Flex justify={"center"} mt={2}>
                  <Input
                    type="file"
                    onChange={handleFileInputChange}
                    display="none"
                    id="fileInput"
                  />
                  <label htmlFor="fileInput">
                    <Flex cursor="pointer">
                      <Box>
                        <MdOutlinePermMedia />
                      </Box>
                      <Text fontSize="sm" color="blue.600" fontStyle="italic">
                        Add Image thumbnail
                      </Text>
                    </Flex>
                  </label>
                </Flex>
              )}
            </Box>
          </Box>

          {/* Bagian Kanan */}
          <Box flex={1}>
            <FormControl mt={5}>
              <FormLabel>Treatment</FormLabel>
              <Flex>
                <Select
                  placeholder="Select Treatment"
                  bg="white"
                  flex={1}
                  onChange={(e) => setNewTreatment(e.target.value)}
                >
                  {Array.isArray(treatment) &&
                    treatment.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.title}
                      </option>
                    ))}
                </Select>
                <Button colorScheme="green" onClick={handleSaveTag} ml={2}>
                  +
                </Button>
              </Flex>
            </FormControl>
            {Array.isArray(dataInput?.treatment) && dataInput?.treatment?.length > 0 && (
            <Box mt={5} width="full">
              <Text fontSize="xl" fontWeight="bold">
                Selected Treatments
              </Text>
              <Table variant="simple" mt={3} borderWidth="1px" borderRadius="md" width="full">
                <Thead>
                  <Tr>
                    <Th>Treatment</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataInput?.treatment.map((treat, index) => (
                    <Tr key={index}>
                      <Td>{treat.title}</Td>
                      <Td>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDeleteTag(index)}
                        >
                          Remove
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          </Box>
        </Flex>
      </Box>

      <Divider my={5} />

      {/* Bagian Bawah */}
      <Box>
        <VStack mt={8} align="start" spacing={4} width="full">
          <Text fontSize="xl" fontWeight="bold">
            Doctor's Availability
          </Text>

          <FormControl mt={2}>
            <FormLabel>Day</FormLabel>
            <Select
              placeholder="Select Day"
              bg="white"
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt={2}>
            <FormLabel>Start Hour</FormLabel>
            <Select
              placeholder="Select Time"
              bg="white"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              {[...Array(24).keys()].map((hour) => (
                <option
                  key={hour}
                  value={`${hour.toString().padStart(2, "0")}:00`}
                >
                  {`${hour.toString().padStart(2, "0")}:00`}
                </option>
              ))}
            </Select>
          </FormControl>

          <Flex>
              <Button colorScheme="teal" mt={3} onClick={generateSchedule}>
                Add Available Schedule
              </Button>
              <Button
                marginLeft={"20px"}
                isLoading={loading}
                my={3}
                size="md"
                colorScheme={"blue"}
                onClick={handleSave}
              >
                Save Data
              </Button>
            </Flex>
          {availableSlots.length > 0 && (
            <Box mt={5} width="full">
              <Text fontSize="xl" fontWeight="bold">
                Available Schedules
              </Text>
              <Table variant="simple" mt={3} borderWidth="1px" borderRadius="md" width="full">
                <Thead>
                  <Tr>
                    <Th>Schedule</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {availableSlots.map((schedule, index) => (
                    <Tr key={index}>
                      <Td>{schedule}</Td>
                      <Td>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDeleteSlot(index)}
                        >
                          Remove
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
        </VStack>
      </Box>
    </Flex>
  );
};

export default DoctorCreatePage;
