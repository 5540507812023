import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Image,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormLabel,
  Select,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useToast,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getCollectionFirebaseV4,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../../Api/firebaseApi';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useUserStore from '../../../Hooks/Zustand/Store';
import { createDomainCustom } from '../../../Api/vercelAPI';

function MarketingDomainPage() {
  const [domains, setDomains] = useState();
  const [data, setData] = useState();
  const [inputDomain, setInputDomain] = useState({
    domain_name: '',
    projectName: 'landingpageview',
  });
  const globalState = useUserStore();
  const modalDomain = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [textVerif, setTextVerif] = useState();
  const [complete, setIsComplete] = useState(false);

  const getDomains = async () => {
    try {
      console.log(globalState.currentCompany);
      //     { field: "nama_field_1", operator: "==", value: "nilai_1" },

      const res = await getCollectionFirebase(
        'domain_landing_page',
        [
          {
            field: 'companyId',
            operator: '==',
            value: globalState.currentCompany,
          },
        ]
        // {sortBy:null},
        // {limitValue:5},
        // {startAfterData:null}
      );
      // console.log(res,'ini hasilnya')
      setDomains(res);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getSubdomain = (verification) => {
    if (verification.name !== verification.apexName) {
      return {
        isSubdomain: true,
        name: verification.name.replaceAll('.' + verification.apexName, ''),
      };
    } else {
      return {
        isSubdomain: false,
        name: verification.name,
      };
    }
  };

  const handleSave = async () => {
    if (!inputDomain.domain_name || /[A-Z]/.test(inputDomain.domain_name)) {
      toast({
        title: 'Please fill the name of domain with a lowercase!',
        duration: 3000,
        status: 'warning',
      });
    } else {
      if (!globalState.currentProject) {
        toast({
          title: 'Please fill the project',
          duration: 3000,
          status: 'warning',
        });
      } else {
        setIsLoading(true);
        // Rules domain di comment di firestorate rule
        const response = await createDomainCustom(inputDomain);
        setIsLoading(false);
        if (response.status) {
          let domainData = response.data;
          let domain = await getSingleDocumentFirebase(
            'domains',
            globalState.currentProject
          );
          if (domain === undefined || !domain?.domain) {
            domain = { domain: [inputDomain.domain_name] };
          } else {
            domain.domain?.push(inputDomain.domain_name);
          }
          await setDocumentFirebase(
            'domains',
            globalState?.currentProject,
            domain
          );

          domainData.domain = inputDomain.domain_name;
          domainData.companyId = globalState.currentCompany;
          domainData.projectId = globalState?.currentProject;
          domainData.projectVercel = 'landingpageview';
          addDocumentFirebase(
            'domain_landing_page',
            domainData,
            globalState.currentCompany
          );
          if (domainData.verification !== undefined) {
            setTextVerif(domainData.verification[0]);
          } else {
            if (domainData.name !== domainData.apexName) {
              const subdomain = domainData.name.replaceAll(
                '.' + domainData.apexName,
                ''
              );
              setData({ ...data, verif: subdomain, isSubdomain: true });
            } else {
              setData({ ...data, verif: domainData.name, isSubdomain: false });
            }
          }
          toast({
            title: 'Success save domain',
            duration: 3000,
            status: 'warning',
          });
          setIsComplete(true);
        } else {
          toast({
            title: 'Error',
            duration: 3000,
            status: 'An Error Occured',
          });
        }
      }
    }
  };

  useEffect(() => {
    getDomains();

    return () => {
      setDomains();
    };
  }, []);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Domains</Heading>
        <Spacer />
        <Button colorScheme="green" onClick={() => modalDomain.onOpen()}>
          Add Domain
        </Button>
      </HStack>

      <Container>
        <TableContainer shadow="base">
          <Table variant="striped">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>Domain</Th>
                <Th>Server</Th>
                <Th>Settings</Th>
              </Tr>
            </Thead>
            <Tbody>
              {domains?.map((x, i) => (
                <Tr key={i}>
                  <Td>
                    <HStack>
                      <Text>{x.domain}</Text>
                      <a
                        href={`https://${x.domain}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ExternalLinkIcon />
                      </a>
                    </HStack>
                  </Td>
                  <Td>
                    <Image
                      boxSize="25px"
                      src="https://www.svgrepo.com/show/327408/logo-vercel.svg"
                    />
                  </Td>
                  <Td>
                    {x.verification !== undefined ? (
                      <Box fontSize="2xs">
                        <Text>{x.verified ? 'verified' : 'unverified'}</Text>
                        <Text>Type: {x.verification[0].type}</Text>
                        <Text>Name: _vercel</Text>
                        <Text>Value: {x.verification[0].value}</Text>
                      </Box>
                    ) : getSubdomain(x).isSubdomain ? (
                      <Box fontSize="2xs">
                        <Text>{x.verified ? 'verified' : 'unverified'}</Text>
                        <Text>Type: CNAME</Text>
                        <Text>Name: {getSubdomain(x).name}</Text>
                        <Text>Value: cname.vercel-dns.com.</Text>
                      </Box>
                    ) : (
                      <Box fontSize="2xs">
                        <Text>{x.verified ? 'verified' : 'unverified'}</Text>
                        <Text>Type: A</Text>
                        <Text>Name: @</Text>
                        <Text>Value: 76.76.21.21</Text>
                      </Box>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>

      <Modal isOpen={modalDomain?.isOpen} onClose={modalDomain.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Domain</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading fontSize="md" mb={4}>
              Domain Name
            </Heading>
            <Input
              m="1"
              type="text"
              placeholder="domain name ex: mydomain.com"
              onChange={(e) =>
                setInputDomain({ ...inputDomain, domain_name: e.target.value })
              }
            />
            {/* <FormLabel mt={3} mb={2}>
              Select your project
            </FormLabel>
            <Select
              m="1"
              placeholder="Project"
              onChange={(e) => setData({ ...data, projectId: e.target.value })}
            >
              {globalState?.projects?.map((x, i) => (
                <option key={i} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Select> */}

            {/* <FormLabel mt={3} mb={2}>
              Select project vercel
            </FormLabel>
            <Select
              m="1"
              placeholder="Project Vercel"
              onChange={(e) =>
                setData({ ...data, projectVercel: e.target.value })
              }
            >
              <option value="pageview">Deoapp Pageview</option>
              <option value="domainview-react">Deoapp Domainview React</option>
            </Select> */}
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme="green"
              onClick={() => handleSave()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MarketingDomainPage;
