import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import useMarketingStore from "../../Hooks/Zustand/MarketingStore";
import axios from "axios";

function MarketingFacebookAddAudiencePage() {
  const navigate = useNavigate();
  const marketingState = useMarketingStore();

  const steps = [
    { title: "Campaign", description: "Create Campaign" },
    { title: "Ads", description: "Create Ads" },
    { title: "Audience", description: "Select Audience" },
    {
      title: "Budget & Strategies",
      description: "Set your budget & strategies",
    },
    { title: "Publish", description: "Review & publish" },
  ];

  console.log(marketingState);

  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={3}
        bg={"white"}
        p={5}
        borderRadius={"md"}
        shadow={"base"}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>
                {step.description}
              </StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Heading textAlign="center">Audience </Heading>

      <Box>
        <Container p="5" mt="5" shadow="base" bg={"white"} borderRadius={"md"}>
          <FormControl>
            <FormLabel>Custom Audience</FormLabel>
            <Select
              value={marketingState?.targeting?.customAudience}
              onChange={(e) =>
                marketingState?.setTargeting({
                  customAudience: e.target.value,
                })
              }
              defaultValue="include"
              maxW="max-content"
            >
              <option>include</option>
              <option>exclude</option>
            </Select>
          </FormControl>

          <HStack my={3}>
            <Box>
              <FormLabel>Age</FormLabel>
              <HStack>
                {/* <Select defaultValue="include" maxW="max-content">
                  <option>include</option>
                  <option>exclude</option>
                </Select> */}
                <Input
                  onChange={(e) =>
                    marketingState?.setTargeting({ ageStart: e?.target.value })
                  }
                  type="number"
                />
                <Text>-</Text>
                {/* <Select defaultValue="include" maxW="max-content">
                  <option>include</option>
                  <option>exclude</option>
                </Select> */}
                <Input
                  onChange={(e) =>
                    marketingState?.setTargeting({ ageEnd: e?.target.value })
                  }
                  type="number"
                />
              </HStack>
            </Box>
            <Box>
              <FormLabel>Sex</FormLabel>
              <HStack>
                <Select
                  value={marketingState?.targeting?.sex}
                  onChange={(e) =>
                    marketingState?.setTargeting({
                      sex: e.target.value,
                    })
                  }
                  defaultValue="include"
                  maxW="max-content"
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Both</option>
                </Select>
              </HStack>
            </Box>
          </HStack>

          <FormControl>
            <FormLabel>Detailed Targetting</FormLabel>
            <Input
              value={marketingState?.targeting?.detailedTargeting}
              onChange={(e) =>
                marketingState?.setTargeting({
                  detailedTargeting: e.target.value,
                })
              }
              type="text"
            />
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
          </FormControl>

          <FormControl my={3}>
            <FormLabel>Devices</FormLabel>
            <Input
              value={marketingState?.targeting?.devices}
              onChange={(e) =>
                marketingState?.setTargeting({
                  devices: e.target.value,
                })
              }
              type="text"
            />
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
          </FormControl>

          <FormControl>
            <Stack spacing={1}>
              <FormLabel>Duration</FormLabel>

              <HStack>
                <Box>
                  <Text>Start Date</Text>
                  <Input
                    value={marketingState?.targeting?.durationStart}
                    onChange={(e) =>
                      marketingState?.setTargeting({
                        durationStart: e.target.value,
                      })
                    }
                    type="datetime-local"
                  />
                </Box>
                <Box>
                  <Text>End Date</Text>
                  <Input
                    value={marketingState?.targeting?.durationEnd}
                    onChange={(e) =>
                      marketingState?.setTargeting({
                        durationEnd: e.target.value,
                      })
                    }
                    type="datetime-local"
                  />
                </Box>
              </HStack>
            </Stack>
          </FormControl>
          <Button
            my={3}
            w="full"
            colorScheme="green"
            onClick={() => navigate("/marketing/meta/facebook/add/budget")}
          >
            Next
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default MarketingFacebookAddAudiencePage;
