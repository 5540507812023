import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  SimpleGrid,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  Tag,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import {
  MdEmail,
  MdAccountCircle,
  MdOutlinePhoneIphone,
  MdLock,
  MdVisibilityOff,
  MdVisibility,
  MdGraphicEq,
  MdStoreMallDirectory,
  MdPriceCheck,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import logo from "../../assets/1.png";
import { auth, db } from "../../Config/firebase";
import { Rupiah } from "../../Utils/NumberUtils";
import Tap from "@tapfiliate/tapfiliate-js";
import {
  addDocumentFirebase,
  getCollectionWhereFirebase,
} from "../../Api/firebaseApi";
import { authAnggaran } from "../../Config/firebaseAnggaran";
import { createCompany, createUserCompany } from "../../Api/firebaseFunction";
import { BsCheckLg } from "react-icons/bs";
import DynamicButton from "../../Components/Buttons/DynamicButton";
import _axios from "../../Api/AxiosBarrier";

function SignUpPage() {
  const [name, setName] = useState("");
  const [nohp, setNohp] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [coupon, setCoupon] = useState();
  const [discount, setDiscount] = useState();
  const [features, setFeatures] = useState({
    value: 0,
    sales: 150000000,
    customers: 1000,
    hr: 10,
    warehouse: 10000,
    outlet: 3,
    price: 1500000,
  });

  const pricing = [
    {
      value: 0,
      kanban: "UNLIMITED",
      user: "UNLIMITED",
      mindMap: "UNLIMITED",
      sales: 150000000,
      customers: 1000,
      hr: 10,
      warehouse: 10000,
      outlet: 3,
      price: 1500000,
    },
    // { value: 20, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 400000000, customers: 5000, hr: 25, warehouse: 10000, outlet: 3, price: 4000000 },
    // { value: 40, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 750000000, customers: 10000, hr: 50, warehouse: 10000, outlet: 3, price: 7500000 },
    // { value: 60, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 1500000000, customers: 30000, hr: 100, warehouse: 10000, outlet: 3, price: 12000000 },
    // { value: 80, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 3000000000, customers: 50000, hr: 200, warehouse: 10000, outlet: 3, price: 20000000 },
    // { value: 100, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 5000000000, customers: 100000, hr: 300, warehouse: 10000, outlet: 3, price: 28000000 },
    // { value: 120, kanban: "UNLIMITED", user: "UNLIMITED", mindMap: "UNLIMITED", sales: 5000000000, customers: "UNLIMITED", hr: "UNLIMITED", warehouse: "UNLIMITED", outlet: "UNLIMITED" },
  ];

  const navigate = useNavigate();
  const toast = useToast();

  const handleSlider = (value) => {
    setSliderValue(value);
    const findData = pricing.find((x) => x.value === value);
    setFeatures(findData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSignup = () => {
    const displayName = name;

    if (
      (email === "" && password === "" && nohp === "" && name === "") ||
      password !== confirmPassword
    )
      return toast({
        title: "Something Wrong",
        description: "check your email, password, data",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-end",
      });

    if (email !== "" && password !== "" && nohp !== "" && name !== "") {
      try {
        setLoading(true);

        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            await updateProfile(auth.currentUser, {
              displayName,
            });
            sendEmailVerification(auth.currentUser);

            // Signed in
            const user = userCredential.user;
            if (user) {
              await setDoc(doc(db, "users", user.uid), {
                name: name,
                keyword_name: name.toLowerCase().split(" ").join(""),
                email: user.email,
                createdAt: new Date(),
              });

              //create company with user
              const newUser = { uid: user.uid, companyName: companyName };
              if (discount) {
                newUser.coupon = coupon;
                newUser.discount = discount;
              }
              await createCompany(newUser);

              if (discount) {
                await _axios.post("tapfiliate-postData", {
                  param: `customers/`,
                  data: {
                    coupon: coupon,
                    customer_id: user?.uid,
                    status: "trial",
                    meta_data: {
                      name: name,
                      company: companyName,
                    },
                  },
                });
              }

              // payment with Imam backend
              // const userCompany = {
              //   "is_production": false,
              //   "package_code": "p_1500k",
              //   "company_name": companyName,
              //   "user_uid": auth.currentUser.uid
              // }
              // const resultUser = await createUserCompany(userCompany)
              // console.log(resultUser,'ini hasil dari createUserCompany')

              setLoading(false);
              navigate("/", { replace: true });

              toast({
                title: "Success Create",
                description: `Success Create account ${user.displayName}`,
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "top-right",
              });
            }
          })
          .catch((error) => {
            toast({
              title: "Something Wrong",
              description: `It looks like you don't have account in your browser, please signup and reload this page / ${error.message}`,
              status: "error",
              duration: 10000,
              isClosable: true,
              position: "top-right",
            });
            setLoading(false);
          });
      } catch (error) {
        toast({
          title: "Something Wrong",
          description: error,
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top-end",
        });
        setLoading(false);
      }
    } else {
      toast({
        title: "Something Wrong",
        description: "check your data",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-end",
      });
    }
  };

  const handleCoupon = () => {
    getCollectionWhereFirebase("coupons", "title", "==", coupon)
      .then((x) => {
        console.log(x[0]);
        setDiscount(x[0]);
      })
      .catch((err) => console.log(err.message));
  };

  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <>
      <Stack
        pt={20}
        spacing={10}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={"center"}
        justifyContent="center"
      >
        <Stack position={"absolute"} top={5} right={5}>
          <DynamicButton
            action="custom"
            title="Go to Recruitment"
            color="blue"
            variant={"solid"}
            onClick={() => navigate("/signup/recruitment")}
          />
        </Stack>

        {/* <Text>{activeStep}</Text> */}
        <SimpleGrid columns={[1, 1, 2]} gap="5">
          <Stack
            w={["100%", null, width / 3]}
            spacing={3}
            p={5}
            bgColor="blackAlpha.500"
            shadow={"md"}
            borderRadius={"xl"}
            // _hover={{ transform: "scale(1.1)", shadow: "xl" }}
            transition={"0.2s ease-in-out"}
            alignItems={"center"}
            justifyContent="center"
            textColor="white"
          >
            <Stack alignItems={"center"} justifyContent="center" spacing={2}>
              <Text fontSize={"lg"} color="gray.500" textAlign={"center"}>
                Pick the right plan for your business
              </Text>
              {/* <AppSponsor /> */}
              <Heading textAlign={"center"} size="lg">
                My monthly sales is up to{" "}
              </Heading>

              <HStack>
                <Tag bgColor={"blue.600"} p={2}>
                  <Heading fontWeight={"extrabold"} color={"white"}>
                    {Rupiah(features?.sales)}
                    {features?.value === 120 ? "+" : ""}
                  </Heading>
                </Tag>
                <Heading size="md">All in</Heading>
              </HStack>
            </Stack>
            {/* <Slider defaultValue={20} min={0} max={120} step={20} onChange={(val) => handleSlider(val)}>
              <SliderTrack bg='red.100'>
                <Box position='relative' right={10} />
                <SliderFilledTrack bg='tomato' />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box color='tomato' as={MdGraphicEq} />

              </SliderThumb>
            </Slider> */}

            <Stack spacing={5} width="full">
              <Heading textAlign="center" fontSize="lg">
                {features.value !== 120
                  ? Rupiah(features.price) + "/month"
                  : "Call Us"}
              </Heading>
              <Text textAlign="center" m="1">
                <Highlight
                  query={
                    parseFloat((features.price / features.sales) * 100).toFixed(
                      2
                    ) + "%"
                  }
                  styles={{ px: "2", py: "1", rounded: "full", bg: "red.100" }}
                >
                  {features.value !== 120
                    ? parseFloat(
                        (features.price / features.sales) * 100
                      ).toFixed(2) + "% from your sales"
                    : ""}
                </Highlight>
              </Text>
              <Stack
                bgColor={"blue.600"}
                p={5}
                color="white"
                borderRadius={"lg"}
              >
                <HStack>
                  <BsCheckLg color="white" />
                  <Text color={"gray.100"}>Management</Text>
                  <Spacer />
                  <Text fontWeight={"bold"}>UNLIMITED</Text>
                </HStack>
                <HStack>
                  <BsCheckLg color="white" />
                  <Text color={"gray.100"}>Kanban</Text>
                  <Spacer />
                  <Text fontWeight={"bold"}>UNLIMITED</Text>
                </HStack>
                <HStack>
                  <BsCheckLg color="white" />
                  <Text color={"gray.100"}>Mind Map</Text>
                  <Spacer />
                  <Text fontWeight={"bold"}>UNLIMITED</Text>
                </HStack>
                <HStack>
                  <BsCheckLg color="white" />
                  <Text color={"gray.100"}>Flow Chart</Text>
                  <Spacer />
                  <Text fontWeight={"bold"}>UNLIMITED</Text>
                </HStack>
                <HStack>
                  <BsCheckLg color="white" />

                  <Text color={"gray.100"}>
                    HRIS (Attendance, Payroll, ext)
                  </Text>
                  <Spacer />
                  <Text fontWeight={"bold"}>UNLIMITED</Text>
                </HStack>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={"center"} justifySelf="start">
            <Stack
              w={["90%", null, width / 3]}
              spacing={3}
              p={10}
              bgColor="blackAlpha.500"
              shadow={"md"}
              borderRadius={"xl"}
              // _hover={{ transform: "scale(1.1)", shadow: "xl" }}
              transition={"0.2s ease-in-out"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Box>
                <Image
                  w="200px"
                  borderRadius={20}
                  src={logo}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    children={
                      <MdStoreMallDirectory
                        name="email"
                        size={24}
                        color="black"
                      />
                    }
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Company Name"
                    fontSize={"sm"}
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    children={<MdAccountCircle size={24} color="black" />}
                  />
                  <Input
                    placeholder="Full name"
                    fontSize={"sm"}
                    type="text"
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    children={<MdOutlinePhoneIphone size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Phone number"
                    fontSize={"sm"}
                    type="number"
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setNohp(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Email"
                    fontSize={"sm"}
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "90%",
                    md: "275",
                  }}
                >
                  <InputLeftAddon
                    children={<MdLock size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Password"
                    fontSize={"sm"}
                    type={showPassword ? "text" : "password"}
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "90%",
                    md: "275",
                  }}
                >
                  <InputLeftAddon
                    children={<MdLock size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Confirm password"
                    fontSize={"sm"}
                    id="password"
                    type={showConfirmPassword ? "text" : "password"}
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSignup();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showConfirmPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "90%",
                    md: "275",
                  }}
                >
                  <InputLeftAddon
                    children={<MdPriceCheck size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="coupon code"
                    fontSize={"sm"}
                    type="text"
                    bgColor={"white"}
                    color={"blackAlpha.700"}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <InputRightElement></InputRightElement>
                </InputGroup>
                {coupon && !discount ? (
                  <Button
                    colorScheme="green"
                    w={{
                      base: "90%",
                      md: "275",
                    }}
                    onClick={() => handleCoupon()}
                  >
                    Check coupon
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>

              <Stack textColor="white">
                <Heading fontSize="lg">
                  {features.value !== 120
                    ? Rupiah(features.price) + "/month"
                    : "Call Us"}
                </Heading>
                {discount ? (
                  <Box>
                    <Text textAlign="center">
                      Discount {discount.discountRate}% /month
                    </Text>
                    <Text textAlign="center">
                      {" "}
                      {Rupiah(
                        features.price -
                          features.price * (discount.discountRate / 100)
                      )}{" "}
                      /month
                    </Text>
                  </Box>
                ) : (
                  <></>
                )}
              </Stack>

              <Stack pt={5}>
                {loading ? (
                  <Spinner size={"sm"} />
                ) : (
                  <Button
                    alignItems={"center"}
                    justifyContent="center"
                    size={"sm"}
                    bgColor={"white"}
                    onClick={() => handleSignup()}
                  >
                    <Text color={"black"} fontSize="xs" fontWeight="bold">
                      CREATE ACCOUNT
                    </Text>
                  </Button>
                )}
              </Stack>

              <Spacer />

              <HStack space={1}>
                <Text color={"gray.400"} fontSize="sm">
                  Back to
                </Text>
                <Text
                  color={"gray.400"}
                  fontWeight="bold"
                  fontSize="sm"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Stack>
    </>
  );
}

export default SignUpPage;
