import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  VStack,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../Config/firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import BackButtons from "../../Components/Buttons/BackButtons";

const PatientHistoryDetailPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [userHistoryData, setUserHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const getUserData = async () => {
    try {
      console.log(id);
      const userDocRef = doc(db, "customers", id);
      const userDocSnapshot = await getDoc(userDocRef);

      const take = userDocSnapshot.data();
      if (userDocSnapshot.exists()) {
        setUserData(userDocSnapshot.data());
      }

      const userHistoryCollectionRef = collection(
        db,
        "booking-list-deoapp-clinic"
      );

      const userHistoryQuerySnapshot = await getDocs(
        query(
          userHistoryCollectionRef,
          where("phoneNumber", "==", take.phoneNumber)
        )
      );

      const historyData = userHistoryQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUserHistoryData(historyData);

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  console.log(userData);
  console.log(userHistoryData, "data");
  
  return (
    <>
      <BackButtons />
      <Heading size={"md"} fontWeight="bold">
        Patient History Detail
      </Heading>
      <Box
        p="4"
        boxShadow="lg"
        borderRadius="lg"
        bg={useColorModeValue("white", "gray.800")}
      >
        <VStack spacing="4">
          <Box textAlign="center">
            <Avatar
              size="2xl"
              name={userData.displayName}
              src={userData.avatar}
              mx="auto"
            />
            <Text fontSize="2xl" fontWeight="bold" mt="2" color="teal.500">
              {userData.displayName}
            </Text>
            <Text>Email: {userData.email}</Text>
            <Text>No HP: {userData.phoneNumber}</Text>
          </Box>

          <Box>
            <Text fontSize="2xl" fontWeight="bold" color="teal.500">
              History Treatment
            </Text>
            <Table variant="simple" borderWidth="1px" mt="2" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Waktu Treatment</Th>
                  <Th>Jenis Treatment</Th>
                  <Th>Lokasi Treatment</Th>
                  <Th>Dokter</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userHistoryData?.map((history) => (
                  <Tr key={history.id}>
                    <Td>{history.time}</Td>
                    <Td>{history.treatment}</Td>
                    <Td>{history.branch}</Td>
                    <Td>{history.doctor}</Td>
                    <Td>
                      <Button
                        onClick={() => console.log(history.id)}
                        colorScheme="teal"
                        size="sm"
                      >
                        Detail
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default PatientHistoryDetailPage;
