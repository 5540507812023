import {
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Text,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import { db } from "../../Config/firebase";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { CloseIcon } from "@chakra-ui/icons";

const DoctorSchudule = () => {
  const params = useParams();
  const [data, setData] = useState("");
  const [dataInput, setDataInput] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dokterCatatan, setDokterCatatan] = useState("");
  const [skincare, setSkincare] = useState("");
  const [skincareRekomendasi, setSkincareRekomendasi] = useState("");
  const [skincareList, setSkincareList] = useState([]);
  const [tanggalBerikutnya, setTanggalBerikutnya] = useState("");
  const [modalStatus, setModalStatus] = useState({});
  const [selectedVisitId, setSelectedVisitId] = useState(null);

  const getSkincare = async () => {
    try {
      const collectionRef = collection(db, "skincare-deoapp-clinic");

      const querySnapshot = await getDocs(collectionRef);

      const data1 = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSkincare(data1);
      console.log("Data dari koleksi:", data1);
    } catch (error) {}
  };

  const handleSkincareSubmit = () => {
    if (skincareRekomendasi) {
      setSkincareList((prevList) => [...prevList, skincareRekomendasi]);
      setSkincareRekomendasi("");
    }
  };

  const getData = async () => {
    try {
      const collectionRef = collection(db, "booking-list-deoapp-clinic");

      const querySnapshot = await getDocs(
        query(
          collectionRef,
          where("status", "==", "proses"),
          where("doctorId", "==", params.id)
        )
      );

      const data1 = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setData(data1);
      console.log("Data dari koleksi:", data1);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const handleRemoveSkincare = (index) => {
    const updatedSkincareList = [...skincareList];
    updatedSkincareList.splice(index, 1);
    setSkincareList(updatedSkincareList);
  };

  const handleModal = (x) => {
    setSelectedVisitId(x);
    onOpen();
  }

console.log(selectedVisitId,"id yang doi klik")

const handleSave = async (visitId) => {
  try {
    const dataUpload = {
      ...dataInput,
      skincareRecomendation: skincareList,
    };

    const userRef = doc(db, "booking-list-deoapp-clinic", visitId);

    // Update dokumen dengan data baru
    await updateDoc(userRef, {
      ...dataUpload,
      status: "selesai", 
    });

    onClose();
    console.log("Data berhasil diperbarui");
  } catch (error) {
    console.error("Gagal mengambil data:", error.message);
  }
};

  console.log(dataInput, "data");
  useEffect(() => {
    getData();
    getSkincare();
  }, []);
  return (
    <>
      <BackButtons />
      <Flex justifyContent="space-between" padding={"1%"}>
        <Heading>Doctor History</Heading>
      </Flex>
      <Table size="sm" mt={4} variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Nama Pasien</Th>
            <Th>Clinic</Th>
            <Th>Treatment</Th>
            <Th>Waktu Kunjungan</Th>
            <Th>Status Kunjungan</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((visit, index) => (
              <Tr key={index}>
                <Td>{visit.name}</Td>
                <Td>{visit.branch}</Td>
                <Td>{visit.treatment}</Td>
                <Td>{visit.time}</Td>
                <Td>
                  <Select>
                    <option value={visit.status}>
                      {visit.status.charAt(0).toUpperCase() +
                        visit.status.slice(1)}
                    </option>
                    <option value="selesai">Selesai</option>
                  </Select>
                </Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    onClick={() => {
                      handleModal(visit.id)
                    }}
                  >
                    Note
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="5">No data available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedVisitId(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detail Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Konten Modal */}
            <Text fontWeight="bold">Catatan Dokter:</Text>
            <Textarea
              onChange={(e) =>
                setDataInput({ ...dataInput, note: e.target.value })
              }
            />

            <Text fontWeight="bold" mt={4}>
              Skincare Rekomendasi:
            </Text>
            <Select
              placeholder="Select Product"
              onChange={(e) => setSkincareRekomendasi(e.target.value)}
            >
              {Array.isArray(skincare) &&
                skincare.map((category) => (
                  <option key={category.title} value={category.title}>
                    {category.title}
                  </option>
                ))}
            </Select>
            <Button onClick={handleSkincareSubmit} mt={2}>
              Tambahkan ke List
            </Button>

            {/* List Skincare Rekomendasi */}
            <Text fontWeight="bold" mt={4}>
              List Skincare Rekomendasi:
            </Text>
            <VStack align="start" spacing={2}>
              {skincareList?.map((item, index) => (
                <HStack key={index}>
                  <Text>{item}</Text>
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    onClick={() => handleRemoveSkincare(index)}
                  />
                </HStack>
              ))}
            </VStack>

            {/* Tanggal Treatment atau Konsultasi Berikutnya */}
            <Text fontWeight="bold" mt={4}>
              Tanggal Treatment/Konsultasi Berikutnya:
            </Text>
            <Input
              type="date"
              onChange={(e) =>
                setDataInput({
                  ...dataInput,
                  nextAppointment: e.target.value,
                })
              }
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleSave(selectedVisitId)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DoctorSchudule;
