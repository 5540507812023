import React from "react";

import UsersPage from "../Pages/Configuration/UsersPage";
import RolesPage from "../Pages/Configuration/RolesPage";
import ProjectPage from "../Pages/Configuration/ProjectPage";
import ProjectNewPage from "../Pages/Configuration/ProjectNewPage";
import ConfigurationPage from "../Pages/Configuration/ConfigurationPage";

const ConfigurationRouter = [
  {
    path: "/configuration",
    element: <ConfigurationPage />,
  },
  {
    path: "/configuration/project",
    element: <ProjectPage />,
  },
  {
    path: "/configuration/project/:id",
    element: <ProjectNewPage />,
  },

  {
    path: "/configuration/users",
    element: <UsersPage />,
  },
  {
    path: "/configuration/role",
    element: <RolesPage />,
  },
];

export default ConfigurationRouter;
