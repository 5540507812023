import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  HStack,
  useDisclosure,
  Spacer,
  Text,
  Stack,
  Tooltip,
  useToast,
  Image,
  Tag,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import useKanbanStore from "../../Hooks/Zustand/kanbanStore";
import { useEffect, useState, useRef } from "react";
import useUserStore from "../../Hooks/Zustand/Store";
import axios from "axios";
import { FiTag } from "react-icons/fi";
import {
  addDocumentFirebase,
  addDocumentFirebaseV2,
  arrayUnionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Api/firebaseApi";
import { decryptToken } from "../../Utils/encrypToken";
import UploadFileToDropbox from "../DropBox/UploadFileToDropbox";

import { auth } from "../../Config/firebase";
import { useDrag } from "react-dnd";
import moment from "moment";
import SingleCalendarComponent from "./SingleCalendarComponent";
import CardFile from "../Accounting/CardFile";
import ProgressCircle from "../Progress/ProgressCircle";
import { FcFolder } from "react-icons/fc";

function TaskCardComponent(props) {
  const [index, setIndex] = useState(0);
  const param = useParams();
  const navigate = useNavigate();
  const { onClose } = useDisclosure();
  const [asignee, setAsignee] = useState(
    props.task?.asignee ? props.task.asignee : []
  );
  const { userDisplay } = useKanbanStore();
  const setSomeFunction = useKanbanStore((state) => state.setFunctionAddData);
  const setTaskData = useKanbanStore((state) => state.setTaskData);
  const globalState = useUserStore();

  const [progress, setProgress] = useState(0)



  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef();



  const toast = useToast();

  const middlewarePic = () => {
    toast({
      status: "warning",
      duration: 9000,
      title: "You don't have access to do this card",
      isClosable: true,
    });
  };

  const handleAddPoint = async (assignee, point, task, status) => {
    const seen = {};
    const result = [];

    if (!assignee || assignee.length === 0) {
      return toast({
        status: "warning",
        duration: 9000,
        title: "You don't have assignee for this point",
        isClosable: true,
      });
    }

    const uniqueAssignee = Array.from(new Set(assignee)); // Remove duplicate assignees

    const promises = uniqueAssignee?.map(async (uid) => {
      if (uid in seen) {
        return;
      }

      const userData = userDisplay.find((user) => user.id === uid);

      if (userData) {
        const newObj = {
          uid: uid,
          email: userData?.email,
          title: props?.kanbanData?.title,
          task: task,
          filesId: props?.task?.filesId,
          score: parseInt(point),
          type: 'kanban',
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          time_backlog_at: moment(new Date(props?.task?.time_backlog_at?.seconds * 1000)).valueOf(),
          time_completed_at: props?.task?.time_completed_at ? moment(new Date(props?.task?.time_completed_at?.seconds * 1000)).valueOf() : moment(new Date()).valueOf(),
          time_from: new Date(props?.task?.createdAt?.seconds * 1000),
          time_from_unix: moment(new Date(props?.task?.createdAt?.seconds * 1000)).valueOf(),
          time_to: props?.task?.time_completed_at ? new Date(props?.task?.time_completed_at?.seconds * 1000) : new Date(),
          time_to_unix: props?.task?.time_completed_at ? moment(new Date(props?.task?.time_completed_at?.seconds * 1000)).valueOf() : moment(new Date()).valueOf(),
          createdAt: new Date(),
          status: "done"
        };




        result.push(newObj);

        const data = {
          collection_name: "files",
          fields: newObj,
        };


        try {
          const res = await axios.post(
            "https://new-apiv2.importir.com/api/general-log/store-deoapp",
            data,
            {
              headers: {
                token:
                  "$2y$10$bN5cOHkcoUR2QaK05xmTrOUHY1vEVmS9ne6h99Jrp7CJJKryMylri",
              },
            }
          );

          if (res?.data?.status === true) {
            const collectionName = "logs";

            try {
              const docID = await addDocumentFirebaseV2(
                collectionName,
                data,
                globalState?.currentCompany
              );
              console.log("ID Dokumen Baru:", docID);
              toast({
                status: "success",
                duration: 9000,
                title: `${userData?.email} get ${point} score from ${task} card`,
                isClosable: true,
              });
            } catch (error) {
              console.log("Terjadi kesalahan:", error);
            }
          } else {
            toast({
              status: "error",
              duration: 9000,
              title: res?.data?.status?.message,
              isClosable: true,
            });
          }
        } catch (error) {
          console.log(error, "ini error");
        }

        seen[uid] = true;
      } else {
        toast({
          status: "warning",
          duration: 9000,
          title: "No matching user found for assignee with UID: " + uid,
          isClosable: true,
        });
      }
    });

    await Promise.all(promises);
  };

  const handleMoveCard = (id, column) => {
    const picProject = props?.kanbanData?.pic;
    const picTask = props?.task?.pic;
    const creator = props?.task?.createdBy;
    const assignee = props?.task?.asignee;
    const score = props?.task?.score;
    const title = props?.task?.title;

    if (column !== "done" && props?.task?.column === "done") {
      return toast({
        status: "warning",
        duration: 9000,
        title: "Cannot put back to review if your task has done",
        isClosable: true,
      });
    }

    if (column !== "backlog" && column !== "todos") {
      if (props?.task?.time_backlog_at === undefined) {
        return toast({
          status: "warning",
          duration: 9000,
          title: "Please make sure to insert your card through the backlog first.",
          isClosable: true,
        });
      }
    }



    if (picProject && picProject.length > 0) {
      const filterPic = picProject.filter((x) => x?.uid === globalState?.uid);

      if (column === "done" && filterPic.length === 0) {
        return middlewarePic();
      }
    }

    if (picTask && picTask.length > 0) {
      const filterPic = picTask.filter((x) => x === globalState?.uid);

      if (column === "done" && filterPic.length === 0) {
        return middlewarePic();
      }
    }

    if (column === "done" && props?.task?.column !== "done") {
      if (!assignee || assignee.length === 0) {
        return toast({
          status: "warning",
          duration: 9000,
          title: "You don't have assignee for this card",
          isClosable: true,
        });
      }


      if (score === 0 || score === undefined) {
        return toast({
          status: "warning",
          duration: 9000,
          title: "You don't have a score for this card",
          isClosable: true,
        });
      }

      if (props?.task?.label === undefined) {
        return toast({
          status: "warning",
          duration: 9000,
          title: "You don't have label status for this card",
          isClosable: true,
        });
      }
    }

    props.setData([
      ...props.columnsData.slice(0, index),
      ...props.columnsData.slice(props.index + 1),
    ]);

    if (column === "backlog" && props?.task?.time_backlog_at === undefined) {

      updateDocumentFirebase("kanban", id, {
        column: column,
        lastUpdated: new Date(),
        time_backlog_at: new Date()
      });


    } else {
      updateDocumentFirebase("kanban", id, {
        column: column,
        lastUpdated: new Date(),
      });
    }


    addDocumentFirebase(
      `kanban/${id}/history`,
      {
        comments: `${auth?.currentUser?.email
          } has moved card to ${column} on ${new Date()}`,
        lastUpdated: new Date(),
      },
      globalState.currentCompany
    );

    if (
      column === "done" &&
      props?.task?.column !== "done" &&
      props?.task?.label === "completed"
    ) {
      handleAddPoint(assignee, score, title, "done");
    }



  };

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "column",
      item: props?.task.id,
      end(item, monitor) {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          const isDropAllowed = dropResult.allowedDropEffect === "move";
          if (isDropAllowed) {
            handleMoveCard(props.task.id, dropResult.name);
          }
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [props.task]
  );



  const onSelectFile = async (arg) => {
    console.log(arg, 'ini arg')
    try {
      const token = await getSingleDocumentFirebase("token", "dropbox");
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        arg,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );

      if (dropboxLink) {
        const collectionName = 'kanban';
        const docName = props.task.id;
        const field = 'files';
        const values = [dropboxLink];

        const result = await arrayUnionFirebase(collectionName, docName, field, values);
        console.log(result); // Pesan toast yang berhasil
        console.log(dropboxLink, 'xxx')

        toast({
          status: "success",
          duration: 9000,
          title: "Success upload your background card.",
          isClosable: true,
        });

      }



    } catch (error) {
      console.log(error, 'ini error')
    }

  }



  useEffect(() => {
    setIndex(props.index);
  }, []);



  return (
    <>
      <Box
        // height='24'
        width="full"
        shadow="base"
        p="2"
        ref={drag}
        style={{ opacity }}
        cursor="pointer"
        onClick={() => {
          navigate(`${props?.task?.id}`, { state: { type: "existing" } });
          setSomeFunction(props?.setData);
          setTaskData(props);
        }}
      >
        {props?.task?.files?.length > 0 ? (
          <Stack shadow={"md"} borderRadius="md">
            {props?.task?.files[props?.task?.files?.length - 1].type === "image" ? (
              <Image src={props?.task?.files[props?.task?.files?.length - 1]?.link} borderRadius="md" objectFit="contain" w="full" alt="www.google.com" />
            ) : (
              <Stack alignItems={"center"} justify="center">
                <FcFolder size={80} />
              </Stack>
            )}
          </Stack>
        ) : (
          <Stack zIndex={100} onChange={onSelectFile} display="none">
            <CardFile IsIcon={true} onChange={onSelectFile} />
          </Stack>

        )}


        <Stack spacing={1} my={2}>
          <Box>
            {props.task?.category && (
              <Tag colorScheme={"blue"} size="sm" fontSize={"xs"}>
                {props?.task?.category}
              </Tag>
            )}
          </Box>
          <Text fontWeight="extrabold">{props.task.title}</Text>

        </Stack>

        {props.task.label ? (
          <Badge
            colorScheme={
              props.task.label === "help"
                ? "yellow"
                : props.task.label === "pending"
                  ? "green"
                  : props.task.label === "blocked"
                    ? "red"
                    : props.task.label === "completed"
                      ? "green"
                      : "gray"
            }
          >
            <HStack>
              <FiTag />
              <Text>{props.task.label}</Text>
            </HStack>
          </Badge>
        ) : (
          <></>
        )}
        <HStack spacing={1}>
          {props.task.dueOn ? (
            <SingleCalendarComponent
              data={props?.task?.dueOn}
              date={moment.unix(props?.task?.dueOn).format("DD")}
              month={moment.unix(props?.task?.dueOn).format("MMM")}
            />
          ) : (
            <></>
          )}
          <Stack
            w="30px"
            borderRadius={"full"}
            h="30px"
            bgColor={props?.task?.score > 0 ? "blue.400" : "red.400"}
            alignItems={"center"}
            justifyContent="center"
          >
            <Text color={"white"} fontSize="sm" fontWeight={"bold"}>
              {props?.task?.score || 0}
            </Text>
          </Stack>

          {props?.task?.createdBy ? (
            <Tooltip
              label={
                userDisplay?.find((z) => z.id === props?.task?.createdBy)?.name
                  ? userDisplay?.find((z) => z.id === props?.task?.createdBy)
                    ?.name
                  : userDisplay?.find((z) => z.id === props?.task?.createdBy)
              }
              aria-label="Name"
            >
              <Avatar
                m="0"
                size="sm"
                name={
                  userDisplay?.find((z) => z.id === props?.task?.createdBy)
                    ?.name
                    ? userDisplay?.find((z) => z.id === props?.task?.createdBy)
                      ?.name
                    : userDisplay?.find((z) => z.id === props?.task?.createdBy)
                      ?.email
                }
                image={
                  userDisplay?.find((x) => x.id === props?.task?.createdBy)
                    ?.image
                }
              />
            </Tooltip>
          ) : (
            <></>
          )}

          <Spacer />

          <AvatarGroup spacing={-3} size="sm" max={1}>
            {props?.task?.asignee ? (
              props.task?.asignee?.sort()?.map((x, i) => {
                let name = props.kanbanData?.usersDisplay?.find(
                  (y) => y.id === x
                )?.name;
                return (
                  <Tooltip key={i} label={name} aria-label="Name">
                    <Avatar size="xs" name={name} />
                  </Tooltip>
                );
              })
            ) : (
              <></>
            )}
          </AvatarGroup>
        </HStack>
        {props?.task?.pic?.length > 0 ? (
          props?.task?.pic.map((x, index) => {
            return (
              <HStack key={index}>
                <Text
                  textAlign="left"
                  fontSize="2xs"
                  fontWeight={500}
                  color="gray.600"
                >
                  PIC
                </Text>
                <Spacer />
                <Text
                  textAlign="right"
                  fontSize="2xs"
                  fontWeight={500}
                  textTransform="capitalize"
                >
                  {userDisplay?.find((y) => y.id === x)?.name}
                </Text>
              </HStack>
            );
          })
        ) : (
          <></>
        )}
        {props?.task?.lastUpdated?.seconds ? (
          <Text textAlign="left" fontSize="2xs">
            Updated: {moment.unix(props?.task?.lastUpdated?.seconds).fromNow()}
          </Text>
        ) : (
          <></>
        )}
        <Text fontSize="3xs">ID: {props.task.id}</Text>
      </Box>

      {
        progress > 0 && progress < 100 ? (
          <Stack p={-10}>
            <ProgressCircle value={progress} />
          </Stack>
        ) : (
          <></>
        )
      }

    </>
  );
}

export default TaskCardComponent;
