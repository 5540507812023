import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Stack, Image, Text } from "@chakra-ui/react";
import DynamicButton from '../Buttons/DynamicButton';

const DynamicTable = ({ header, data, onEdit, onDelete, onCreate, onRead, onCustom, clickableColumns, onColumnClick, ...props }) => {
    const hasData = data && data.length > 0;

    return (
        <Box
            bgColor="white"
            spacing={1}
            borderRadius="xl"
            p={3}
            m={[1, 1, 5]}
            shadow="md"
            overflowX={"auto"}
        >
            <Table variant="striped" colorScheme="gray">
                <Thead>
                    <Tr>
                        {header?.map((column) => (
                            <Th key={column}>{column}</Th>
                        ))}
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                {hasData ? (
                    <Tbody fontSize="sm" >
                        {data?.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                                {header?.map((column, columnIndex) => (
                                    <Td
                                        key={columnIndex}
                                        onClick={() => {
                                            if (clickableColumns?.includes(column) && onColumnClick) {
                                                onColumnClick(column, row);
                                            }
                                        }}
                                        sx={{
                                            cursor: clickableColumns?.includes(column) ? 'pointer' : 'default',
                                            color: clickableColumns?.includes(column) ? 'blue.500' : 'inherit'
                                        }}
                                    >
                                        {column === "image" ? (
                                            <Image src={row[column]} alt="Image" boxSize="50px" objectFit="cover" />
                                        ) : (
                                            row[column]
                                        )}
                                    </Td>
                                ))}
                                <Td>
                                    <Stack flexDirection={"row"} spacing={2}>
                                        {onCustom && (
                                            <DynamicButton color={props.action_color} icon={props.action_icon} action={"custom"} size="sm" onClick={() => onCustom(row)} />
                                        )}
                                        {onEdit && (
                                            <DynamicButton action={"update"} size="sm" onClick={() => onEdit(row)} />
                                        )}
                                        {onDelete && (
                                            <DynamicButton action={"delete"} size="sm" onClick={() => onDelete(row)} />
                                        )}
                                        {onCreate && (
                                            <DynamicButton action={"create"} size="sm" onClick={() => onCreate(row)} />
                                        )}
                                        {onRead && (
                                            <DynamicButton action={"read"} size="sm" onClick={() => onRead(row)} />
                                        )}
                                    </Stack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                ) : (
                    <Tbody>
                        <Stack minH={"50vh"} alignItems="center" justifyContent={"center"}>
                            <Text fontWeight={500} color="gray.600">No data found</Text>
                        </Stack>
                    </Tbody>
                )}
            </Table>
        </Box>
    );
};

export default DynamicTable;
