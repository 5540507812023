import { Avatar, AvatarGroup, Box, Heading, HStack, Input, SimpleGrid, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { DndProvider } from 'react-dnd';
import KanbanColumnsComponent from '../../../Components/Columns/KanbanColumnsComponent'
import { HTML5Backend } from "react-dnd-html5-backend";
import BackButtons from '../../../Components/Buttons/BackButtons';
import { Link } from 'react-router-dom';

function MarketingKolCampaignPage() {
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const data = [
        {title:'Campaign #1',pic:['a','b','c']},
        {title:'Campaign #2',pic:['a','b','c']},
        {title:'Campaign #3',pic:['a','b','c']},
        {title:'JFW',pic:['a','b','c']},
    ]

  return (
    <Box>
        <HStack>
            <BackButtons/>
            <Heading>KOL Campaign</Heading>
            <Spacer/>
            <Input type='text' maxW='sm' size='sm' bgColor='white'/>
        </HStack>

        <SimpleGrid columns={4} gap={3} p='5'>
            {data.map((x,i)=>
                <Box key={i} shadow='base' p='5'>
                    <Link to={`${i}`}>
                        <Heading fontSize='md'>{x.title}</Heading>
                        <AvatarGroup size='md' max={2}>
                            <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                            <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
                            <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                            <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
                            <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
                        </AvatarGroup>
                    </Link>
                </Box>
            )}
        </SimpleGrid>
        
    </Box>
  )
}

export default MarketingKolCampaignPage