import axios from 'axios'
// const baseURL = 'http://localhost:3001/';
const baseURL = "https://asia-southeast2-anggaran-v2.cloudfunctions.net/anggaran/";


export const anggaranApi = async (params,session) =>{
    
    let response = {}
  
    const baseUrl = `${baseURL}${params}`
    const configurationObject = {
        url: baseUrl,
        method: "GET",
    };
    if (session)
        configurationObject.headers = {
            Authorization: `Bearer ${session}`,
        };
    try {
      const resp = await axios(configurationObject)
      response = resp.data
    } catch (error) {
        console.log(error.message);
    }
    return response
}


export const anggaranApiPost = async (params,body,session) =>{
    
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'POST',
    data:body
  }
  if(session)
    configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const getCashFlow = async (params, session) =>{
    
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'GET',
  }

  if(session)
  configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const getCoaByCompany = async (params, session) =>{
    
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'GET',
  }

  if(session)
  configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const getAllCompany = async (params, session) =>{
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'GET',
  }

  if(session)
  configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const getAllProject = async (params, session) =>{
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'GET',
  }

  if(session)
  configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const getAllAdmins = async (params, session) =>{
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'GET',
  }

  if(session)
  configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const completedStatus = async (params,body,session) =>{
    
  let response = {}

  const baseUrl = `${baseURL}${params}`
  const configurationObject = {
    url: baseUrl,
    method: 'POST',
    data:body
  }
  if(session)
    configurationObject.headers={
      Authorization: `Bearer ${session}`,
    }

  try {
    const resp = await axios(configurationObject)
    response = resp.data
  } catch (error) {
    console.log(error.message)
  }
  return response
}

export const anggaranApiDelete = async (params, session) => {
  let response = {};
  const baseUrl = `${baseURL}${params}`;
  const configurationObject = {
      url: baseUrl,
      method: "DELETE",
  };
  if (session)
      configurationObject.headers = {
          Authorization: `Bearer ${session}`,
      };
  try {
      const resp = await axios(configurationObject);
      response = resp.data;
  } catch (error) {
      console.log(error.message);
  }
  return response;
};

export const anggaranApiPut = async (params, body, session) => {
  let response = {};
  const baseUrl = `${baseURL}${params}`;
  const configurationObject = {
      url: baseUrl,
      method: "PUT",
  data: body,
  };
  if (session)
      configurationObject.headers = {
          Authorization: `Bearer ${session}`,
      };
  try {
      const resp = await axios(configurationObject);
      response = resp.data;
  } catch (error) {
      console.log(error.message);
  }
  return response;
};

export const postTax = async (params, body, session) => {
  let response = {};
  const baseUrl = `${baseURL}${params}`;
  const configurationObject = {
      url: baseUrl,
      method: "POST",
      data: body,
  };
  if (session)
      configurationObject.headers = {
          Authorization: `Bearer ${session}`,
      };
  try {
      const resp = await axios(configurationObject);
      response = resp.data;
  } catch (error) {
      console.log(error.message);
  }
  return response;
};

export const postBank = async (params, body, session) => {
  let response = {};
  const baseUrl = `${baseURL}${params}`;
  const configurationObject = {
      url: baseUrl,
      method: "POST",
      data: body,
  };
  if (session)
      configurationObject.headers = {
          Authorization: `Bearer ${session}`,
      };
  try {
      const resp = await axios(configurationObject);
      response = resp.data;
  } catch (error) {
      console.log(error.message);
  }
  return response;
};

export const checkAccountBank = async (params) => {
  let response = {};
  const baseUrl = `${params}`;
  const configurationObject = {
      url: baseUrl,
      method: "GET",
  };
  try {
      const resp = await axios(configurationObject);
      response = resp.data;
  } catch (error) {
      console.log(error.message);
  }
  return response;
};
