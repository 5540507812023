import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdOutlinePermMedia } from "react-icons/md";
import DropboxUploader from "../../Components/DropBox/DropboxUploader";
import RichTextEditor from "../../Components/Quill/RichTextEditor";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";

const DoctorEditPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const modalTag = useDisclosure();
  const globalState = useUserStore();
  const modalUpload = useDisclosure();
  const params = useParams();
  const [newTreatment, setNewTreatment] = useState("");
  const [content, setContent] = useState("");
  const [treatment, setTreatment] = useState([]);
  const [dataInput, setDataInput] = useState({
    treatment: [],
  });
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);

  const getDoctor = async () => {
    const documentRef = doc(db, "doctor-deoapp-clinic", params.id);
    try {
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const dataEdit = documentSnapshot.data();
        console.log("Data dokumen:", dataEdit);

        // Set dataInput dengan dataEdit sebagai defaultValue
        setDataInput({
          name: dataEdit.name || "",
          hp: dataEdit.hp || "",
          treatment: dataEdit.treatment || [],
        });
        setImageUrl(dataEdit.thumbnail || "");
        setAvailableSlots(dataEdit.avaible || []);
      } else {
        console.log("Dokumen tidak ditemukan.");
      }
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const getTreatment = async () => {
    const collectionRef = collection(db, "treatment-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTreatment(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const contentChange = (value) => {
    setContent(value);
  };

  const generateSchedule = () => {
    const formattedDay =
      selectedDay ||
      selectedDay.toLocaleDateString("id-ID", { weekday: "long" });

    const startHour = parseInt(selectedTime.slice(0, 2));
    const endHour = startHour + 1;

    const formattedSchedule = `${formattedDay} ${selectedTime}-${endHour
      .toString()
      .padStart(2, "0")}:00 WIB`;

    setAvailableSlots([...availableSlots, formattedSchedule]);
  };

  const handleSelectSlot = (index) => {
    setSelectedSlotIndex(index);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...availableSlots];
    updatedSlots.splice(index, 1);
    setAvailableSlots(updatedSlots);
    setSelectedSlotIndex(null);
  };

  console.log(availableSlots, "avaible");

  const handleSave = async () => {
    try {
      const docRef = doc(db, "doctor-deoapp-clinic", params.id);
      let data = {
        ...dataInput,
        thumbnail: imageUrl,
        avaible: availableSlots,
        createdAt: serverTimestamp(),
        type: "doctor",
      };

      await updateDoc(docRef, data);

      if (
        Array.isArray(dataInput.treatment) &&
        dataInput.treatment.length > 0
      ) {
        for (const treatment of dataInput.treatment) {
          const treatmentDocRef = doc(
            db,
            "treatment-deoapp-clinic",
            treatment.id
          );

          const treatmentDocSnapshot = await getDoc(treatmentDocRef);

          if (treatmentDocSnapshot.exists()) {
            const existingData = treatmentDocSnapshot.data();

            const updatedData = {
              ...existingData,
              doctor: [
                ...(existingData.doctor || []),
                {
                  id: docRef.id,
                  name: dataInput.name,
                },
              ],
            };

            await updateDoc(treatmentDocRef, updatedData);
          }
        }
      }

      console.log("Data berhasil ditambahkan dengan ID:", docRef.id);

      toast({
        title: "Deoapp Clinic",
        description: "Doctor Updated!",
        status: "success",
        duration: 1000,
      });

      setTimeout(() => {
        navigate("/booking/list-doctor");
      }, 1000);
    } catch (error) {
      console.error(error);

      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });

      toast({
        title: "Which project you want to post this article to?",
        description:
          "Please select project from the sidebar on the left and make sure to fill the content",
        isClosable: true,
        duration: 1000,
        status: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTag = () => {
    let arr = [...dataInput.treatment];
    const selectedTreatment = treatment.find((t) => t.id === newTreatment);

    if (selectedTreatment) {
      arr.push({
        id: selectedTreatment.id,
        title: selectedTreatment.title,
      });
      setDataInput({
        ...dataInput,
        treatment: arr,
      });
    }
  };

  const handleDeleteTag = (index) => {
    let updatedCategories = [...dataInput.treatment];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      treatment: updatedCategories,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    // if (dataInput?.title === "" || dataInput?.title === undefined) {
    //   toast({
    //     status: "warning",
    //     title: " Deoapp Clinic",
    //     description: "Please input title first",
    //     duration: 2000,
    //   });
    // } else {
    if (newFiles.length) {
      const newFileArray = [];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);

          if (i === 0) {
            setImageUrl(reader.result);
          }
        };
      }
      setFilesImage(newFiles);
    }
    // }
  };

  const openModal = () => {
    setModalUploadOpen(true);
  };

  function closeModal() {
    setModalUploadOpen(false);
  }
  console.log(dataInput, "data");
  console.log(imageUrl, "thumbnail");
  useEffect(() => {
    getDoctor();
    getTreatment();
  }, []);

  return (
    <>
      <Flex direction="column" mx={5} my={10}>
        {/* Bagian Atas */}
        <Box mb={5}>
          <BackButtons />
          <Heading fontSize="2xl" mt={5} mb={3}>
            Add Doctor
          </Heading>

          <Flex>
            {/* Bagian Kiri */}
            <Box flex={1} mr={5}>
              <FormControl mt={5}>
                <FormLabel>Name</FormLabel>
                <Input
                  bg="white"
                  value={dataInput.name}
                  onChange={(e) =>
                    setDataInput({ ...dataInput, name: e.target.value })
                  }
                  placeholder="Enter Name"
                />
              </FormControl>

              <FormControl mt={5}>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  value={dataInput.hp}
                  bg="white"
                  onChange={(e) =>
                    setDataInput({ ...dataInput, hp: e.target.value })
                  }
                  placeholder="Enter Phone Number"
                />
              </FormControl>

              <Box mt={8}>
                <Tooltip label="Thumbnail image for your articles">
                  <Text fontWeight={600} color="gray.600">
                    Thumbnail
                  </Text>
                </Tooltip>
                {imageUrl ? (
                  <>
                    <Image src={imageUrl} boxSize="full" objectFit="cover" />
                    <Flex justify={"center"} mt={2}>
                      <Input
                        type="file"
                        onChange={handleFileInputChange}
                        display="none"
                        id="fileInput"
                      />
                      <label htmlFor="fileInput">
                        <Flex cursor="pointer">
                          <Box>
                            <MdOutlinePermMedia />
                          </Box>
                          <Text
                            fontSize="sm"
                            color="blue.600"
                            fontStyle="italic"
                          >
                            Change Image thumbnail
                          </Text>
                        </Flex>
                      </label>
                    </Flex>
                  </>
                ) : (
                  <Flex justify={"center"} mt={2}>
                    <Input
                      type="file"
                      onChange={handleFileInputChange}
                      display="none"
                      id="fileInput"
                    />
                    <label htmlFor="fileInput">
                      <Flex cursor="pointer">
                        <Box>
                          <MdOutlinePermMedia />
                        </Box>
                        <Text fontSize="sm" color="blue.600" fontStyle="italic">
                          Add Image thumbnail
                        </Text>
                      </Flex>
                    </label>
                  </Flex>
                )}
              </Box>
            </Box>

            {/* Bagian Kanan */}
            <Box flex={1}>
              <FormControl mt={5}>
                <FormLabel>Treatment</FormLabel>
                <Flex>
                  <Select
                    placeholder="Select Treatment"
                    bg="white"
                    flex={1}
                    onChange={(e) => setNewTreatment(e.target.value)}
                  >
                    {Array.isArray(dataInput.treatment) &&
                      dataInput.treatment.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                  </Select>
                  <Button colorScheme="green" onClick={handleSaveTag} ml={2}>
                    +
                  </Button>
                </Flex>
              </FormControl>
              {Array.isArray(dataInput?.treatment) &&
                dataInput?.treatment?.length > 0 && (
                  <Box mt={5} width="full">
                    <Text fontSize="xl" fontWeight="bold">
                      Selected Treatments
                    </Text>
                    <Table
                      variant="simple"
                      mt={3}
                      borderWidth="1px"
                      borderRadius="md"
                      width="full"
                    >
                      <Thead>
                        <Tr>
                          <Th>Treatment</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataInput?.treatment.map((treat, index) => (
                          <Tr key={index}>
                            <Td>{treat.title}</Td>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleDeleteTag(index)}
                              >
                                X
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
            </Box>
          </Flex>
        </Box>

        <Divider my={5} />

        {/* Bagian Bawah */}
        <Box>
          <VStack mt={8} align="start" spacing={4} width="full">
            <Text fontSize="xl" fontWeight="bold">
              Doctor's Availability
            </Text>

            <FormControl mt={2}>
              <FormLabel>Day</FormLabel>
              <Select
                placeholder="Select Day"
                bg="white"
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
              >
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={2}>
              <FormLabel>Start Hour</FormLabel>
              <Select
                placeholder="Select Time"
                bg="white"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                {[...Array(24).keys()].map((hour) => (
                  <option
                    key={hour}
                    value={`${hour.toString().padStart(2, "0")}:00`}
                  >
                    {`${hour.toString().padStart(2, "0")}:00`}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex>
              <Button colorScheme="teal" mt={3} onClick={generateSchedule}>
                Add Available Schedule
              </Button>
              <Button
                marginLeft={"20px"}
                isLoading={loading}
                my={3}
                size="md"
                colorScheme={"blue"}
                onClick={handleSave}
              >
                Save Data
              </Button>
            </Flex>

            {availableSlots.length > 0 && (
              <Box mt={5} width="full">
                <Text fontSize="xl" fontWeight="bold">
                  Available Schedules
                </Text>
                <Table
                  variant="simple"
                  mt={3}
                  borderWidth="1px"
                  borderRadius="md"
                  width="full"
                >
                  <Thead>
                    <Tr>
                      <Th>Schedule</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {availableSlots.map((schedule, index) => (
                      <Tr key={index}>
                        <Td>{schedule}</Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDeleteSlot(index)}
                          >
                            X
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </VStack>
        </Box>
      </Flex>
    </>
  );
};

export default DoctorEditPage;
