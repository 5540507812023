import { create } from "zustand";

const useMarketingStore = create((set, get) => ({
  //initial state
  campaign: {
    campaignName: "",
    facebookLink: "",
    adAccount: "",
    instagramLink: "",
    pixelID: "",
    tags: "",
    promotionType: "",
  },

  adsCreation: {
    adName: "",
    websiteUrl: "",
    location: "",
    language: "",
    format: "",
    businessName: "",
    businessLogo: "",
    primaryText: "",
    mediaLink: null,
    headline: "",
    description: "",
    cta: "",
  },

  targeting: {
    customAudience: "",
    ageStart: "",
    ageEnd: "",
    sex: "",
    detailedTargeting: "",
    devices: "",
    durationStart: "",
    durationEnd: "",
  },

  budgeting: {
    dailyBudget: "",
    durationStart: "",
    durationEnd: "",
    strategies: "",
  },

  //function dispatch reducer
  setCampaign: (data) => {
    set({
      campaign: {
        ...get().campaign,
        ...data,
      },
    });
  },

  setAdsCreation: (data) => {
    set({
      adsCreation: {
        ...get().adsCreation,
        ...data,
      },
    });
  },

  setTargeting: (data) => {
    set({
      targeting: {
        ...get().targeting,
        ...data,
      },
    });
  },

  setBudgeting: (data) => {
    set({
      budgeting: {
        ...get().budgeting,
        ...data,
      },
    });
  },

  //   setFunctionAddData: (func) => {
  //     set({
  //       setData: func,
  //     });
  //   },
  //   setTaskData: (data) => {
  //     set({
  //       taskData: {
  //         ...get().taskData,
  //         ...data,
  //       },
  //     });
  //   },
  //   setNewCardId: (str) => {
  //     set({
  //       newCardId: str,
  //     });
  //   },
  //   resetTaskData: () => {
  //     set({
  //       taskData: {},
  //     });
  //   },
  //   setFilterData: (data) => {
  //     set({
  //       filterData: {
  //         ...get().filterData,
  //         ...data,
  //       },
  //     });
  //   },
  //   resetFilterData: () => {
  //     set({
  //       filterData: {
  //         asignee: "",
  //         label: "",
  //         category: "",
  //       },
  //     });
  //   },
}));

export default useMarketingStore;
