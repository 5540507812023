import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Spacer,
  Text,
  Flex,
  Heading,
  Stack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  SimpleGrid,
  Image,
  Select,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FcExpand } from 'react-icons/fc';
import AddButtonComponentMindmap from '../../Components/Buttons/AddButtonComponentMindmap';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  getCollectionFirebase,
  arrayUnionFirebase,
  updateDocumentFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import { FcMultipleDevices, FcPlus } from 'react-icons/fc';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import useUserStore from '../../Hooks/Zustand/Store';

function MarketingFunnelsViewPage() {
  const param = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const globalState = useUserStore();
  const [title, setTitle] = useState();
  const modalTemplate = useDisclosure();
  const [dataFunnel, setDataFunnel] = useState();
  const [dataFunnelPage, setDataFunnelPage] = useState();
  const [modalNewPage, setModalNewPage] = useState(false);
  const [editedPage, setEditedPage] = useState([]);
  const [titlePage, setTitlePage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [detailTemplates, setDetailTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [domainPath, setDomainPath] = useState('');
  const [selectedDomain, setSelectedDomain] = useState();
  const [domainData, setDomainData] = useState([]);

  const [page, setPage] = useState();

  const getDomain = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
    ];

    const res = await getCollectionFirebase('domain_landing_page', conditions);

    setDomainData(res);
  };

  const handleAddFunnel = () => {
    setIsLoading(true);
    addDocumentFirebase(
      `funnels/${param.id}/page`,
      {
        title: title,
        title_page: titlePage,
        projectId: globalState?.currentProject,
      },
      globalState.currentCompany
    )
      .then((id) => {
        arrayUnionFirebase('funnels', param.id, 'funnels', [id])
          .then((x) => {
            setModalNewPage(false);
            setIsLoading(false);
            getFunnel();
          })
          .catch((err) => console.log(err.message));

        setDocumentFirebase(
          `websites`,
          id,
          {
            html: detailTemplates?.data || '',
            css: detailTemplates?.css || '',
            projectId: globalState?.currentProject,
            companyId: globalState?.currentCompany,
            domainName: selectedDomain || 'lpage.id',
            domainPath: domainPath,
            folderPath: `${selectedDomain}/${domainPath}`,
          },
          globalState.currentCompany
        );
      })
      .catch((err) => console.log(err.message));
  };

  const handleAddFunnelWithTemplate = async () => {
    console.log('masuk');
    setIsLoading(true);
    console.log(page?.id, 'ini page id');
    try {
      console.log('masuk 2');
      // const response = await addDocumentFirebase(
      //   `funnels/${param?.id}/page/${page?.id}/html`,
      //   { html: detailTemplates?.data, css: detailTemplates?.css },
      //   globalState.currentCompany
      // );

      const response = await updateDocumentFirebase(
        `websites`,
        page?.id,
        {
          html: detailTemplates?.data,
          css: detailTemplates?.css,
        },
        globalState.currentCompany
      );
      console.log(response, 'test');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      modalTemplate?.onClose();
      getEditedFunnelPage();
    }
  };

  const handleSaveDomainPath = async () => {
    setIsLoading(true);
    try {
      const res = await updateDocumentFirebase('websites', page?.id, {
        domainName: selectedDomain,
        domainPath: domainPath,
      });
      console.log(res);

      toast({
        title: 'success',
        description: 'success save domain path',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetTemplateDetail = async (template) => {
    setSelectedTemplate(template);

    const res = await getCollectionFirebase(`templates/${template?.id}/data`);

    setDetailTemplates(res[0]);
  };

  const handlePage = (id) => {
    getSingleDocumentFirebase(`funnels/${param.id}/page`, id).then((x) => {
      setPage({ ...x, id });
    });
  };

  const getFunnel = () => {
    getSingleDocumentFirebase('funnels', param.id)
      .then(async (x) => {
        setDataFunnel(x);
        setTitle(x?.title);

        const dataFunnel = x.funnels;

        if (dataFunnel.length > 0) {
          const conditions = [];
          const sortBy = { field: 'createdAt', direction: 'desc' };

          try {
            const res = await getCollectionFirebase(
              `funnels/${param.id}/page`,
              conditions,
              sortBy
            );
            setDataFunnelPage(res);
          } catch (error) {
            console.log(error, 'ini error');
          }
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getEditedFunnelPage = async () => {
    try {
      // const editedFunnel = await getCollectionFirebase(
      //   `funnels/${param.id}/page/${page.id}/html`
      // );

      const editedFunnel = await getSingleDocumentFirebase(
        `websites`,
        page?.id
      );
      setEditedPage(editedFunnel);
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const getTemplateCollection = async () => {
    const conditions = [
      { field: 'type', operator: '==', value: 'landingPage' },
    ];
    const res = await getCollectionFirebase('templates', conditions);
    setTemplates(res);
  };

  console.log(editedPage);
  const handleModalTemplate = () => {
    getTemplateCollection();
    modalTemplate?.onOpen();
  };

  useEffect(() => {
    getEditedFunnelPage();
    if (page) {
      getDomain();
    }

    return () => {
      setDomainData([]);
    };
  }, [page]);

  useEffect(() => {
    getFunnel();

    //   getDomain();
    // getAllDomain();

    return () => {};
  }, []);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading size={'lg'} textTransform="capitalize">
          {dataFunnel?.title}
        </Heading>
      </HStack>

      {/* <HStack m='2' p='2' shadow='base' height='full' borderRadius='md'>
              <Box  p='2' width='sm' border='Highlight'>
                  <HStack>
                    <Text>Funnels</Text>
                    <Spacer/>
                    <Button size='sm' colorScheme='green'>Add</Button>
                  </HStack>
                  {data?.map((x,i)=>
                  <Box key={i}>                  
                  <Text >{x.title}</Text>
                  <Divider/>
                  <Center>
                  <FcExpand/>
                  </Center>
                    </Box>)}

              </Box>
              <Box w='full' borderLeft='1px' p='2'>
                    <HStack>
                        <Text>Landing Page</Text>
                        <Spacer/>
                        <Button size='sm' colorScheme='blue'>View</Button>
                        <Button size='sm' colorScheme='yellow'>Edit</Button>
                        <Button size='sm' colorScheme='red'>Delete</Button>
                    </HStack>
                    <Container>
                        <Text>Ini dia HTML nya</Text>
                    </Container>
              </Box>
          </HStack> */}

      <Flex
        my={5}
        gap={3}
        borderRadius="md"
        shadow="base"
        minH="80vh"
        flexDir={['column', null, 'row']}
      >
        <Box
          border="1px"
          borderRadius="md"
          p="5"
          minWidth="20%"
          borderColor={'gray.200'}
          bgColor={'white'}
        >
          <HStack>
            <Heading fontSize="lg" p="2">
              Pages
            </Heading>
            <Spacer />
            <Stack cursor={'pointer'} onClick={() => setModalNewPage(true)}>
              <FcPlus />
            </Stack>
          </HStack>

          {dataFunnel?.funnels?.map((z, i) => {
            const funnelPage = dataFunnelPage?.find((page) => page.id === z);
            if (funnelPage) {
              return (
                <HStack
                  key={i}
                  borderTop="1px"
                  cursor={'pointer'}
                  onClick={() => handlePage(z)}
                >
                  <Icon as={FcMultipleDevices} />
                  <Text
                    noOfLines={1}
                    fontSize={'sm'}
                    py={2}
                    textTransform="capitalize"
                  >
                    {funnelPage.title_page}
                  </Text>
                </HStack>
              );
            } else {
              return null;
            }
          })}
        </Box>

        <Stack
          w={'full'}
          p="5"
          overflowY="auto"
          bgColor={'white'}
          borderRadius={'md'}
          borderColor={'gray.200'}
        >
          {page ? (
            editedPage?.hasOwnProperty('domainName') &&
            editedPage?.hasOwnProperty('domainPath') ? (
              <Box>
                <HStack>
                  <Text>{page.title_page}</Text>
                  <Spacer />
                  {/* {page.domain ? (
                    <Text to={page?.domain}>{page?.domain}</Text>
                  ) : (
                    <Text>No domain added to this page</Text>
                  )} */}
                </HStack>
                <HStack>
                  {/* <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      placeholder={page?.domain ? page?.domain : "Enter Domain"}
                      onChange={(e) => setDomainPath(e.target.value)}
                    />
                    <InputRightElement width="50">
                      <Button
                        size="sm"
                        colorScheme="blue"
                        mr={2}
                        // onClick={handleAddDomain}
                      >
                        Add Domain
                      </Button>
                    </InputRightElement>
                  </InputGroup> */}
                  {/* <Select
                  isDisabled={true}
                    defaultValue={'lpage.id'}
                    onChange={(e) => setSelectedDomain(e?.target.value)}
                    width="max-content"
                    placeholder="Select option"
                  >
                   
                    <option value={'lpage.id'}>lpage.id</option>
                    <option value={'Domainku.com'}>domainku.com</option>
                  </Select> */}
                  <Input
                    w={'fit-content'}
                    isDisabled={true}
                    value={editedPage?.domainName}
                    type="text"
                    maxW="2xs"
                    onChange={(e) => setDomainPath(e.target.value)}
                  />
                  <Text>/</Text>
                  <Input
                    w={'fit-content'}
                    isDisabled={true}
                    value={editedPage?.domainPath}
                    type="text"
                    maxW="2xs"
                    onChange={(e) => setDomainPath(e.target.value)}
                  />
                  {/* <Button
                    colorScheme="green"
                    size={'sm'}
                    maxW="2xs"
                    onClick={handleSaveDomainPath}
                  >
                    Save
                  </Button> */}
                  <Spacer />
                  {/* <Button
                    colorScheme="blue"
                    mt="2"
                    fontSize={"sm"}
                    onClick={() => handleOpenDomain()}
                  >
                    Domain List
                  </Button> */}
                  <Button
                    size={'sm'}
                    mt="2"
                    colorScheme="green"
                    onClick={() =>
                      window.open(
                        `https://www.${editedPage?.domainName}/${editedPage?.domainPath}`
                      )
                    }
                  >
                    View
                  </Button>

                  <Button
                    size={'sm'}
                    mt="2"
                    colorScheme="red"
                    // onClick={() =>
                    //   navigate(
                    //     `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                    //     { state: page }
                    //   )
                    // }
                  >
                    Delete
                  </Button>
                  <Button
                    size={'sm'}
                    mt="2"
                    colorScheme="blue"
                    onClick={() =>
                      navigate(
                        `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                        { state: page }
                      )
                    }
                  >
                    Edit
                  </Button>
                  {/* <Button
                    colorScheme="blue"
                    mt="2"
                    // onClick={() =>
                    //   window.open(`http://${page?.domain}`, "_blank")
                    // toastComingSoon('View feature')
                    // }
                  >
                    View
                  </Button> */}
                </HStack>
                <Stack>
                  {editedPage?.html !== '' && editedPage?.css !== '' ? (
                    <>
                      <Stack bgColor={'white'} mt="2">
                        {/* <Text>Result</Text> */}
                        <Stack
                          shadow={'base'}
                          bgColor={'white'}
                          border={'1px'}
                          color="black"
                        >
                          <div
                            style={{
                              backgroundColor: 'white!important',
                              color: 'black!important',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: `
                              ${editedPage?.html} <style>${editedPage?.css}</style>`,
                            }}
                          />
                        </Stack>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack
                        mt={10}
                        justifyContent="center"
                        alignItems="center"
                        h={'full'}
                        spacing={5}
                        bgColor={'white'}
                      >
                        <Heading size={'lg'} textTransform="capitalize">
                          Create new {page.title_page}
                        </Heading>
                        <HStack>
                          {/* <Link
                            to={`/marketing/funnel/create/${param.id}/lp-builder/${page.id}`}
                            target="_blank"
                          > */}
                          <Button
                            colorScheme="yellow"
                            onClick={() =>
                              navigate(
                                `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                                { state: page }
                              )
                            }
                          >
                            Create From Scratch
                          </Button>
                          {/* </Link> */}
                          <Button
                            colorScheme="teal"
                            onClick={handleModalTemplate}
                          >
                            Create With Template
                          </Button>
                        </HStack>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Box>
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                h={'full'}
                spacing={5}
                bgColor={'white'}
              >
                <Heading size={'lg'} textTransform="capitalize">
                  Create new {page.title_page}
                </Heading>
                <HStack>
                  <Link
                    to={`/marketing/funnel/create/${param.id}/lp-builder/${page.id}`}
                    target="_blank"
                  >
                    <Button
                      colorScheme="yellow"
                      // onClick={() =>
                      //   navigate(
                      //     `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                      //     { state: page }
                      //   )
                      // }
                    >
                      Create From Scratch
                    </Button>
                  </Link>
                  <Button colorScheme="teal" onClick={handleModalTemplate}>
                    Create With Template
                  </Button>
                </HStack>
              </Stack>
            )
          ) : (
            <Stack
              alignItems="center"
              justifyContent={'center'}
              h="full"
              bgColor={'white'}
            >
              <Heading size={'lg'} textTransform="capitalize">
                Please select your funnel page
              </Heading>
            </Stack>
          )}
        </Stack>
      </Flex>

      <Modal
        isOpen={modalNewPage}
        onClose={() => setModalNewPage(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Page</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Box>
                <Text fontWeight={500} mb={3}>
                  Page Name
                </Text>
                <Input
                  type="text"
                  placeholder="Page Name"
                  onChange={(e) => setTitlePage(e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight={500}>Domain</Text>
                <HStack w={'100%'}>
                  <Select
                    defaultValue={'lpage.id'}
                    onChange={(e) => setSelectedDomain(e?.target.value)}
                    width="40%"
                    placeholder="Select option"
                  >
                    {/* {domainData?.map((x) => (
                      <option value={x?.name}>{x?.name}</option>
                      ))} */}
                    <option value={'lpage.id'}>lpage.id</option>
                    <option value={'Domainku.com'}>domainku.com</option>
                  </Select>
                  <Text>/</Text>
                  <Input
                    w={'60%'}
                    defaultValue={editedPage?.domainPath}
                    type="text"
                    maxW="2xs"
                    onChange={(e) => setDomainPath(e.target.value)}
                  />
                </HStack>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                isLoading={isLoading}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddFunnel()}
              >
                Add New
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalNewPage(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={'xl'}
        isOpen={modalTemplate?.isOpen}
        onClose={modalTemplate?.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <Text align={'center'} fontWeight={500}>
                Available Template Themes
              </Text>
              <Box
                h={400}
                overflow={'auto'}
                sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }}
              >
                <SimpleGrid columns={3} spacing={3} p={2}>
                  {templates?.map((template) => (
                    <Stack
                      spacing={3}
                      cursor={'pointer'}
                      align={'center'}
                      border={
                        selectedTemplate?.id === template?.id ? '2px' : '1px'
                      }
                      borderColor={
                        selectedTemplate?.id === template?.id
                          ? 'blue.400'
                          : 'gray.100'
                      }
                      borderRadius={'md'}
                      p={3}
                      shadow={'base'}
                      onClick={() => handleGetTemplateDetail(template)}
                    >
                      <Image w={300} src={template?.image} />
                      <Text align={'center'} fontSize={14} fontWeight={500}>
                        {template?.title}
                      </Text>
                    </Stack>
                  ))}
                </SimpleGrid>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                isLoading={isLoading}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddFunnelWithTemplate()}
              >
                Create
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={modalTemplate?.onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Modal isOpen={modalDomain} onClose={() => setModalDomain(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Select Domain</Text>
          </ModalHeader>
          <ModalBody>
            {dataDomain?.map((item) => (
              <HStack mb={4}>
                <Box>
                  {item.pageId ? (
                    <Text>
                      {item.name}
                      <Text as={"i"} color={"GrayText"}>
                        {" "}
                        {"(already used)"}{" "}
                      </Text>
                    </Text>
                  ) : (
                    <Text>{item.name}</Text>
                  )}
                </Box>
                <Spacer />
                {item.pageId ? null : (
                  <Button
                    size={"sm"}
                    onClick={() => addToDomain(item)}
                    colorScheme="green"
                  >
                    +
                  </Button>
                )}
              </HStack>
            ))}
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                isLoading={isLoading}
                // leftIcon={<AddIcon boxSize={3} />}
                size={"sm"}
                colorScheme="green"
                onClick={() => {
                  navigate("/create-domain");
                }}
              >
                Add New Domain
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                size={"sm"}
                colorScheme="red"
                onClick={() => {
                  setModalDomain(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  );
}

export default MarketingFunnelsViewPage;
