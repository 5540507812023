// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyD7p9mZ9lxaq5ubssx9ICwqmwgVYtvoaiw",
  authDomain: "anggaran-v2.firebaseapp.com",
  projectId: "anggaran-v2",
  storageBucket: "anggaran-v2.appspot.com",
  messagingSenderId: "598846963238",
  appId: "1:598846963238:web:f570fe67f99a0d933870e7",
  measurementId: "G-L6C1LSEHMR"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig,'Anggaran')
const authAnggaran = getAuth(app)
const storageAnggaran = getStorage(app)

export { authAnggaran, storageAnggaran }
