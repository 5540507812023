import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    HStack,
    Heading,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import {
    deleteDocumentFirebase,
    deleteFileFirebase,
    getCollectionFirebase,
    updateDocumentFirebase,
  } from "../../Api/firebaseApi";
  import useUserStore from "../../Hooks/Zustand/Store";
  import moment from "moment";
  import { useNavigate } from "react-router-dom";
  import AddButtons from "../../Components/Buttons/AddButtons";
  import { FcPlus } from "react-icons/fc";
  import { DeleteIcon } from "@chakra-ui/icons";
  import { CiBoxList } from "react-icons/ci";
  import { FiEdit, FiEye } from "react-icons/fi";
  import BackButtons from "../../Components/Buttons/BackButtons";
  import DynamicButton from "../../Components/Buttons/DynamicButton";
  import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
  import { db } from "../../Config/firebase";

const TreatmentHistoryPage = () => {
    const modalDelete = useDisclosure();
    const globalState = useUserStore();
    const navigate = useNavigate();
    const toast = useToast();
  
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 6;
  
    const [dataProducts, setDataProducts] = useState([]);
    const [dataModal, setDataModal] = useState();
    const [categories, setCategories] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [searchedDataProduct, setSearchedDataProduct] = useState([]);
  
    const getTreatment = async () => {
    const collectionRef = collection(db, "treatment-deoapp-clinic");
    try {
      const querySnapshot = await getDocs(collectionRef);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDataProducts(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error);
    }
  };
  
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage); 
  };

  const totalItems = dataProducts?.length || searchedDataProduct?.length || 0;
  const shouldShowLoadMore = totalItems >= startIndex + itemsPerPage;
  
  const searchFilterFunction = (text) => {
    if (text || text !== "") {
      const newData = dataProducts.filter((item) => {
        const itemData = item.title
          ? item.title.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      console.log(newData,"new")
      setSearchedDataProduct(newData);
      setSearchInput(text);
    } else {
      setSearchedDataProduct(dataProducts);
      setSearchInput(text);
    }
  };

  const getCategory = async () => {
    const collectionRef = collection(db, "category-artikel-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error);
    }
  };

  const handleModal = (data) => {
    modalDelete.onOpen();
    setDataModal(data);
  };

  console.log(dataModal);

  const handleDeletePages = async (categoryId) => {
    try {
      if (Array.isArray(categoryId.doctor) && categoryId.doctor.length > 0) {
        for (const doctor of categoryId.doctor) {
          const doctorDocRef = doc(db, "doctor-deoapp-clinic", doctor.id);
  
          const doctorDocSnapshot = await getDoc(doctorDocRef);
  
          if (doctorDocSnapshot.exists()) {
            const existingData = doctorDocSnapshot.data();

            const indexToRemove = (existingData.treatment || []).indexOf(categoryId.title);
  
            if (indexToRemove !== -1) {
              existingData.treatment.splice(indexToRemove, 1);
  
              await updateDoc(doctorDocRef, { treatment: existingData.treatment });
            }
          }
        }
      }
  
      await deleteDoc(doc(db, "treatment-deoapp-clinic", categoryId.id));
      toast({
        title: "Success",
        description: "Treatment Deleted",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      modalDelete.onClose();
      getTreatment();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast({
        title: "Error",
        description: "Failed to delete category",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    getTreatment();
    getCategory();
    return () => {};
  }, [globalState.currentProject]);

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={4}>
        <HStack gap={5}>
          <BackButtons />
          <Heading size={"md"} fontWeight="bold">
            List Treatment
          </Heading>
          <Spacer />
          {/* <HStack> */}
          <Input
            type="text"
            placeholder="Search Product ..."
            bgColor="white"
            color="black"
            sx={inputStyles}
            w={"15%"}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />
          {/* <DynamicButton
            action={"create"}
            title={"Add Treatment"}
            onClick={() => navigate("/booking/list-treatment/create")}
          /> */}

          {/* </HStack> */}
        </HStack>
        <Stack bg={"white"} p={5} borderRadius={"md"} shadow={"md"}>
          {searchInput !== "" ? (
            <>
              {searchedDataProduct?.length > 0 ? (
                <SimpleGrid columns={[1, null, 4]} spacing={3}>
                  {searchedDataProduct.map((product, i) => (
                    <Stack
                      shadow={"base"}
                      gap={3}
                      key={i}
                      bg={"white"}
                      borderRadius={"md"}
                      p={3}
                    >
                      <Stack>
                        <Image
                          src={product?.thumbnail}
                          boxSize={"fit-content"}
                          // w={"fit-content"}
                          objectFit="cover"
                        />
                      </Stack>
                      <Spacer />
                      <Stack align={"center"}>
                        <Box alignSelf={"center"}>
                          <HStack justifyContent={"center"}>
                            <Heading size={"md"}>{product?.title}</Heading>
                          </HStack>
                        </Box>
                        <HStack
                          alignItems={"center"}
                          justify={"space-evenly"}
                          spacing={3}
                        >
                          {/* <HStack spacing={1}>
                            <FiEye /> */}
                            {/* <Text fontSize={12}>{product?.description}</Text> */}
                            {/* <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/booking/list-treatment/create/${product.id}`)
                              } */}
                            {/* > */}
                              {/* <a href={`/products/article/${product.id}`}> */}
                              {/* View */}
                              {/* </a> */}
                            {/* </Text>
                          </HStack> */}
                          <HStack spacing={1}>
                            <CiBoxList />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/history/treatment-history/${product.id}`)
                              }
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              List
                              {/* </a> */}
                            </Text>
                          </HStack>

                          {/* <Button
                            variant={"ghost"}
                            size={"xs"}
                            w={"fit-content"}
                            onClick={() => handleModal(product)}
                          >
                            <DeleteIcon boxSize={3} />
                          </Button> */}
                        </HStack>
                        <Spacer />
                      </Stack>
                    </Stack>
                  ))}
                </SimpleGrid>
              ) : (
                <Box bg={"white"} borderRadius={"md"} p={3}>
                  <Center>
                    <Heading size={"md"}>No Products</Heading>
                  </Center>
                </Box>
              )}
            </>
          ) : (
            <>
              {dataProducts?.length > 0 ? (
                <SimpleGrid columns={[1, null, 4]} spacing={3}>
                  {dataProducts.map((product, i) => (
                    <Stack
                      shadow={"base"}
                      gap={3}
                      key={i}
                      bg={"white"}
                      borderRadius={"md"}
                      p={3}
                    >
                      <Stack>
                        <Image
                          src={product?.thumbnail}
                          boxSize={"fit-content"}
                          // w={"fit-content"}
                          objectFit="cover"
                        />
                      </Stack>
                      <Spacer />
                      <Stack align={"center"}>
                        <Box alignSelf={"center"}>
                          <HStack justifyContent={"center"}>
                            <Heading size={"md"}>{product?.title}</Heading>
                          </HStack>
                        </Box>
                        <HStack
                          alignItems={"center"}
                          justify={"space-evenly"}
                          spacing={3}
                        >
                          {/* <HStack spacing={1}>
                            <FiEye /> */}
                            {/* <Text fontSize={12}>{product?.description}</Text> */}
                            {/* <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/booking/list-treatment/create/${product.id}`)
                              }
                            > */}
                              {/* <a href={`/products/article/${product.id}`}> */}
                              {/* View */}
                              {/* </a> */}
                            {/* </Text>
                          </HStack> */}
                          <HStack spacing={1}>
                            <CiBoxList />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(`/history/treatment-history/${product.id}`)
                              }
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              List
                              {/* </a> */}
                            </Text>
                          </HStack>

                          {/* <Button
                              variant={"ghost"}
                              size={"xs"}
                              w={"fit-content"}
                              onClick={() => handleModal(product)}
                            >
                              <DeleteIcon boxSize={3} />
                            </Button> */}
                        </HStack>
                        <Spacer />
                      </Stack>
                    </Stack>
                  ))}
                </SimpleGrid>
              ) : (
                <Box bg={"white"} borderRadius={"md"} p={3}>
                  <Center>
                    <Heading size={"md"}>No Products</Heading>
                  </Center>
                </Box>
              )}
            </>
          )}
        </Stack>

        <Stack alignItems={"center"} justifyContent="center">
          <Box>
            {shouldShowLoadMore && (
              <Button onClick={handleLoadMore} size="sm">
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TreatmentHistoryPage