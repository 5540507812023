import React from "react";
import HistoryPage from "../Pages/History/HistoryPage";
import TreatmentHistoryPage from "../Pages/History/TreatmentHistoryPage";
import TreatmentHistoryDetailPage from "../Pages/History/TreatmentHistoryDetailPage";

const HistoryRouter = [
  {
    path: "/history",
    element: <HistoryPage />,
  },  
  {
    path: "/history/treatment-history",
    element: <TreatmentHistoryPage />,
  },
  {
    path: "/history/treatment-history/:id",
    element: <TreatmentHistoryDetailPage />,
  },
];

export default HistoryRouter;
