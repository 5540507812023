import { Box, Button } from '@chakra-ui/react'
import React from 'react'
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { authMarketer } from '../../Config/firebaseMarketer';

function MarketingMetaIntegration() {
    const provider = new FacebookAuthProvider();
    
    const facebookSignIn = ()=>{
        
        provider.addScope('business_management')

        signInWithPopup(authMarketer, provider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;
            console.log(user,'ini usernya')
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            console.log(accessToken,'this is the access token')

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);

            // ...
        });
    }

  return (
    <Box>
        <Button onClick={()=>facebookSignIn()}>Connect Facebook</Button>
    </Box>
  )
}

export default MarketingMetaIntegration