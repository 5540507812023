import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Image,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  ListItem,
  Card,
  Stack,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Config/firebase";
import { useParams } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from "../../Api/firebaseApi";

const TreatmentHistoryDetailPage = () => {
  const [treatmentData, setTreatmentData] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const params = useParams();

  const getTreatmentData = async () => {
    try {
      const result = await getSingleDocumentFirebase(
        "treatment-deoapp-clinic",
        params.id
      );
      console.log(result, "ini resut");
      setTreatmentData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getTreatment = async () => {
    try {
      console.log(params.id, "po");
      const collectionRef = collection(
        db,
        "booking-list-deoapp-clinic"
      );

      const querySnapshot = await getDocs(
        query(collectionRef, where("productId", "==", params.id))
      );

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTreatment(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  useEffect(() => {
    getTreatmentData();
    getTreatment();
  }, []);
  return (
    <>
      <BackButtons />
      <Flex justifyContent="space-between" padding={"1%"}>
        <Heading>Treatment History</Heading>
      </Flex>
      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        boxShadow="md" // Menambah bayangan
        borderRadius="md" // Menambah border-radius
        backgroundColor="white" // Warna latar belakang
        p={4} // Padding
      >
        <Image
          objectFit="cover"
          maxW={{ base: "100%", sm: "200px" }}
          src={treatmentData?.thumbnail}
          alt={treatmentData?.title}
        />

        <Stack spacing={4} ml={{ base: 0, sm: 4 }}>
          <CardBody>
            <Heading size="md">{treatmentData?.title}</Heading>
          </CardBody>
          <Text py="2">{treatmentData?.description}</Text>
          <FormControl>
            <FormLabel>Doctor:</FormLabel>
            <UnorderedList>
              {treatmentData?.doctor?.map((x, index) => (
                <ListItem key={index}>{x.name}</ListItem>
              ))}
            </UnorderedList>
          </FormControl>

          {/* Tambahkan elemen desain tambahan jika diperlukan */}
          <CardFooter></CardFooter>
        </Stack>
      </Card>

      <Table size="sm" mt={4} variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Nama Pasien</Th>
            <Th>Clinic</Th>
            <Th>Dokter Penanggung Jawab</Th>
            <Th>Waktu Kunjungan</Th>
            <Th>Status Kunjungan</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {treatment?.map((visit, index) => (
            <Tr key={index}>
              <Td>{visit.name}</Td>
              <Td>{visit.location}</Td>
              <Td>{visit.doctor}</Td>
              <Td>{visit.time}</Td>
              <Td>{visit.status}</Td>
              <Td>
                <Button
                  size="sm"
                  colorScheme="teal"
                  // onClick={() => handleDetailClick(visit)}
                >
                  Detail
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default TreatmentHistoryDetailPage;
