import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import useMarketingStore from "../../Hooks/Zustand/MarketingStore";

function MarketingFacebookAddBudgetPage() {
  const marketingState = useMarketingStore();

  const steps = [
    { title: "Campaign", description: "Create Campaign" },
    { title: "Ads", description: "Create Ads" },
    { title: "Audience", description: "Select Audience" },
    {
      title: "Budget & Strategies",
      description: "Set your budget & strategies",
    },
    { title: "Publish", description: "Review & publish" },
  ];

  const strategies = [
    {
      name: "Sneak Attack Method",
      description:
        "This is for the Sneak Attack Method. There are a few values you will need to change in the rules like your target ROAS or cost per purchase etc. Enjoy!",
      strategy: [
        "Trim the fat - Purchases Today > 0 and Cost Per Purchase > $40",
        "Stop/loss - 0 Purchases And Spend Today > $60",
        "Bid bumper 2 - 2% Increase Bid Every 3 Hours If  Last 3 Hours Spend Under $100",
        "Bid bumper 1 - Spend < $100 Today - Increase Bid 2% After Noon",
        "Safety Net - Turn Ad Set Back on If ROAS > 2",
      ],
      image: "https://cdn.revealbot.com/vite/assets/pause-19f701a1.png",
    },
    {
      name: "Surfing Strategy",
      description:
        "This rule increases your budget if your ROAS is higher than 3x, if your profitable ROAS is lower feel free to adjust that to whatever ROAS puts you around 150-200% profit It is set to look for at least 15 purchases on the campaign level at a minimum of 3 ROAS, then will double your budget only once per day",
      strategy: ["Surfing Strategy PUR > 15 & ROAS > 3"],
      image: "https://cdn.revealbot.com/vite/assets/increase-ee6879e6.png",
    },
    {
      name: "Slow Vertical Scaling",
      description:
        "Scale your well-performing prospecting campaigns with our automatic budget increase rule each morning.",
      strategy: ["V-Scale - 20% - Morning", "SPF: Stop Ads > A4A"],
      image: "https://cdn.revealbot.com/vite/assets/increase-ee6879e6.png",
    },
  ];

  console.log(marketingState);
  const navigate = useNavigate();
  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={4}
        bg={"white"}
        p={5}
        borderRadius={"md"}
        shadow={"base"}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>
                {step.description}
              </StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Heading textAlign="center">Budget & Strategies </Heading>

      <Box>
        <Container p="5" mt="5" shadow="base" bg={"white"} borderRadius={"md"}>
          <FormControl>
            <FormLabel>Daily Budget</FormLabel>
            <Input
              value={marketingState?.budgeting?.dailyBudget}
              onChange={(e) =>
                marketingState?.setBudgeting({
                  dailyBudget: e.target.value,
                })
              }
              type="number"
            />
          </FormControl>
          <FormControl my={3}>
            <Stack spacing={1}>
              <FormLabel>Duration</FormLabel>

              <HStack>
                <Box>
                  <Text>Start Date</Text>
                  <Input
                    value={marketingState?.budgeting?.durationStart}
                    onChange={(e) =>
                      marketingState?.setBudgeting({
                        durationStart: e.target.value,
                      })
                    }
                    type="datetime-local"
                  />
                </Box>
                <Box>
                  <Text>End Date</Text>
                  <Input
                    value={marketingState?.budgeting?.durationEnd}
                    onChange={(e) =>
                      marketingState?.setBudgeting({
                        durationEnd: e.target.value,
                      })
                    }
                    type="datetime-local"
                  />
                </Box>
              </HStack>
            </Stack>
          </FormControl>

          <FormControl as="fieldset">
            <FormLabel as="legend">Strategies</FormLabel>
            <RadioGroup
              value={marketingState?.budgeting?.strategies}
              onChange={(e) =>
                marketingState?.setBudgeting({
                  strategies: e,
                })
              }
              defaultValue="External Website"
            >
              {strategies.map((x, i) => (
                <HStack key={i} spacing="24px" m="2" shadow="base" p="2">
                  <Radio value={x.name}>
                    <Box>
                      <Heading mb={2} fontSize="md">
                        {x.name}
                      </Heading>
                      <Flex gap={2}>
                        <Image width="100px" src={x.image} alt={x.name} />
                        <Text fontSize={13}>{x.description}</Text>
                      </Flex>
                      <Text>Steps</Text>
                      {x.strategy.map((y, a) => (
                        <Text pl="3" fontSize={13}>
                          {a + 1}. {y}
                        </Text>
                      ))}
                    </Box>
                  </Radio>
                </HStack>
              ))}
            </RadioGroup>
            {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
          </FormControl>

          <Button
            w="full"
            colorScheme="green"
            onClick={() => navigate("/marketing/meta/facebook/add/publish")}
          >
            Next
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default MarketingFacebookAddBudgetPage;
