import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  sendEmailVerification,
  signOut,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import logo from "../../assets/1.png";
import React, { useState } from "react";
import { auth } from "../../Config/firebase";
import { useNavigate } from "react-router-dom";
import { updateDocumentFirebase } from "../../Api/firebaseApi";
import { MdVisibility, MdVisibilityOff, MdVpnKey } from "react-icons/md";
import { logoutUserWithIp } from "../../Hooks/Middleware/sessionMiddleWare";
import store from "store";

function EmailVerification() {
  const [emailSent, setEmailSent] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const verify = () => {
    if (!auth.currentUser) {
      // Jika pengguna tidak terotentikasi, maka alihkan mereka ke halaman login atau lakukan tindakan lain sesuai kebutuhan.
      // Misalnya:
      toast({
        title: "Error",
        description: "User is not authenticated",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (!name) {
      showErrorToast("Name cannot be blank, please input your name");
    } else if (!password) {
      showErrorToast("Password cannot be blank, please input your password");
    } else if (!confirmPassword) {
      showErrorToast("Confirm password cannot be blank");
    } else if (password !== confirmPassword) {
      showErrorToast("Password and confirm password cannot be different");
    } else {
      setIsVerifying(true);
      updateProfile(auth?.currentUser, {
        displayName: name,
        photoURL: "",
      })
        .then(() => {
          sendEmailVerification(auth.currentUser).then(() => {
            setEmailSent(true);
            updateDocumentFirebase("users", auth?.currentUser?.uid, {
              name: name,
              email: auth?.currentUser?.email,
            }).then(() => {
              updatePassword(auth.currentUser, password)
                .then(() => {
                  handleLogout();
                })
                .catch((error) => {
                  showErrorToast(error.message);
                })
                .finally(() => {
                  setIsVerifying(false);
                });
            });
          });
        })
        .catch((error) => {
          showErrorToast(error.message);
        });
    }
  };
  const showErrorToast = (message) => {
    toast({
      title: "Oops!",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const handleLogout = async () => {
    const pathLink = "business";

    await logoutUserWithIp(window?.location?.hostname, auth?.currentUser?.email, pathLink);

    signOut(auth)
      .then(() => {
        toast({
          status: "success",
          description: "Logged out successfully",
          duration: 2000,
        });
        navigate("/");
        store.clearAll();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error, "ini error");
      });
  };

  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <Stack
      spacing={10}
      pt={20}
      minH={height}
      bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
      bgSize="cover"
      alignItems="center"
      justifyContent="center"
    >
      <Stack>
        <Stack alignItems="center" justifyContent="center">
          <Stack
            spacing={5}
            pb={10}
            bgColor="blackAlpha.500"
            p={10}
            borderRadius="xl"
          >
            <Stack w="full">
              <Image w="200px" borderRadius={20} src={logo} alt="Alternate Text" />
            </Stack>

            <Stack w="full" px={[1, 1, 5]} d color="white" spacing={1} alignItems="flex-start" justifyContent="flex-start">
              <Text fontWeight={500}>Before Start, please</Text>
              <Text fontSize={35} fontWeight="bold">
                Verify Your Email
              </Text>
            </Stack>

            <Stack color="white" spacing={4} p="3" borderRadius="xl">
              <Stack alignItems="center" justifyContent="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                  alignItems="center"
                >
                  <Text alignItems="flex-start" w="250px" fontWeight="bold">
                    Your email :
                  </Text>
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    fontSize="md"
                    shadow={3}
                    bgColor="white"
                    color="black"
                    value={auth?.currentUser?.email}
                    isDisabled={true}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center" justifyContent="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                  alignItems="center"
                >
                  <Text alignItems="flex-start" w="250px" fontWeight="bold">
                    Your display name :
                  </Text>
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Enter your name"
                    fontSize="md"
                    shadow={3}
                    bgColor="white"
                    color="black"
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                >
                  <InputLeftAddon
                    shadow="md"
                    children={<MdVpnKey size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="password"
                    fontSize="md"
                    shadow="md"
                    type={showPassword ? "text" : "password"}
                    bgColor="white"
                    color="black"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={() =>
                          setShowPassword((showConfirmPassword) => !showConfirmPassword)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                >
                  <InputLeftAddon
                    shadow="md"
                    children={<MdVpnKey size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="Confirm password"
                    fontSize="md"
                    shadow="md"
                    type={showConfirmPassword ? "text" : "password"}
                    bgColor="white"
                    color="black"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputRightElement>
                    {showConfirmPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={() =>
                          setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={() =>
                          setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <HStack spacing={3} w="full" pt={2} alignItems="center" justifyContent="center">
                <Button
                  onClick={handleLogout}
                  size="md"
                  w="fit-content"
                  fontWeight="bold"
                >
                  Back to login
                </Button>

                <Button
                  onClick={() => verify()}
                  isLoading={isVerifying}
                  loadingText="Verifying email, please wait..."
                  size="md"
                  fontWeight="bold"
                >
                  Click to Verify Email
                </Button>
              </HStack>

              {emailSent ? (
                <Heading size="sm">
                  Email sent, please check your email at {auth?.currentUser?.email}
                </Heading>
              ) : (
                <></>
              )}
            </Stack>

            <Spacer />
          </Stack>
        </Stack>
        <Spacer />

        <Stack>{/* <AppSponsor /> */}</Stack>
      </Stack>
    </Stack>
  );
}

export default EmailVerification;
