import axios from "axios";

const baseUrl = 'https://new-apiv2.importir.com/'

export const uploadAws = async (data, md5check) => {
    const configTest = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-MD5': md5check
        },
      }
    try {
        // Make the HTTP request to upload the file to AWS
        const response = await axios.post(`${baseUrl}api/upload-aws/image`, 
          data, configTest
        );
    
        return response
      } catch (error) {
        console.error('Error uploading file to AWS', error);
      }
}