import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FcFilledFilter, FcPlus } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import AddButtonComponentMindmap from "../../Components/Buttons/AddButtonComponentMindmap";
import BackButtons from "../../Components/Buttons/BackButtons";
import useUserStore from "../../Hooks/Zustand/Store";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import moment from "moment";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
} from "../../Api/firebaseApi";

function MarketingFunnelsPage() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const globalState = useUserStore();
  const deleteFunnelModal = useDisclosure();
  const toast = useToast();
  const [detailFunnel, setDetailFunnel] = useState({});
  const [modalNewPage, setModalNewPage] = useState(false);
  const [funnels, setFunnels] = useState([]);

  const data = [
    { id: "123", title: "ini kodok", usersProjectData: ["kodok", "anjing"] },
  ];

  const getFunnels = async () => {
    if (!globalState?.currentCompany) {
      return toast({
        title: "Error",
        description: "Please check your select company",
        status: "error",
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: "Error",
        description: "Please check your select project",
        status: "error",
      });
    }

    try {
      let q = query(
        collection(db, "funnels"),
        where("projectId", "==", globalState?.currentProject),
        where("companyId", "==", globalState?.currentCompany),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const funnelArr = [];
        querySnapshot.forEach((doc) => {
          funnelArr.push({ ...doc.data(), id: doc.id });
        });
        // setFunnels((prevData) => [...prevData, ...funnelArr]);
        setFunnels(funnelArr);
      });
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  };

  const handleOpenModal = (dataFunnel) => {
    deleteFunnelModal.onOpen();
    setDetailFunnel(dataFunnel);
  };

  const handleNewPage = async () => {
    if (!globalState?.currentCompany) {
      return toast({
        title: "Error",
        description: "Please check your select company",
        status: "error",
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: "Error",
        description: "Please check your select project",
        status: "error",
      });
    }

    const dataPage = {
      title: title,
      funnels: [],
      type: "website",
      projectId: globalState.currentProject,
    };

    const collectionName = "funnels";
    const data = dataPage;

    try {
      const docID = await addDocumentFirebase(
        collectionName,
        data,
        globalState.currentCompany
      );

      setModalNewPage(false);
      getFunnels();

      const resultUpdate = await addDocumentFirebase(
        "logs",
        {
          activity: `add funnel ai landing page`,
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            ...dataPage,
            id: docID,
          },
        },
        globalState.currentCompany
      );
      console.log(resultUpdate, "logs updated");
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  const handleDeleteFunnel = async () => {
    try {
      const result = await deleteDocumentFirebase("funnels", detailFunnel?.id);

      toast({
        status: "success",
        title: "Template Deleted",
        duration: 1000,
      });

      deleteFunnelModal.onClose();
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }
  };

  useEffect(() => {
    getFunnels();

    return () => {};
  }, [globalState?.currentProject, globalState?.currentCompany]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Landing Page</Heading>
        <Spacer />
        {/* <AddButtonComponentMindmap /> */}
        <Button
          colorScheme="green"
          variant="outline"
          onClick={() => setModalNewPage(true)}
        >
          <HStack spacing={2}>
            <FcPlus />
            <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
              New Funnel
            </Text>
          </HStack>
        </Button>
      </HStack>

      {/* <SimpleGrid columns={4}>
        {data.map((item, index) => (
          <Stack shadow={"base"} bgColor="white" key={index}>
            <VStack
              align={"flex-start"}
              w={"full"}
              h={"full"}
              justify={"space-between"}
            >
              <HStack spacing={3} px={4} pt={4} w="100%">
                <Stack onClick={() => navigate(`${item?.id}`)}>
                  <FcFilledFilter size={50} />
                </Stack>
                <Stack>
                  <Text
                    textTransform={"capitalize"}
                    color="blackAlpha.800"
                    onClick={() => navigate(`${item?.id}`)}
                    noOfLines={2}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                  >
                    {item.title}
                  </Text>
                  <AvatarGroup
                    size="sm"
                    max={5}
                    //   onClick={() => modalTeam(item)}
                  >
                    {item?.usersProjectData?.map((z, i) => (
                      <Avatar key={i} name={z.name} />
                    ))}
                  </AvatarGroup>
                </Stack>
              </HStack>
              <HStack
                alignItems={"center"}
                justifyContent="space-around"
                w={"full"}
                p={3}
              >
                <Text spacing={3} fontSize={"2xs"}>
                  {`${moment(item?.createdAt * 1000).format("llll")}`}
                </Text>
                <Spacer />
                {item?.owner?.includes(globalState?.uid) && (
                  <Box>
                    <DeleteIcon
                      onClick={() => {
                        onOpen();
                        setSelectedFlowchart(item);
                      }}
                    />
                  </Box>
                )}
              </HStack>
            </VStack>
          </Stack>
        ))}
      </SimpleGrid> */}

      <SimpleGrid my={5} columns={[1, 2, 3]} gap="4">
        <>
          {funnels?.length > 0 ? (
            <>
              {funnels?.map((x, i) => (
                // <Stack
                //   _hover={{
                //     transform: "scale(1.05)",
                //     shadow: "md",
                //   }}
                //   transition={"0.2s ease-in-out"}
                //   spacing={2}
                //   border={"1px"}
                //   bg={"white"}
                //   borderColor={"gray.300"}
                //   key={i}
                //   py={4}
                //   px={4}
                //   borderRadius="md"
                //   shadow="md"
                // >
                //   <Link to={`view/${x.id}`}>
                //     <Text fontWeight={"semibold"}>{x.title}</Text>
                //     <Text>{x.items} Landing Page</Text>
                //   </Link>
                //   <HStack mt={5}>
                //     <Text fontSize={"sm"}>
                //       {moment
                //         .unix(x?.lastUpdated?.seconds ?? x?.createdAt?.seconds)
                //         .fromNow()}
                //     </Text>
                //     <Spacer />
                //     <Button size={"xs"} onClick={() => handleOpenModal(x)}>
                //       <DeleteIcon />
                //     </Button>
                //   </HStack>
                // </Stack>
                <HStack
                  _hover={{
                    transform: "scale(1.05)",
                    shadow: "md",
                  }}
                  cursor={"pointer"}
                  transition={"0.2s ease-in-out"}
                  spacing={2}
                  border={"1px"}
                  bg={"white"}
                  borderColor={"gray.300"}
                  key={i}
                  py={4}
                  px={4}
                  borderRadius="md"
                  shadow="md"
                >
                  <Stack onClick={() => navigate(`${x?.id}`)}>
                    <FcFilledFilter size={50} />
                  </Stack>
                  <Stack>
                    <Text
                      textTransform={"capitalize"}
                      color="blackAlpha.800"
                      onClick={() => navigate(`${x?.id}`)}
                      noOfLines={2}
                      fontSize={"sm"}
                      fontWeight={"medium"}
                    >
                      {x.title}
                    </Text>
                    <AvatarGroup
                      size="sm"
                      max={5}
                      //   onClick={() => modalTeam(x)}
                    >
                      {x?.usersProjectData?.map((z, i) => (
                        <Avatar key={i} name={z.name} />
                      ))}
                    </AvatarGroup>
                  </Stack>
                </HStack>
              ))}
            </>
          ) : (
            <Box>
              <Text textAlign="center" fontWeight="semibold">
                No Data Funnel, Please Create One
              </Text>
            </Box>
          )}
        </>
      </SimpleGrid>

      <Modal
        isOpen={modalNewPage}
        onClose={() => setModalNewPage(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Funnels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder="Funnels Name"
              onChange={(e) => setTitle(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleNewPage()}
              >
                Add New
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalNewPage(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={deleteFunnelModal.isOpen}
        onClose={deleteFunnelModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{detailFunnel.title} Funnel</ModalHeader>
          <ModalBody>Funnel will be deleted, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                size={"sm"}
                colorScheme="green"
                onClick={handleDeleteFunnel}
              >
                Yes
              </Button>
              <Button
                size={"sm"}
                colorScheme="red"
                onClick={deleteFunnelModal.onClose}
              >
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MarketingFunnelsPage;
