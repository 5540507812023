import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/1.png";
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { auth, db } from "../../Config/firebase";
import useUserStore from "../../Hooks/Zustand/Store";
import {
  checkUserAccess,
  loginUserWithIp,
} from "../../Hooks/Middleware/sessionMiddleWare";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const globalState = useUserStore();

  if (globalState?.uid) {
    navigate("/");
  }

  const toast = useToast();

  const handleUpdatePassword = () => {
    setLoading(true);
    if (email === null || email === "") {
      toast({
        title: "Error",
        description: "Input email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast({
            title: "Success",
            description:
              "Reset password request has been sent, kindly check your email!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
          navigate("/login");
        })
        .catch((error) => {
          toast({
            title: error.code,
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
    }
  };

  // const handleLogin = async () => {
  //   if (email !== "" || password !== "" || password !== confirmPassword) {
  //     const pathLink = "business";
  //     const resCheck = await checkUserAccess(
  //       window.location.hostname,
  //       email,
  //       pathLink
  //     );

  //     if (!resCheck) {
  //       return middleWareAccess();
  //     }

  //     try {
  //       setLoading(true);
  //       const userCredential = await signInWithEmailAndPassword(
  //         auth,
  //         email,
  //         password
  //       );
  //       const user = userCredential.user;

  //       if (user) {
  //         const pathLink = "business";
  //         const res = await loginUserWithIp(
  //           window.location.hostname,
  //           email,
  //           pathLink,
  //           user.uid
  //         );

  //         if (!res) {
  //           return middleWareAccess();
  //         }
  //       }

  //       toast({
  //         title: "Login Successful",
  //         description: `You have successfully logged in as ${userCredential.user.email} `,
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //       });

  //       // navigate("/");
  //     } catch (error) {
  //       console.log(error, "ini error");
  //       toast({
  //         title: "Error",
  //         description:
  //           error.code === "auth/wrong-password"
  //             ? "Wrong email or password. Please try again."
  //             : "An error occurred. Please try again.",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <>
      <Stack
        spacing={10}
        pt={20}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={"center"}
        justifyContent="center"
      >
        <Stack>
          <Stack alignItems={"center"} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor="blackAlpha.500"
              p={10}
              borderRadius="xl"
            >
              <Box>
                <Image
                  w="200px"
                  borderRadius={20}
                  src={logo}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    shadow={"md"}
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="email"
                    fontSize={"md"}
                    shadow={3}
                    bgColor={"white"}
                    color={"black"}
                    onChange={
                      (e) => setEmail(e.target.value)
                      //   setDataLogin({ ...dataLogin, email: e.target.value })
                    }
                  />
                </InputGroup>
              </Stack>
              {/* <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                >
                  <InputLeftAddon
                    shadow={"md"}
                    children={<MdVpnKey size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="password"
                    fontSize={"md"}
                    shadow={"md"}
                    type={showPassword ? "text" : "password"}
                    bgColor={"white"}
                    color={"black"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                
              </Stack> */}

              <Stack pt={5}>
                {loading ? (
                  <Spinner size={"sm"} />
                ) : (
                  <Button
                    alignItems={"center"}
                    justifyContent="center"
                    size={"sm"}
                    bgColor={"white"}
                    onClick={() => handleUpdatePassword()}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdatePassword();
                      }
                    }}
                  >
                    <Text color={"black"} fontSize="xs" fontWeight="bold">
                      SEND RESET PASSWORD
                    </Text>
                  </Button>
                )}
              </Stack>

              <Spacer />

              {/* <HStack space={1}>
                <Text color={"gray.400"} fontSize={["xs", null, "sm"]}>
                  Don't have any account ?
                </Text>
                <Text
                  color={"gray.400"}
                  fontSize={["xs", null, "sm"]}
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() => navigate("/signup")}
                >
                  Join now !
                </Text>
              </HStack> */}
            </Stack>
          </Stack>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default ForgotPassword;
