import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  deleteDocumentFirebase,
  deleteFileFirebase,
  getCollectionFirebase,
  updateDocumentFirebase,
} from "../../Api/firebaseApi";
import useUserStore from "../../Hooks/Zustand/Store";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AddButtons from "../../Components/Buttons/AddButtons";
import { FcPlus } from "react-icons/fc";
import { DeleteIcon } from "@chakra-ui/icons";
import { FiEdit, FiEye } from "react-icons/fi";
import BackButtons from "../../Components/Buttons/BackButtons";
import DynamicButton from "../../Components/Buttons/DynamicButton";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { GrSchedule } from "react-icons/gr";

const DoctorListPage = () => {
  const modalDelete = useDisclosure();
  const globalState = useUserStore();
  const navigate = useNavigate();
  const toast = useToast();

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 6;

  const [dataProducts, setDataProducts] = useState([]);
  const [dataModal, setDataModal] = useState();
  const [categories, setCategories] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchedDataProduct, setSearchedDataProduct] = useState([]);

  const getDoctor = async () => {
    const collectionRef = collection(db, "doctor-deoapp-clinic");
    try {
      const querySnapshot = await getDocs(collectionRef);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDataProducts(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error);
    }
  };
  console.log(dataProducts, "hhahaha");
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const totalItems = dataProducts?.length || searchedDataProduct?.length || 0;
  const shouldShowLoadMore = totalItems >= startIndex + itemsPerPage;

  const searchTreatmentFunction = (text) => {
    if (text || text !== "") {
      const newData = dataProducts.filter((item) => {
        const treatmentData = item?.treatment || [];
        console.log(treatmentData, "treat");
  
        const matchedTreatments = treatmentData.filter((treatment) => {
          
          if (typeof treatment === 'string') {
            return treatment.toUpperCase().includes(text.toUpperCase());
          } else if (typeof treatment === 'object' && treatment.title) {
            // Jika treatment berupa objek dengan properti 'title'
            return treatment.title.toUpperCase().includes(text.toUpperCase());
          } else {
            return false;
          }
        });
  console.log(matchedTreatments,"matched")
        return matchedTreatments.length > 0;
      });
  
      setSearchedDataProduct(newData);
      setSearchInput(text);
    } else {
      setSearchedDataProduct(dataProducts);
      setSearchInput(text);
    }
  };
  
  
  const searchFilterFunction = (text) => {
    if (text || text !== "") {
      const newData = dataProducts.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      console.log(newData, "new");
      setSearchedDataProduct(newData);
      setSearchInput(text);
    } else {
      setSearchedDataProduct(dataProducts);
      setSearchInput(text);
    }
  };

  const getTreatment = async () => {
    const collectionRef = collection(db, "treatment-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error);
    }
  };

  const handleModal = (data) => {
    modalDelete.onOpen();
    setDataModal(data);
  };

  console.log(dataModal);

  const handleDeletePages = async (categoryId) => {
    try {
      console.log("treatment", categoryId.treatment);
      if (
        Array.isArray(categoryId.treatment) &&
        categoryId.treatment.length > 0
      ) {
        for (const treatment of categoryId.treatment) {
          const treatmentDocRef = doc(
            db,
            "treatment-deoapp-clinic",
            treatment.id
          );

          const treatmentDocSnapshot = await getDoc(treatmentDocRef);
          console.log(treatmentDocSnapshot.data(), "data ini");
          if (treatmentDocSnapshot.exists()) {
            const existingData = treatmentDocSnapshot.data();
            console.log("existingData:", existingData);

            const updatedDoctors = existingData.doctor.filter(
              (doc) => doc.id !== categoryId.id && doc.name !== categoryId.name
            );

            await updateDoc(treatmentDocRef, { doctor: updatedDoctors });
          }
        }
      }

      await deleteDoc(doc(db, "doctor-deoapp-clinic", categoryId.id));
      toast({
        title: "Success",
        description: "Doctor Removed",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      modalDelete.onClose();
      getDoctor();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast({
        title: "Error",
        description: "Failed to remove doctor",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const inputStyles = {
    "&::placeholder": {
      color: "gray.500",
    },
  };

  useEffect(() => {
    getDoctor();
    getTreatment();
    return () => {};
  }, [globalState.currentProject]);

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={4}>
        <HStack gap={5}>
          <BackButtons />
          <Heading size={"md"} fontWeight="bold">
            List Doctor
          </Heading>
          <Spacer />
          {/* <HStack> */}
          <Select
            placeholder="Select Treatment"
            bg="white"
            w={"35%"}
            onChange={(e) => searchTreatmentFunction(e.target.value)}
          >
            {Array.isArray(categories) &&
              categories.map((category) => (
                <option key={category.id} value={category.title}>
                  {category.title}
                </option>
              ))}
          </Select>
          <Input
            type="text"
            placeholder="Search Doctor ..."
            bgColor="white"
            color="black"
            sx={inputStyles}
            w={"15%"}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />
          <DynamicButton
            action={"create"}
            title={"Add Doctor"}
            onClick={() => navigate("/booking/list-doctor/create")}
          />

          {/* </HStack> */}
        </HStack>
        <Stack bg={"white"} p={5} borderRadius={"md"} shadow={"md"}>
          {searchInput !== "" ? (
            <>
              {searchedDataProduct?.length > 0 ? (
                <SimpleGrid columns={[1, null, 4]} spacing={3}>
                  {searchedDataProduct.map((product, i) => (
                    <Stack
                      shadow={"base"}
                      gap={3}
                      key={i}
                      bg={"white"}
                      borderRadius={"md"}
                      p={3}
                    >
                      <Stack>
                        <Image
                          src={product?.thumbnail}
                          boxSize={"fit-content"}
                          // w={"fit-content"}
                          objectFit="cover"
                        />
                      </Stack>
                      <Spacer />
                      <Stack align={"center"}>
                        <Box alignSelf={"center"}>
                          <HStack justifyContent={"center"}>
                            <Heading size={"md"}>{product?.name}</Heading>
                          </HStack>
                        </Box>
                        <HStack
                          alignItems={"center"}
                          justify={"space-evenly"}
                          spacing={3}
                        >
                          {/* <HStack spacing={1}>
                              <FiEye /> */}
                          {/* <Text fontSize={12}>{product?.description}</Text> */}
                          {/* <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-treatment/create/${product.id}`)
                                }
                              > */}
                          {/* <a href={`/products/article/${product.id}`}> */}
                          {/* View */}
                          {/* </a> */}
                          {/* </Text>
                            </HStack> */}
                          <HStack spacing={1}>
                            <FiEdit />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(
                                  `/booking/list-doctor/edit/${product.id}`
                                )
                              }
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              Edit
                              {/* </a> */}
                            </Text>
                          </HStack>

                          <HStack spacing={1}>
                            <DeleteIcon boxSize={3} />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() => handleModal(product)}
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              Delete
                              {/* </a> */}
                            </Text>
                          </HStack>

                          {/* <Button
                              variant={"ghost"}
                              size={"xs"}
                              w={"fit-content"}
                              onClick={() => handleModal(product)}
                            >
                              <DeleteIcon boxSize={3} />
                            </Button> */}
                        </HStack>
                        <Spacer />
                      </Stack>
                    </Stack>
                  ))}
                </SimpleGrid>
              ) : (
                <Box bg={"white"} borderRadius={"md"} p={3}>
                  <Center>
                    <Heading size={"md"}>No Products</Heading>
                  </Center>
                </Box>
              )}
            </>
          ) : (
            <>
              {dataProducts?.length > 0 ? (
                <SimpleGrid columns={[1, null, 4]} spacing={3}>
                  {dataProducts.map((product, i) => (
                    <Stack
                      shadow={"base"}
                      gap={3}
                      key={i}
                      bg={"white"}
                      borderRadius={"md"}
                      p={3}
                    >
                      <Stack>
                        <Image
                          src={product?.thumbnail}
                          boxSize={"fit-content"}
                          // w={"fit-content"}
                          objectFit="cover"
                        />
                      </Stack>
                      <Spacer />
                      <Stack align={"center"}>
                        <Box alignSelf={"center"}>
                          <HStack justifyContent={"center"}>
                            <Heading size={"md"}>{product?.name}</Heading>
                          </HStack>
                        </Box>
                        <HStack
                          alignItems={"center"}
                          justify={"space-evenly"}
                          spacing={3}
                        >
                          {/* <HStack spacing={1}>
                              <FiEye /> */}
                          {/* <Text fontSize={12}>{product?.description}</Text> */}
                          {/* <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-treatment/create/${product.id}`)
                                }
                              > */}
                          {/* <a href={`/products/article/${product.id}`}> */}
                          {/* View */}
                          {/* </a> */}
                          {/* </Text>
                            </HStack> */}
                          <HStack spacing={1}>
                            <FiEdit />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>
                                navigate(
                                  `/booking/list-doctor/edit/${product.id}`
                                )
                              }
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              Edit
                              {/* </a> */}
                            </Text>
                          </HStack>

                          <HStack spacing={1}>
                            <DeleteIcon boxSize={3} />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() => handleModal(product)}
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              Delete
                              {/* </a> */}
                            </Text>
                          </HStack>

                          <HStack spacing={1}>
                            <GrSchedule  boxSize={3} />
                            <Text
                              fontSize={12}
                              cursor={"pointer"}
                              onClick={() =>                               
                                navigate(
                                  `/doctor/doctor-schudle/${product.id}`
                                )}
                            >
                              {/* <a href={`/products/article/${product.id}`}> */}
                              Schedule
                              {/* </a> */}
                            </Text>
                          </HStack>

                          {/* <Button
                                variant={"ghost"}
                                size={"xs"}
                                w={"fit-content"}
                                onClick={() => handleModal(product)}
                              >
                                <DeleteIcon boxSize={3} />
                              </Button> */}
                        </HStack>
                        <Spacer />
                      </Stack>
                    </Stack>
                  ))}
                </SimpleGrid>
              ) : (
                <Box bg={"white"} borderRadius={"md"} p={3}>
                  <Center>
                    <Heading size={"md"}>No Products</Heading>
                  </Center>
                </Box>
              )}
            </>
          )}
        </Stack>

        <Stack alignItems={"center"} justifyContent="center">
          <Box>
            {shouldShowLoadMore && (
              <Button onClick={handleLoadMore} size="sm">
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Doctor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure want to Remove Doctor <b>{dataModal?.name}</b>?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              leftIcon={<DeleteIcon />}
              onClick={() => handleDeletePages(dataModal)}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default DoctorListPage;
