// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

import song from "../assets/CoinDrop-Notification.mp3";
import { getDatabase } from "firebase/database";

let firebaseConfig = {};

  firebaseConfig = {
    apiKey: "AIzaSyASn7-_uwbRKTXNXk4EzJ9WaNVzjJul-oI",
    authDomain: "deoapp-indonesia-staging.firebaseapp.com",
    databaseURL: "https://deoapp-indonesia-staging-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "deoapp-indonesia-staging",
    storageBucket: "deoapp-indonesia-staging.appspot.com",
    messagingSenderId: "668514366913",
    appId: "1:668514366913:web:9e763ec42c426ade386b40",
    measurementId: "G-GTT63FV9SF"
  };



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
const configMessage = getMessaging(app);
auth.languageCode = "id";

export { app, analytics, auth, db, storage, database };
export const fetchToken = async () => {
  try {
    const token = await getToken(configMessage, {
      vapidKey: firebaseConfig.token_option,
    });
    if (token) {
      return token;
    } else {
      console.log("no push notif token for now");
    }
  } catch (error) {}
};

export const onMessageListener = (toast) => {
  onMessage(configMessage, (payload) => {
    const notif = new Audio(song);
    notif.play();
    const { data } = payload;
    const { title, description } = data;
    toast({
      title: title,
      description: description,
      position: "top-right",
      isClosable: true,
    });
  });
};
