import { Box, HStack,Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Image,
    Text,
    Input,
    Heading,
    Spacer,
    Button, } from '@chakra-ui/react'
import React from 'react'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import BackButtons from '../../../Components/Buttons/BackButtons'

function MarketingKolListPage() {
    const data = [
        {
          "no": 1,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "raisyalmadea",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic1\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@raisyalmadea\" target=\"_blank\">raisyalmadea</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "45,000",
          "TOTAL_VIEW_LAST_12": "5,847,677",
          "AVG_VIEW_LAST_12": "487,306",
          "AVG_LIKE_LAST_12": "50,003",
          "AVG_COMMENT_LAST_12": "332",
          "AVG_SHARE_LAST_12": "775",
          "MIN_VIEW_LAST_12": "3,393",
          "MIN_LIKE_LAST_12": "102",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "2,200,000",
          "MAX_LIKE_LAST_12": "306,900",
          "MAX_COMMENT_LAST_12": "3,471",
          "MAX_SHARE_LAST_12": "6,350",
          "MED_VIEW_LAST_12": "11,200",
          "MED_LIKE_LAST_12": "532",
          "MED_COMMENT_LAST_12": "7",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14698\" data-kol_nama=\"raisyalmadea\" data-hp_kol=\"\" data-username_tiktok=\"raisyalmadea\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 2,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "valerieaurora",
          "kategori_id": "life style",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic2\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@valerieaurora\" target=\"_blank\">valerieaurora</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "322,300",
          "TOTAL_VIEW_LAST_12": "1,297,978",
          "AVG_VIEW_LAST_12": "108,165",
          "AVG_LIKE_LAST_12": "3,507",
          "AVG_COMMENT_LAST_12": "32",
          "AVG_SHARE_LAST_12": "20",
          "MIN_VIEW_LAST_12": "1,863",
          "MIN_LIKE_LAST_12": "155",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "430,200",
          "MAX_LIKE_LAST_12": "13,200",
          "MAX_COMMENT_LAST_12": "221",
          "MAX_SHARE_LAST_12": "77",
          "MED_VIEW_LAST_12": "44,800",
          "MED_LIKE_LAST_12": "2,082",
          "MED_COMMENT_LAST_12": "10",
          "MED_SHARE_LAST_12": "8",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14697\" data-kol_nama=\"valerieaurora\" data-hp_kol=\"\" data-username_tiktok=\"valerieaurora\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"life style\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 3,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "lifnisanders",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic3\"></ul>",
          "KETERANGAN_KOL": "consider",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@lifnisanders\" target=\"_blank\">lifnisanders</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "129,600",
          "TOTAL_VIEW_LAST_12": "2,910,600",
          "AVG_VIEW_LAST_12": "242,550",
          "AVG_LIKE_LAST_12": "8,897",
          "AVG_COMMENT_LAST_12": "69",
          "AVG_SHARE_LAST_12": "47",
          "MIN_VIEW_LAST_12": "13,900",
          "MIN_LIKE_LAST_12": "666",
          "MIN_COMMENT_LAST_12": "11",
          "MIN_SHARE_LAST_12": "2",
          "MAX_VIEW_LAST_12": "1,100,000",
          "MAX_LIKE_LAST_12": "53,100",
          "MAX_COMMENT_LAST_12": "178",
          "MAX_SHARE_LAST_12": "160",
          "MED_VIEW_LAST_12": "114,450",
          "MED_LIKE_LAST_12": "4,352",
          "MED_COMMENT_LAST_12": "60",
          "MED_SHARE_LAST_12": "18",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14696\" data-kol_nama=\"lifnisanders\" data-hp_kol=\"\" data-username_tiktok=\"lifnisanders\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"consider\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 4,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "skincarebyjessi",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic4\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@skincarebyjessi\" target=\"_blank\">skincarebyjessi</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "588,800",
          "TOTAL_VIEW_LAST_12": "15,988,767",
          "AVG_VIEW_LAST_12": "1,332,400",
          "AVG_LIKE_LAST_12": "119,760",
          "AVG_COMMENT_LAST_12": "355",
          "AVG_SHARE_LAST_12": "919",
          "MIN_VIEW_LAST_12": "4,667",
          "MIN_LIKE_LAST_12": "418",
          "MIN_COMMENT_LAST_12": "5",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "7,500,000",
          "MAX_LIKE_LAST_12": "686,500",
          "MAX_COMMENT_LAST_12": "1,798",
          "MAX_SHARE_LAST_12": "7,695",
          "MED_VIEW_LAST_12": "43,200",
          "MED_LIKE_LAST_12": "2,426",
          "MED_COMMENT_LAST_12": "21",
          "MED_SHARE_LAST_12": "3",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14695\" data-kol_nama=\"skincarebyjessi\" data-hp_kol=\"\" data-username_tiktok=\"skincarebyjessi\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 5,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "tessa_aan",
          "kategori_id": "story telling",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic5\"></ul>",
          "KETERANGAN_KOL": "consider",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@tessa_aan\" target=\"_blank\">tessa_aan</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "364,600",
          "TOTAL_VIEW_LAST_12": "1,108,200",
          "AVG_VIEW_LAST_12": "92,350",
          "AVG_LIKE_LAST_12": "3,649",
          "AVG_COMMENT_LAST_12": "41",
          "AVG_SHARE_LAST_12": "16",
          "MIN_VIEW_LAST_12": "12,900",
          "MIN_LIKE_LAST_12": "488",
          "MIN_COMMENT_LAST_12": "9",
          "MIN_SHARE_LAST_12": "1",
          "MAX_VIEW_LAST_12": "474,600",
          "MAX_LIKE_LAST_12": "25,300",
          "MAX_COMMENT_LAST_12": "159",
          "MAX_SHARE_LAST_12": "122",
          "MED_VIEW_LAST_12": "23,800",
          "MED_LIKE_LAST_12": "1,170",
          "MED_COMMENT_LAST_12": "24",
          "MED_SHARE_LAST_12": "5",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14694\" data-kol_nama=\"tessa_aan\" data-hp_kol=\"\" data-username_tiktok=\"tessa_aan\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"consider\" data-kategori=\"story telling\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 6,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "yolanlimmm",
          "kategori_id": "a day in my life",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic6\"></ul>",
          "KETERANGAN_KOL": "awarenes",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@yolanlimmm\" target=\"_blank\">yolanlimmm</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "223,900",
          "TOTAL_VIEW_LAST_12": "3,856,997",
          "AVG_VIEW_LAST_12": "321,416",
          "AVG_LIKE_LAST_12": "42,414",
          "AVG_COMMENT_LAST_12": "104",
          "AVG_SHARE_LAST_12": "175",
          "MIN_VIEW_LAST_12": "6,451",
          "MIN_LIKE_LAST_12": "306",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "6",
          "MAX_VIEW_LAST_12": "2,000,000",
          "MAX_LIKE_LAST_12": "294,800",
          "MAX_COMMENT_LAST_12": "591",
          "MAX_SHARE_LAST_12": "1,176",
          "MED_VIEW_LAST_12": "52,000",
          "MED_LIKE_LAST_12": "7,288",
          "MED_COMMENT_LAST_12": "54",
          "MED_SHARE_LAST_12": "37",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14693\" data-kol_nama=\"yolanlimmm\" data-hp_kol=\"\" data-username_tiktok=\"yolanlimmm\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awarenes\" data-kategori=\"a day in my life\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 7,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "florie_aa_",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic7\"></ul>",
          "KETERANGAN_KOL": "consider",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@florie_aa_\" target=\"_blank\">florie_aa_</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "2,700,000",
          "TOTAL_VIEW_LAST_12": "29,406,500",
          "AVG_VIEW_LAST_12": "2,450,540",
          "AVG_LIKE_LAST_12": "280,378",
          "AVG_COMMENT_LAST_12": "1,264",
          "AVG_SHARE_LAST_12": "1,480",
          "MIN_VIEW_LAST_12": "28,900",
          "MIN_LIKE_LAST_12": "620",
          "MIN_COMMENT_LAST_12": "6",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "15,500,000",
          "MAX_LIKE_LAST_12": "1,500,000",
          "MAX_COMMENT_LAST_12": "11,100",
          "MAX_SHARE_LAST_12": "12,100",
          "MED_VIEW_LAST_12": "202,850",
          "MED_LIKE_LAST_12": "13,150",
          "MED_COMMENT_LAST_12": "61",
          "MED_SHARE_LAST_12": "11",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14692\" data-kol_nama=\"florie_aa_\" data-hp_kol=\"\" data-username_tiktok=\"florie_aa_\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"consider\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 8,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "salyangkamu",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic8\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@salyangkamu\" target=\"_blank\">salyangkamu</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "790,700",
          "TOTAL_VIEW_LAST_12": "233,302",
          "AVG_VIEW_LAST_12": "19,442",
          "AVG_LIKE_LAST_12": "1,435",
          "AVG_COMMENT_LAST_12": "10",
          "AVG_SHARE_LAST_12": "13",
          "MIN_VIEW_LAST_12": "1,782",
          "MIN_LIKE_LAST_12": "103",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "151,800",
          "MAX_LIKE_LAST_12": "12,000",
          "MAX_COMMENT_LAST_12": "26",
          "MAX_SHARE_LAST_12": "130",
          "MED_VIEW_LAST_12": "7,319",
          "MED_LIKE_LAST_12": "467",
          "MED_COMMENT_LAST_12": "9",
          "MED_SHARE_LAST_12": "3",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14691\" data-kol_nama=\"salyangkamu\" data-hp_kol=\"\" data-username_tiktok=\"salyangkamu\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 9,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "nadiaramadhani_",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic9\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@nadiaramadhani_\" target=\"_blank\">nadiaramadhani_</a>",
          "RATE": "Rp. 850,000",
          "TIKTOK_FOLLOWERS": "196,700",
          "TOTAL_VIEW_LAST_12": "3,328,720",
          "AVG_VIEW_LAST_12": "277,393",
          "AVG_LIKE_LAST_12": "28,285",
          "AVG_COMMENT_LAST_12": "357",
          "AVG_SHARE_LAST_12": "304",
          "MIN_VIEW_LAST_12": "4,407",
          "MIN_LIKE_LAST_12": "180",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "1,800,000",
          "MAX_LIKE_LAST_12": "186,100",
          "MAX_COMMENT_LAST_12": "2,553",
          "MAX_SHARE_LAST_12": "2,231",
          "MED_VIEW_LAST_12": "8,854",
          "MED_LIKE_LAST_12": "504",
          "MED_COMMENT_LAST_12": "4",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">3,064</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">192,875</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">472</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">96,002</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14690\" data-kol_nama=\"nadiaramadhani_\" data-hp_kol=\"\" data-username_tiktok=\"nadiaramadhani_\" data-rate=\"850000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 10,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "soleilmoon_",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic10\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@soleilmoon_\" target=\"_blank\">soleilmoon_</a>",
          "RATE": "Rp. 595,000",
          "TIKTOK_FOLLOWERS": "19,500",
          "TOTAL_VIEW_LAST_12": "8,791,526",
          "AVG_VIEW_LAST_12": "732,627",
          "AVG_LIKE_LAST_12": "10,766",
          "AVG_COMMENT_LAST_12": "57",
          "AVG_SHARE_LAST_12": "53",
          "MIN_VIEW_LAST_12": "652",
          "MIN_LIKE_LAST_12": "93",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "6,400,000",
          "MAX_LIKE_LAST_12": "62,800",
          "MAX_COMMENT_LAST_12": "358",
          "MAX_SHARE_LAST_12": "323",
          "MED_VIEW_LAST_12": "11,000",
          "MED_LIKE_LAST_12": "1,280",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "12",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">812</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">912,577</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">93</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">54,091</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14689\" data-kol_nama=\"soleilmoon_\" data-hp_kol=\"\" data-username_tiktok=\"soleilmoon_\" data-rate=\"595000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 11,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "fellows.hi",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic11\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@fellows.hi\" target=\"_blank\">fellows.hi</a>",
          "RATE": "Rp. 850,000",
          "TIKTOK_FOLLOWERS": "318,700",
          "TOTAL_VIEW_LAST_12": "2,597,529",
          "AVG_VIEW_LAST_12": "216,461",
          "AVG_LIKE_LAST_12": "24,041",
          "AVG_COMMENT_LAST_12": "228",
          "AVG_SHARE_LAST_12": "393",
          "MIN_VIEW_LAST_12": "1,116",
          "MIN_LIKE_LAST_12": "95",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "1,600,000",
          "MAX_LIKE_LAST_12": "191,900",
          "MAX_COMMENT_LAST_12": "1,375",
          "MAX_SHARE_LAST_12": "3,830",
          "MED_VIEW_LAST_12": "4,071",
          "MED_LIKE_LAST_12": "308",
          "MED_COMMENT_LAST_12": "2",
          "MED_SHARE_LAST_12": "5",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">3,927</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">761,649</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">531</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">208,794</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14688\" data-kol_nama=\"fellows.hi\" data-hp_kol=\"\" data-username_tiktok=\"fellows.hi\" data-rate=\"850000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 12,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "onedinda",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic12\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@onedinda\" target=\"_blank\">onedinda</a>",
          "RATE": "Rp. 1,600,000",
          "TIKTOK_FOLLOWERS": "359,700",
          "TOTAL_VIEW_LAST_12": "4,023,872",
          "AVG_VIEW_LAST_12": "335,323",
          "AVG_LIKE_LAST_12": "23,820",
          "AVG_COMMENT_LAST_12": "139",
          "AVG_SHARE_LAST_12": "131",
          "MIN_VIEW_LAST_12": "2,572",
          "MIN_LIKE_LAST_12": "268",
          "MIN_COMMENT_LAST_12": "20",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "3,000,000",
          "MAX_LIKE_LAST_12": "210,000",
          "MAX_COMMENT_LAST_12": "856",
          "MAX_SHARE_LAST_12": "1,363",
          "MED_VIEW_LAST_12": "76,650",
          "MED_LIKE_LAST_12": "7,282",
          "MED_COMMENT_LAST_12": "61",
          "MED_SHARE_LAST_12": "15",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">4,772</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">622,084</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">533</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-warning font-weight-normal\" style=\"font-weight: normal !important;\">20,874</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14687\" data-kol_nama=\"onedinda\" data-hp_kol=\"\" data-username_tiktok=\"onedinda\" data-rate=\"1600000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 13,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "faizaannisahh",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic13\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@faizaannisahh\" target=\"_blank\">faizaannisahh</a>",
          "RATE": "Rp. 950,000",
          "TIKTOK_FOLLOWERS": "66,200",
          "TOTAL_VIEW_LAST_12": "1,569,495",
          "AVG_VIEW_LAST_12": "130,791",
          "AVG_LIKE_LAST_12": "15,931",
          "AVG_COMMENT_LAST_12": "169",
          "AVG_SHARE_LAST_12": "152",
          "MIN_VIEW_LAST_12": "524",
          "MIN_LIKE_LAST_12": "40",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "542,200",
          "MAX_LIKE_LAST_12": "91,900",
          "MAX_COMMENT_LAST_12": "1,010",
          "MAX_SHARE_LAST_12": "862",
          "MED_VIEW_LAST_12": "2,762",
          "MED_LIKE_LAST_12": "87",
          "MED_COMMENT_LAST_12": "1",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">7,263</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,812,980</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,752</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">343,954</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14686\" data-kol_nama=\"faizaannisahh\" data-hp_kol=\"\" data-username_tiktok=\"faizaannisahh\" data-rate=\"950000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 14,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "akuubukanbocil",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic14\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@akuubukanbocil\" target=\"_blank\">akuubukanbocil</a>",
          "RATE": "Rp. 995,000",
          "TIKTOK_FOLLOWERS": "713,800",
          "TOTAL_VIEW_LAST_12": "6,068,012",
          "AVG_VIEW_LAST_12": "505,668",
          "AVG_LIKE_LAST_12": "57,143",
          "AVG_COMMENT_LAST_12": "780",
          "AVG_SHARE_LAST_12": "1,083",
          "MIN_VIEW_LAST_12": "2,202",
          "MIN_LIKE_LAST_12": "107",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "5,100,000",
          "MAX_LIKE_LAST_12": "634,700",
          "MAX_COMMENT_LAST_12": "8,897",
          "MAX_SHARE_LAST_12": "12,200",
          "MED_VIEW_LAST_12": "6,186",
          "MED_LIKE_LAST_12": "526",
          "MED_COMMENT_LAST_12": "8",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,968</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">451,862</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">195</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">160,860</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14685\" data-kol_nama=\"akuubukanbocil\" data-hp_kol=\"\" data-username_tiktok=\"akuubukanbocil\" data-rate=\"995000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 15,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "thisisn0teunooiaa._",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic15\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@thisisn0teunooiaa._\" target=\"_blank\">thisisn0teunooiaa._</a>",
          "RATE": "Rp. 765,000",
          "TIKTOK_FOLLOWERS": "84,100",
          "TOTAL_VIEW_LAST_12": "3,327,481",
          "AVG_VIEW_LAST_12": "277,290",
          "AVG_LIKE_LAST_12": "43,984",
          "AVG_COMMENT_LAST_12": "99",
          "AVG_SHARE_LAST_12": "285",
          "MIN_VIEW_LAST_12": "4,204",
          "MIN_LIKE_LAST_12": "581",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "1",
          "MAX_VIEW_LAST_12": "2,700,000",
          "MAX_LIKE_LAST_12": "427,800",
          "MAX_COMMENT_LAST_12": "832",
          "MAX_SHARE_LAST_12": "2,710",
          "MED_VIEW_LAST_12": "29,100",
          "MED_LIKE_LAST_12": "4,061",
          "MED_COMMENT_LAST_12": "20",
          "MED_SHARE_LAST_12": "14",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">2,759</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">181,970</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">283</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-warning font-weight-normal\" style=\"font-weight: normal !important;\">26,289</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14684\" data-kol_nama=\"thisisn0teunooiaa._\" data-hp_kol=\"\" data-username_tiktok=\"thisisn0teunooiaa._\" data-rate=\"765000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 16,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "thatsenjaa",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic16\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@thatsenjaa\" target=\"_blank\">thatsenjaa</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "67,700",
          "TOTAL_VIEW_LAST_12": "1,186,953",
          "AVG_VIEW_LAST_12": "98,913",
          "AVG_LIKE_LAST_12": "19,837",
          "AVG_COMMENT_LAST_12": "173",
          "AVG_SHARE_LAST_12": "333",
          "MIN_VIEW_LAST_12": "554",
          "MIN_LIKE_LAST_12": "43",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "679,800",
          "MAX_LIKE_LAST_12": "168,400",
          "MAX_COMMENT_LAST_12": "1,901",
          "MAX_SHARE_LAST_12": "3,882",
          "MED_VIEW_LAST_12": "3,515",
          "MED_LIKE_LAST_12": "280",
          "MED_COMMENT_LAST_12": "1",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">9,048</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,615,520</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,317</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">254,623</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14683\" data-kol_nama=\"thatsenjaa\" data-hp_kol=\"\" data-username_tiktok=\"thatsenjaa\" data-rate=\"895000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 17,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "tatanzrh",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic17\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@tatanzrh\" target=\"_blank\">tatanzrh</a>",
          "RATE": "Rp. 875,000",
          "TIKTOK_FOLLOWERS": "245,600",
          "TOTAL_VIEW_LAST_12": "28,496",
          "AVG_VIEW_LAST_12": "2,375",
          "AVG_LIKE_LAST_12": "48",
          "AVG_COMMENT_LAST_12": "1",
          "AVG_SHARE_LAST_12": "0",
          "MIN_VIEW_LAST_12": "254",
          "MIN_LIKE_LAST_12": "20",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "8,896",
          "MAX_LIKE_LAST_12": "92",
          "MAX_COMMENT_LAST_12": "3",
          "MAX_SHARE_LAST_12": "2",
          "MED_VIEW_LAST_12": "1,550",
          "MED_LIKE_LAST_12": "37",
          "MED_COMMENT_LAST_12": "1",
          "MED_SHARE_LAST_12": "0",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">368,473</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">3,444,880</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">98,359</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">564,516</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14682\" data-kol_nama=\"tatanzrh\" data-hp_kol=\"\" data-username_tiktok=\"tatanzrh\" data-rate=\"875000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 18,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "tyvarra",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic18\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@tyvarra\" target=\"_blank\">tyvarra</a>",
          "RATE": "Rp. 1,200,000",
          "TIKTOK_FOLLOWERS": "249,900",
          "TOTAL_VIEW_LAST_12": "158,288",
          "AVG_VIEW_LAST_12": "13,191",
          "AVG_LIKE_LAST_12": "435",
          "AVG_COMMENT_LAST_12": "3",
          "AVG_SHARE_LAST_12": "3",
          "MIN_VIEW_LAST_12": "782",
          "MIN_LIKE_LAST_12": "47",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "120,700",
          "MAX_LIKE_LAST_12": "2,101",
          "MAX_COMMENT_LAST_12": "11",
          "MAX_SHARE_LAST_12": "11",
          "MED_VIEW_LAST_12": "3,153",
          "MED_LIKE_LAST_12": "256",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">90,973</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,534,530</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">9,942</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">380,590</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14680\" data-kol_nama=\"tyvarra\" data-hp_kol=\"\" data-username_tiktok=\"tyvarra\" data-rate=\"1200000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 19,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "sukmaasuk",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic19\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@sukmaasuk\" target=\"_blank\">sukmaasuk</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "63,600",
          "TOTAL_VIEW_LAST_12": "7,232,834",
          "AVG_VIEW_LAST_12": "602,736",
          "AVG_LIKE_LAST_12": "39,779",
          "AVG_COMMENT_LAST_12": "193",
          "AVG_SHARE_LAST_12": "962",
          "MIN_VIEW_LAST_12": "498",
          "MIN_LIKE_LAST_12": "18",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "5,600,000",
          "MAX_LIKE_LAST_12": "390,300",
          "MAX_COMMENT_LAST_12": "1,941",
          "MAX_SHARE_LAST_12": "9,711",
          "MED_VIEW_LAST_12": "5,252",
          "MED_LIKE_LAST_12": "204",
          "MED_COMMENT_LAST_12": "6",
          "MED_SHARE_LAST_12": "0",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,485</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,797,190</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">160</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">170,427</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14679\" data-kol_nama=\"sukmaasuk\" data-hp_kol=\"\" data-username_tiktok=\"sukmaasuk\" data-rate=\"895000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 20,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "inialipaaa",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic20\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@inialipaaa\" target=\"_blank\">inialipaaa</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "146,200",
          "TOTAL_VIEW_LAST_12": "6,523,237",
          "AVG_VIEW_LAST_12": "543,603",
          "AVG_LIKE_LAST_12": "46,280",
          "AVG_COMMENT_LAST_12": "197",
          "AVG_SHARE_LAST_12": "173",
          "MIN_VIEW_LAST_12": "357",
          "MIN_LIKE_LAST_12": "21",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "3,300,000",
          "MAX_LIKE_LAST_12": "255,200",
          "MAX_COMMENT_LAST_12": "1,171",
          "MAX_SHARE_LAST_12": "1,058",
          "MED_VIEW_LAST_12": "4,118",
          "MED_LIKE_LAST_12": "144",
          "MED_COMMENT_LAST_12": "8",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,646</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">2,507,000</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">271</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">217,365</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14678\" data-kol_nama=\"inialipaaa\" data-hp_kol=\"\" data-username_tiktok=\"inialipaaa\" data-rate=\"895000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 21,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "inayatnnisa",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic21\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@inayatnnisa\" target=\"_blank\">inayatnnisa</a>",
          "RATE": "Rp. 595,000",
          "TIKTOK_FOLLOWERS": "16,500",
          "TOTAL_VIEW_LAST_12": "1,526,056",
          "AVG_VIEW_LAST_12": "127,171",
          "AVG_LIKE_LAST_12": "2,738",
          "AVG_COMMENT_LAST_12": "29",
          "AVG_SHARE_LAST_12": "46",
          "MIN_VIEW_LAST_12": "318",
          "MIN_LIKE_LAST_12": "6",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "952,600",
          "MAX_LIKE_LAST_12": "20,400",
          "MAX_COMMENT_LAST_12": "198",
          "MAX_SHARE_LAST_12": "391",
          "MED_VIEW_LAST_12": "2,058",
          "MED_LIKE_LAST_12": "27",
          "MED_COMMENT_LAST_12": "0",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">4,679</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,871,070</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">625</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">289,186</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14677\" data-kol_nama=\"inayatnnisa\" data-hp_kol=\"\" data-username_tiktok=\"inayatnnisa\" data-rate=\"595000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 22,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "imnotnadya",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic22\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@imnotnadya\" target=\"_blank\">imnotnadya</a>",
          "RATE": "Rp. 795,000",
          "TIKTOK_FOLLOWERS": "60,900",
          "TOTAL_VIEW_LAST_12": "489,567",
          "AVG_VIEW_LAST_12": "40,797",
          "AVG_LIKE_LAST_12": "1,296",
          "AVG_COMMENT_LAST_12": "5",
          "AVG_SHARE_LAST_12": "16",
          "MIN_VIEW_LAST_12": "1,388",
          "MIN_LIKE_LAST_12": "31",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "337,400",
          "MAX_LIKE_LAST_12": "10,400",
          "MAX_COMMENT_LAST_12": "32",
          "MAX_SHARE_LAST_12": "162",
          "MED_VIEW_LAST_12": "4,062",
          "MED_LIKE_LAST_12": "125",
          "MED_COMMENT_LAST_12": "1",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-warning font-weight-normal\" style=\"font-weight: normal !important;\">19,487</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">572,767</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">2,356</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">195,716</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14676\" data-kol_nama=\"imnotnadya\" data-hp_kol=\"\" data-username_tiktok=\"imnotnadya\" data-rate=\"795000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 23,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "chupachups5ss",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic23\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@chupachups5ss\" target=\"_blank\">chupachups5ss</a>",
          "RATE": "Rp. 695,000",
          "TIKTOK_FOLLOWERS": "45,500",
          "TOTAL_VIEW_LAST_12": "274,330",
          "AVG_VIEW_LAST_12": "22,861",
          "AVG_LIKE_LAST_12": "2,843",
          "AVG_COMMENT_LAST_12": "8",
          "AVG_SHARE_LAST_12": "19",
          "MIN_VIEW_LAST_12": "6,480",
          "MIN_LIKE_LAST_12": "127",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "78,800",
          "MAX_LIKE_LAST_12": "11,300",
          "MAX_COMMENT_LAST_12": "25",
          "MAX_SHARE_LAST_12": "45",
          "MED_VIEW_LAST_12": "16,200",
          "MED_LIKE_LAST_12": "2,029",
          "MED_COMMENT_LAST_12": "7",
          "MED_SHARE_LAST_12": "18",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">30,401</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">107,253</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">8,820</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">42,901</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14675\" data-kol_nama=\"chupachups5ss\" data-hp_kol=\"\" data-username_tiktok=\"chupachups5ss\" data-rate=\"695000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 24,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "payy______sya",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic24\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@payy______sya\" target=\"_blank\">payy______sya</a>",
          "RATE": "Rp. 1,000,000",
          "TIKTOK_FOLLOWERS": "207,900",
          "TOTAL_VIEW_LAST_12": "10,649,567",
          "AVG_VIEW_LAST_12": "887,464",
          "AVG_LIKE_LAST_12": "14,477",
          "AVG_COMMENT_LAST_12": "108",
          "AVG_SHARE_LAST_12": "172",
          "MIN_VIEW_LAST_12": "504",
          "MIN_LIKE_LAST_12": "38",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "6,100,000",
          "MAX_LIKE_LAST_12": "73,900",
          "MAX_COMMENT_LAST_12": "741",
          "MAX_SHARE_LAST_12": "1,185",
          "MED_VIEW_LAST_12": "6,432",
          "MED_LIKE_LAST_12": "253",
          "MED_COMMENT_LAST_12": "1",
          "MED_SHARE_LAST_12": "3",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,127</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,984,130</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">164</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">155,485</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14674\" data-kol_nama=\"payy______sya\" data-hp_kol=\"\" data-username_tiktok=\"payy______sya\" data-rate=\"1000000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 25,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "tinaanqelin",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic25\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@tinaanqelin\" target=\"_blank\">tinaanqelin</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "46,900",
          "TOTAL_VIEW_LAST_12": "680,693",
          "AVG_VIEW_LAST_12": "56,724",
          "AVG_LIKE_LAST_12": "3,860",
          "AVG_COMMENT_LAST_12": "18",
          "AVG_SHARE_LAST_12": "18",
          "MIN_VIEW_LAST_12": "1,453",
          "MIN_LIKE_LAST_12": "99",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "266,800",
          "MAX_LIKE_LAST_12": "37,600",
          "MAX_COMMENT_LAST_12": "138",
          "MAX_SHARE_LAST_12": "147",
          "MED_VIEW_LAST_12": "4,323",
          "MED_LIKE_LAST_12": "257",
          "MED_COMMENT_LAST_12": "4",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-warning font-weight-normal\" style=\"font-weight: normal !important;\">15,778</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">615,967</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">3,355</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">207,032</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14673\" data-kol_nama=\"tinaanqelin\" data-hp_kol=\"\" data-username_tiktok=\"tinaanqelin\" data-rate=\"895000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 26,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "ndissie",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic26\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@ndissie\" target=\"_blank\">ndissie</a>",
          "RATE": "Rp. 950,000",
          "TIKTOK_FOLLOWERS": "247,800",
          "TOTAL_VIEW_LAST_12": "1,015,803",
          "AVG_VIEW_LAST_12": "84,650",
          "AVG_LIKE_LAST_12": "10,326",
          "AVG_COMMENT_LAST_12": "58",
          "AVG_SHARE_LAST_12": "97",
          "MIN_VIEW_LAST_12": "691",
          "MIN_LIKE_LAST_12": "44",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "1",
          "MAX_VIEW_LAST_12": "943,200",
          "MAX_LIKE_LAST_12": "115,200",
          "MAX_COMMENT_LAST_12": "650",
          "MAX_SHARE_LAST_12": "1,071",
          "MED_VIEW_LAST_12": "5,839",
          "MED_LIKE_LAST_12": "797",
          "MED_COMMENT_LAST_12": "4",
          "MED_SHARE_LAST_12": "9",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">11,223</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,374,820</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,007</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">162,713</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14671\" data-kol_nama=\"ndissie\" data-hp_kol=\"\" data-username_tiktok=\"ndissie\" data-rate=\"950000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 27,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "silviafajrins",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic27\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@silviafajrins\" target=\"_blank\">silviafajrins</a>",
          "RATE": "Rp. 850,000",
          "TIKTOK_FOLLOWERS": "114,700",
          "TOTAL_VIEW_LAST_12": "1,794,430",
          "AVG_VIEW_LAST_12": "149,536",
          "AVG_LIKE_LAST_12": "15,041",
          "AVG_COMMENT_LAST_12": "107",
          "AVG_SHARE_LAST_12": "228",
          "MIN_VIEW_LAST_12": "1,870",
          "MIN_LIKE_LAST_12": "61",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "1,600,000",
          "MAX_LIKE_LAST_12": "162,000",
          "MAX_COMMENT_LAST_12": "1,177",
          "MAX_SHARE_LAST_12": "2,645",
          "MED_VIEW_LAST_12": "6,294",
          "MED_LIKE_LAST_12": "430",
          "MED_COMMENT_LAST_12": "7",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">5,684</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">454,545</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">531</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">135,049</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14670\" data-kol_nama=\"silviafajrins\" data-hp_kol=\"\" data-username_tiktok=\"silviafajrins\" data-rate=\"850000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 28,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "micloudy",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic28\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@micloudy\" target=\"_blank\">micloudy</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "64,200",
          "TOTAL_VIEW_LAST_12": "14,399,531",
          "AVG_VIEW_LAST_12": "1,199,960",
          "AVG_LIKE_LAST_12": "48,406",
          "AVG_COMMENT_LAST_12": "277",
          "AVG_SHARE_LAST_12": "234",
          "MIN_VIEW_LAST_12": "552",
          "MIN_LIKE_LAST_12": "17",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "9,300,000",
          "MAX_LIKE_LAST_12": "257,700",
          "MAX_COMMENT_LAST_12": "1,740",
          "MAX_SHARE_LAST_12": "1,052",
          "MED_VIEW_LAST_12": "3,897",
          "MED_LIKE_LAST_12": "124",
          "MED_COMMENT_LAST_12": "2",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">746</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,621,380</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">96</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">229,664</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14669\" data-kol_nama=\"micloudy\" data-hp_kol=\"\" data-username_tiktok=\"micloudy\" data-rate=\"895000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 29,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "s.aturnurce",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic29\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@s.aturnurce\" target=\"_blank\">s.aturnurce</a>",
          "RATE": "Rp. 900,000",
          "TIKTOK_FOLLOWERS": "147,500",
          "TOTAL_VIEW_LAST_12": "1,498,376",
          "AVG_VIEW_LAST_12": "124,865",
          "AVG_LIKE_LAST_12": "10,117",
          "AVG_COMMENT_LAST_12": "15",
          "AVG_SHARE_LAST_12": "20",
          "MIN_VIEW_LAST_12": "4,929",
          "MIN_LIKE_LAST_12": "70",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "1,300,000",
          "MAX_LIKE_LAST_12": "114,100",
          "MAX_COMMENT_LAST_12": "145",
          "MAX_SHARE_LAST_12": "211",
          "MED_VIEW_LAST_12": "17,500",
          "MED_LIKE_LAST_12": "563",
          "MED_COMMENT_LAST_12": "4",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">7,208</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">182,593</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">692</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">51,429</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14668\" data-kol_nama=\"s.aturnurce\" data-hp_kol=\"6281993431312\" data-username_tiktok=\"s.aturnurce\" data-rate=\"900000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white\">Wait for Approval</label>",
          "keterangan_approve": null
        },
        {
          "no": 30,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "sofhiasyaariha",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic30\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@sofhiasyaariha\" target=\"_blank\">sofhiasyaariha</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "176,700",
          "TOTAL_VIEW_LAST_12": "1,465,400",
          "AVG_VIEW_LAST_12": "122,117",
          "AVG_LIKE_LAST_12": "7,455",
          "AVG_COMMENT_LAST_12": "94",
          "AVG_SHARE_LAST_12": "43",
          "MIN_VIEW_LAST_12": "16,600",
          "MIN_LIKE_LAST_12": "149",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "2",
          "MAX_VIEW_LAST_12": "768,100",
          "MAX_LIKE_LAST_12": "49,500",
          "MAX_COMMENT_LAST_12": "869",
          "MAX_SHARE_LAST_12": "172",
          "MED_VIEW_LAST_12": "38,000",
          "MED_LIKE_LAST_12": "1,281",
          "MED_COMMENT_LAST_12": "18",
          "MED_SHARE_LAST_12": "21",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14667\" data-kol_nama=\"sofhiasyaariha\" data-hp_kol=\"\" data-username_tiktok=\"sofhiasyaariha\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 31,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "zefnotsev",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic31\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@zefnotsev\" target=\"_blank\">zefnotsev</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "37,200",
          "TOTAL_VIEW_LAST_12": "4,964,396",
          "AVG_VIEW_LAST_12": "413,700",
          "AVG_LIKE_LAST_12": "50,065",
          "AVG_COMMENT_LAST_12": "138",
          "AVG_SHARE_LAST_12": "217",
          "MIN_VIEW_LAST_12": "4,433",
          "MIN_LIKE_LAST_12": "201",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "2,300,000",
          "MAX_LIKE_LAST_12": "284,800",
          "MAX_COMMENT_LAST_12": "618",
          "MAX_SHARE_LAST_12": "1,466",
          "MED_VIEW_LAST_12": "12,150",
          "MED_LIKE_LAST_12": "646",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "3",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14666\" data-kol_nama=\"zefnotsev\" data-hp_kol=\"\" data-username_tiktok=\"zefnotsev\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 32,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "dhtaura",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic32\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@dhtaura\" target=\"_blank\">dhtaura</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "18,400",
          "TOTAL_VIEW_LAST_12": "797,749",
          "AVG_VIEW_LAST_12": "66,479",
          "AVG_LIKE_LAST_12": "11,104",
          "AVG_COMMENT_LAST_12": "22",
          "AVG_SHARE_LAST_12": "129",
          "MIN_VIEW_LAST_12": "7,002",
          "MIN_LIKE_LAST_12": "332",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "23",
          "MAX_VIEW_LAST_12": "330,900",
          "MAX_LIKE_LAST_12": "55,900",
          "MAX_COMMENT_LAST_12": "111",
          "MAX_SHARE_LAST_12": "470",
          "MED_VIEW_LAST_12": "17,650",
          "MED_LIKE_LAST_12": "2,028",
          "MED_COMMENT_LAST_12": "11",
          "MED_SHARE_LAST_12": "80",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14665\" data-kol_nama=\"dhtaura\" data-hp_kol=\"\" data-username_tiktok=\"dhtaura\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 33,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "happinessjunkiee",
          "kategori_id": "life style",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "Natalia",
          "pic_jadwal": "<ul id=\"pic33\"></ul>",
          "KETERANGAN_KOL": "",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@happinessjunkiee\" target=\"_blank\">happinessjunkiee</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "8,532",
          "TOTAL_VIEW_LAST_12": "149,664",
          "AVG_VIEW_LAST_12": "12,472",
          "AVG_LIKE_LAST_12": "1,080",
          "AVG_COMMENT_LAST_12": "10",
          "AVG_SHARE_LAST_12": "13",
          "MIN_VIEW_LAST_12": "112",
          "MIN_LIKE_LAST_12": "6",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "54,500",
          "MAX_LIKE_LAST_12": "5,799",
          "MAX_COMMENT_LAST_12": "57",
          "MAX_SHARE_LAST_12": "65",
          "MED_VIEW_LAST_12": "1,030",
          "MED_LIKE_LAST_12": "30",
          "MED_COMMENT_LAST_12": "0",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14664\" data-kol_nama=\"happinessjunkiee\" data-hp_kol=\"\" data-username_tiktok=\"happinessjunkiee\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"\" data-kategori=\"life style\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 34,
          "tgl_listing": "01-12-2023",
          "KOL_NAMA": "akuvale",
          "kategori_id": "Viral",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "Natalia",
          "pic_jadwal": "<ul id=\"pic34\"></ul>",
          "KETERANGAN_KOL": "",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@akuvale\" target=\"_blank\">akuvale</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "571,300",
          "TOTAL_VIEW_LAST_12": "14,856,900",
          "AVG_VIEW_LAST_12": "1,238,080",
          "AVG_LIKE_LAST_12": "106,177",
          "AVG_COMMENT_LAST_12": "552",
          "AVG_SHARE_LAST_12": "505",
          "MIN_VIEW_LAST_12": "159,100",
          "MIN_LIKE_LAST_12": "2,513",
          "MIN_COMMENT_LAST_12": "23",
          "MIN_SHARE_LAST_12": "12",
          "MAX_VIEW_LAST_12": "4,600,000",
          "MAX_LIKE_LAST_12": "424,800",
          "MAX_COMMENT_LAST_12": "2,943",
          "MAX_SHARE_LAST_12": "4,056",
          "MED_VIEW_LAST_12": "745,550",
          "MED_LIKE_LAST_12": "74,950",
          "MED_COMMENT_LAST_12": "337",
          "MED_SHARE_LAST_12": "74",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14663\" data-kol_nama=\"akuvale\" data-hp_kol=\"\" data-username_tiktok=\"akuvale\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"\" data-kategori=\"Viral\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 35,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "uyunawl",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic35\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@uyunawl\" target=\"_blank\">uyunawl</a>",
          "RATE": "Rp. 1,200,000",
          "TIKTOK_FOLLOWERS": "653,500",
          "TOTAL_VIEW_LAST_12": "12,809,490",
          "AVG_VIEW_LAST_12": "1,067,460",
          "AVG_LIKE_LAST_12": "93,245",
          "AVG_COMMENT_LAST_12": "643",
          "AVG_SHARE_LAST_12": "674",
          "MIN_VIEW_LAST_12": "2,868",
          "MIN_LIKE_LAST_12": "298",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "8,000,000",
          "MAX_LIKE_LAST_12": "752,100",
          "MAX_COMMENT_LAST_12": "5,234",
          "MAX_SHARE_LAST_12": "6,829",
          "MED_VIEW_LAST_12": "6,135",
          "MED_LIKE_LAST_12": "649",
          "MED_COMMENT_LAST_12": "4",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,124</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">418,410</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">150</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">195,599</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14662\" data-kol_nama=\"uyunawl\" data-hp_kol=\"\" data-username_tiktok=\"uyunawl\" data-rate=\"1200000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": null
        },
        {
          "no": 36,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "navisaevlya",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic36\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@navisaevlya\" target=\"_blank\">navisaevlya</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "25,300",
          "TOTAL_VIEW_LAST_12": "5,744,631",
          "AVG_VIEW_LAST_12": "478,719",
          "AVG_LIKE_LAST_12": "56,952",
          "AVG_COMMENT_LAST_12": "118",
          "AVG_SHARE_LAST_12": "279",
          "MIN_VIEW_LAST_12": "2,448",
          "MIN_LIKE_LAST_12": "65",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "2,400,000",
          "MAX_LIKE_LAST_12": "248,200",
          "MAX_COMMENT_LAST_12": "787",
          "MAX_SHARE_LAST_12": "1,122",
          "MED_VIEW_LAST_12": "37,850",
          "MED_LIKE_LAST_12": "4,457",
          "MED_COMMENT_LAST_12": "11",
          "MED_SHARE_LAST_12": "6",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14661\" data-kol_nama=\"navisaevlya\" data-hp_kol=\"\" data-username_tiktok=\"navisaevlya\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 37,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "vionaaaviooo",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<a data-id=\"14660\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic37\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@vionaaaviooo\" target=\"_blank\">vionaaaviooo</a>",
          "RATE": "Rp. 1,350,000",
          "TIKTOK_FOLLOWERS": "230,400",
          "TOTAL_VIEW_LAST_12": "7,496,391",
          "AVG_VIEW_LAST_12": "624,699",
          "AVG_LIKE_LAST_12": "68,898",
          "AVG_COMMENT_LAST_12": "161",
          "AVG_SHARE_LAST_12": "1,456",
          "MIN_VIEW_LAST_12": "2,819",
          "MIN_LIKE_LAST_12": "218",
          "MIN_COMMENT_LAST_12": "5",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "6,900,000",
          "MAX_LIKE_LAST_12": "774,100",
          "MAX_COMMENT_LAST_12": "1,666",
          "MAX_SHARE_LAST_12": "17,300",
          "MED_VIEW_LAST_12": "13,132",
          "MED_LIKE_LAST_12": "920",
          "MED_COMMENT_LAST_12": "12",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">2,161</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">478,893</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">196</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">102,806</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"vionaaaviooo\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14660\" data-kol_nama=\"vionaaaviooo\" data-hp_kol=\"\" data-username_tiktok=\"vionaaaviooo\" data-rate=\"1350000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "900k"
        },
        {
          "no": 38,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "trishaeas",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "Natalia",
          "pic_jadwal": "<ul id=\"pic38\"></ul>",
          "KETERANGAN_KOL": "",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@trishaeas\" target=\"_blank\">trishaeas</a>",
          "RATE": "Rp. 0",
          "TIKTOK_FOLLOWERS": "183,800",
          "TOTAL_VIEW_LAST_12": "829,800",
          "AVG_VIEW_LAST_12": "69,150",
          "AVG_LIKE_LAST_12": "3,461",
          "AVG_COMMENT_LAST_12": "54",
          "AVG_SHARE_LAST_12": "15",
          "MIN_VIEW_LAST_12": "21,900",
          "MIN_LIKE_LAST_12": "425",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "431,200",
          "MAX_LIKE_LAST_12": "29,200",
          "MAX_COMMENT_LAST_12": "404",
          "MAX_SHARE_LAST_12": "125",
          "MED_VIEW_LAST_12": "35,150",
          "MED_LIKE_LAST_12": "1,097",
          "MED_COMMENT_LAST_12": "26",
          "MED_SHARE_LAST_12": "4",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14659\" data-kol_nama=\"trishaeas\" data-hp_kol=\"\" data-username_tiktok=\"trishaeas\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
          "keterangan_approve": null
        },
        {
          "no": 39,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "shelaamaliyaputri",
          "kategori_id": "story telling",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic39\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@shelaamaliyaputri\" target=\"_blank\">shelaamaliyaputri</a>",
          "RATE": "Rp. 875,000",
          "TIKTOK_FOLLOWERS": "74,300",
          "TOTAL_VIEW_LAST_12": "7,823,001",
          "AVG_VIEW_LAST_12": "651,917",
          "AVG_LIKE_LAST_12": "50,210",
          "AVG_COMMENT_LAST_12": "444",
          "AVG_SHARE_LAST_12": "447",
          "MIN_VIEW_LAST_12": "3,888",
          "MIN_LIKE_LAST_12": "260",
          "MIN_COMMENT_LAST_12": "8",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "5,600,000",
          "MAX_LIKE_LAST_12": "362,300",
          "MAX_COMMENT_LAST_12": "3,026",
          "MAX_SHARE_LAST_12": "3,333",
          "MED_VIEW_LAST_12": "8,270",
          "MED_LIKE_LAST_12": "750",
          "MED_COMMENT_LAST_12": "16",
          "MED_SHARE_LAST_12": "0",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,342</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">225,051</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">156</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">105,804</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14658\" data-kol_nama=\"shelaamaliyaputri\" data-hp_kol=\"\" data-username_tiktok=\"shelaamaliyaputri\" data-rate=\"875000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"story telling\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "flop"
        },
        {
          "no": 40,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "martabakcheeseadalahkeju",
          "kategori_id": "Fashion",
          "set_tgl_button": "<a data-id=\"14657\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic40\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@martabakcheeseadalahkeju\" target=\"_blank\">martabakcheeseadalahkeju</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "35,100",
          "TOTAL_VIEW_LAST_12": "780,801",
          "AVG_VIEW_LAST_12": "65,067",
          "AVG_LIKE_LAST_12": "4,515",
          "AVG_COMMENT_LAST_12": "47",
          "AVG_SHARE_LAST_12": "36",
          "MIN_VIEW_LAST_12": "6,807",
          "MIN_LIKE_LAST_12": "318",
          "MIN_COMMENT_LAST_12": "3",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "266,900",
          "MAX_LIKE_LAST_12": "21,200",
          "MAX_COMMENT_LAST_12": "176",
          "MAX_SHARE_LAST_12": "192",
          "MED_VIEW_LAST_12": "15,500",
          "MED_LIKE_LAST_12": "581",
          "MED_COMMENT_LAST_12": "20",
          "MED_SHARE_LAST_12": "11",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">13,755</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">131,482</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">3,353</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">57,742</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"martabakcheeseadalahkeju\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14657\" data-kol_nama=\"martabakcheeseadalahkeju\" data-hp_kol=\"\" data-username_tiktok=\"martabakcheeseadalahkeju\" data-rate=\"895000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Fashion\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "odor 800k"
        },
        {
          "no": 41,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "belladeftt",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic41\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@belladeftt\" target=\"_blank\">belladeftt</a>",
          "RATE": "Rp. 925,000",
          "TIKTOK_FOLLOWERS": "77,400",
          "TOTAL_VIEW_LAST_12": "163,789",
          "AVG_VIEW_LAST_12": "13,649",
          "AVG_LIKE_LAST_12": "1,250",
          "AVG_COMMENT_LAST_12": "6",
          "AVG_SHARE_LAST_12": "8",
          "MIN_VIEW_LAST_12": "3,814",
          "MIN_LIKE_LAST_12": "182",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "63,100",
          "MAX_LIKE_LAST_12": "8,655",
          "MAX_COMMENT_LAST_12": "33",
          "MAX_SHARE_LAST_12": "55",
          "MED_VIEW_LAST_12": "6,420",
          "MED_LIKE_LAST_12": "360",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">67,770</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">242,528</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">14,659</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">144,092</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14656\" data-kol_nama=\"belladeftt\" data-hp_kol=\"\" data-username_tiktok=\"belladeftt\" data-rate=\"925000\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "MERAH"
        },
        {
          "no": 42,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "gitaesthetic",
          "kategori_id": "Cantik",
          "set_tgl_button": "<a data-id=\"14655\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic42\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@gitaesthetic\" target=\"_blank\">gitaesthetic</a>",
          "RATE": "Rp. 1,100,000",
          "TIKTOK_FOLLOWERS": "75,800",
          "TOTAL_VIEW_LAST_12": "23,758,825",
          "AVG_VIEW_LAST_12": "1,979,900",
          "AVG_LIKE_LAST_12": "167,910",
          "AVG_COMMENT_LAST_12": "1,643",
          "AVG_SHARE_LAST_12": "1,232",
          "MIN_VIEW_LAST_12": "2,708",
          "MIN_LIKE_LAST_12": "92",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "15,600,000",
          "MAX_LIKE_LAST_12": "1,300,000",
          "MAX_COMMENT_LAST_12": "12,500",
          "MAX_SHARE_LAST_12": "11,700",
          "MED_VIEW_LAST_12": "76,400",
          "MED_LIKE_LAST_12": "7,386",
          "MED_COMMENT_LAST_12": "255",
          "MED_SHARE_LAST_12": "25",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">556</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">406,204</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">71</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">14,398</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"gitaesthetic\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14655\" data-kol_nama=\"gitaesthetic\" data-hp_kol=\"\" data-username_tiktok=\"gitaesthetic\" data-rate=\"1100000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "700k"
        },
        {
          "no": 43,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "alelococo",
          "kategori_id": "Cantik",
          "set_tgl_button": "<a data-id=\"14653\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic43\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "\t+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@alelococo\" target=\"_blank\">alelococo</a>",
          "RATE": "Rp. 1,800,000",
          "TIKTOK_FOLLOWERS": "82,400",
          "TOTAL_VIEW_LAST_12": "995,151",
          "AVG_VIEW_LAST_12": "82,929",
          "AVG_LIKE_LAST_12": "1,924",
          "AVG_COMMENT_LAST_12": "39",
          "AVG_SHARE_LAST_12": "7",
          "MIN_VIEW_LAST_12": "1,232",
          "MIN_LIKE_LAST_12": "39",
          "MIN_COMMENT_LAST_12": "1",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "277,500",
          "MAX_LIKE_LAST_12": "12,400",
          "MAX_COMMENT_LAST_12": "149",
          "MAX_SHARE_LAST_12": "37",
          "MED_VIEW_LAST_12": "41,350",
          "MED_LIKE_LAST_12": "1,323",
          "MED_COMMENT_LAST_12": "15",
          "MED_SHARE_LAST_12": "5",
          "CPM_AVG": "<label class=\"badge badge-md bg-warning font-weight-normal\" style=\"font-weight: normal !important;\">21,705</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,461,040</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">6,486</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">43,531</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"alelococo\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14653\" data-kol_nama=\"alelococo\" data-hp_kol=\"\" data-username_tiktok=\"alelococo\" data-rate=\"1800000\" data-rate_keterangan=\"\t+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "1 slot 1,5"
        },
        {
          "no": 44,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "keyzhadarya",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic44\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "\t+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@keyzhadarya\" target=\"_blank\">keyzhadarya</a>",
          "RATE": "Rp. 1,350,000",
          "TIKTOK_FOLLOWERS": "106,200",
          "TOTAL_VIEW_LAST_12": "5,708,658",
          "AVG_VIEW_LAST_12": "475,722",
          "AVG_LIKE_LAST_12": "68,012",
          "AVG_COMMENT_LAST_12": "169",
          "AVG_SHARE_LAST_12": "269",
          "MIN_VIEW_LAST_12": "4,863",
          "MIN_LIKE_LAST_12": "244",
          "MIN_COMMENT_LAST_12": "2",
          "MIN_SHARE_LAST_12": "3",
          "MAX_VIEW_LAST_12": "2,800,000",
          "MAX_LIKE_LAST_12": "497,600",
          "MAX_COMMENT_LAST_12": "882",
          "MAX_SHARE_LAST_12": "1,614",
          "MED_VIEW_LAST_12": "10,450",
          "MED_LIKE_LAST_12": "1,054",
          "MED_COMMENT_LAST_12": "5",
          "MED_SHARE_LAST_12": "22",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">2,838</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">277,606</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">482</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">129,187</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14652\" data-kol_nama=\"keyzhadarya\" data-hp_kol=\"\" data-username_tiktok=\"keyzhadarya\" data-rate=\"1350000\" data-rate_keterangan=\"\t+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": null
        },
        {
          "no": 45,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "salmaqilla",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic45\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "\t+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@salmaqilla\" target=\"_blank\">salmaqilla</a>",
          "RATE": "Rp. 795,000",
          "TIKTOK_FOLLOWERS": "44,400",
          "TOTAL_VIEW_LAST_12": "790,127",
          "AVG_VIEW_LAST_12": "65,844",
          "AVG_LIKE_LAST_12": "2,686",
          "AVG_COMMENT_LAST_12": "20",
          "AVG_SHARE_LAST_12": "32",
          "MIN_VIEW_LAST_12": "401",
          "MIN_LIKE_LAST_12": "5",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "385,000",
          "MAX_LIKE_LAST_12": "19,000",
          "MAX_COMMENT_LAST_12": "95",
          "MAX_SHARE_LAST_12": "229",
          "MED_VIEW_LAST_12": "1,339",
          "MED_LIKE_LAST_12": "17",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "0",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">12,074</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,982,540</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">2,065</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">593,727</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14651\" data-kol_nama=\"salmaqilla\" data-hp_kol=\"\" data-username_tiktok=\"salmaqilla\" data-rate=\"795000\" data-rate_keterangan=\"\t+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "flop"
        },
        {
          "no": 46,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "preciousnaa",
          "kategori_id": "Cantik",
          "set_tgl_button": "<a data-id=\"14650\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic46\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@preciousnaa\" target=\"_blank\">preciousnaa</a>",
          "RATE": "Rp. 785,000",
          "TIKTOK_FOLLOWERS": "39,200",
          "TOTAL_VIEW_LAST_12": "8,829,322",
          "AVG_VIEW_LAST_12": "735,777",
          "AVG_LIKE_LAST_12": "100,156",
          "AVG_COMMENT_LAST_12": "405",
          "AVG_SHARE_LAST_12": "490",
          "MIN_VIEW_LAST_12": "894",
          "MIN_LIKE_LAST_12": "49",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "3,800,000",
          "MAX_LIKE_LAST_12": "624,800",
          "MAX_COMMENT_LAST_12": "2,418",
          "MAX_SHARE_LAST_12": "2,627",
          "MED_VIEW_LAST_12": "4,555",
          "MED_LIKE_LAST_12": "277",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "3",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,067</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">878,076</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">207</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">172,338</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"preciousnaa\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14650\" data-kol_nama=\"preciousnaa\" data-hp_kol=\"\" data-username_tiktok=\"preciousnaa\" data-rate=\"785000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "2 slot 1jt"
        },
        {
          "no": 47,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "jilrmdhn",
          "kategori_id": "Cantik",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic47\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@jilrmdhn\" target=\"_blank\">jilrmdhn</a>",
          "RATE": "Rp. 895,000",
          "TIKTOK_FOLLOWERS": "193,200",
          "TOTAL_VIEW_LAST_12": "12,922,552",
          "AVG_VIEW_LAST_12": "1,076,880",
          "AVG_LIKE_LAST_12": "113,248",
          "AVG_COMMENT_LAST_12": "985",
          "AVG_SHARE_LAST_12": "1,644",
          "MIN_VIEW_LAST_12": "609",
          "MIN_LIKE_LAST_12": "19",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "8,000,000",
          "MAX_LIKE_LAST_12": "647,700",
          "MAX_COMMENT_LAST_12": "5,443",
          "MAX_SHARE_LAST_12": "10,400",
          "MED_VIEW_LAST_12": "2,404",
          "MED_LIKE_LAST_12": "200",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">831</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">1,469,620</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">112</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">372,296</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14649\" data-kol_nama=\"jilrmdhn\" data-hp_kol=\"\" data-username_tiktok=\"jilrmdhn\" data-rate=\"895000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Cantik\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "flop"
        },
        {
          "no": 48,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "jihanmndaa",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic48\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312\t",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@jihanmndaa\" target=\"_blank\">jihanmndaa</a>",
          "RATE": "Rp. 2,800,000",
          "TIKTOK_FOLLOWERS": "442,000",
          "TOTAL_VIEW_LAST_12": "275,125",
          "AVG_VIEW_LAST_12": "22,927",
          "AVG_LIKE_LAST_12": "376",
          "AVG_COMMENT_LAST_12": "5",
          "AVG_SHARE_LAST_12": "3",
          "MIN_VIEW_LAST_12": "915",
          "MIN_LIKE_LAST_12": "58",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "237,500",
          "MAX_LIKE_LAST_12": "2,842",
          "MAX_COMMENT_LAST_12": "13",
          "MAX_SHARE_LAST_12": "13",
          "MED_VIEW_LAST_12": "3,677",
          "MED_LIKE_LAST_12": "112",
          "MED_COMMENT_LAST_12": "3",
          "MED_SHARE_LAST_12": "2",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">122,126</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">3,060,110</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">11,790</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">761,490</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14648\" data-kol_nama=\"jihanmndaa\" data-hp_kol=\"\" data-username_tiktok=\"jihanmndaa\" data-rate=\"2800000\" data-rate_keterangan=\"+62 819-9343-1312\t\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "MERAH"
        },
        {
          "no": 49,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "marshaaazhr",
          "kategori_id": "Skincare Reviewer",
          "set_tgl_button": "<a data-id=\"14647\" class=\"btn btn-sm btn-warning btn-item set_jadwal\" ><i class=\"ti-calendar\" style=\"color:white\"></i></a>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic49\"><li>lala</li></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@marshaaazhr\" target=\"_blank\">marshaaazhr</a>",
          "RATE": "Rp. 950,000",
          "TIKTOK_FOLLOWERS": "55,700",
          "TOTAL_VIEW_LAST_12": "1,057,135",
          "AVG_VIEW_LAST_12": "88,095",
          "AVG_LIKE_LAST_12": "7,921",
          "AVG_COMMENT_LAST_12": "16",
          "AVG_SHARE_LAST_12": "30",
          "MIN_VIEW_LAST_12": "1,957",
          "MIN_LIKE_LAST_12": "122",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "708,000",
          "MAX_LIKE_LAST_12": "55,500",
          "MAX_COMMENT_LAST_12": "126",
          "MAX_SHARE_LAST_12": "242",
          "MED_VIEW_LAST_12": "16,500",
          "MED_LIKE_LAST_12": "1,052",
          "MED_COMMENT_LAST_12": "2",
          "MED_SHARE_LAST_12": "7",
          "CPM_AVG": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">10,784</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">485,437</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">1,342</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">57,576</label>",
          "tombol": "<a title=\"Move to Profile\" data-toggle=\"modal\" data-tiktok_id=\"marshaaazhr\" class=\"btn btn-sm btn-primary item_move btn-item\" style=\"color:white\"><i class=\"fa-solid fa-user-plus\"></i></a><a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14647\" data-kol_nama=\"marshaaazhr\" data-hp_kol=\"\" data-username_tiktok=\"marshaaazhr\" data-rate=\"950000\" data-rate_keterangan=\"+62 819-9343-1312\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">Approved</label>",
          "keterangan_approve": "odor 800k"
        },
        {
          "no": 50,
          "tgl_listing": "29-11-2023",
          "KOL_NAMA": "anda12064",
          "kategori_id": "Beauty",
          "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
          "PIC": "lala",
          "pic_jadwal": "<ul id=\"pic50\"></ul>",
          "KETERANGAN_KOL": "awareness",
          "KETERANGAN_RATE": "+62 819-9343-1312",
          "PROFILE_LINK": "<a href= \"https://www.tiktok.com/@anda12064\" target=\"_blank\">anda12064</a>",
          "RATE": "Rp. 1,950,000",
          "TIKTOK_FOLLOWERS": "63,600",
          "TOTAL_VIEW_LAST_12": "456,816",
          "AVG_VIEW_LAST_12": "38,068",
          "AVG_LIKE_LAST_12": "1,240",
          "AVG_COMMENT_LAST_12": "15",
          "AVG_SHARE_LAST_12": "2",
          "MIN_VIEW_LAST_12": "3,701",
          "MIN_LIKE_LAST_12": "153",
          "MIN_COMMENT_LAST_12": "0",
          "MIN_SHARE_LAST_12": "0",
          "MAX_VIEW_LAST_12": "246,400",
          "MAX_LIKE_LAST_12": "6,318",
          "MAX_COMMENT_LAST_12": "68",
          "MAX_SHARE_LAST_12": "10",
          "MED_VIEW_LAST_12": "9,834",
          "MED_LIKE_LAST_12": "535",
          "MED_COMMENT_LAST_12": "7",
          "MED_SHARE_LAST_12": "1",
          "CPM_AVG": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">51,224</label>",
          "CPM_MIN": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">526,885</label>",
          "CPM_MAX": "<label class=\"badge badge-md bg-primary font-weight-normal\" style=\"font-weight: normal !important;\">7,914</label>",
          "CPM_MED": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">198,302</label>",
          "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14646\" data-kol_nama=\"anda12064\" data-hp_kol=\"\" data-username_tiktok=\"anda12064\" data-rate=\"1950000\" data-rate_keterangan=\"+62 819-9343-1312\" data-kol_keterangan=\"awareness\" data-kategori=\"Beauty\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
          "approved": "<label class=\"badge badge-md bg-danger font-weight-normal\" style=\"font-weight: normal !important;\">Rejected</label>",
          "keterangan_approve": "MERAH"
        }
      ]

      const dataApify =[
        {
            "id": "7597345100",
            "username": "faizaledrus",
            "url": "https://www.instagram.com/faizaledrus",
            "fullName": "Faizal Azfar Al-Edrus",
            "biography": "🏅 Entrepreneur\n🥇@importirorg \n🥈@deoappcom\n🥉@entrepreneursid_",
            "externalUrl": "http://bit.ly/2Y4lKNX",
            "externalUrlShimmed": "https://l.instagram.com/?u=http%3A%2F%2Fbit.ly%2F2Y4lKNX&e=AT2mP1EdKWIisxqOS15HxpOleAi4hdO-IE6zyibhwrE-hXa5UAiU6dc_j-kLVHjkSrYgJJ-ScrEDmk5ahABeDEujkb5DrsiH",
            "followersCount": 14842,
            "followsCount": 3830,
            "hasChannel": false,
            "highlightReelCount": 12,
            "isBusinessAccount": true,
            "joinedRecently": false,
            "businessCategoryName": "None",
            "private": false,
            "verified": true,
            "profilePicUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
            "profilePicUrlHD": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s320x320&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCOuxdPl9KPOHfwc6yy0Qj5sicfGHWFHxzKKpc-fVmO1A&oe=65724461&_nc_sid=8b3546",
            "igtvVideoCount": 4,
            "relatedProfiles": [
                {
                    "id": "395571394",
                    "full_name": "Pak Win",
                    "is_private": false,
                    "is_verified": true,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/252199179_3022291708097621_1524691642318427318_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=wzbsXW8CVh8AX8PONAe&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfB1_40ocJgInfp9z-bBW7B8XVxs23B5mfR_gndE7HVQhA&oe=6571E1F0&_nc_sid=8b3546",
                    "username": "pakwinofficial"
                },
                {
                    "id": "44597763109",
                    "full_name": "EverPlate CloudKitchens®",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/128829773_703219746990791_3015891292897477679_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=OfuLv7FdZWwAX-OhGVB&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCrQI7iuFKYwube2j4v7kUzyHLT4jz8EIpbEVmQibxaEw&oe=6571D74D&_nc_sid=8b3546",
                    "username": "everplatekitchens"
                },
                {
                    "id": "49987285061",
                    "full_name": "Kemal Speaking Camp",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/245298233_1478391339220583_7498717799061677113_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=dJaFCluuYkgAX9BwKTb&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfD5NI3DgEGy2GixAGletxtKp0842rxqohkAEQhImJoAHA&oe=657228AA&_nc_sid=8b3546",
                    "username": "kemalspeakingcamp"
                },
                {
                    "id": "10922553502",
                    "full_name": "SkillSavvy | Belajar Marketing",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/357308178_795286475598939_662388730491297664_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=103&_nc_ohc=O4YO0JzQygUAX9Ig6jB&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA4sdJEOJ1H0LM_tgj2w5Z5ULXG8aoiNolshrYBtFl8SQ&oe=65717E53&_nc_sid=8b3546",
                    "username": "skillsavvy.id"
                },
                {
                    "id": "2113646668",
                    "full_name": "Yanita Yuristia",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/357655504_249947054457513_8434753201345651470_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=XKWuuWni1SsAX8Ke6D-&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAGPrVeuZDWLA7iSnUIy7OlmZ30CRqSaAmO6TvHc48sig&oe=657230E9&_nc_sid=8b3546",
                    "username": "yanitatrg"
                },
                {
                    "id": "2245670798",
                    "full_name": "Teguh Hidayat",
                    "is_private": false,
                    "is_verified": true,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/344770477_776594954045310_6538022894014640363_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=Nt7EEmGjCoAAX_4ThSW&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCmtjZcHewPXMivpqEre5w243xGwQSAnbMiJ9Fr0b9dCQ&oe=6571A336&_nc_sid=8b3546",
                    "username": "tghidayat"
                },
                {
                    "id": "36593889",
                    "full_name": "Dani Rachmat Kurniawan",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/379468851_355303380163082_4320604890592120911_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=l0QQ7JeapjEAX8q5Cas&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfB4QmLGZb94mEbdIGWFz2H0H34hC0wjy0n0p8Fqo_sOvg&oe=65725C21&_nc_sid=8b3546",
                    "username": "danirachmat"
                },
                {
                    "id": "50671333183",
                    "full_name": "Ganda Makindo Mandiri",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/340693935_1264166307636047_2708093034187700590_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=xWEofhaq4ZEAX8YK7RE&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCmbljBCkzSpEkQ5vo9ytyeHB_rKnunzsANTuHUgXKa_A&oe=6571A814&_nc_sid=8b3546",
                    "username": "modernpowertools.id"
                },
                {
                    "id": "4191385191",
                    "full_name": "Rona Binham",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/182690834_1161993590892862_3288599249134537356_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=nGjQEQoJCFIAX-p27Mc&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBiHcm3BKKUCEQ94wmL6v3JKpNLZUTf6OY9tyxxG8Z6JQ&oe=6572B308&_nc_sid=8b3546",
                    "username": "ronabinham"
                },
                {
                    "id": "225738278",
                    "full_name": "Leonard Hartono",
                    "is_private": false,
                    "is_verified": true,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/361379125_1213671815990807_7688345699224074968_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=ZAxzuJxagjcAX81r--v&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCkRvueNlVDOPdSl96N6ABUl69fO3fEVp4LyFC3D3v6EQ&oe=657267FE&_nc_sid=8b3546",
                    "username": "leonhartono"
                },
                {
                    "id": "1557801914",
                    "full_name": "Reda Gaudiamo",
                    "is_private": false,
                    "is_verified": true,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/404939109_354129710493474_319339688552674985_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=gsqGhd3ejC0AX9ujptv&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAb3syyaN9PjMyMK-Xa3v3SD0SmZXvz8EtI0eVkkCzomQ&oe=6571B49B&_nc_sid=8b3546",
                    "username": "reda.gaudiamo"
                },
                {
                    "id": "5597794421",
                    "full_name": "Perizinan & Virtual Office",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/31970495_1930494763650680_7555955158614540288_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=5GKZTVagXr8AX8Aet0P&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCsZzeoENqez_6QtsYUPN-523GdAIexYKNzY9UXPyaZLQ&oe=6572EFFC&_nc_sid=8b3546",
                    "username": "izincoid"
                },
                {
                    "id": "8653173923",
                    "full_name": "SIGNATURE GARDEN SERANG",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/324256013_851843249231606_8078148077396311924_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=5EUCSCHzP_YAX_WVqm8&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAVbIgsDtRNTcFPBtJkA_5BvRh1KvDFyoDfGP1jEndEww&oe=65716B72&_nc_sid=8b3546",
                    "username": "bumimutiaraserang"
                },
                {
                    "id": "1087122643",
                    "full_name": "Coach Yuszak | Finance",
                    "is_private": false,
                    "is_verified": true,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/305076909_805612123796898_31722330531829777_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=3K00zpifPlkAX8CEvQq&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA23g93oddfXk5272D2jlMY4UejtFGrmWotnR67OUBoHg&oe=6572DC0A&_nc_sid=8b3546",
                    "username": "yuszakmyahya"
                },
                {
                    "id": "32449848525",
                    "full_name": "portal edukasi value investor",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/90527532_668756613858954_3360367946940547072_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=V4iSZoPF68EAX88reNj&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA0cFHoOsdBwA9rbZ0yrQFpBksAOP3PcW8Q9Gme7tUNDQ&oe=65725874&_nc_sid=8b3546",
                    "username": "investorsaham.id"
                },
                {
                    "id": "53529337700",
                    "full_name": "MiiTel by RevComm",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/280482912_748084636221928_6923466398254614680_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=4Js_dclWiYIAX8eWLAh&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAKT7VHw3h5YhAmwTG0ML2f__jFzLInKjqt-HkwgiebNA&oe=6572CA8C&_nc_sid=8b3546",
                    "username": "miitel.revcomm"
                },
                {
                    "id": "44096011078",
                    "full_name": "Rajanya Produk Digital",
                    "is_private": false,
                    "is_verified": false,
                    "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/349034988_2141091736081282_2683389853224333085_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=fERK0OkXNykAX-sFJnI&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDahdT-X2QPpBDoG8oZ0WAJh-m7gG5y1bGVIb64CP4zbA&oe=65719CF8&_nc_sid=8b3546",
                    "username": "nekat.berbisnis"
                }
            ],
            "latestIgtvVideos": [
                {
                    "type": "Video",
                    "shortCode": "B881jW3gHTn",
                    "title": "Lazada & jenius",
                    "caption": "Iseng vlogging",
                    "commentsCount": 3,
                    "commentsDisabled": false,
                    "dimensionsHeight": 1138,
                    "dimensionsWidth": 640,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/85165005_603753497142217_1116152947778428698_n.jpg?stp=dst-jpg_e35&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=jNF7-FIYqq8AX9lv3NN&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCcJAp1sNswzTWT4mxmXocsM04qyTpchvL4oyFHRn8zKg&oe=656F5047&_nc_sid=8b3546",
                    "likesCount": 79,
                    "videoDuration": 210.566,
                    "videoViewCount": 6173
                },
                {
                    "type": "Video",
                    "shortCode": "B8soy4UA4xc",
                    "title": "Bukalapak meeting",
                    "caption": "Ceritanya ak miting sm tim bukalapak cross border",
                    "commentsCount": 4,
                    "commentsDisabled": false,
                    "dimensionsHeight": 496,
                    "dimensionsWidth": 320,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/84820612_487808765461206_11268749109676738_n.jpg?stp=dst-jpg_e35&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=Gd3XPzSbKGwAX_O_TQa&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCcuuP1PaixbHhZFs8a-DUNJUXfqfpQxV4DkwNd6RRuiQ&oe=656EF425&_nc_sid=8b3546",
                    "likesCount": 71,
                    "videoDuration": 139.133,
                    "videoViewCount": 5126
                },
                {
                    "type": "Video",
                    "shortCode": "B8iojveAJfW",
                    "title": "Business Idea Creation : influencer pltform 1.0",
                    "caption": "Guyss, ak lg ada ide bangun platform utk influencer. Ide dasar nya dari crowdfunding giveaway..",
                    "commentsCount": 8,
                    "commentsDisabled": false,
                    "dimensionsHeight": 1138,
                    "dimensionsWidth": 640,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/84436350_175864227040395_6325628364308798264_n.jpg?stp=dst-jpg_e35&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=103&_nc_ohc=-oCR1RVIU3oAX_1KaUh&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDvA-F1RrUYFCrFMzUiqRmeYyxDIDehQMjUwMtNGADyZA&oe=656F3ED3&_nc_sid=8b3546",
                    "likesCount": 93,
                    "videoDuration": 346.43,
                    "videoViewCount": 5959
                },
                {
                    "type": "Video",
                    "shortCode": "BzQqjyHnYKS",
                    "title": "Jalan2 ke China, dipanggil alibaba.",
                    "caption": "Nih gengs, sedikit preview pas aku jalan-jalan di Cina kemarin karena Alibaba minta meeting *THUGLIFE*",
                    "commentsCount": 6,
                    "commentsDisabled": false,
                    "dimensionsHeight": 1089,
                    "dimensionsWidth": 612,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/65171879_220350042267475_4852740675839900797_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=hw_HqoytcgMAX8FMaMg&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDm7sygNlJ91OvLnF7HC5_bSSGDJS8SA6yjutagh_77rA&oe=656EBB6E&_nc_sid=8b3546",
                    "likesCount": 99,
                    "videoDuration": 326.566,
                    "videoViewCount": 4825
                }
            ],
            "postsCount": 139,
            "latestPosts": [
                {
                    "id": "3237843605092127821",
                    "type": "Video",
                    "shortCode": "CzvIZqfyVRN",
                    "caption": "Keren banget dari sampah sampai bisa jadi bisnis yang diekspor. Mantap om!\n\n#entrepreneur #business",
                    "hashtags": [
                        "entrepreneur",
                        "business"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/CzvIZqfyVRN/",
                    "commentsCount": 429,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/402777718_317006471283223_1025027810720604295_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=6ce2xO0RPfUAX_30vtO&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfC0yY9yNqLNbIMPj9lQ05nlqN6Ss7CM7ts6CFllpqv4Qg&oe=656F0C22&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_1243489090374936_1350730990583381432_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=9FAtg7nVpWIAX8RhsDo&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCjh3vjWBsBH6ctUZE0Uo-T3N-5-N3_lHet_E5hUFttdw&oe=656EE082&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 44544,
                    "videoViewCount": 1098460,
                    "timestamp": "2023-11-17T06:08:27.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ],
                    "isPinned": true
                },
                {
                    "id": "3212628073539748390",
                    "type": "Video",
                    "shortCode": "CyVjDYuyMIm",
                    "caption": "Ini dia cara kita rekrut MT di kantor entrepreneurs… #entrepreneur #recruitment",
                    "hashtags": [
                        "entrepreneur",
                        "recruitment"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/CyVjDYuyMIm/",
                    "commentsCount": 462,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/403740040_896066958971944_1456091968856815631_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=J23J88pcGPkAX_ScB1f&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCqDQNnqVb-5rAfL1u72jH0HnY6C8HE1XDbcby5Dy0F4A&oe=656F452A&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_989439185434032_8820504327375813122_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=aCb580FDflYAX8IB0h-&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCtz20_Fi1fToRmSb7sZcawTH5x4jPVtxJvoPadXTik3w&oe=656F1AE8&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 7782,
                    "videoViewCount": 257489,
                    "timestamp": "2023-10-13T11:07:11.000Z",
                    "childPosts": [],
                    "locationName": "Entrepreneurs.id-official",
                    "locationId": "100893849462603",
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ],
                    "isPinned": true
                },
                {
                    "id": "3207555354439928098",
                    "type": "Video",
                    "shortCode": "CyDhpm6y0ki",
                    "caption": "Manusia dan sistem guys untuk jalanin multiple businesses #entrepreneur #autopilot",
                    "hashtags": [
                        "entrepreneur",
                        "autopilot"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/CyDhpm6y0ki/",
                    "commentsCount": 144,
                    "dimensionsHeight": 1920,
                    "dimensionsWidth": 1080,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/386457581_7420101474670159_2377978234958573403_n.jpg?stp=dst-jpg_e15_fr_p1080x1080&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=coToPYZceq4AX_CfBL_&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBssv_JCroU9oQahgHCr57DS5Dr_H5chcpSYyDMkx95sg&oe=656F524D&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_282793091237752_1890281912638782527_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=C9OXiTTKrRIAX-iGBeM&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCRosH0S8m6Q3pair_SCxWBi1UTiBa4Q_6iymNhRKaH-Q&oe=656F1B1D&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 5071,
                    "videoViewCount": 182130,
                    "timestamp": "2023-10-06T11:08:10.000Z",
                    "childPosts": [],
                    "locationName": "Entrepreneurs.id-official",
                    "locationId": "100893849462603",
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ],
                    "isPinned": true
                },
                {
                    "id": "3249435863272821701",
                    "type": "Video",
                    "shortCode": "C0YULOOypPF",
                    "caption": "Keren banget couplepreneur yang satu ini, yang mau belajar sama mereka bisa datang ke event desember kita ya✨\n\n#entrepreneur #fashion #bisnis",
                    "hashtags": [
                        "entrepreneur",
                        "fashion",
                        "bisnis"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0YULOOypPF/",
                    "commentsCount": 6,
                    "dimensionsHeight": 1917,
                    "dimensionsWidth": 1080,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405242276_2915681588567989_3027080789312162324_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=pLDn05nqtm0AX8u9lBW&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDgSN1CITvgzufnawm_KZ6nfAueXZyCFsg9NL4B25ImdA&oe=656F03D9&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_1489150228539713_4316850663626469311_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=j-Z5N7fRDmoAX_yxnOg&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDe1W2D0TFeoi0DsxXRxA1AZs6zbiWzLxivX9h4fEqO1w&oe=656EE6B3&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 78,
                    "videoViewCount": 1907,
                    "timestamp": "2023-12-03T05:57:36.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "LYF OFFICIAL",
                            "id": "5400422835",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/326781168_149270720936825_5587711063209126075_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=sSS5Ai82i2wAX87HkgJ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBTuHcOaCS28sZI-ilGvVNZPoypWWXgYOtPFtOA7wVRGg&oe=6572E627&_nc_sid=8b3546",
                            "username": "lyf.official"
                        },
                        {
                            "full_name": "Arya Septiadi Bayu Agung",
                            "id": "5888809922",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/363326042_746846750462515_615162696920033341_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=_wK0SI65bF0AX_9zzbZ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAo-2bF7CBoiKTpJbohKEbWQ4m3wYzVOwHKZtLm0x4Z-A&oe=657194AF&_nc_sid=8b3546",
                            "username": "aryasbaa"
                        },
                        {
                            "full_name": "Luthfi Mala’il Khusna",
                            "id": "5320342155",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/280711058_405271098119507_1405703466559940347_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=VL9kXr-udcsAX9RtQrZ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfC9Lr2zrEOO7UEh4zt1By10NLDOA14FvAF805eliwmmLw&oe=65720521&_nc_sid=8b3546",
                            "username": "kamalaluthfi"
                        },
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3248959724532128440",
                    "type": "Video",
                    "shortCode": "C0Wn6feSdq4",
                    "caption": "Walaupun jadi mompreneur susah susah susah tapi @wearingklamby growth luar biasa banget✨. Keren kak @nadinegaus \n\n#entrepreneur #hijab #bisnis",
                    "hashtags": [
                        "entrepreneur",
                        "hijab",
                        "bisnis"
                    ],
                    "mentions": [
                        "wearingklamby",
                        "nadinegaus"
                    ],
                    "url": "https://www.instagram.com/p/C0Wn6feSdq4/",
                    "commentsCount": 3,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405268190_6629553457166877_892126019017434599_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=pB8w35CgmAQAX_k9EdM&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAMY5YoVpdFKGyFF6TYJD4Agxab6yvZ0u8fD65vXgxOmQ&oe=656F4A8D&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_1980257622374976_8764741327970730641_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=4JcxL1pIgRMAX85B_FE&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBlaAgKGw6wjH222yXyk37G_c7N5jFR3Ojst0eL-yFWgg&oe=656F5018&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 187,
                    "videoViewCount": 4960,
                    "timestamp": "2023-12-02T14:12:01.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "KLAMBY",
                            "id": "386559785",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/399937079_363218146086790_4337144734549977388_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=cMo6UnO0BogAX_8N2fP&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBDNp0wWpbqQqQE0qFYUXBqMws0iA9JGceWrU_BPr9kCQ&oe=6572765C&_nc_sid=8b3546",
                            "username": "wearingklamby"
                        },
                        {
                            "full_name": "Nadine Gaus",
                            "id": "24835429",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/299907646_1125093934882614_1514955154002506111_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=V1eGMTOAMSoAX-hri0_&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCyZ9xAxQvIdruvosobAbIujQkUbbC6erXX1O0A63h3Vg&oe=657265AB&_nc_sid=8b3546",
                            "username": "nadinegaus"
                        },
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3248698542277749019",
                    "type": "Video",
                    "shortCode": "C0Vshyiy2kb",
                    "caption": "Dari reseller bisa punya omzet miliaran, keren banget kak @andesnihirda ✨",
                    "hashtags": [],
                    "mentions": [
                        "andesnihirda"
                    ],
                    "url": "https://www.instagram.com/p/C0Vshyiy2kb/",
                    "commentsCount": 1,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405263250_1318870446174990_6612066086154856015_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=uRlVHp7QZfoAX_Vp70j&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA2OOlrj_y-6gZE_D9Y79rOh22a3GGgDgdj2YfNHsRWLw&oe=656F1ABE&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_243315798582714_8734953635232292938_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=O7CSwVfvK9oAX9FW1Hd&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAWun4Qxt_Vy_PzbR8fLLqE9KoJkUu2HtCS5LTCfPXrHg&oe=656F1463&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 187,
                    "videoViewCount": 4518,
                    "timestamp": "2023-12-02T05:38:40.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Lozy Hijab",
                            "id": "1959110048",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/319945501_485313867041185_20274043711726470_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=kHt4Fxp74KMAX-Vl9zC&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAz-7RO1OKF9G5mQQiUNq07PD3G8JoP7zPr9j-d2fuXZw&oe=6573412E&_nc_sid=8b3546",
                            "username": "lozyhijab"
                        },
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        },
                        {
                            "full_name": "andesni hirda putri",
                            "id": "582076740",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/324543248_690458762571109_2675409701645507665_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=yj_U_cDHLM8AX_cURp3&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDYn5kkgp1bifduRVmET5S9wUcBk1DUYP49B3deTJReZg&oe=65732171&_nc_sid=8b3546",
                            "username": "andesnihirda"
                        }
                    ]
                },
                {
                    "id": "3248240735178558128",
                    "type": "Video",
                    "shortCode": "C0UEb0dRYqw",
                    "caption": "Tempat sampah gantung cuma 30ribu, cus buruan dapetin gratis ongkos kirim💸✨😱\n\n#import #entrepreneur #bisnis",
                    "hashtags": [
                        "import",
                        "entrepreneur",
                        "bisnis"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0UEb0dRYqw/",
                    "commentsCount": 0,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405242272_2304234093098966_5143908824060256804_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=0Ndp7HN0E2cAX-78j10&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDmdLNhTtqIjLhoUlhrwwvQLyP8iCsbR5GyU6vu_5YK3Q&oe=656F4C90&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/121316781_322346920749976_6092051394594146818_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=_PZo8wUNDLsAX_iBJDV&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAtKaBE844UfvVfmWdZvkOLReBbDdt1qbub5onFSr2dEA&oe=656F1B40&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 28,
                    "videoViewCount": 2840,
                    "timestamp": "2023-12-01T14:23:40.000Z",
                    "childPosts": [],
                    "ownerUsername": "importirorg",
                    "ownerId": "2164207191",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3248161964262236643",
                    "type": "Video",
                    "shortCode": "C0TyhjUySHj",
                    "caption": "Dapet omzet 400 juta tanpa punya dapur. Keren banget!✨\n\n#entrepreneur #pengusaha #bisnis",
                    "hashtags": [
                        "entrepreneur",
                        "pengusaha",
                        "bisnis"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0TyhjUySHj/",
                    "commentsCount": 8,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405761812_1695862107591849_2929857893936180745_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=KuMDmzw9_CsAX-djdr0&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA0cU7Dr7qTgHE4AS9iGsuPzCRrdUnDaysxw8O2PDzdMQ&oe=656EBCBD&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_647953680881826_6991252406165366048_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=-tBau-bxp4sAX8XAuBZ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfB3uhLYHJxeFLyk0MoV-dTQh0lvq-KWpeTPXCbV8e9MDg&oe=656EC356&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 424,
                    "videoViewCount": 9321,
                    "timestamp": "2023-12-01T11:49:16.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Steven Djingga",
                            "id": "17862725435",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/404290477_720754369587442_850647968145331010_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cij5l0IPQMgAX98VFJK&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfA_F05yRWBZsWCfHmRUl8clW5nRxRTZI25FHrqSsT74aw&oe=65734D4E&_nc_sid=8b3546",
                            "username": "stevendjingga"
                        },
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        },
                        {
                            "full_name": "PT. CATERING AJA INDONESIA",
                            "id": "39628859524",
                            "is_verified": false,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/328297736_220750013666993_2878436438125262917_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=O_PHNGDiVP4AX8dFNnh&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBxrdIk5JdEV_SVdbSW9SI2VHOndmbiNkA2L67Vh8O83Q&oe=657292E0&_nc_sid=8b3546",
                            "username": "cateringaja"
                        }
                    ]
                },
                {
                    "id": "3248063089048112195",
                    "type": "Video",
                    "shortCode": "C0TcCumxUxD",
                    "caption": "Kamu bisa dapetin hingga 195 JUTA🥹😭💸\nBahkan dikasih gratis ongkos kirim\n\n#importir #ecommerce #entrepreneur",
                    "hashtags": [
                        "importir",
                        "ecommerce",
                        "entrepreneur"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0TcCumxUxD/",
                    "commentsCount": 6,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405502171_993530528409167_1747428723765379228_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=iiKAwkP7ZE0AX-491MS&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAYGCjAKgrSpQ6oSIjMS1Wy9x7Zx_fOph0EqEl3Mvbp1g&oe=656F25CD&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/319968796_893630178513300_4872030755923167500_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=gK7N1_Fj0gEAX8t4AmZ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfC45vuJuR3PBDHPi6ymid0GtQ8Gp-LOUJrfwG_gpvR8kg&oe=656F3098&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 107,
                    "videoViewCount": 8575,
                    "timestamp": "2023-12-01T08:29:34.000Z",
                    "childPosts": [],
                    "ownerUsername": "importirorg",
                    "ownerId": "2164207191",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3247979391007392578",
                    "type": "Video",
                    "shortCode": "C0TJAwuyQNC",
                    "caption": "Keren banget dari konten lalu bisa punya usaha makanan yang omzetnya miliaran @sambalbakar ✨\n\n#entrepreneur",
                    "hashtags": [
                        "entrepreneur"
                    ],
                    "mentions": [
                        "sambalbakar"
                    ],
                    "url": "https://www.instagram.com/p/C0TJAwuyQNC/",
                    "commentsCount": 5,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/401269484_719703106694570_614744012894874462_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=VRiZcPAhr8UAX-ovGfL&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBpGSoJckOhqd6AgQaHqYUIkD7J5wGLGMb1uy4luGvh7A&oe=656EF9AE&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_1274885119855739_362996855951948209_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=7LpdT-kFzuUAX-tuMHQ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBkYpAhIeYbZh55-_f8bLyYyK70XGolbGGXgFcZfzjiHA&oe=656F55B3&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 368,
                    "videoViewCount": 7322,
                    "timestamp": "2023-12-01T05:45:31.000Z",
                    "childPosts": [],
                    "ownerUsername": "entrepreneursid_",
                    "ownerId": "18976664032",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Richard Theodore",
                            "id": "3114771572",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/390940612_917437390084583_1132319747909127077_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=EMTBW17t-GsAX9VqyrU&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCziU2C2Q85Xf6lUY-m-aVGShuYgWmDr_rzUZjwBgY_lA&oe=65716F51&_nc_sid=8b3546",
                            "username": "richardtheodoreofficial"
                        },
                        {
                            "full_name": "SAMBAL BAKAR INDONESIA",
                            "id": "5572940819",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/404947091_6841546572633544_7139828481279330168_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=1SxG_vjLBxsAX9ilZHy&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBKObRaVoc2g84Gi9R5oWjjuMbU93uYhmqD1fax-DWC0A&oe=65723FED&_nc_sid=8b3546",
                            "username": "sambalbakar"
                        },
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3247568003560554078",
                    "type": "Video",
                    "shortCode": "C0RreSUJr5e",
                    "caption": "Bagus banget guys buku ini untuk pemula yang mau ngiklan, mudah dipahami\n\n#selfdeveloent #bookstagram #entretainment",
                    "hashtags": [
                        "selfdeveloent",
                        "bookstagram",
                        "entretainment"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0RreSUJr5e/",
                    "commentsCount": 1,
                    "dimensionsHeight": 1920,
                    "dimensionsWidth": 1080,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/404952800_1029155911701248_7104949904953680586_n.jpg?stp=dst-jpg_e15_fr_p1080x1080&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=oMVnD-1LsIAAX8HyUQm&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfCxWr4NtHziAe3r1qvCuT0resZrP3xlKwScdwcG01Fv5g&oe=656F0CFD&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/10000000_719381123439037_2860748389525487766_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=IeKDy3YLaxQAX81kPIJ&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfAS8bvOPeFvcucXqtjEpQ393mZCtT7R_j4Cg6o8pnfMfw&oe=656EF81B&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 49,
                    "videoViewCount": 1724,
                    "timestamp": "2023-11-30T16:10:36.000Z",
                    "childPosts": [],
                    "ownerUsername": "mastermind.indonesia",
                    "ownerId": "56105208010",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                },
                {
                    "id": "3247509164087612062",
                    "type": "Video",
                    "shortCode": "C0ReGDyRJKe",
                    "caption": "Tong sampah plastik murah ini ada di bio nomor 9 yaa. Belanjanya jangan lupa pake gratis ongkir💸🥹\n\n#import #cuan #commerce",
                    "hashtags": [
                        "import",
                        "cuan",
                        "commerce"
                    ],
                    "mentions": [],
                    "url": "https://www.instagram.com/p/C0ReGDyRJKe/",
                    "commentsCount": 9,
                    "dimensionsHeight": 1333,
                    "dimensionsWidth": 750,
                    "displayUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-15/405521552_584042020529398_2277650977873298370_n.jpg?stp=dst-jpg_e15&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=2YKKwwZB-8YAX84JU06&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfDSvpNuPEZdcMEU9H5Nm-K_m9hSsk62ETh4A5yy0it0JA&oe=656EE19E&_nc_sid=8b3546",
                    "images": [],
                    "videoUrl": "https://instagram.fstv4-1.fna.fbcdn.net/v/t66.30100-16/48818731_741744994470790_1614683268099120296_n.mp4?_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=BEJwHqPnk8MAX9pPDfe&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBzgkB5iu5bWE-SgMETAADFCVhV8HCrPMo9SUIlN-78nQ&oe=656F31A1&_nc_sid=8b3546",
                    "alt": null,
                    "likesCount": 90,
                    "videoViewCount": 7921,
                    "timestamp": "2023-11-30T14:10:24.000Z",
                    "childPosts": [],
                    "ownerUsername": "importirorg",
                    "ownerId": "2164207191",
                    "productType": "clips",
                    "taggedUsers": [
                        {
                            "full_name": "Faizal Azfar Al-Edrus",
                            "id": "7597345100",
                            "is_verified": true,
                            "profile_pic_url": "https://instagram.fstv4-1.fna.fbcdn.net/v/t51.2885-19/64818934_464957040927834_2763702362806681600_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fstv4-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=C-XQ1IHIiSQAX8qfbC2&edm=AOQ1c0wBAAAA&ccb=7-5&oh=00_AfBRAJSBTgIFyoADrg8qvGbjX82tNuU7UBoIdrChJb1e3g&oe=65724461&_nc_sid=8b3546",
                            "username": "faizaledrus"
                        }
                    ]
                }
            ]
        }
    ]
  return (
    <Box>
        <HStack>
            <BackButtons/>
            <Heading textAlign='center'>KOL</Heading>
            <Spacer/>
            <FaTiktok/>
            <FaInstagram/>
            <FaYoutube/>
        </HStack>

        <HStack>
            <Input type='date'/>
            <Input type='date'/>
            <Input type='text'/>
            <Input type='text'/>
        </HStack>
        <HStack>
            <Input type='text'/>
            <Input type='text'/>
            <Input type='text'/>
            <Input type='text'/>
        </HStack>
        <HStack>
            <Input type='text'/>
            <Input type='text'/>
            <Input type='text'/>
            <Input type='text'/>
        </HStack>

        <Box>
        <TableContainer>
            <Table variant='simple'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead>

                <Tr>
                    {/* <Th></Th> */}
                    <Th>Account</Th>
                    <Th>Category</Th>
                    <Th>Rate</Th>
                    <Th>Views (last 12 posts)</Th>
                    <Th></Th>
                    <Th>Views</Th>
                    <Th>Likes</Th>
                    <Th>Comment</Th>
                    <Th>Shares</Th>
                </Tr>
                </Thead>
                <Tbody>
       

                    {dataApify.map((x,i)=>
                    <Tr key={i}>

                    <Td>
                        <HStack>
                        <Image    
                      boxSize='100px'
                      src={x.profilePicUrl} 
                      alt={x.username}/>
                            <Box>
                                <Text>{x.username}</Text>
                                <Text fontSize='sm'>Followers: {x.followersCount}</Text>
                                <Text fontSize='sm'>{x.username}</Text>
                            </Box>
                            {/* <Text>Link</Text>    */}
                        </HStack>
                        </Td>
                    <Td>{x.username}</Td>
                    <Td>{x.username}</Td>
                    <Td>
                      {/* <Text>{x.TOTAL_VIEW_LAST_12}</Text> */}
                      <HStack>
                      <Text fontSize='2xs'>Last Updated 2 days ago</Text>
                      <Button fontSize='2xs' size='2xs'>Refrensh</Button>

                      </HStack>
                    </Td>
                    <Td>
                        <Text>Average</Text>
                        <Text>Min</Text>
                        <Text>Max</Text>
                        <Text>Median</Text>
                        <Text>CPM</Text>
                    </Td>
                    <Td>
                        {/* <Text>{x.AVG_VIEW_LAST_12}</Text>
                        <Text>{x.MIN_VIEW_LAST_12}</Text>
                        <Text>{x.MAX_VIEW_LAST_12}</Text>
                        <Text>{x.MED_VIEW_LAST_12}</Text> */}
                        <Text>
                            {/* {parseInt((x.RATE.replace(/[^0-9]/g, ''))/(x.MED_VIEW_LAST_12.replace(/[^0-9]/g, '')/1000))} */}
                        </Text>
                    </Td>
                    <Td>
                        {/* <Text>{x.AVG_LIKE_LAST_12}</Text>
                        <Text>{x.MIN_LIKE_LAST_12}</Text>
                        <Text>{x.MAX_LIKE_LAST_12}</Text>
                        <Text>{x.MED_LIKE_LAST_12}</Text> */}
                    </Td>
                    <Td>
                        {/* <Text>{x.AVG_COMMENT_LAST_12}</Text>
                        <Text>{x.MIN_COMMENT_LAST_12}</Text>
                        <Text>{x.MAX_COMMENT_LAST_12}</Text>
                        <Text>{x.MED_COMMENT_LAST_12}</Text> */}
                    </Td>
                    <Td>
                        {/* <Text>{x.AVG_SHARE_LAST_12}</Text>
                        <Text>{x.MIN_SHARE_LAST_12}</Text>
                        <Text>{x.MAX_SHARE_LAST_12}</Text>
                        <Text>{x.MED_SHARE_LAST_12}</Text> */}
                    </Td>
                    
                   
             </Tr>
                    )}
               
                
                </Tbody>
                {/* <Tfoot>
                <Tr>
                    <Th>To convert</Th>
                    <Th>into</Th>
                    <Th isNumeric>multiply by</Th>
                </Tr>
                </Tfoot> */}
            </Table>
            </TableContainer>
        </Box>


    </Box>
  )
}

// "no": 1,

// "set_tgl_button": "<button  class=\"btn btn-sm btn-primary btn-item\" style=\"pointer-events:none\" disabled><i class=\"ti-calendar\" style=\"color:white\"></i></button>",
// "PIC": "lala",
// "pic_jadwal": "<ul id=\"pic1\"></ul>",
// "KETERANGAN_KOL": "awareness",
// "KETERANGAN_RATE": "",
// "RATE": "Rp. 0",
// "": "45,000",


// "CPM_AVG": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
// "CPM_MIN": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
// "CPM_MAX": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
// "CPM_MED": "<label class=\"badge badge-md bg-success font-weight-normal\" style=\"font-weight: normal !important;\">0</label>",
// "tombol": "<a title=\"Update Data\" data-toggle=\"modal\" data-id_listing=\"14698\" data-kol_nama=\"raisyalmadea\" data-hp_kol=\"\" data-username_tiktok=\"raisyalmadea\" data-rate=\"0\" data-rate_keterangan=\"\" data-kol_keterangan=\"awareness\" data-kategori=\"Skincare Reviewer\" class=\"btn btn-sm btn-warning item_edit btn-item\" ><i class=\"fa fa-pencil\" style=\"color:white\"></i></a>",
// "approved": "<label class=\"badge badge-md bg-secondary font-weight-normal\" style=\"font-weight: normal !important;color:white !important\">Pending</label>",
// "keterangan_approve": null

export default MarketingKolListPage