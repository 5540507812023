import {
  AbsoluteCenter,
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import { languages } from '../../Utils/getLanguange';
import useMarketingStore from '../../Hooks/Zustand/MarketingStore';
import axios from 'axios';
import ReactSelect from 'react-select';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import useUserStore from '../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';

function MarketingFacebookAddAdCampaignPage() {
  const navigate = useNavigate();
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [progress, setProgress] = useState(0);
  const [listDisrict, setListDistrict] = useState([]);
  const [logo, setLogo] = useState('');
  const [media, setMedia] = useState();
  const [selectedLocation, setSelectedLocation] = useState({
    province: '',
    city: '',
    district: '',
  });
  const [disable, setDisable] = useState({
    province: false,
    city: true,
    district: true,
  });

  const companyData = globalState?.companies?.find(
    (x) => x.id === globalState?.currentCompany
  );

  const steps = [
    { title: 'Campaign', description: 'Create Campaign' },
    { title: 'Ads', description: 'Create Ads' },
    { title: 'Audience', description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish', description: 'Review & publish' },
  ];

  const fetchDataProvince = async () => {
    const res = await axios.get(
      'https://ibnux.github.io/data-indonesia/provinsi.json'
    );

    const options = res?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListProvince(options);
  };

  const fetchDataKabupaten = async (provinceId) => {
    const resKabupaten = await axios.get(
      `https://ibnux.github.io/data-indonesia/kabupaten/${provinceId}.json`
    );

    const options = resKabupaten?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListCity(options);
  };

  const fetchDataKecamatan = async (cityId) => {
    const resKecamatan = await axios.get(
      `https://ibnux.github.io/data-indonesia/kecamatan/${cityId}.json`
    );

    const options = resKecamatan?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListDistrict(options);
  };

  const handleSelectProvince = async (x) => {
    setSelectedLocation({ ...selectedLocation, province: x?.label });
    marketingState?.setAdsCreation({
      location: { ...selectedLocation, province: x?.label },
    });

    if (x.value !== '') {
      fetchDataKabupaten(x.value);
    }
  };

  const handleSelectDistrict = async (x) => {
    setSelectedLocation({ ...selectedLocation, district: x?.label });
    marketingState?.setAdsCreation({
      location: { ...selectedLocation, district: x?.label },
    });
  };

  const handleSelectCity = async (x) => {
    setSelectedLocation({ ...selectedLocation, city: x?.label });
    marketingState?.setAdsCreation({
      location: { ...selectedLocation, city: x?.label },
    });

    if (x.value !== '') {
      fetchDataKecamatan(x.value);
    }
  };

  const handleChangeLogo = async (file) => {
    console.log(file[0]);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/facebook-campaign`;

    const token = await getSingleDocumentFirebase('token', 'dropbox');
    const decryptResult = decryptToken(`${token?.access_token}`);

    const res = await UploadFileToDropbox(
      file[0],
      parentPath,
      decryptResult,
      setProgress,
      progress
    );

    console.log(res);
    setLogo(res?.link);
    marketingState?.setAdsCreation({ businessLogo: res?.link });
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/facebook-campaign`;

    const token = await getSingleDocumentFirebase('token', 'dropbox');
    const decryptResult = decryptToken(`${token?.access_token}`);

    const res = await UploadFileToDropbox(
      file,
      parentPath,
      decryptResult,
      setProgress,
      progress
    );

    console.log(res);
    setMedia(res?.link);
    marketingState?.setAdsCreation({ mediaLink: res });
  };

  useEffect(() => {
    fetchDataProvince();

    return () => {};
  }, []);

  return (
    <Box mb="5">
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={2}
        bg={'white'}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>
                {step.description}
              </StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box>
        <Heading textAlign="center">Ads</Heading>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2 }}
          p={5}
          bg={'white'}
          my={4}
        >
          <Container alignSelf="end" p={5} mt="5" shadow="base">
            <FormControl>
              <FormLabel>Ad Name</FormLabel>
              <Input
                value={marketingState?.adsCreation?.adName}
                onChange={(e) =>
                  marketingState?.setAdsCreation({ adName: e.target.value })
                }
                type="text"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Website URL</FormLabel>
              <Input
                value={marketingState?.adsCreation?.websiteUrl}
                onChange={(e) =>
                  marketingState?.setAdsCreation({ websiteUrl: e.target.value })
                }
                type="text"
              />
            </FormControl>

            <FormControl
              my={4}
              border={'1px'}
              borderRadius={'md'}
              p={2}
              borderColor={'gray.300'}
            >
              <FormLabel>Location</FormLabel>
              <Stack>
                <Text fontWeight={500}>Province</Text>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="color"
                  options={listProvince}
                  onChange={handleSelectProvince}
                />
              </Stack>
              <Stack>
                <Text fontWeight={500}>City</Text>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  //  defaultValue={colourOptions[0]}
                  isDisabled={selectedLocation?.province !== '' ? false : true}
                  //  isLoading={isLoading}
                  //  isClearable={isClearable}
                  //  isRtl={isRtl}
                  isSearchable={true}
                  onChange={handleSelectCity}
                  name="color"
                  options={listCity}
                />
              </Stack>
              <Stack>
                <Text fontWeight={500}>District</Text>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  //  defaultValue={colourOptions[0]}
                  isDisabled={selectedLocation?.city !== '' ? false : true}
                  //  isLoading={isLoading}
                  //  isClearable={isClearable}
                  //  isRtl={isRtl}
                  onChange={handleSelectDistrict}
                  isSearchable={true}
                  name="color"
                  options={listDisrict}
                />
              </Stack>
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                value={marketingState?.adsCreation?.language}
                onChange={(e) =>
                  marketingState?.setAdsCreation({ language: e.target.value })
                }
                maxW="max-content"
              >
                {languages.map((x, i) => (
                  <option key={i} value={x.name}>
                    {x.name} - {x.nativeName}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl as="fieldset" my={4}>
              <FormLabel as="legend">Format</FormLabel>
              <RadioGroup
                value={marketingState?.adsCreation?.format}
                onChange={(e) => marketingState?.setAdsCreation({ format: e })}
                defaultValue="External Website"
              >
                <HStack gap="3">
                  <Radio value="single">Single Image / Video</Radio>
                  <Radio value="carousel">Carousel</Radio>
                  {/* <Radio value='Facebook Messanger'>Collection</Radio> */}
                </HStack>
              </RadioGroup>
            </FormControl>

            <Text fontWeight={500} mb={3}>
              Ads Display
            </Text>
            <HStack>
              <FormControl>
                <FormLabel>Logo</FormLabel>
                <Input
                  // value={marketingState?.adsCreation?.logo}
                  onChange={(e) => handleChangeLogo(e?.target.files)}
                  type="file"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Business Name</FormLabel>
                <Input
                  value={marketingState?.adsCreation?.businessName}
                  onChange={(e) =>
                    marketingState?.setAdsCreation({
                      businessName: e.target.value,
                    })
                  }
                  type="text"
                />
              </FormControl>
            </HStack>

            <FormControl my={4}>
              <FormLabel>Primary Text</FormLabel>
              <Textarea
                value={marketingState?.adsCreation?.primaryText}
                onChange={(e) =>
                  marketingState?.setAdsCreation({
                    primaryText: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Media</FormLabel>
              <Input
                // value={marketingState?.adsCreation?.mediaLink?.link}
                onChange={handleChange}
                type="file"
              />
            </FormControl>

            <FormControl my={4}>
              <FormLabel>Headline</FormLabel>
              <Input
                value={marketingState?.adsCreation?.headline}
                onChange={(e) =>
                  marketingState?.setAdsCreation({ headline: e.target.value })
                }
                type="text"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                value={marketingState?.adsCreation?.description}
                onChange={(e) =>
                  marketingState?.setAdsCreation({
                    description: e.target.value,
                  })
                }
                type="text"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Call to Action</FormLabel>
              <Input
                value={marketingState?.adsCreation?.cta}
                onChange={(e) =>
                  marketingState?.setAdsCreation({ cta: e.target.value })
                }
                type="text"
              />
            </FormControl>

            <Button
              mt={5}
              w="full"
              colorScheme="green"
              onClick={() => navigate('/marketing/meta/facebook/add/audience')}
            >
              Next
            </Button>
          </Container>

          <Container alignSelf="start">
            <Box borderRadius={'md'} shadow={'base'} overflow={'hidden'}>
              <HStack p={2}>
                <Image
                  // w={75}
                  boxSize={70}
                  borderRadius="full"
                  src={
                    marketingState?.adsCreation?.businessLogo === ''
                      ? 'https://scontent.fcgk27-1.fna.fbcdn.net/v/t39.35426-6/406579295_373908135085268_3861333469265015122_n.jpg?stp=dst-jpg_s60x60&_nc_cat=111&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeEGH6IsRTw35Ad7h4t2JwB8ghaTzGOdgDSCFpPMY52ANFNzsBwy2alRNLPxf8ekL8o&_nc_ohc=pD4zGzi6ogwAX_ajZJ3&_nc_ht=scontent.fcgk27-1.fna&oh=00_AfCaiom984i5RcBxdWjQVZyarMFX4z-KUvB-Qb5QCUehrg&oe=656ED39A'
                      : marketingState?.adsCreation?.businessLogo
                  }
                  alt="your brand name"
                />
                <Box>
                  <Heading size="md">
                    {marketingState?.adsCreation?.businessName === ''
                      ? 'Your Business Name'
                      : marketingState?.adsCreation?.businessName}
                  </Heading>
                  <Text>Sponsored</Text>
                </Box>
              </HStack>
              <Text mt="5" p={2}>
                {marketingState?.adsCreation?.businessName === ''
                  ? 'Your Primary Text'
                  : marketingState?.adsCreation?.primaryText}
                {/* 𝟱 𝗝𝗮𝗺 𝗣𝗿𝗮𝗸𝘁𝗲𝗸 𝗶𝗺𝗽𝗼𝗿𝘁 & 𝗥𝗶𝘀𝗲𝘁 𝗣𝗿𝗼𝗱𝘂𝗸 𝗟𝗮𝗿𝗶𝘀 berdasarkan data dari
                Tokopedia/Shopee dengan Omset 100 Juta per bulan😱 . Kamu akan
                di bimbing dari 0 hingga mendapatkan 1 Produk potensi CUAN 100
                Juta Pertama Hanya dari 1 Produk Saja😍 . Yuk Catat tanggal &
                waktunya */}
              </Text>
              <Center bgColor="F2F2F2">
                {marketingState?.adsCreation?.mediaLink === null ? (
                  <Image
                    w={300}
                    src={
                      'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                    }
                    alt="ads Image"
                  />
                ) : (
                  <>
                    {marketingState?.adsCreation?.mediaLink?.type ===
                    'video' ? (
                      <Box align={'center'}>
                        {/* <AspectRatio> */}
                        <iframe
                          width={'100%'}
                          src={marketingState?.adsCreation?.mediaLink?.link}
                          allowFullScreen
                        />
                        {/* </AspectRatio> */}
                      </Box>
                    ) : (
                      <Image
                        w={300}
                        src={marketingState?.adsCreation?.mediaLink?.link}
                        alt="ads Image"
                      />
                    )}
                  </>
                )}
              </Center>
              <HStack
                bgColor="white"
                p="2"
                borderTop={'1px'}
                borderColor={'gray.300'}
              >
                <Box>
                  <Text fontSize="xs">
                    {' '}
                    {marketingState?.adsCreation?.websiteUrl === ''
                      ? 'Your Website URL'
                      : marketingState?.adsCreation?.websiteUrl}
                    {/* www.kodok.com */}
                  </Text>
                  <Text>
                    {marketingState?.adsCreation?.headline === ''
                      ? 'Your headline'
                      : marketingState?.adsCreation?.headline}
                    {/* Dapatkan Tiketnya segera */}
                  </Text>
                  <Text fontSize="sm">
                    {marketingState?.adsCreation?.description === ''
                      ? 'Your description'
                      : marketingState?.adsCreation?.description}
                    {/* Pendaftaran Terbatas */}
                  </Text>
                </Box>
                <Spacer />
                <Button>
                  {marketingState?.adsCreation?.cta === ''
                    ? 'Your CTA Button'
                    : marketingState?.adsCreation?.cta}
                  {/* Learn More */}
                </Button>
              </HStack>
            </Box>
          </Container>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default MarketingFacebookAddAdCampaignPage;
