import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import WarehouseRouter from './WarehouseRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import useUserStore from '../Hooks/Zustand/Store';
import Layout from '../Layouts';
import OperationRouter from './OperationRouter';
import ConfigurationRouter from './ConfigurationRouter';
// import ManagementRouter from "./ManagementRouter";
import MarketingRouter from './MarketingRouter';
import MarketingLandingPage from '../Pages/Marketing/MarketingLandingPage';
import ArtikelRouter from './ArtikelRouter';
import BookingRouter from './BookingRouter';
import HistoryRouter from './HistoryRouter';
import PatientRouter from './PatientRouter';
import DoctorRouter from './DoctorRouter';
import ProductRouter from './ProductRouter';

function MainRouter() {
  const globalState = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();

  const allRouter = [
    ...AuthenticationMainRouter,
    // ...ArtikelRouter,
    ...BookingRouter,
    ...HomeRouter,
    ...ConfigurationRouter,
    ...WarehouseRouter,
    ...HistoryRouter,
    ...PatientRouter,
    ...ProductRouter,
    ...DoctorRouter,
    ...OperationRouter,
    ...MarketingRouter,
  ];

  const isPathAllowed = (pathname) => {
    const roleKeys = Object.keys(globalState.role).filter(
      (key) => globalState.role[key] === true
    );

    const allowed = roleKeys.map((key) => {
      const modifiedKey = key.split('-')[0];
      const keyParts = modifiedKey.split('/');

      if (pathname.includes(modifiedKey)) {
        return true;
      }

      return false;
    });

    return allowed.some((value) => value === true);
  };

  useEffect(() => {
    const path = location.pathname;

    // if (globalState.roleCompany !== 'owner') {
    //   if (
    //     path &&
    //     path !== "/" &&
    //     path !== "/login" &&
    //     path !== "/artikel" &&
    //     path !== "/configuration" &&
    //     path !== "/hris" &&
    //     path !== "/management" &&
    //     path !== "/booking" &&
    //     path !== "history" &&
    //     path !== "/no-access" && // Hindari loop ke halaman "no-access"
    //     !isPathAllowed(path)
    //   ) {
    //     return navigate("/no-access");
    //   }
    // }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path="/marketing/funnel/create/:funnelId/lp-builder/:pageId"
        element={<MarketingLandingPage />}
      />

      {allRouter.map((item, index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        );
      })}
    </Routes>
  );
}

export default MainRouter;
