import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdDelete, MdOutlinePermMedia } from "react-icons/md";
import DropboxUploader from "../../Components/DropBox/DropboxUploader";
import RichTextEditor from "../../Components/Quill/RichTextEditor";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../Config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getCollectionFirebase } from "../../Api/firebaseApi";

const AddBookingPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const modalTag = useDisclosure();
  const globalState = useUserStore();
  const modalUpload = useDisclosure();

  const [newTreatment, setNewTreatment] = useState("");
  const [newBranch, setNewBranch] = useState("");
  const [treatment, setTreatment] = useState([]);
  const [branch, setBranch] = useState([]);
  const [dataInput, setDataInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState();
  const [city, setCity] = useState();
  const [newDoctor, setNewDoctor] = useState();
  const [newCity, setNewCity] = useState();
  const [selectedTreatmentData, setSelectedTreatmentData] = useState(null);
  const [selectedBranchData, setSelectedBranchData] = useState(null);
  const [date, setDate] = useState([]);
  const [newDate, setNewDate] = useState();
  const [isi, setIsi] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [userData, setUserData] = useState();
  const [value, setValue] = useState(false);

  const getCity = async () => {
    const collectionRef = collection(db, "branch-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCity(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const getUser = async () => {
    const collectionRef = collection(db, "customers");
    const phoneNumber = dataInput.hp;
    const q = query(collectionRef, where("phoneNumber", "==", phoneNumber));

    try {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // Tidak ada data yang cocok
        setUserData(null);
        console.log("Data tidak ditemukan.");
      } else {
        // Ada data yang cocok
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserData(data);
        console.log("Data dari koleksi:", data);
      }
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const getBranch = async () => {
    if (newCity) {
      const collectionRef = collection(db, "branch-deoapp-clinic");

      try {
        const querySnapshot = await getDocs(collectionRef);

        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter klinik berdasarkan kota yang dipilih
        const filteredBranches = data.filter((b) => b.city === newCity);

        setBranch(filteredBranches);
        console.log("Data dari koleksi:", filteredBranches);
      } catch (error) {
        console.error("Gagal mengambil data:", error.message);
      }
    }
  };

  const getTreatment = async () => {
    const documentRef = doc(db, "treatment-deoapp-clinic", newTreatment);
    try {
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const dataEdit = documentSnapshot.data();
        // console.log("Data dokumen treatment:", dataEdit);
        setTreatment(dataEdit);
      } else {
        console.log("Dokumen tidak ditemukan.");
      }
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const convertArrayToDayAndTimeFormat = (formattedTimes) => {
    try {
      if (Array.isArray(formattedTimes)) {
        return formattedTimes.map((formattedTime) => {
          const regexFormat = /(\d{2}-\d{2}-\d{4}) (\d{2}:\d{2})-(\d{2}:\d{2})/;
          const matchFormat = formattedTime.match(regexFormat);

          if (matchFormat) {
            const tanggalData = matchFormat[1];
            const jamMulaiData = matchFormat[2];
            const jamSelesaiData = matchFormat[3];

            const momentObj = moment(
              `${tanggalData} ${jamMulaiData}`,
              "DD-MM-YYYY HH:mm"
            );
            const startMoment = moment(jamMulaiData, "HH:mm");
            const endMoment = moment(jamSelesaiData, "HH:mm");

            // Mendapatkan nama hari dalam Bahasa Indonesia
            const dayName = momentObj.format("dddd");

            // Objek hasil
            return {
              formatted: `${dayName} ${startMoment.format(
                "HH:mm"
              )}-${endMoment.format("HH:mm")}`,
              day: dayName,
              jamMulai: startMoment.format("HH:mm"),
              jamSelesai: endMoment.format("HH:mm"),
              doctorId: doctor.id,
            };
          } else {
            console.error(`Format data waktu tidak valid: ${formattedTime}`);
            return null;
          }
        });
      } else {
        console.error("Masukan bukan array.");
        return null;
      }
    } catch (error) {
      console.error("Gagal mengonversi format waktu:", error.message);
      return null;
    }
  };

  const getDoctor = async () => {
    try {
      const documentRef = collection(db, "doctor-deoapp-clinic");
      const querySnapshot = await getDocs(documentRef);
      const dataEdit = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter dokter berdasarkan selectedBranchData.doctor
      const filteredDoctors = dataEdit.filter((doctor) =>
        selectedBranchData.doctor.some(
          (selectedDoctor) => selectedDoctor.id === doctor.id
        )
      );

      setDoctor(filteredDoctors);

      const combinedValues = filteredDoctors.reduce(
        (result, obj) => [...result, ...obj.avaible],
        []
      );

      setIsi(combinedValues);
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const getMatchingDoctorNames = (doctors, times) => {
    const doctorNames = [];

    times.forEach((time) => {
      const doctorsAvailableAtTime = doctors.filter((doctor) =>
        doctor.avaible.some((availableTime) =>
          availableTime.includes(time.formatted)
        )
      );

      if (doctorsAvailableAtTime.length > 0) {
        doctorsAvailableAtTime.forEach((doctor) => {
          doctorNames.push({
            name: doctor.name,
            doctorId: doctor.id,
          });
        });
      }
    });

    return doctorNames;
  };

  const convertedTime = convertArrayToDayAndTimeFormat(selectedTimes);

  let selectedDoctorData;

  if (doctor && convertedTime) {
    const result = getMatchingDoctorNames(doctor, convertedTime);
    selectedDoctorData = result;
  } else {
    selectedDoctorData = null;
  }

  const getTime = () => {
    try {
      const entries = Object.values(isi);
      const optionsTanggal = [];

      entries.forEach((entry) => {
        const regexWaktu = /(\w+) (\d{2}:\d{2})-(\d{2}:\d{2})/;
        const matchWaktu = entry.match(regexWaktu);

        if (matchWaktu) {
          const hariData = matchWaktu[1];
          const jamMulaiData = matchWaktu[2];
          const jamSelesaiData = matchWaktu[3];

          const hariMoment = moment().day(hariData);
          const tanggal = hariMoment.clone().format("DD-MM-YYYY");
          const optionTanggal = `${tanggal} ${jamMulaiData}-${jamSelesaiData}`;
          optionsTanggal.push(optionTanggal);
        } else {
          // console.log(`Format data waktu tidak valid: ${entry}`);
        }
      });

      // console.log("Opsi Tanggal:", optionsTanggal);
      setDate(optionsTanggal);
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const handleCreate = () => {
    setValue(true);
  };

  let totalForLoop = 0;
  for (let i = 0; i < selectedTreatments?.length; i++) {
    totalForLoop += selectedTreatments[i].price;
  }
  // console.log(selectedTreatments,"iiiii")
  const postArray = {
    name: dataInput?.name || "",
    status: "menunggu pembayaran",
    createdAt: moment().format("dddd, DD-MM-YYYY hh:mm:ss"),
    email: dataInput?.email || "",
    hp: dataInput?.hp || "",
    city: newCity || "",
    branch: selectedBranchData?.name || "",
    branchId: selectedBranchData?.id || "",
    totalPrice: totalForLoop,
    type: "treatment",
    treatment: [],
  };
  for (let i = 0; i < selectedTreatments?.length; i++) {
    postArray.treatment.push({
      title: selectedTreatments[i]?.title,
      id: selectedTreatments[i]?.id,
      doctor: selectedDoctorData[i]?.name,
      docId: selectedDoctorData[i]?.doctorId,
      time: selectedTimes[i],
    });
  }

  const historyTreatment = [];
  for (
    let i = 0;
    i < Math.max(selectedTreatments?.length, selectedTimes?.length);
    i++
  ) {
    const postData = {
      displayName: dataInput.name || "",
      status: "menunggu pembayaran",
      createdAt: moment().format("dddd, DD-MM-YYYY hh:mm:ss"),
      email: dataInput.email || "",
      phoneNumber: dataInput.hp || "",
      city: newCity || "",
      branch: selectedBranchData.name || "",
      branchId: selectedBranchData.id || "",
      title: selectedTreatments[i]?.title || "",
      productId: selectedTreatments[i]?.id || "",
      doctor: selectedDoctorData[i]?.name || "",
      doctorId: selectedDoctorData[i]?.doctorId || "",
      time: selectedTimes[i] || "",
      totalPrice: selectedTreatments[i]?.price || "",
      type: "treatment",
    };
    historyTreatment.push(postData);
  }
console.log(historyTreatment,"iooi")
  const handleSave = async () => {
    try {
      if (value === true) {
        try {
          const password = "user123";
          const signUpResult = await createUserWithEmailAndPassword(
            auth,
            dataInput.email,
            password
          );
          const user = signUpResult.user;
          if (user) {
            const userCreate = {
              displayName: dataInput.name,
              email: dataInput.email,
              phoneNumber: dataInput.hp,
            };
            const userRef = doc(db, "customers", user.uid);
            await setDoc(userRef, userCreate);

            if (
              Array.isArray(historyTreatment) &&
              historyTreatment.length > 0
            ) {
              for (const treatmentObject of historyTreatment) {
                const docRef = await addDoc(
                  collection(db, "booking-list-deoapp-clinic"),
                  treatmentObject
                );
              }
            } else {
              console.log("History treatment is not a valid array.");
            }
            toast({
              title: "Deoapp Clinic",
              description: "Booking Added!",
              status: "success",
              duration: 1000,
            });

            setTimeout(() => {
              navigate("/booking/list-booking");
            }, 1000);
          } else {
            console.error("User registration failed");
          }
        } catch (error) {
          console.error("Error during user registration:", error.message);
        }
      } 
      if(value === false) {
        
        if (historyTreatment) {
          const collectionRef = collection(db, "booking-list-deoapp-clinic");
          console.log(historyTreatment,"nilai")
          for (const historyTreatmentArray of historyTreatment) {
            const docRef = await addDoc(collectionRef, historyTreatmentArray);
            console.log("Dokumen berhasil ditambahkan dengan ID:", docRef.id);
          }
        }

        toast({
          title: "Deoapp Clinic",
          description: "Booking Added!",
          status: "success",
          duration: 1000,
        });

        setTimeout(() => {
          navigate("/booking/list-booking");
        }, 1000);
      }
    } catch (error) {
      console.error(error);

      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    if (dataInput.hp) {
      getUser();
    }
  }, [dataInput.hp]);

  useEffect(() => {
    if (newCity) {
      getBranch();
    }
  }, [newCity]);

  useEffect(() => {
    if (selectedBranchData) {
      getDoctor();
    }
  }, [selectedBranchData]);

  useEffect(() => {
    if (isi) {
      getTime();
    }
  }, [isi]);

  // console.log(postArray, "POSSTTT");
  // console.log(userData, "user");
  // console.log(historyTreatment, "user2");
console.log(dataInput,"uuu")
  return (
    <>
      <BackButtons />
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>Add Booking Schedule</Heading>
      </Flex>
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Box w="48%">
            <FormControl mt={4}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                bg="white"
                onChange={(e) =>
                  setDataInput({ ...dataInput, hp: e.target.value })
                }
                placeholder="Phone Number"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                bg="white"
                value={userData?.[0]?.displayName}
                onChange={(e) =>
                  setDataInput({ ...dataInput, name: e.target.value })
                }
                placeholder="Name Here"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                value={userData?.[0]?.email}
                bg="white"
                onChange={(e) =>
                  setDataInput({ ...dataInput, email: e.target.value })
                }
                placeholder="Email"
              />
            </FormControl>
          </Box>

          <Box w="48%" borderWidth="1px" borderRadius="lg" p={4} boxShadow="lg">
            {userData ? (
              <>
                <Box textAlign="center">
                  <Avatar
                    size="2xl"
                    name={userData[0].name}
                    src={userData[0].avatar}
                    mx="auto"
                  />
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    mt="2"
                    color="teal.500"
                  >
                    {userData[0].displayName}
                  </Text>
                  <Text>Email: {userData[0].email}</Text>
                  <Text>No HP: {userData[0].phoneNumber}</Text>
                  <Text>
                    Total Spend: Rp.{" "}
                    {userData[0]?.spend?.toLocaleString("en-US")}
                  </Text>
                  <Button>History</Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  textAlign="center"
                  bgColor={value ? "green.200" : "white"}
                  transition="background-color 0.3s"
                >
                  <Avatar size="2xl" mx="auto" />
                  <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    mt="2"
                    color="teal.500"
                  >
                    {value
                      ? "The account will be registered"
                      : "Account Not Registered Yet"}
                  </Text>
                  <Button onClick={handleCreate} mt="2" colorScheme="teal">
                    Create Account
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Flex>

        {/* Container 2 */}
        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="lg">
          {/* Bagian 1 - Formulir City, Clinic, Treatment, Select Time */}
          <Box>
            <FormControl mt={4}>
              <FormLabel>City</FormLabel>
              <Select
                bg="white"
                onChange={(e) => setNewCity(e.target.value)}
                placeholder="Select option"
              >
                {city?.map((b) => (
                  <option key={b.id} value={b.city}>
                    {b.city}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl marginTop={"2%"}>
              <FormLabel>Clinic</FormLabel>
              <Flex>
                <Select
                  placeholder="Select option"
                  bg="white"
                  onChange={(e) => {
                    console.log("Selected value:", e.target.value);
                    setNewBranch(e.target.value);
                    setSelectedBranchData(
                      branch.find((t) => t.id === e.target.value) || null
                    );
                  }}
                >
                  {Array.isArray(branch) &&
                    branch?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </Select>
              </Flex>
            </FormControl>

            <FormControl marginTop={"2%"}>
              <FormLabel>Treatment</FormLabel>
              <Flex>
                <Select
                  placeholder="Select option"
                  bg="white"
                  onChange={(e) => {
                    const selectedTreatment = selectedBranchData.treatment.find(
                      (t) => t.id === e.target.value
                    );
                    setSelectedTreatmentData(selectedTreatment);
                    setSelectedTreatments([
                      ...selectedTreatments,
                      selectedTreatment,
                    ]);
                  }}
                >
                  {Array.isArray(selectedBranchData?.treatment) &&
                  selectedBranchData.treatment.length > 0 ? (
                    selectedBranchData.treatment.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.title}
                      </option>
                    ))
                  ) : (
                    <option key="no-treatment" value="">
                      No Treatment Available
                    </option>
                  )}
                </Select>
              </Flex>
            </FormControl>

            <FormControl marginTop={"2%"}>
              <FormLabel>Select Time</FormLabel>
              <Flex>
                <Select
                  placeholder="Select option"
                  bg="white"
                  w={"95%"}
                  onChange={(e) =>
                    setSelectedTimes([...selectedTimes, e.target.value])
                  }
                >
                  {Array.isArray(date) && date.length > 0 ? (
                    date.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))
                  ) : (
                    <option key="loading" value="">
                      Loading...
                    </option>
                  )}
                </Select>
              </Flex>
            </FormControl>
          </Box>

          {/* Bagian 2 - Tabel dan Button Save */}
          <Box mt={4}>
            <Heading as="h2" size="md" mb={2}>
              Selected Treatments, Times, and Doctor
            </Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Treatment</Th>
                  <Th>Time</Th>
                  <Th>Doctor</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedTreatments.map((treatment, index) => (
                  <Tr key={index}>
                    <Td>{treatment.title}</Td>
                    <Td>{selectedTimes[index]}</Td>
                    <Td>
                      {Array.isArray(selectedDoctorData) &&
                      selectedDoctorData.length > 0
                        ? selectedDoctorData[index]?.name ||
                          "No Doctor Available"
                        : "No Doctor Available"}
                    </Td>
                    <Td>
                      <IconButton
                        colorScheme="red"
                        aria-label="Delete"
                        icon={<MdDelete />}
                        onClick={() => {
                          const updatedTreatments = [...selectedTreatments];
                          const updatedTimes = [...selectedTimes];

                          updatedTreatments.splice(index, 1);
                          updatedTimes.splice(index, 1);

                          setSelectedTreatments(updatedTreatments);
                          setSelectedTimes(updatedTimes);
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Box mt={4}>
              <Heading as="h2" size="md" mb={2}>
                Total Price
              </Heading>
              <Text fontWeight="bold" fontSize="lg">
                Rp. {totalForLoop.toLocaleString("en-US")}
              </Text>
            </Box>
          </Box>
        </Box>
        <Button
          mt={6}
          isLoading={loading}
          colorScheme="teal"
          onClick={handleSave}
        >
          Save Data
        </Button>
      </Box>
    </>
  );
};

export default AddBookingPage;
