import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdOutlinePermMedia } from "react-icons/md";
import DropboxUploader from "../../Components/DropBox/DropboxUploader";
import RichTextEditor from "../../Components/Quill/RichTextEditor";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";

const BranchEditPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const modalTag = useDisclosure();
  const globalState = useUserStore();
  const modalUpload = useDisclosure();
  const params = useParams();
  const [newTreatment, setNewTreatment] = useState("");
  const [newDoctor, setNewDoctor] = useState("");

  const [content, setContent] = useState("");
  const [treatment, setTreatment] = useState([]);
  const [doctor, setDoctor] = useState([]);

  const [dataInput, setDataInput] = useState({
    treatment: [],
    doctor: [],
  });
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);

  const getBranch = async () => {
    const documentRef = doc(db, "branch-deoapp-clinic", params.id);
    try {
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const dataEdit = documentSnapshot.data();
        console.log("Data dokumen branch:", dataEdit);

        // Set dataInput dengan dataEdit sebagai defaultValue
        setDataInput({
          name: dataEdit.name || "",
          description: dataEdit.description || "",
          address: dataEdit.address || "",
          treatment: dataEdit.treatment || [],
          doctor: dataEdit.doctor || [],
          city: dataEdit.city || [],
          facebook: dataEdit.facebook || [],
          instagram: dataEdit.instagram || [],
          hp: dataEdit.hp || [],
        });
        setImageUrl(dataEdit.thumbnail || "")
      } else {
        console.log("Dokumen tidak ditemukan.");
      }
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const getTreatment = async () => {
    const collectionRef = collection(db, "treatment-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTreatment(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const getDoctor = async () => {
    const collectionRef = collection(db, "doctor-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDoctor(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const contentChange = (value) => {
    setContent(value);
  };

  const handleSelectSlot = (index) => {
    setSelectedSlotIndex(index);
  };

  const handleDeleteSlot = (index) => {
    const updatedSlots = [...availableSlots];
    updatedSlots.splice(index, 1);
    setAvailableSlots(updatedSlots);
    setSelectedSlotIndex(null);
  };

  console.log(availableSlots, "avaible");

  const handleSave = async () => {
    try {
      const docRef = doc(db, "branch-deoapp-clinic", params.id);
      let data = {
        ...dataInput,
        thumbnail: imageUrl,
        createdAt: serverTimestamp(),
      };

      await updateDoc(docRef, data)
      
      if (
        Array.isArray(dataInput.treatment) &&
        dataInput.treatment.length > 0
      ) {
        for (const treatment of dataInput.treatment) {
          const treatmentDocRef = doc(
            db,
            "treatment-deoapp-clinic",
            treatment.id
          );

          const treatmentDocSnapshot = await getDoc(treatmentDocRef);

          if (treatmentDocSnapshot.exists()) {
            const existingData = treatmentDocSnapshot.data();

            const updatedData = {
              ...existingData,
              branch: [
                ...(existingData.branch || []),
                {
                  id: docRef.id,
                  name: dataInput.name,
                },
              ],
            };

            await updateDoc(treatmentDocRef, updatedData);
          }
        }
      }

      if (
        Array.isArray(dataInput.doctor) &&
        dataInput.doctor.length > 0
      ) {
        for (const doctor of dataInput.doctor) {
          const treatmentDocRef = doc(
            db,
            "doctor-deoapp-clinic",
            doctor.id
          );

          const treatmentDocSnapshot = await getDoc(treatmentDocRef);

          if (treatmentDocSnapshot.exists()) {
            const existingData = treatmentDocSnapshot.data();

            const updatedData = {
              ...existingData,
              branch: [
                ...(existingData.branch || []),
                {
                  id: docRef.id,
                  name: dataInput.name,
                },
              ],
            };

            await updateDoc(treatmentDocRef, updatedData);
          }
        }
      }

      console.log("Data berhasil ditambahkan dengan ID:", docRef.id);

      toast({
        title: "Deoapp Clinic",
        description: "Doctor Updated!",
        status: "success",
        duration: 1000,
      });

      setTimeout(() => {
        navigate("/booking/list-branch");
      }, 1000);
    } catch (error) {
      console.error(error);

      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });

      toast({
        title: "Which project you want to post this article to?",
        description:
          "Please select project from the sidebar on the left and make sure to fill the content",
        isClosable: true,
        duration: 1000,
        status: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTag = () => {
    let arr = [...dataInput.treatment];
    const selectedTreatment = treatment.find((t) => t.id === newTreatment);

    if (selectedTreatment) {
      arr.push({
        id: selectedTreatment.id,
        title: selectedTreatment.title,
      });
      setDataInput({
        ...dataInput,
        treatment: arr,
      });
    }
  };

  const handleDeleteTag = (index) => {
    let updatedCategories = [...dataInput.treatment];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      treatment: updatedCategories,
    });
  };

  const handleSaveTag2 = () => {
    let arr = [...dataInput.doctor];
    const selectedTreatment = doctor.find((t) => t.id === newDoctor);

    if (selectedTreatment) {
      arr.push({
        id: selectedTreatment.id,
        name: selectedTreatment.name,
      });
      setDataInput({
        ...dataInput,
        doctor: arr,
      });
    }
  };

  const handleDeleteTag2 = (index) => {
    let updatedCategories = [...dataInput.doctor];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      doctor: updatedCategories,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    // if (dataInput?.title === "" || dataInput?.title === undefined) {
    //   toast({
    //     status: "warning",
    //     title: " Deoapp Clinic",
    //     description: "Please input title first",
    //     duration: 2000,
    //   });
    // } else {
    if (newFiles.length) {
      const newFileArray = [];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);

          if (i === 0) {
            setImageUrl(reader.result);
          }
        };
      }
      setFilesImage(newFiles);
    }
    // }
  };

  const openModal = () => {
    setModalUploadOpen(true);
  };

  function closeModal() {
    setModalUploadOpen(false);
  }
  console.log(dataInput, "data");
  useEffect(() => {
    getBranch();
    getDoctor();
    getTreatment();
  }, []);

  return (
<>
  <BackButtons />
  <Flex justifyContent="space-between">
    <Heading>Adding Branch</Heading>
  </Flex>
  {/* <BreadCrumbComponent data={breadcrumbData} /> */}
  <Box my={10}>
    <Flex>
      {/* Bagian Atas - Kiri */}
      <Box flex={1} mr={5}>
        {imageUrl ? (
            <>
              <Image src={imageUrl} boxSize="full" objectFit="cover" />
              <Flex justify={"center"}>
                <Input
                  type="file"
                  onChange={handleFileInputChange}
                  display="none"
                  id="fileInput"
                />

                <label htmlFor="fileInput">
                  <HStack cursor="pointer">
                    <Stack>
                      <MdOutlinePermMedia />
                    </Stack>
                    <Text fontSize="sm" color="blue.600" fontStyle="italic">
                      Change Image thumbnail
                    </Text>
                  </HStack>
                </label>
              </Flex>
            </>
          ) : (
            <Flex justify={"center"}>
              <Input
                type="file"
                onChange={handleFileInputChange}
                display="none"
                id="fileInput"
              />

              <label htmlFor="fileInput">
                <HStack cursor="pointer">
                  <Stack>
                    <MdOutlinePermMedia />
                  </Stack>
                  <Text fontSize="sm" color="blue.600" fontStyle="italic">
                    Add Image thumbnail
                  </Text>
                </HStack>
              </label>
            </Flex>
          )}
      </Box>

      {/* Bagian Atas - Kanan */}
      <Box flex={1}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            bg="white"
            value={dataInput.name}
            onChange={(e) =>
              setDataInput({ ...dataInput, name: e.target.value })
            }
            placeholder="Name Here"
          />
        </FormControl>
        <FormControl mt={5}>
          <FormLabel>City</FormLabel>
          <Input
            bg="white"
            value={dataInput.city}
            onChange={(e) =>
              setDataInput({ ...dataInput, city: e.target.value })
            }
            placeholder="Branch City"
          />
        </FormControl>
        <FormControl mt={5}>
          <FormLabel>Call Centre</FormLabel>
          <Input
            bg="white"
            value={dataInput.hp}
            onChange={(e) =>
              setDataInput({ ...dataInput, hp: e.target.value })
            }
            placeholder="Branch Call Centre"
          />
        </FormControl>
        <FormControl mt={5}>
          <FormLabel>Instagram</FormLabel>
          <Input
            bg="white"
            value={dataInput.instagram}
            onChange={(e) =>
              setDataInput({ ...dataInput, instagram: e.target.value })
            }
            placeholder="Branch Instagram"
          />
        </FormControl>
        <FormControl mt={5}>
          <FormLabel>Facebook</FormLabel>
          <Input
            bg="white"
            value={dataInput.facebook}
            onChange={(e) =>
              setDataInput({ ...dataInput, facebook: e.target.value })
            }
            placeholder="Branch Facebook"
          />
        </FormControl>
      </Box>
    </Flex>

    {/* Bagian Bawah */}
    <FormControl mt={5}>
      <FormLabel>Description</FormLabel>
      <Textarea
        w={"full"}
        bg="white"
        value={dataInput?.description}
        onChange={(e) =>
          setDataInput({ ...dataInput, description: e.target.value })
        }
        placeholder="Branch Description"
      />
    </FormControl>
    <FormControl mt={5}>
      <FormLabel>Address</FormLabel>
      <Textarea
        w={"full"}
        bg="white"
        value={dataInput?.address}
        onChange={(e) =>
          setDataInput({ ...dataInput, address: e.target.value })
        }
        placeholder="Branch Address"
      />
    </FormControl>

    <FormControl mt={5}>
      <FormLabel>Treatment</FormLabel>
      <Flex>
        <Select
          placeholder="Select option"
          bg="white"
          w={"95%"}
          onChange={(e) => setNewTreatment(e.target.value)}
        >
          {Array.isArray(treatment) &&
            treatment.map((category) => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
            ))}
        </Select>
        <Button colorScheme="green" onClick={handleSaveTag2}>
          +
        </Button>
      </Flex>
    </FormControl>
    {Array.isArray(dataInput?.treatment) && dataInput?.treatment?.length > 0 && (
      <Box
        padding={5}
        mt={5}
        bg="white"
        borderRadius="md"
        overflow="hidden"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Treatment</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataInput?.treatment.map((x, i) => (
              <Tr key={i}>
                <Td>{x.title}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleDeleteTag2(i)}
                  >
                    X
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    )}

    <FormControl mt={5}>
      <FormLabel>Doctor</FormLabel>
      <Flex>
        <Select
          placeholder="Select option"
          bg="white"
          w={"95%"}
          onChange={(e) => setNewDoctor(e.target.value)}
        >
          {Array.isArray(doctor) &&
            doctor.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </Select>
        <Button colorScheme="green" onClick={handleSaveTag}>
          +
        </Button>
      </Flex>
    </FormControl>
    {Array.isArray(dataInput?.doctor) && dataInput?.doctor?.length > 0 && (
      <Box
        padding={5}
        mt={5}
        bg="white"
        borderRadius="md"
        overflow="hidden"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Doctor</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataInput?.doctor.map((x, i) => (
              <Tr key={i}>
                <Td>{x.name}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleDeleteTag(i)}
                  >
                    X
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    )}

    <Button
      isLoading={loading}
      my={3}
      size="md"
      colorScheme={"blue"}
      onClick={handleSave}
    >
      Save Branch
    </Button>
  </Box>
</>
  );
};

export default BranchEditPage;