import { Box, Spinner, Flex, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import React from "react";

const ProgressCircle = ({value}) => {
    return (
        <Flex
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            zIndex="9999"
            justifyContent="center"
            alignItems="center"
        >
            <Box>
                <CircularProgress value={value} color='green.400' size='80px'>
                    <CircularProgressLabel>{value}%</CircularProgressLabel>
                </CircularProgress>
            </Box>
        </Flex>
    );
};

export default ProgressCircle;
