import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Avatar,
    Button,
    HStack,
    TableContainer,
    Input,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Box,
    useDisclosure,
    Heading,
    useToast,
    InputGroup,
    InputLeftElement,
  } from "@chakra-ui/react";
  import React, { useEffect, useRef, useState } from "react";
  import BackButtons from "../../Components/Buttons/BackButtons";
  import { FcPlus } from "react-icons/fc";
  import {
    MdCancel,
    MdFileDownloadDone,
    MdNavigateBefore,
    MdNavigateNext,
    MdOutlineRefresh,
    MdUploadFile,
  } from "react-icons/md";
  import { useDropzone } from "react-dropzone";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as yup from "yup";
  import api from "../../Api/Api";
  import useUserStore from "../../Hooks/Zustand/Store";
  
  import HrisUserViewPage from "./HrisUserViewPage";
  import { clientTypessense } from "../../Api/Typesense";
  import _ from "lodash";
  import {
    arrayRemoveFirebase,
    deleteDocumentFirebase,
    getCollectionFirebase,
    getCollectionWhereFirebase,
  } from "../../Api/firebaseApi";
  import { db } from "../../Config/firebase";
  import DynamicTable from "../../Components/Table/DynamicTable";
  import DynamicButton from "../../Components/Buttons/DynamicButton";
  import { TbReload } from "react-icons/tb";
  import ApiBackend from "../../Api/ApiBackend";
  import { Search2Icon } from "@chakra-ui/icons";
  import _axios from "../../Api/AxiosBarrier";
  
  const UsersPage = () => {
    const globalState = useUserStore();
    const cancelRef = useRef();
    const [modal, setModal] = useState(false);
    const [loadFile, setLoadFile] = useState(null);
    const projectId = localStorage.getItem("currentProject");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
  
    //state form
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [data, setData] = useState();
    const [dataUser, setDataUser] = useState();
    const [page, setPage] = useState(1);
    const [employeeId, setEmployeeId] = useState(null);
    const [chunkUser, setChunkUser] = useState();
    const [alertRole, setAlertRole] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const apikey = process.env.REACT_APP_PAYMENT_KEY;
    const toast = useToast();
  
    const tableHeader = ["name", "email", "status", "role"];
  
    const handleTypesenseSearch = (q, kodok, pagination = page) => {
      globalState.setIsLoading(true);
      setPage(pagination);
      const users = globalState?.companies?.find(
        (x) => x.id === globalState.currentCompany
      );
        
      const newArr = _.chunk(users.users, 10);
      if (!newArr) return console.log("no data user");
  
      const searchParameters = {
        q: q,
        query_by: "name,email",
        per_page: 10,
        filter_by: `id: [${newArr[page - 1]?.join(",")}]`,
        sort_by: "_text_match:desc",
      };
      
      clientTypessense
        .collections("users")
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => {
            return { ...y.document };
          });
          
          // Fetch role data for each user
          getCollectionFirebase(`companies/${globalState.currentCompany}/roles`)
            .then((rolesData) => {
              const mergedData = newData.map((user) => {
                const roles = rolesData.find((role) =>
                  role.users?.includes(user.id)
                );
                user.role = roles ? roles.title : "";
                user.roleId = roles ? roles.id : "";
                return user;
              });
  
              setData(mergedData);
            })
            .catch((err) => {
              setData([]);
              console.error(err.message);
            });
  
          globalState.setIsLoading(false);
        })
  
        .catch((err) => {
          setData([]);
          console.error(err.message);
          globalState.setIsLoading(false);
        });
  
      return null;
    };
  
    const handleSingleTypesenseSearch = (q) => {
      let searchResult = [];
      if (q === "") return handleTypesenseSearch("", null, 1);
  
      const searchPromises = chunkUser.map((userChunk) => {
        const searchParameters = {
          q: q,
          query_by: "name,email",
          filter_by: `id: [${userChunk.join(",")}]`,
          sort_by: "_text_match:desc",
        };
        return clientTypessense
          .collections("users")
          .documents()
          .search(searchParameters);
      });
  
      Promise.all(searchPromises)
        .then((results) => {
          const rolePromises = results.flatMap((result) => {
            return result.hits.map((x) => {
              return getCollectionFirebase(
                `companies/${globalState.currentCompany}/roles`
              ).then((resultRole) => {
                const matchRole = resultRole?.find((res) =>
                  res?.users?.includes(x?.document?.id)
                );
  
                return { ...x?.document, role: matchRole?.title };
              });
            });
          });
  
          return Promise.all(rolePromises);
        })
        .then((combinedResults) => {
          setData(combinedResults);
        })
        .catch((error) => {
          console.error("Error performing search:", error);
        });
    };
  
    const handleAdd = () => {
      setEmployeeId(null);
      setModal(true);
    };
  
    const handleRemoveRole = async () => {
      try {
        const dataObj = {
          collection: `companies/${globalState.currentCompany}/roles`,
          doc: selectedUser.data.roleId,
          field: "users",
          values: [selectedUser.data.id],
        };
  
        if (dataObj.doc !== "") {
          const res = await _axios.post("/company-removeFromArrayField", dataObj);
          if (res) {
            toast({
              title: "success!",
              description: "succes delete role this user",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: "warning!",
            description: "this user dont have any role",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error, "ini error ");
      } finally {
        getDataFirst();
        setAlertRole(false);
      }
    };
  
    const deleteFromFirestore = async (collection, doc, field, value) => {
      const dataObj = {
        collection: collection,
        doc: doc,
        field: field,
        values: value,
      };
  
      await _axios.post("/company-removeFromArrayField", dataObj);
    };
  
    const deleteUserFromCompany = async () => {
      // const dataConditions = [
      //   {
      //     field: "users",
      //     operator: "array-contains",
      //     value: selectedUser?.data?.id,
      //   },
      // ];
  
      // const deleteUserFromCompany = await getCollectionFirebase("companies", dataConditions);
  
      // const updateCompanyPromises = deleteUserFromCompany.map(async (company) => {
      //   await deleteFromFirestore("companies", company?.id, "owners", [selectedUser?.data?.id]);
      //   await deleteFromFirestore("companies", company?.id, "users", [selectedUser?.data?.id]);
      // });
  
      // await Promise.all(updateCompanyPromises);
  
      await deleteFromFirestore(
        "companies",
        globalState?.currentCompany,
        "owners",
        [selectedUser?.data?.id]
      );
      await deleteFromFirestore(
        "companies",
        globalState?.currentCompany,
        "users",
        [selectedUser?.data?.id]
      );
    };
  
    const deleteFromProject = async () => {
      const conditions = [
        { field: "companyId", operator: "==", value: globalState.currentCompany },
        {
          field: "users",
          operator: "array-contains",
          value: selectedUser?.data?.id,
        },
      ];
  
      const CompanyProject = await getCollectionFirebase("projects", conditions);
  
      const updateProjectPromises = CompanyProject.map(async (project) => {
        await deleteFromFirestore("projects", project?.id, "owners", [
          selectedUser?.data?.id,
        ]);
        await deleteFromFirestore("projects", project?.id, "users", [
          selectedUser?.data?.id,
        ]);
  
        const getSubcol = await getCollectionFirebase(
          `projects/${project.Id}/users`
        );
  
        if (getSubcol && getSubcol.length > 0) {
          await deleteDocumentFirebase(
            `projects/${project.id}/users`,
            selectedUser?.data?.id
          );
        }
      });
  
      await Promise.all(updateProjectPromises);
    };
  
    const handleDeleteUser = async () => {
      if (globalState.roleCompany !== "owner") {
        return toast({
          title: "Alert!",
          description: "You don't have access to delete user",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
  
      setLoading(true);
  
      try {
        await deleteUserFromCompany();
        await deleteFromProject();
  
        setLoading(false);
  
        toast({
          title: "Deoapp",
          status: "success",
          description: "Account deleted",
          duration: 3000,
        });
      } catch (error) {
        console.log(error, "ini error");
        setLoading(false);
      } finally {
        setLoading(false);
        onClose();
        getDataFirst();
      }
    };
  
  
    const getDataFirst = () => {
      const users = globalState?.companies?.find(
        (x) => x.id === globalState.currentCompany
      );
      setDataUser(users?.users);
      setChunkUser(_.chunk(users.users, 10));
      handleTypesenseSearch("", users.users, 1);
    };
  
    useEffect(() => {
      getDataFirst();
  
      return () => {
        setPage(1);
        setDataUser();
      };
    }, [globalState.currentCompany]);
  
    let tableData = "";
    tableData = data?.map((data, index) => {
      const name = data?.name || "";
      const email = data?.email || "";
      const status = data?.status || "";
      const role = data?.role || "Role not assigned";
  
      return {
        data,
        name: (
          <HStack>
            <Avatar size={"md"} name={name} />
            <Stack spacing={1}>
              <Text fontWeight={500} textTransform={"capitalize"}>
                {name}
              </Text>
              <Text fontSize={9}>{data?.id}</Text>
            </Stack>
          </HStack>
        ),
        email: email,
        status: status,
        role: role,
      };
    });
  
    const openModalEdit = (x) => {
      setEmployeeId(x?.data?.id);
      setModal(true);
    };
  
    const openModalDeleteRole = (x) => {
      setAlertRole(true);
      setSelectedUser(x);
    };
  
    const openModalDelete = (x) => {
      setSelectedUser(x);
      onOpen();
    };
  
    return (
      <Stack p={[0, 1, 5]}>
        <HStack pb={3}>
          <BackButtons />
          <Spacer />
          <Heading size={"lg"}>Users</Heading>
        </HStack>
  
        <Stack bg={"white"} p="5" borderRadius="md" shadow="base">
          <HStack flexDirection={["column", "row", "row"]} mt={3} p={2}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
              </InputLeftElement>
              <Input
                bg={"white"}
                maxW="2xs"
                type="text"
                placeholder="Search User"
                onChange={(e) => handleSingleTypesenseSearch(e.target.value)}
                // onChange={(e) => handleTypesenseSearch(e.target.value)}
              />
            </InputGroup>
  
            <HStack>
              <DynamicButton
                action={"create"}
                onClick={handleAdd}
                title={"User"}
              />
              <DynamicButton
                action={"custom"}
                icon={TbReload}
                variant="solid"
                title="Configuration"
                color="blue"
                onClick={() => window.location.reload()}
              />
            </HStack>
          </HStack>
          {/* <HStack>
          <Spacer />
          {page > 1 ? (
            <Button onClick={() => handleTypesenseSearch("", null, page - 1)}>
              Previous
            </Button>
          ) : (
            <></>
          )}
          <Text>{page}</Text>
          {page < dataUser?.length / 10 ? (
            <Button onClick={() => handleTypesenseSearch("", null, page + 1)}>
              Next
            </Button>
          ) : (
            <></>
          )}
        </HStack> */}
  
          <Box>
            <DynamicTable
              header={tableHeader}
              data={tableData}
              onDelete={openModalDelete}
              onRead={openModalEdit}
              onEdit={openModalDeleteRole}
            />
          </Box>
  
          <HStack justifyContent={"center"} spacing={5} pb={5}>
            {page > 1 ? (
              <Button
                colorScheme="blue"
                size={"sm"}
                onClick={() => handleTypesenseSearch("", null, page - 1)}
                // isDisabled={page > 1 ? false : true}
              >
                <HStack color="white" spacing={1} pr={3}>
                  <MdNavigateBefore size={23} />
                  <Text>Prev</Text>
                </HStack>
              </Button>
            ) : null}
  
            {page < dataUser?.length / 10 ? (
              <Button
                colorScheme="blue"
                size={"sm"}
                onClick={() => handleTypesenseSearch("", null, page + 1)}
                // isDisabled={page < mongoData ? false : true}
              >
                <HStack color="white" spacing={1} pl={3}>
                  <Text>Next</Text>
                  <Box>
                    <MdNavigateNext size={23} />
                  </Box>
                </HStack>
              </Button>
            ) : null}
          </HStack>
        </Stack>
  
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          motionPreset="slideInBottom"
          size={"3xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Employee Form</ModalHeader>
            <HrisUserViewPage
              uid={employeeId}
              setModal={setModal}
              getData={getDataFirst}
            />
          </ModalContent>
        </Modal>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete User
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure want to delete user '<b>{selectedUser?.name}</b>'?
                You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isLoading={loading}
                  colorScheme="red"
                  onClick={() => handleDeleteUser()}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
  
        <AlertDialog isOpen={alertRole} onClose={() => setAlertRole(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete User Role
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure want to delete this user role? You can assign it
                again in edit user
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button onClick={() => setAlertRole(false)}>Cancel</Button>
                <Button colorScheme="red" onClick={handleRemoveRole} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>
    );
  };
  
  export default UsersPage;
  