import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdOutlinePermMedia } from "react-icons/md";
// import { addDocumentFirebase, uploadFile } from "../../Api/firebaseApi";
import DropboxUploader from "../../Components/DropBox/DropboxUploader";
import RichTextEditor from "../../Components/Quill/RichTextEditor";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";

const SkincareCreatePage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const modalTag = useDisclosure();
  const globalState = useUserStore();
  const modalUpload = useDisclosure();

  const [newDoctor, setNewDoctor] = useState("");
  const [content, setContent] = useState("");
  const [doctor, setDoctor] = useState([]);
  //   const [data, setData] = useState({
  //   writer: "",
  //   category: "",
  //   title: "",
  // });
  const [dataInput, setDataInput] = useState({
    doctor: [],
    branch: [],
  });
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [branch, setBranch] = useState([]);
  const [newBranch, setNewBranch] = useState("");
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [thumbnailImages, setThumbnailImages] = useState([]);

  const category1 = ["face", "hair", "body", "skin"];

  // const handleDropImage = async (file) => {
  //   const filesFormats = ["image/jpg", "image/jpeg", "image/png", "image/heic"];
  //   const isRightFormat = filesFormats.includes(file?.type);
  //   if (!isRightFormat) {
  //     toast({
  //       title: "Please upload image with the following formats:",
  //       description: ".png, .jpeg, .jpg, .webp, .heic",
  //       isClosable: true,
  //       duration: 9000,
  //       status: "error",
  //     });
  //     return;
  //   }
  //   setIsUploading(true);

  //   if (dataInput.title || dataInput.title === "") {
  //     await uploadFile(dataInput.title, "articles", file).then(
  //       (uploadedImg) => {
  //         console.log(uploadedImg, "this is data result");
  //         setDataInput({
  //           ...dataInput,
  //           thumbnailURL: uploadedImg,
  //         });
  //         setIsUploading(false);
  //       }
  //     );

  //     setIsUploading(false);
  //   } else {
  //     toast({
  //       status: "warning",
  //       title: " Deoapp CRM",
  //       description: "Please input title first",
  //       duration: 2000,
  //     });
  //   }

  //   setIsUploading(false);
  // };

  const contentChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    try {
      let data = {
        ...dataInput,
        thumbnail: thumbnailImages.map((image) => image.file),
        createdAt: serverTimestamp(),
        type: "skincare",
      };

      const docRef = await addDoc(
        collection(db, "skincare-deoapp-clinic"),
        data
      );

      console.log("Data berhasil ditambahkan dengan ID:", docRef.id);

      toast({
        title: "Deoapp Clinic",
        description: "Treatment created!",
        status: "success",
        duration: 1000,
      });

      setTimeout(() => {
        navigate("/product/list-skincare");
      }, 1000);
    } catch (error) {
      console.error(error);

      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });

      toast({
        title: "Which project you want to post this article to?",
        description:
          "Please select project from the sidebar on the left and make sure to fill the content",
        isClosable: true,
        duration: 1000,
        status: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTag = () => {
    let arr = [...dataInput.doctor];
    const selectedDoctor = doctor.find((d) => d.id === newDoctor);

    if (selectedDoctor) {
      arr.push({
        id: selectedDoctor.id,
        name: selectedDoctor.name,
      });
      setDataInput({
        ...dataInput,
        doctor: arr,
      });
    }
  };

  const handleDeleteTag = (index) => {
    let updatedCategories = [...dataInput.doctor];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      doctor: updatedCategories,
    });
  };

  const handleSaveTag2 = () => {
    let arr = [...dataInput.branch];
    const selectedDoctor = branch.find((d) => d.id === newBranch);

    if (selectedDoctor) {
      arr.push({
        id: selectedDoctor.id,
        name: selectedDoctor.name,
      });
      setDataInput({
        ...dataInput,
        branch: arr,
      });
    }
  };

  const handleDeleteTag2 = (index) => {
    let updatedCategories = [...dataInput.branch];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      branch: updatedCategories,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    if (dataInput?.title === "" || dataInput?.title === undefined) {
      toast({
        status: "warning",
        title: " Deoapp Clinic",
        description: "Please input title first",
        duration: 2000,
      });
    } else {
      if (newFiles.length) {
        const newFileArray = [...thumbnailImages]; // Salin array yang sudah ada

        for (let i = 0; i < newFiles.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(newFiles[i]);
          reader.onload = () => {
            newFileArray.push({
              file: reader.result,
              fileName: newFiles[i].name,
              description: newFiles[i].type,
            });

            if (i === newFiles.length - 1) {
              // Update state setelah semua gambar diunggah
              setThumbnailImages(newFileArray);
            }
          };
        }
        setFilesImage(newFiles);
      }
    }
  };

  const handleDeleteThumbnail = (index) => {
    let updatedThumbnails = [...thumbnailImages];
    updatedThumbnails.splice(index, 1);
    setThumbnailImages(updatedThumbnails);
  };

  const openModal = () => {
    setModalUploadOpen(true);
  };

  function closeModal() {
    setModalUploadOpen(false);
  }
  console.log(thumbnailImages);
  return (
    <>
      <BackButtons />
      <Flex justifyContent="space-between">
        <Heading>Added Skincare</Heading>
      </Flex>
      <Box my={10}>
        <Flex>
          {/* Bagian Atas - Kiri */}
          <Box flex={1} mr={5}>
            <Flex justify={"center"}>
              <Input
                type="file"
                onChange={handleFileInputChange}
                display="none"
                id="fileInput"
                multiple
              />

              <label htmlFor="fileInput">
                <HStack cursor="pointer">
                  <Stack>
                    <MdOutlinePermMedia />
                  </Stack>
                  <Text fontSize="sm" color="blue.600" fontStyle="italic">
                    Add Image thumbnails
                  </Text>
                </HStack>
              </label>
            </Flex>

            {Array.isArray(thumbnailImages) && thumbnailImages.length > 0 && (
              <Box
                padding={5}
                mt={5}
                bg="white"
                borderRadius="md"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Thumbnail</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {thumbnailImages.map((image, i) => (
                      <Tr key={i}>
                        <Td>
                          <Image
                            src={image.file}
                            boxSize="50px"
                            objectFit="cover"
                            alt={`Thumbnail ${i + 1}`}
                          />
                        </Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDeleteThumbnail(i)}
                          >
                            X
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>

          {/* Bagian Atas - Kanan */}
          <Box flex={1}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                bg="white"
                value={dataInput.title}
                onChange={(e) =>
                  setDataInput({ ...dataInput, title: e.target.value })
                }
                placeholder="Title Here"
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Price</FormLabel>
              <Input
                bg="white"
                value={dataInput.price}
                onChange={(e) =>
                  setDataInput({ ...dataInput, price: e.target.value })
                }
                placeholder="Price Here"
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>SKU</FormLabel>
              <Input
                bg="white"
                value={dataInput.sku}
                onChange={(e) =>
                  setDataInput({ ...dataInput, sku: e.target.value })
                }
                placeholder="SKU Here"
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Category</FormLabel>
              <Select
                placeholder="Select option"
                bg="white"
                w={"95%"}
                onChange={(e) =>
                  setDataInput({ ...dataInput, category: e.target.value })
                }
              >
                {Array.isArray(category1) &&
                  category1.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Stok</FormLabel>
              <Input
                bg="white"
                value={dataInput.stok}
                onChange={(e) =>
                  setDataInput({ ...dataInput, stok: e.target.value })
                }
                placeholder="Title Here"
              />
            </FormControl>
          </Box>
        </Flex>

        {/* Bagian Bawah */}
        <FormControl mt={5}>
          <FormLabel>Description</FormLabel>
          <Textarea
            w={"full"}
            bg="white"
            value={dataInput?.description}
            onChange={(e) =>
              setDataInput({ ...dataInput, description: e.target.value })
            }
            placeholder="Treatment Description"
          />
        </FormControl>

        <Button
          isLoading={loading}
          my={3}
          size="md"
          colorScheme={"blue"}
          onClick={handleSave}
        >
          Save Skincare
        </Button>
      </Box>
    </>
  );
};

export default SkincareCreatePage;
