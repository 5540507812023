import { Box, Heading, HStack } from '@chakra-ui/react'
import React from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'

function MarketingKolCalendarPage() {
  return (
    <Box>
        <HStack>
            <BackButtons/>
            <Heading>KOL Calendar</Heading>
        </HStack>
    </Box>
  )
}

export default MarketingKolCalendarPage