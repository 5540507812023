import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Fade,
  filter,
  Heading,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUserStore from "../../Hooks/Zustand/Store";
import useKanbanStore from "../../Hooks/Zustand/kanbanStore";
import {
  getCollectionFirebase,
  getCollectionFirebaseV4,
} from "../../Api/firebaseApi";
import { clientTypessense } from "../../Api/Typesense";
import { db } from "../../Config/firebase";
import { useDrop } from "react-dnd";
import TaskCardComponent from "../Card/TaskCardComponent";

const ColumnColorScheme = {
  TODOS: "blue",
  BACKLOG: "red",
  PROGRESS: "green",
  REVIEW: "yellow",
  DONE: "gray",
};

function KanbanColumnsComponent({
  allowedDropEffect,
  column,
  kanbanData,
  filterData,
}) {
  const globalState = useUserStore();
  const [columnsData, setColumnsData] = useState([]);
  const [columnsData2, setColumnsData2] = useState([]);
  const param = useParams();
  const navigate = useNavigate();
  const { resetTaskData, setTaskData, setNewCardId } = useKanbanStore();
  const setSomeFunction = useKanbanStore((state) => state.setFunctionAddData);

  // const handleLoad = () => {
  //   const conditions = [
  //     { field: "filesId", operator: "==", value: param?.id },
  //     { field: "column", operator: "==", value: column },
  //     {
  //       field: "companyId",
  //       operator: "==",
  //       value: globalState?.currentCompany,
  //     },
  //   ];
  //   const sortBy = { field: "lastUpdated", direction: "desc" };
  //   const limitValue = 5;
  //   let startAfter = "";
  //   if (columnsData2.length > 0) {
  //     startAfter = columnsData2[columnsData2.length - 1].lastUpdated;
  //     if (filterData?.category)
  //       conditions.push({
  //         field: "category",
  //         operator: "==",
  //         value: filterData?.category,
  //       });

  //     if (filterData?.label)
  //       conditions.push({
  //         field: "label",
  //         operator: "==",
  //         value: filterData?.label,
  //       });

  //     if (filterData?.asignee)
  //       conditions.push({
  //         field: "asignee",
  //         operator: "==",
  //         value: filterData?.asignee,
  //       });
  //   } else {
  //     // console.log('pertama kali')
  //     startAfter = columnsData[columnsData.length - 1].lastUpdated;
  //     if (filterData?.category)
  //       conditions.push({
  //         field: "category",
  //         operator: "==",
  //         value: filterData?.category,
  //       });

  //     if (filterData?.label)
  //       conditions.push({
  //         field: "label",
  //         operator: "==",
  //         value: filterData?.label,
  //       });

  //     if (filterData?.asignee)
  //       conditions.push({
  //         field: "asignee",
  //         operator: "==",
  //         value: filterData?.asignee,
  //       });
  //   }

  //   getCollectionFirebaseV4(
  //     "kanban",
  //     { conditions },
  //     { sortBy },
  //     { limitValue },
  //     { startAfterData: startAfter }
  //   ).then((x) => {
  //     const updateData = [...columnsData2, ...x];
  //     setColumnsData2(updateData);
  //   });
  // };

  // const handleTypesenseSearch = (q) => {
  //   // console.log(q)
  //   const searchParameters = {
  //     q: q,
  //     query_by: "title",
  //     filter_by: `filesId: ${param.id} && column:${column} `,
  //     sort_by: "_text_match:desc",
  //   };
  //   clientTypessense
  //     .collections("kanban")
  //     .documents()
  //     .search(searchParameters)
  //     .then((x) => {
  //       // console.log(x)
  //       const newData = x?.hits?.map((y) => {
  //         return { ...y.document };
  //       });
  //       setColumnsData(newData);
  //     });
  // };

  // useEffect(() => {
  //   let unsubscribe = () => {};

  //   if (filterData?.search)
  //     setTimeout(function () {
  //       handleTypesenseSearch(filterData.search);
  //     }, 300);

  //   if (globalState.currentCompany && !filterData?.search) {
  //     let collectionRef = collection(db, "kanban");
  //     collectionRef = query(collectionRef, where("filesId", "==", param.id));
  //     collectionRef = query(collectionRef, where("column", "==", column));
  //     collectionRef = query(
  //       collectionRef,
  //       where("companyId", "==", globalState?.currentCompany)
  //     );

  //     if (filterData?.category)
  //       collectionRef = query(
  //         collectionRef,
  //         where("category", "==", filterData.category)
  //       );

  //     if (filterData?.label)
  //       collectionRef = query(
  //         collectionRef,
  //         where("label", "==", filterData.label)
  //       );

  //     if (filterData?.asignee)
  //       collectionRef = query(
  //         collectionRef,
  //         where("asignee", "array-contains-any", filterData.asignee)
  //       );

  //     collectionRef = query(collectionRef, orderBy("lastUpdated", "desc"));
  //     collectionRef = query(collectionRef, limit(5));

  //     unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
  //       const addTask = [];

  //       querySnapshot.forEach((doc) => {
  //         addTask.push({ id: doc.id, ...doc.data() });
  //       });

  //       // console.log(querySnapshot.length, 'kodok')
  //       setColumnsData(addTask);
  //     });
  //   }

  //   return () => {
  //     unsubscribe();
  //     setColumnsData([]);
  //     setColumnsData2([]);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [globalState.currentCompany, filterData]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "column",
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect]
  );

  const ColumnTasks = (datas, type) =>
    datas?.map((x, index) => {
      // console.log(x, 'ini datanya di columntask')
      if (x?.id)
        return (
          <Fade in={true} initialscale={0.9} key={index}>
            <TaskCardComponent
              key={index}
              task={x}
              index={index}
              setData={type === "snapshot" ? setColumnsData : setColumnsData2}
              columnsData={datas}
              kanbanData={kanbanData}
              // onDropHover={swapTasks}
              //   onUpdate={updateTask}
              //   onDelete={deleteTask}
            />
          </Fade>
        );
    });

  return (
    <Box maxW="full" m="1">
      <Heading fontSize="md" mb={4} letterSpacing="wide" textAlign="center">
        <Badge
          w="full"
          fontSize="xl"
          px={2}
          py={1}
          rounded="lg"
          colorScheme={ColumnColorScheme[column.toUpperCase()]}
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction="column"
        h={{ base: "30vh", md: "80vh" }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? "red" : "white"}
        rounded="lg"
        boxShadow="md"
        overflow="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            w: "2",
            h: "3",
          },
          "&::-webkit-scrollbar-track": {
            w: "6",
            h: "3",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10",
            bg: `gray.300`,
          },
        }}
        // scrollSnapStop={false}
      >
        {ColumnTasks(columnsData, "snapshot")}
        {columnsData2?.length ? ColumnTasks(columnsData2, "manual") : <></>}
        {columnsData?.length > 4 && columnsData2?.length === 0 ? (
          <Button
            // onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={"white"}
            fontSize="sm"
          >
            {" "}
            Load more...
          </Button>
        ) : columnsData2?.length > 4 ? (
          <Button
            // onClick={() => handleLoad()}
            py={2}
            bgColor="blue.300"
            color={"white"}
            fontSize="sm"
          >
            {" "}
            Load more...
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
}

export default KanbanColumnsComponent;
