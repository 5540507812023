import React, { useEffect, useState } from "react";
import BackButtons from "../../Components/Buttons/BackButtons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdOutlinePermMedia } from "react-icons/md";
// import { addDocumentFirebase, uploadFile } from "../../Api/firebaseApi";
import DropboxUploader from "../../Components/DropBox/DropboxUploader";
import RichTextEditor from "../../Components/Quill/RichTextEditor";
import useUserStore from "../../Hooks/Zustand/Store";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { updateDocumentFirebase } from "../../Api/firebaseApi";

const TreatmentEditPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const modalTag = useDisclosure();
  const globalState = useUserStore();
  const modalUpload = useDisclosure();

  const [newDoctor, setNewDoctor] = useState("");
  const [content, setContent] = useState("");
  const [doctor, setDoctor] = useState("");
  const [data, setData] = useState({});
  const [dataInput, setDataInput] = useState({
    doctor: [],
    branch: [],
  });
  const [branch, setBranch] = useState([]);
  const [newBranch, setNewBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const params = useParams();

  const getData = async () => {
    const documentRef = doc(db, "treatment-deoapp-clinic", params.id);
    try {
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const dataEdit = documentSnapshot.data();
        console.log("Data dokumen:", dataEdit);

        // Set dataInput dengan dataEdit sebagai defaultValue
        setDataInput({
          title: dataEdit.title || "",
          author: dataEdit.author || "",
          description: dataEdit.description || "",
          branch: dataEdit.branch || [],
          doctor: dataEdit.doctor || [],
        });
        setImageUrl(dataEdit.thumbnail || "")
      } else {
        console.log("Dokumen tidak ditemukan.");
      }
    } catch (error) {
      console.error("Gagal mendapatkan dokumen:", error.message);
    }
  };

  const getDoctor = async () => {
    const collectionRef = collection(db, "doctor-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDoctor(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const getBranch = async () => {
    const collectionRef = collection(db, "branch-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(collectionRef);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setBranch(data);
      console.log("Data dari koleksi:", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  // const handleDropImage = async (file) => {
  //   const filesFormats = ["image/jpg", "image/jpeg", "image/png", "image/heic"];
  //   const isRightFormat = filesFormats.includes(file?.type);
  //   if (!isRightFormat) {
  //     toast({
  //       title: "Please upload image with the following formats:",
  //       description: ".png, .jpeg, .jpg, .webp, .heic",
  //       isClosable: true,
  //       duration: 9000,
  //       status: "error",
  //     });
  //     return;
  //   }
  //   setIsUploading(true);

  //   if (dataInput.title || dataInput.title === "") {
  //     await uploadFile(dataInput.title, "articles", file).then(
  //       (uploadedImg) => {
  //         console.log(uploadedImg, "this is data result");
  //         setDataInput({
  //           ...dataInput,
  //           thumbnailURL: uploadedImg,
  //         });
  //         setIsUploading(false);
  //       }
  //     );

  //     setIsUploading(false);
  //   } else {
  //     toast({
  //       status: "warning",
  //       title: " Deoapp CRM",
  //       description: "Please input title first",
  //       duration: 2000,
  //     });
  //   }

  //   setIsUploading(false);
  // };

  const contentChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, "treatment-deoapp-clinic", params.id);
      const updatedProductData = {
        ...dataInput,
        thumbnail: imageUrl,
        createdAt: serverTimestamp(),
        type: "treatment",
      };

      await updateDoc(docRef, updatedProductData);
      
      if (Array.isArray(dataInput.doctor) && dataInput.doctor.length > 0) {
        for (const doctor of dataInput.doctor) {
          const doctorDocRef = doc(db, "doctor-deoapp-clinic", doctor.id);
  
          const doctorDocSnapshot = await getDoc(doctorDocRef);
  
          if (doctorDocSnapshot.exists()) {
            const existingData = doctorDocSnapshot.data();
  
            if (existingData.treatment && existingData.treatment.includes(dataInput.title)) {
              continue;
            }
  
            const updatedData = {
              ...existingData,
              treatment: [
                ...(existingData.treatment || []),
                {
                  id: docRef.id,
                  title: dataInput.title,
                },
              ],
            };
  
            await updateDoc(doctorDocRef, updatedData);
          }
        }
      }

      if (Array.isArray(dataInput.branch) && dataInput.branch.length > 0) {
        for (const branch of dataInput.branch) {
          const doctorDocRef = doc(db, "branch-deoapp-clinic", branch.id);
  
          const doctorDocSnapshot = await getDoc(doctorDocRef);
  
          if (doctorDocSnapshot.exists()) {
            const existingData = doctorDocSnapshot.data();
  
            if (existingData.branch && existingData.branch.includes(dataInput.title)) {
              continue;
            }
  
            const updatedData = {
              ...existingData,
              treatment: [
                ...(existingData.treatment || []),
                {
                  id: docRef.id,
                  title: dataInput.title,
                },
              ],
            };
  
            await updateDoc(doctorDocRef, updatedData);
          }
        }
      }
  
      toast({
        title: "Deoapp Clinic",
        description: "Treatment updated!",
        status: "success",
        duration: 1000,
      });
  
      setTimeout(() => {
        navigate("/booking/list-treatment");
      }, 1000);
    } catch (error) {
      console.error(error);
  
      toast({
        title: "Deoapp Clinic",
        description: error.message,
        status: "error",
        duration: 1000,
      });
  
      toast({
        title: "Which project you want to post this article to?",
        description:
          "Please select project from the sidebar on the left and make sure to fill the content",
        isClosable: true,
        duration: 1000,
        status: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTag = () => {
    let arr = [...dataInput.doctor];
    const selectedDoctor = doctor.find((d) => d.id === newDoctor);

    if (selectedDoctor) {
      arr.push({
        id: selectedDoctor.id,
        name: selectedDoctor.name,
      });
      setDataInput({
        ...dataInput,
        doctor: arr,
      });
    }
  };

  const handleDeleteTag = (index) => {
    let updatedCategories = [...dataInput.doctor];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      doctor: updatedCategories,
    });
  };

  const handleSaveTag2 = () => {
    let arr = [...dataInput.branch];
    const selectedDoctor = branch.find((d) => d.id === newBranch);

    if (selectedDoctor) {
      arr.push({
        id: selectedDoctor.id,
        name: selectedDoctor.name,
      });
      setDataInput({
        ...dataInput,
        branch: arr,
      });
    }
  };

  const handleDeleteTag2 = (index) => {
    let updatedCategories = [...dataInput.branch];
    updatedCategories.splice(index, 1);
    setDataInput({
      ...dataInput,
      branch: updatedCategories,
    });
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    if (dataInput?.title === "" || dataInput?.title === undefined) {
      toast({
        status: "warning",
        title: " Deoapp Clinic",
        description: "Please input title first",
        duration: 2000,
      });
    } else {
      if (newFiles.length) {
        const newFileArray = [];
        for (let i = 0; i < newFiles.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(newFiles[i]);
          reader.onload = () => {
            newFileArray.push({
              file: reader.result,
              fileName: newFiles[i].name,
              description: newFiles[i].type,
            });
            setFiles(newFileArray);

            if (i === 0) {
              setImageUrl(reader.result);
            }
          };
        }
        setFilesImage(newFiles);
      }
    }
  };

  const openModal = () => {
    setModalUploadOpen(true);
  };

  function closeModal() {
    setModalUploadOpen(false);
  }
  console.log(dataInput);
  useEffect(() => {
    getDoctor();
    getBranch();
    getData();
  }, []);
  return (
    <>
      <BackButtons />
      <Flex justifyContent="space-between">
        <Heading>Create Treatment</Heading>
      </Flex>
      <Box my={10}>
        <Flex>
          {/* Bagian Atas - Kiri */}
          <Box flex={1} mr={5}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                bg="white"
                value={dataInput.title}
                onChange={(e) =>
                  setDataInput({ ...dataInput, title: e.target.value })
                }
                placeholder="Title Here"
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Price</FormLabel>
              <Input
                bg="white"
                value={dataInput.price}
                onChange={(e) =>
                  setDataInput({ ...dataInput, price: e.target.value })
                }
                placeholder="Price Here"
              />
            </FormControl>
          </Box>

          {/* Bagian Atas - Kanan */}
          <Box flex={1}>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                w="full"
                bg="white"
                value={dataInput?.description}
                onChange={(e) =>
                  setDataInput({ ...dataInput, description: e.target.value })
                }
                placeholder="Treatment Description"
              />
            </FormControl>
            {dataInput.price && (
              <Text mt={2} fontSize="lg" fontWeight="bold" color="black">
                Price: Rp.{" "}
                {new Intl.NumberFormat("id-ID").format(dataInput.price)}
              </Text>
            )}
          </Box>
        </Flex>

        <Divider my={5} />

        {/* Bagian Bawah */}
        <Box>
          <Tooltip label="Thumbnail image for your articles">
            <Text fontWeight={600} color="gray.600">
              Thumbnail
            </Text>
          </Tooltip>
          {imageUrl ? (
            <>
              <Image src={imageUrl} boxSize="full" objectFit="cover" />
              <Flex justify="center" mt={2}>
                <Input
                  type="file"
                  onChange={handleFileInputChange}
                  display="none"
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Flex cursor="pointer">
                    <Box>
                      <MdOutlinePermMedia />
                    </Box>
                    <Text fontSize="sm" color="blue.600" fontStyle="italic">
                      Change Image thumbnail
                    </Text>
                  </Flex>
                </label>
              </Flex>
            </>
          ) : (
            <Flex justify="center" mt={2}>
              <Input
                type="file"
                onChange={handleFileInputChange}
                display="none"
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <Flex cursor="pointer">
                  <Box>
                    <MdOutlinePermMedia />
                  </Box>
                  <Text fontSize="sm" color="blue.600" fontStyle="italic">
                    Add Image thumbnail
                  </Text>
                </Flex>
              </label>
            </Flex>
          )}

          <FormControl marginTop={"2%"}>
            <FormLabel>Branch</FormLabel>
            <Flex>
              <Select
                placeholder="Select option"
                bg="white"
                w="95%"
                onChange={(e) => setNewBranch(e.target.value)}
              >
                {Array.isArray(branch) &&
                  branch.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Select>
              <Button colorScheme="green" onClick={handleSaveTag2}>
                +
              </Button>
            </Flex>
          </FormControl>
          {Array.isArray(dataInput?.branch) &&
            dataInput?.branch?.length > 0 && (
              <Box
                padding={5}
                mt={5}
                bg="white"
                borderRadius="md"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Branch</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataInput?.branch.map((x, i) => (
                      <Tr key={i}>
                        <Td>{x.name}</Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDeleteTag2(i)}
                          >
                            X
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}

          <FormControl marginTop={"2%"}>
            <FormLabel>Doctor</FormLabel>
            <Flex>
              <Select
                placeholder="Select option"
                bg="white"
                w="95%"
                onChange={(e) => setNewDoctor(e.target.value)}
              >
                {Array.isArray(doctor) &&
                  doctor.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Select>
              <Button colorScheme="green" onClick={handleSaveTag}>
                +
              </Button>
            </Flex>
          </FormControl>
          {Array.isArray(dataInput?.doctor) &&
            dataInput?.doctor?.length > 0 && (
              <Box
                padding={5}
                mt={5}
                bg="white"
                borderRadius="md"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Doctor</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataInput?.doctor.map((x, i) => (
                      <Tr key={i}>
                        <Td>{x.name}</Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDeleteTag(i)}
                          >
                            X
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}

          <Button
            isLoading={loading}
            my={3}
            size="md"
            colorScheme="blue"
            onClick={handleSave}
          >
            Save Treatment
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TreatmentEditPage;
