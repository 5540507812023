/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import { Icon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
  SimpleGrid,
  Drawer,
  useDisclosure,
  Avatar,
  TagLabel,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FiSettings, FiBell } from "react-icons/fi";
import store from "store";
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropdownCircle,
  IoMdSettings,
} from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Config/firebase";

import { data } from "./DataMenu";
import useUserStore from "../../Hooks/Zustand/Store";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from "../../Api/firebaseApi";
import themeConfig from "../../Config/themeConfig";
import { logoutUserWithIp } from "../../Hooks/Middleware/sessionMiddleWare";
import { encryptToken } from "../../Utils/encrypToken";
import { anggaranApi } from "../../Api/Anggaran";
import { ApiAccountingToken } from "../../Api/ApiAccountingToken";
import moment from "moment";
import roleFilter from "../../Hooks/Plugins/Role";
import determineUserRoles from "../../Hooks/Plugins/RoleGlobal";
import { AiOutlineDesktop, AiOutlineMobile } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { HiBell } from "react-icons/hi";

// ** Theme Configuration

function SidebarComponentV3({ layout }) {
  const [menu, setMenu] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [userInfo, setUserInfo] = useState("");
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [desktopShow, setDesktopShow] = useState(true);
  const [hidden, setHidden] = useState(!isOpen);
  const modalAddProject = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [dataProject, setDataProject] = useState({
    name: "",
    description: "",
    modules: ["business"],
  });

  const detailSubMenu = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

  const [listProject, setListProject] = useState([]);

  const globalState = useUserStore();

  const uid = globalState?.uid;

  const encryptUid = encryptToken(uid);
  const encryptFix = encodeURIComponent(encryptUid);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevIsVisible) => !prevIsVisible);
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // console.log(globalState);

  const getUserRole = (data, uid, dataId) => {
    let findData = {};
    findData = data.find((x) => x.id === dataId);

    if (findData && findData.owner?.includes(uid)) {
      return 'owner';
    } else if (findData && findData?.managers?.includes(uid)) {
      return 'managers';
    } else {
      return 'user';
    }
  };
  
  const fetchProjectsAndCompanies = async (uid) => {
    // let fetchCompanyId = localStorage.getItem('currentCompany');
    // let fetchProjectId = localStorage.getItem('currentProject');
    let fetchCompanyId = localStorage.getItem('currentCompany')
    let fetchProjectId = localStorage.getItem('currentProject')

    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: uid,
      },
    ];

    try {
      const [companies, projects] = await Promise.all([
        getCollectionFirebase('companies', conditions),
        getCollectionFirebase('projects', conditions),
      ]);

      if (!fetchCompanyId) {
        // Set data to localStorage only if it's not already set
        fetchCompanyId = companies[0]?.id; // Set the fetched ID to the variable
        const userRoleInCompany = getUserRole(
          companies,
          uid,
          companies[0]?.id,
          'company'
        );

        // localStorage.setItem('currentCompany', fetchCompanyId);
        localStorage.setItem('currentCompany', fetchCompanyId);
        globalState.setExpired(
          companies[0]?.expired_at?.seconds >= moment().unix() ? false : true
        );
        globalState.setCompanies(companies);
        globalState.setCurrentCompany(fetchCompanyId);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);
        await handleUsersDisplay(companies, fetchCompanyId);

      } else {
        globalState.setExpired(
          companies[0]?.expired_at?.seconds >= moment().unix() ? false : true
        );
        globalState.setCurrentCompany(fetchCompanyId);

        const userRoleInCompany = getUserRole(
          companies,
          uid,
          fetchCompanyId,
          'company'
        );
        globalState.setCompanies(companies);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);

        await handleUsersDisplay(companies, fetchCompanyId);

      }

      if (!fetchProjectId) {
        if (companies[0]?.id) {
          fetchProjectId = projects[0]?.id;
          const userRoleInProject = getUserRole(
            projects,
            uid,
            fetchProjectId,
            'project'
          );
          // localStorage.setItem('currentProject', fetchProjectId);
          localStorage.setItem('currentProject', fetchProjectId);
          globalState.setProjects(projects);
          globalState.setCurrentProject(fetchProjectId);
          globalState.setRoleProject(userRoleInProject);
        }
      } else {
        const userRoleInProject = getUserRole(
          projects,
          uid,
          fetchProjectId,
          'project'
        );
        globalState.setProjects(projects);
        globalState.setCurrentProject(fetchProjectId);
        globalState.setRoleProject(userRoleInProject);
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const fetchProjects = async (id) => {
    const searchProjectId = globalState?.projects?.find(
      (x) => x.companyId === id
    );

    const conditions = [
      {
        field: "users",
        operator: "array-contains",
        value: globalState.uid,
      },
      {
        field: "companyId",
        operator: "==",
        value: id,
      },
    ];

    const projects = await getCollectionFirebase("projects", conditions);

    const fetchProjectId = localStorage.getItem("currentProject");

    // Set the projects for the company
    globalState.setProjects(projects);

    if (!fetchProjectId) {
      // If there's no project explicitly selected, set the first project as the current project
      if (projects?.length > 0) {
        globalState.setCurrentProject(projects[0].id);
        localStorage.setItem("currentProject", projects[0].id);
      } else {
        // Handle the case when no projects are available for the company
        globalState.setCurrentProject(null);
        localStorage.removeItem("currentProject");
      }
    } else {
      // Handle the case when a project was explicitly selected
      globalState.setCurrentProject(fetchProjectId);
    }
    setListProject(projects);
  };

  const user = auth.currentUser;

  useEffect(() => {
    fetchProjects(globalState.currentCompany);

    return () => {};
  }, [globalState.currentCompany]);

  const navigate = useNavigate();

  const toast = useToast();

  const handleSelectMenu = (value) => {
    setMenu(value);

    if (value.status) {
      null;
    } else {
      if (value?.link?.includes("https")) {
        window.open(value?.link, "_blank");
      } else {
        navigate(value.link);
      }
    }
  };

  const logout = async () => {
    const pathLink = "business";

    await logoutUserWithIp(
      window.location.hostname,
      globalState?.email,
      pathLink
    );

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: "success",
          description: "Logged out success",
          duration: 2000,
        });
        navigate("/");
        store.clearAll();
        globalState.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error, "ini error");
      });
  };

  // const handleTokenAccounting = async (value) => {
  //   try {
  //     const res = await ApiAccountingToken(globalState, value);
  //     globalState.setAccountingData(res.data);
  //   } catch (error) {
  //     console.log(error, "ini error");
  //   }
  // };

  const handleUsersDisplay = async (companies, currentCompany) => {
    try {
      const findCompany = companies.find((x) => x.id === currentCompany);

      if (!findCompany) {
        console.log("Company not found");
        return;
      }

      const allUsers = [...new Set([...findCompany.users, findCompany.owner])];
      const dataUsersDisplay = [];

      await Promise.all(
        allUsers.map(async (userId) => {
          try {
            const result = await getSingleDocumentFirebase("users", userId);

            const user = {
              id: userId || "",
              name: result.name || result.displayName,
              email: result.email || "",
              phoneNumber: result.phoneNumber || result.phone,
              image: result.image || "",
            };

            dataUsersDisplay.push(user);
          } catch (error) {
            console.log(error, "ini error");
          }
        })
      );

      globalState.setUsers(dataUsersDisplay);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleCompanySelect = async (e) => {
    globalState.setIsLoading(true);
    const dataCompany = globalState.companies;
    const findCompany = dataCompany.find((x) => x.id === e);
    const companyId = findCompany?.id || e;

    localStorage.setItem("currentCompany", companyId);

    await handleUsersDisplay(dataCompany, companyId);
    globalState.setCurrentCompany(companyId);
    globalState.setCurrentXenditId(findCompany?.xenditId);
    globalState.setExpired(
      findCompany?.expired_at >= moment().unix() ? false : true
    );

    const userRoles = determineUserRoles(findCompany, globalState.uid);
    globalState.setRoleCompany(userRoles);

    if (companyId) {
      const conditions = [
        {
          field: "users",
          operator: "array-contains",
          value: globalState.uid,
        },
        {
          field: "companyId",
          operator: "==",
          value: companyId,
        },
      ];

      try {
        const anggaranId = await anggaranApi(
          `api/company/${companyId}/by-uid`,
          globalState.anggaranData.token
        );
        globalState.setAnggaranId(anggaranId.data.id);

        const projects = await getCollectionFirebase("projects", conditions);
        globalState.setProjects(projects);

        const currentProjectId = getCurrentProjectId(projects, companyId);

        if (currentProjectId) {
          globalState.setCurrentProject(currentProjectId);
          localStorage.setItem("currentProject", currentProjectId);

          const project = projects.find(
            (project) => project.id === currentProjectId
          );
          const projectRoles = determineUserRoles(project, globalState.uid);
          globalState.setRoleProject(projectRoles);
        } else if (projects.length > 0) {
          const defaultProjectId = projects[0].id;
          globalState.setCurrentProject(defaultProjectId);
          localStorage.setItem("currentProject", defaultProjectId);

          const defaultProject = projects.find(
            (project) => project.id === defaultProjectId
          );
          const defaultProjectRoles = determineUserRoles(
            defaultProject,
            globalState.uid
          );
          globalState.setRoleProject(defaultProjectRoles);
        } else {
          globalState.setCurrentProject(null);
          localStorage.removeItem("currentProject");
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    globalState.setIsLoading(false);
  };

  const getCurrentProjectId = (projects, companyId) => {
    const currentProjectId = localStorage.getItem("currentProject");
    if (currentProjectId) {
      const project = projects.find(
        (project) => project.companyId === companyId
      );
      return project ? project.id : null;
    }
    return null;
  };
  const handleProjectSelect = async (e) => {
    if (e === "add project") {
      modalAddProject.onOpen();
    } else {
      globalState.setIsLoading(true);
      const dataProject = listProject;

      const findProject = dataProject.find((x) => x.id === e);
      localStorage.setItem("currentProject", findProject.id || e);
      globalState.setCurrentProject(findProject.id || e);

      determineUserRoles(findProject, globalState.uid);

      globalState.setIsLoading(false);
    }
  };

  const handleAddProject = async () => {
    const data = {
      ...dataProject,
      owner: [globalState.uid],
      users: [globalState.uid],
    };
    setIsLoading(true);

    try {
      if (dataProject.name === "" || dataProject.description === "") {
        toast({
          title: "Deoapp CRM",
          description: "Please fill the form",
          status: "error",
          duration: 3000,
        });
      } else {
        const res = await addDocumentFirebase(
          "projects",
          data,
          globalState.currentCompany
        );

        const result = await setDocumentFirebase(
          `projects/${res}/users`,
          globalState?.uid,
          {
            name: globalState?.name,
            email: globalState?.email,
            createdAt: new Date(),
            id: globalState?.uid,
          }
        );

        localStorage.setItem("currentProject", res);
        globalState.setCurrentProject(res);

        toast({
          title: "Deoapp CRM",
          description: "Project Created!",
          status: "success",
          duration: 3000,
        });
      }

      setIsLoading(false);
      modalAddProject.onClose();
      location.reload();
      // window.open('/crm')
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRole = async () => {
    const conditions = [
      { field: "users", operator: "array-contains", value: globalState?.uid },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };
    const limitValue = 10;
    const startAfterData = null;

    try {
      const res = await getCollectionFirebase(
        `companies/${globalState?.currentCompany}/roles`,
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );

      if (res[0]?.id) {
        const result = await getSingleDocumentFirebase("files", res[0]?.id);

        globalState.setRole(result);
      }
    } catch (error) {
      console.log(error, "ini error");
      globalState.setRole({});
    }
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getRole();
    }

    return () => {};
  }, [globalState?.currentCompany]);

  const handleNavigate = (value) => {
    if (value?.link?.includes("https")) {
      window.open(`${value.link}?id=${encryptFix}`, "_blank");
    } else {
      navigate(value.link);
    }
  };

  const handleClick = () => {
    setDesktopShow(!desktopShow);
  };

  const height = window?.innerHeight;

  if (layout.type === "vertical" || layout.type === "vertical-horizontal")
    return (
      <HStack spacing={"-1"}>
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              w: "2",
              h: "3",
            },
            "&::-webkit-scrollbar-track": {
              w: "6",
              h: "5",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10",
              bg: `gray.200`,
            },
          }}
          width={desktopShow ? "100px" : "15px"}
          transition={"0.2s ease-in-out"}
          shadow={"xl"}
          roundedBottomRight={"lg"}
          roundedTopRight={"lg"}
          minH={height}
          backgroundColor={themeConfig.color.colorFirst}
        >
          <Box position="sticky">
            <Stack
              position={"absolute"}
              right={-4}
              cursor={"pointer"}
              onClick={handleClick}
              alignItems="flex-end"
              justifyContent={"flex-end"}
              p={2}
              borderRadius="full"
              bgColor="blue.200"
              zIndex={500}
            >
              {desktopShow ? (
                <AiOutlineMobile size={17} />
              ) : (
                <AiOutlineDesktop size={17} />
              )}
            </Stack>

            <Stack
              {...getButtonProps()}
              position={"absolute"}
              right={-5}
              top={300}
              py={[5, null, null]}
              bg={"blue.300"}
              minH={50}
              overflowY="scroll"
              borderRadius={"md"}
              zIndex={-1}
              cursor={"pointer"}
              p={1}
              alignItems={"center"}
              justify={"center"}
            >
              {isOpen ? (
                <IoIosArrowBack size={18} color="white" />
              ) : (
                <IoIosArrowForward size={18} color="white" />
              )}
            </Stack>

            <Flex as="section">
              <Stack display={desktopShow ? "flex" : "none"}>
                <Box onClick={() => navigate("/")} cursor={"pointer"}>
                  <Image src={themeConfig.logokotak} borderRadius="full" />
                </Box>

                <Box px={2}>
                  <Divider />
                </Box>

                <Stack alignItems={"center"}>
                  <Text fontWeight={500} fontSize="xs">
                    Company :
                  </Text>

                  <Select
                    w={["90%", "100%", "80%"]}
                    size={"sm"}
                    borderColor={
                      !globalState?.currentCompany ? "red.600" : "blue.600"
                    }
                    borderWidth={2}
                    placeholder={
                      globalState?.companies?.length === 0 && "select Company"
                    }
                    value={globalState?.currentCompany}
                    onChange={(e) => {
                      handleCompanySelect(e.target.value);
                    }}
                  >
                    {globalState?.companies?.map((select, i) => (
                      <option key={i} value={select?.id}>
                        <Text textTransform={"capitalize"}>{select?.name}</Text>
                      </option>
                    ))}
                  </Select>
                </Stack>

                <Stack alignItems={"center"}>
                  <Select
                    w={["90%", "100%", "80%"]}
                    size={"sm"}
                    borderColor={
                      !globalState?.currentProject ? "red.600" : "blue.600"
                    }
                    borderWidth={2}
                    placeholder={listProject?.length === 0 && "select project"}
                    value={globalState?.currentProject}
                    onChange={(e) => {
                      handleProjectSelect(e.target.value);
                    }}
                  >
                    {listProject?.map((select, i) => (
                      <option key={i} value={select?.id}>
                        <Text textTransform={"capitalize"}>{select?.name}</Text>
                      </option>
                    ))}
                    <option value={"add project"} style={{ padding: "10px 0" }}>
                      + New Project / Team
                    </option>
                  </Select>
                </Stack>

                <Stack
                  height={["40vh", "50vh", "50vh"]}
                  overflowY="scroll"
                  sx={{
                    "&::-webkit-scrollbar": {
                      w: "2",
                      h: "3",
                    },
                    "&::-webkit-scrollbar-track": {
                      w: "6",
                      h: "5",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "10",
                      bg: `gray.200`,
                    },
                  }}
                >
                  {data.map((menu, i) => (
                    <Stack
                      key={i}
                      pt={2}
                      spacing={1}
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() => handleSelectMenu(menu)}
                      cursor={"pointer"}
                      position="relative"
                    >
                      <Icon as={menu.icon} boxSize={6} />
                      <Text fontSize={10}>{menu.name}</Text>
                      {menu.status ? (
                        <Stack
                          size={"xs"}
                          position="absolute"
                          bgColor={"red"}
                          borderRadius="md"
                          visibility={isVisible ? "visible" : "hidden"}
                          top={2}
                        >
                          <Text
                            color={"white"}
                            fontSize={"xx-small"}
                            fontWeight={500}
                            py={"0.5"}
                            px={1}
                          >
                            Coming Soon
                          </Text>
                        </Stack>
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
                {data.lenght > 7 && (
                  <Stack alignItems={"center"} justifyContent="center">
                    <IoIosArrowDropdownCircle />
                  </Stack>
                )}

                <Spacer />

                <Stack spacing={3}>
                  {/* <Stack
                    align={"center"}
                    onClick={() => navigate("/settings")}
                    cursor={"pointer"}
                  >

                    <Icon as={HiBell} boxSize={6} color="subtle" />
                  </Stack> */}

                  <Stack
                    align={"center"}
                    onClick={() => navigate("/settings")}
                    cursor={"pointer"}
                  >
                    <Icon as={IoMdSettings} boxSize={6} color="subtle" />
                  </Stack>
                  {/* </Button> */}
                </Stack>

                <Box py={3}>
                  <Center py={2}>
                    <Avatar
                      boxSize={10}
                      name={user?.displayName}
                      src={user?.photoURL}
                    />
                  </Center>

                  <Box align={"center"}>
                    <Button size={"xs"} colorScheme="red" onClick={logout}>
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Flex>
          </Box>
        </Box>

        <motion.div
          {...getDisclosureProps()}
          hidden={hidden}
          initial={false}
          onAnimationStart={menu?.submenu ? () => setHidden(false) : null}
          onAnimationComplete={menu?.submenu ? () => setHidden(!isOpen) : null}
          animate={menu?.submenu ? { width: isOpen ? 200 : 0 } : 0}
          style={{
            borderStartEndRadius: 20,
            borderEndEndRadius: 20,
            background: "#f5f5f5",
            overflow: "hidden",
            whiteSpace: "nowrap",
            // position: "absolute",
            // left: "0",
            height: "100vh",
            // top: "0",
          }}
        >
          <Stack p={3} h={"100vh"}>
            <Text
              color={"gray.600"}
              my={3}
              fontWeight={"semibold"}
              align={"center"}
              textTransform="uppercase"
            >
              Business Deoapp
            </Text>
            <Box>
              <Divider />
            </Box>
            <Stack spacing={3} pt={3}>
              <Accordion allowToggle>
                {menu.submenu?.map((sub, i) => (
                  <AccordionItem
                    key={i}
                    // isDisabled={x.name === "Social Media" ? true : false}
                  >
                    <h2>
                      <AccordionButton w={"100%"}>
                        <HStack spacing={2} w={"100%"}>
                          <Icon as={sub.icon} boxSize={isDesktop ? 5 : 7} />
                          <Text
                            fontWeight={500}
                            fontSize={isDesktop ? "sm" : "xs"}
                            noOfLines={1}
                          >
                            {sub.name}
                          </Text>
                          <Spacer />
                          <AccordionIcon />
                        </HStack>
                      </AccordionButton>
                    </h2>
                    {sub.submenu ? (
                      <>
                        <AccordionPanel>
                          <Stack>
                            {sub.submenu?.map((subitem, i) => {
                              return (
                                <Box
                                  cursor={"pointer"}
                                  onClick={() => handleNavigate(subitem)}
                                  key={i}
                                >
                                  <HStack spacing="3">
                                    <Icon as={subitem.icon} boxSize={5} />
                                    <Text
                                      pl={3}
                                      fontWeight={300}
                                      fontSize={isDesktop ? "sm" : "xs"}
                                      noOfLines={1}
                                    >
                                      {subitem.name}
                                    </Text>
                                  </HStack>
                                  <Divider py={1} />
                                </Box>
                              );
                            })}
                          </Stack>
                        </AccordionPanel>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
            <Spacer />
            <Stack spacing={0} align={"center"} color={"gray.500"}>
              <Text fontSize={"xs"}>{globalState?.name}</Text>
              <Text fontSize={"xs"}>{globalState?.email}</Text>
            </Stack>
            <Divider />
            <Text
              color={"gray.500"}
              align={"center"}
              fontSize={12}
              fontWeight={"semibold"}
            >
              Business Deoapp
            </Text>
          </Stack>
        </motion.div>

        <Modal
          isOpen={modalAddProject.isOpen}
          onClose={modalAddProject.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Fill the form correctly</Text>

              <FormControl>
                <FormLabel fontSize={14} my={3}>
                  Project Name
                  <Text as={"span"} color={"gray.400"} fontStyle={"italic"}>
                    {"  "}
                    {"("}your business name{")"}
                  </Text>
                </FormLabel>
                <Input
                  onChange={(e) =>
                    setDataProject({ ...dataProject, name: e.target.value })
                  }
                  placeholder={"Enter project/business name"}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={14} my={3}>
                  Project Description
                </FormLabel>
                <Input
                  onChange={(e) =>
                    setDataProject({
                      ...dataProject,
                      description: e.target.value,
                    })
                  }
                  placeholder={"Enter project/business description"}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={isLoading}
                onClick={handleAddProject}
                colorScheme="green"
              >
                Add New Project
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    );

  return <></>;
}

export default SidebarComponentV3;
