// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3B6hz3hqUYJ-18mQavC7WX23HhkMx-lM",
  authDomain: "marketerid-app.firebaseapp.com",
  databaseURL: "https://marketerid-app-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "marketerid-app",
  storageBucket: "marketerid-app.appspot.com",
  messagingSenderId: "262355582495",
  appId: "1:262355582495:web:6c3c8be13682c4580edf2a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig,'marketer');
export const authMarketer = getAuth(app);