import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../src/Config/firebase";
import useUserStore from "../Hooks/Zustand/Store";

const config = {
  baseURL: process.env.REACT_APP_HRIS,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const accessToken = localStorage.getItem("tokenFirebase");
    const getCompany = localStorage.getItem("currentCompany")
    const getProject = localStorage.getItem("currentProject")
    const token = JSON.parse(accessToken);
    

    // const { currentProject, currentCompany } = useUserStore()

    onAuthStateChanged(auth, (user) => {
      if (user) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["company"] = getCompany;
        config.headers["project"] = getProject;
      }
    });
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    response = typeof response.data !== "undefined" ? response.data : response;
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
