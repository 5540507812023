import React, { useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { IoArrowDown } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  deleteDocumentFirebase,
  deleteFileFirebase,
  getDocWithSnapshotFirebase,
} from "../../Api/firebaseApi";
import useUserStore from "../../Hooks/Zustand/Store";
import DynamicButton from "../../Components/Buttons/DynamicButton";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { FaPhoneAlt } from "react-icons/fa";
import BackButtons from "../../Components/Buttons/BackButtons";
import { BiSolidUserDetail } from "react-icons/bi";

const BookingListPage = () => {
  const [deleteActive, setDeleteActive] = useState({});
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState([]);
  const [detail, setDetail] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();
  const globalState = useUserStore();
  const modal = useDisclosure();

  const getBooking = async () => {
    const collectionRef = collection(db, "booking-list-deoapp-clinic");

    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy("createdAt", "desc"))
      );

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setBooking(data);
      console.log("Data dari koleksi (diurutkan berdasarkan createdAt):", data);
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleEdit = (x) => {
    navigate(`/news/edit?id=${x.id}`);
  };

  const handleDetail = async (x) => {
    const documentRef = doc(db, "booking-list-deoapp-clinic", x);
    console.log(x, "id nya ini");
    try {
      const docSnapshot = await getDoc(documentRef);

      if (docSnapshot.exists()) {
        const data = { id: docSnapshot.id, ...docSnapshot.data() };
        setDetail(data);
        console.log(" :", data);

        // Jika Anda menggunakan modal.isOpen() untuk menampilkan modal, pastikan fungsinya benar
        modal.onOpen();
      } else {
        console.log("Dokumen tidak ditemukan.");
      }
    } catch (error) {
      console.error("Gagal mengambil data:", error.message);
    }
  };

  console.log(booking, "detail");
  console.log(statusFilter, "detail");
  useEffect(() => {
    getBooking();
  }, []);

  const filteredBooking = booking?.filter((item) => {
    const nameMatch = item?.displayName
      ?.toLowerCase()
      .includes(searchInput?.toLowerCase());
    const hpMatch = item?.phoneNumber
      ?.toLowerCase()
      .includes(searchInput?.toLowerCase());
    const statusMatch = statusFilter
      ? item.status.toLowerCase() === statusFilter.toLowerCase()
      : true;
  
    return nameMatch || hpMatch ;
  });
  
  

  return (
    <>
      <BackButtons />
      <Heading>Booking Schedule</Heading>
      <Flex justifyContent="space-between" padding={"1%"}>
        <HStack spacing="4">
          <Input
            placeholder="Search by name or phone number"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <Select
            placeholder="Filter by status"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">All Status</option>
            <option value="menunggu pembayaran">Menunggu Pembayaran</option>
            <option value="proses">Proses</option>
            <option value="selesai">Selesai</option>
            <option value="dibatalkan">Dibatalkan</option>
          </Select>
        </HStack>
        <DynamicButton
          action={"create"}
          title={"Add Booking"}
          onClick={() => navigate("/booking/add-booking")}
        />
      </Flex>

      <Table bgColor={"white"} variant="simple">
        <Thead>
          <Tr>
            <Th>Patient</Th>
            <Th>Phone Number</Th>
            <Th>Location</Th>
            <Th>Total Biaya</Th>
            {/* <Th>Treatment</Th>
            <Th>Doctor</Th>
            <Th>Time</Th> */}
            <Th>Status</Th>
            <Th>Created At</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Array.isArray(filteredBooking) &&
            filteredBooking.map((news, index) => (
              <Tr key={index}>
                <Td>{news.displayName}</Td>
                <Td>{news.phoneNumber}</Td>
                <Td>{news.branch}</Td>
                <Td>Rp. {news?.totalPrice?.toLocaleString("en-US")}</Td>
                <Td w={{ base: "10%", lg: "15%" }}>
                  <Badge
                    colorScheme={news.status === "complete" ? "green" : "gray"}
                  >
                    {news.status}
                  </Badge>
                </Td>
                <Td>{news.createdAt}</Td>
                <Td>
                  <HStack spacing="1">
                    <IconButton
                      icon={<BiSolidUserDetail fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Booking Detail"
                      onClick={() => handleDetail(news.id)}
                    />

                    <IconButton
                      icon={<FaPhoneAlt fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Open WhatsApp"
                      onClick={() =>
                        window.open(`https://wa.me/${news.phoneNumber}`, "_blank")
                      }
                    />
                    <IconButton
                      icon={<FiTrash2 fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Delete news"
                      onClick={() => console.log(news)}
                    />
                    <IconButton
                      icon={<FiEdit2 fontSize="1.25rem" />}
                      variant="ghost"
                      aria-label="Edit news"
                      onClick={() => handleEdit(news)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Modal isOpen={modal.isOpen} size="xl" onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Booking Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table bgColor={"white"} variant="simple">
              <Thead>
                <Tr>
                  <Th>Treatment</Th>
                  <Th>Dokter</Th>
                  <Th>Waktu</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(detail.treatment) &&
                  detail.treatment.map((news, index) => (
                    <Tr key={index}>
                      <Td>{news.title}</Td>
                      <Td>{news.doctor}</Td>
                      <Td>{news.time}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modal.onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete {deleteActive?.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={() => console.log()}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default BookingListPage;
