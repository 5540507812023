import React from "react";
import LineIndexPage from "../Pages/Operation/LineIndexPage";
import LineProductionPage from "../Pages/Operation/LineProductionPage";
import OperationPage from "../Pages/Operation/OperationPage";

const OperationRouter = [
  {
    path: "/operation",
    element: <OperationPage />,
  },
  {
    path: "/operation/line",
    element: <LineProductionPage />,
  },

  {
    path: "/operation/line/:id",
    element: <LineIndexPage />,
  },




];

export default OperationRouter;
