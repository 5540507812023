import {
  FcCalendar,
  FcConferenceCall,
  FcEditImage,
  FcKindle,
  FcNews,
  FcSettings,
  FcSms,
  FcSurvey,
  FcParallelTasks,
  FcPackage,
  FcGlobe,
  FcBusinessContact,
  FcAutomatic,
  FcBriefcase,
  FcShop,
  FcLibrary,
  FcCollaboration,
  FcCustomerSupport,
  FcFile,
  FcLike,
  FcDeployment,
  FcInTransit,
  FcEnteringHeavenAlive,
  FcRules,
  FcCloseUpMode,
  FcReading,
  FcMoneyTransfer,
  FcOrganization,
  FcHome,
  FcCurrencyExchange,
  FcMindMap,
  FcShipped,
  FcContacts,
  FcManager,
  FcPieChart,
  FcPaid,
  FcIdea,
  FcTreeStructure,
  FcSportsMode,
  FcAdvertising,
  FcAssistant,
  FcDepartment,
  FcDisplay,
  FcBarChart,
  FcDocument,
  FcOpenedFolder,
  FcWorkflow,
  FcFrame,
  FcPlus,
  FcPicture,
  FcSpeaker,
  FcFilmReel,
  FcBullish,
  FcFilingCabinet,
  FcBusinessman,
  FcDiploma2,
  FcTwoSmartphones,
  FcEngineering,
  FcBusiness,
  FcViewDetails,
  FcMusic,
  FcMultipleCameras,
  FcList,
  FcRightDown2,
  FcRightUp2,
  FcTimeline,
  FcProcess,
  FcBiohazard,
  FcFactory,
  FcClearFilters,
  FcClock,
  FcStatistics,
  FcDataSheet,
  FcEmptyFilter,
  FcComboChart,
  FcGenericSortingAsc,
  FcSalesPerformance,
  FcSynchronize,
  FcLeft,
  FcRight,
  FcCheckmark,
  FcBusinesswoman,
  FcFlowChart,
  FcPrint,
  FcDownload,
  FcAddDatabase,
  FcRightUp,
  FcLeftDown,
  FcUpload,
  FcDatabase,
  FcSerialTasks,
  FcExport,
  FcHighPriority,
  FcPlanner,
  FcLeave,
  FcAnswers,
  FcOvertime,
  FcGraduationCap,
  FcRadarPlot,
  FcDebt,
  FcLockLandscape,
  FcTodoList,
  FcGoogle,
  FcFilledFilter,
  FcFlashOn,
  FcImageFile,
  FcPhone,
  FcTemplate,
  FcLowPriority,
  FcApprove,
  FcDonate,
  FcStart,
  FcVoicePresentation,
  FcFinePrint,
  FcDataConfiguration,
} from "react-icons/fc";
import { TbWorld } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";

import React from "react";

export const data = [
  {
    name: "Home",
    icon: FcHome,
    link: "/",
    description:
      "Transform financial operations, streamlining tasks, ensuring compliance while providing valuable data-driven insights for informed financial decisions.",
  },

  // {
  //   name: "Web",
  //   icon: TbWorld,
  //   link: "/artikel",
  //   description:
  //     "Explore our concise articles for valuable insights, from industry trends to practical guides. Stay informed, make sound decisions, and navigate the evolving landscape with ease.",
  //   submenu: [
  //     {
  //       name: "Artikel",
  //       icon: FcAnswers,
  //       link: "/artikel/create-artikel",
  //       description:
  //         "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
  //       submenu: [
  //         {
  //           name: "Create Artikel",
  //           icon: FcAnswers,
  //           link: "/artikel/create-artikel",
  //           description:
  //             "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
  //         },
  //         {
  //           name: "List Artikel",
  //           icon: FcFinePrint,
  //           link: "/artikel/list-artikel",
  //           description:
  //             "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
  //         },
  //         {
  //           name: "Artikel Category",
  //           icon: FcDataConfiguration,
  //           link: "/artikel/artikel-category",
  //           description:
  //             "Simplify content creation, manage tasks, and boost productivity with our user-friendly platform for article creation. Explore a seamless way to share knowledge and information.",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    name: "Patient",
    icon: FcBusinessman ,
    link: "/patient",
    description:
      "Explore our booking services to efficiently manage appointments, medical professionals, and treatments.",
    submenu: [
      {
        name: "History",
        icon: FcClock,
        link: "/history",
        description:
          "Explore our concise articles for valuable insights, from industry trends to practical guides. Stay informed, make sound decisions, and navigate the evolving landscape with ease.",
        submenu: [
          {
            name: "Patient history",
            icon: FcFinePrint,
            link: "/patient/patient-history",
            description:
              "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
          },
        ],
      },
    ],
  },

  {
    name: "Doctor",
    icon: FcManager  ,
    link: "/doctor",
    description:
      "Explore our booking services to efficiently manage appointments, medical professionals, and treatments.",
      submenu: [
        {
          name: "Input Form",
          icon: FcAddDatabase,
          // link: '/booking/list-booking',
          description:
            "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
          submenu: [
            {
              name: "Doctor List",
              icon: FcManager,
              link: "/doctor/list-doctor",
              description:
                "Discover our comprehensive list of medical professionals. Easily manage and organize your team of doctors for efficient healthcare services.",
            },
          ],
        },
      ],
  },

  {
    name: "Product",
    icon: FcDeployment   ,
    link: "/product",
    description:
      "Explore our booking services to efficiently manage appointments, medical professionals, and treatments.",
      submenu: [
        {
          name: "Input Form",
          icon: FcAddDatabase,
          // link: '/booking/list-booking',
          description:
            "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
          submenu: [
            {
              name: "Treatment List",
              icon: FcLike,
              link: "/product/list-treatment",
              description:
                "Explore our range of treatments and services. Simplify the booking process for various medical procedures and enhance patient care.",
            },
            {
              name: "Product List",
              icon: FcApprove,
              link: "/product/list-product",
              description:
                "Explore our range of treatments and services. Simplify the booking process for various medical procedures and enhance patient care.",
            },
          ],
        },
      ],
  },

  {
    name: "Calendar",
    icon: FcPlanner,
    link: "/booking",
    description:
      "Explore our booking services to efficiently manage appointments, medical professionals, and treatments.",
    submenu: [
      {
        name: "Booking List",
        icon: FcDataSheet,
        // link: '/booking/list-booking',
        description:
          "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
        submenu: [
          {
            name: "Open Booking List",
            icon: FcDataSheet,
            link: "/booking/list-booking",
            description:
              "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
          },
          {
            name: "Create Booking List",
            icon: FcPlus,
            link: "/booking/add-booking",
            description:
              "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
          },
        ],
      },
      {
        name: "Input Form",
        icon: FcAddDatabase,
        // link: '/booking/list-booking',
        description:
          "Browse through the list of bookings to track appointments, manage schedules, and streamline the booking process.",
        submenu: [
          {
            name: "Branch List",
            icon: FcDepartment,
            link: "/booking/list-branch",
            description:
              "Explore our range of treatments and services. Simplify the booking process for various medical procedures and enhance patient care.",
          },
        ],
      },
      {
        name: "History",
        icon: FcClock,
        link: "/history",
        description:
          "Explore our concise articles for valuable insights, from industry trends to practical guides. Stay informed, make sound decisions, and navigate the evolving landscape with ease.",
        submenu: [
          {
            name: "Treatment History",
            icon: FcAnswers,
            link: "/history/treatment-history",
            description:
              "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
          },
          {
            name: "Patient history",
            icon: FcFinePrint,
            link: "/history/patient-history",
            description:
              "Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.",
          },
        ],
      },
    ],
  },

  // {
  //   name: 'History',
  //   icon: FcClock  ,
  //   link: '/history',
  //   description:
  //     'Explore our concise articles for valuable insights, from industry trends to practical guides. Stay informed, make sound decisions, and navigate the evolving landscape with ease.',
  //   submenu: [
  //     {
  //       name: 'Treatment History',
  //       icon: FcAnswers,
  //       link: '/history/treatment-history',
  //       description:
  //         'Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.'
  //     },
  //     {
  //       name: 'Patient history',
  //       icon: FcFinePrint,
  //       link: '/history/patient-history',
  //       description:
  //         'Embark on a journey of content creation with our Create Artikel feature. Streamline your business processes by efficiently managing tasks, fostering collaboration, and optimizing scheduling. Empower your team to make informed decisions and enhance overall productivity. Dive into the world of article creation and discover a seamless way to share knowledge, insights, and information. Unlock the potential of streamlined task management with our user-friendly platform.'
  //     }
  //   ],
  // },

  // {
  //   name: "Configuration",
  //   icon: FcConferenceCall,
  //   link: "/configuration",
  //   description:
  //     "All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.",
  //   submenu: [
  //     {
  //       name: "Users",
  //       icon: FcConferenceCall,
  //       link: "/configuration/users",
  //       description:
  //         "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //       submenu: [
  //         {
  //           name: "Project",
  //           icon: FcBriefcase,
  //           link: "/configuration/project",
  //           description:
  //             "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //         },

  //         {
  //           name: "Users",
  //           icon: FcConferenceCall,
  //           link: "/configuration/users",
  //           description:
  //             "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //         },
  //       ],
  //     },

  //     {
  //       name: "Role",
  //       icon: FcLockLandscape,
  //       link: "/configuration/role",
  //       description:
  //         "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //       submenu: [
  //         {
  //           name: "Role",
  //           icon: FcLockLandscape,
  //           link: "/configuration/role",
  //           description:
  //             "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'Configuration',
  //   icon: FcConferenceCall,
  //   link: '/configuration',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Project',
  //       icon: FcBriefcase,
  //       link: '/configuration/project',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },

  //     {
  //       name: 'Users',
  //       icon: FcConferenceCall,
  //       link: '/configuration/users',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },

  //     {
  //       name: 'Role',
  //       icon: FcLockLandscape,
  //       link: '/configuration/role',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //   ],
  // },

  // {
  //   name: 'Operation',
  //   icon: FcEngineering,
  //   link: '/operation',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Production',
  //       icon: FcProcess,
  //       link: '/operation',
  //       submenu: [
  //         {
  //           name: 'Line',
  //           icon: FcTimeline,
  //           link: '/operation/line',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   name: 'Warehouse',
  //   icon: FcPackage,
  //   link: '/wms',
  //   description:
  //     'Enhance warehouse operations, simplifies inventory management, order processing, and logistics tracking, ensuring efficient and error-free warehouse processes.',
  //   submenu: [
  //     {
  //       name: 'Receiving',
  //       icon: FcDownload,
  //       link: '/wms/receiving',
  //     },
  //     {
  //       name: 'Put-away',
  //       icon: FcDeployment,
  //       link: '/wms/put-awau',
  //     },
  //     {
  //       name: 'Storage',
  //       icon: FcAddDatabase,
  //       link: '/wms/storage',
  //     },
  //     {
  //       name: 'Picking',
  //       icon: FcSportsMode,
  //       link: '/wms/picking',
  //     },
  //     {
  //       name: 'Packing',
  //       icon: FcRightDown2,
  //       link: '/wms/packing',
  //     },
  //     {
  //       name: 'Shipping',
  //       icon: FcShipped,
  //       link: '/wms/shipping',
  //     },
  //     {
  //       name: 'Setting',
  //       icon: FcSettings,
  //       link: '/wms/settings',
  //       submenu: [
  //         {
  //           name: 'Integration',
  //           icon: FcFlowChart,
  //           link: '/wms/settings/integration',
  //         },
  //         {
  //           name: 'Warehouse',
  //           icon: FcDepartment,
  //           link: '/wms/settings/warehouse',
  //         },
  //         {
  //           name: 'Rack',
  //           icon: FcDatabase,
  //           link: '/wms/settings/rack',
  //         },
  //         {
  //           name: 'Shipping',
  //           icon: FcInTransit,
  //           link: '/wms/settings/shipping',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   name: 'Marketing',
  //   icon: FcPackage,
  //   link: '/marketing',
  //   description:
  //     'Enhance warehouse operations, simplifies inventory management, order processing, and logistics tracking, ensuring efficient and error-free warehouse processes.',
  //   submenu: [
  //     {
  //       name: 'KOL',
  //       icon: FcVoicePresentation,
  //       link: '/marketing/kol',
  //       submenu: [
  //         {
  //           name: 'KOL Lists',
  //           icon: FcList,
  //           link: '/marketing/kol/list',
  //         },
  //         {
  //           name: 'Campaign',
  //           icon: FcCollaboration,
  //           link: '/marketing/kol/campaign',
  //         },
  //         {
  //           name: 'Calendar',
  //           icon: FcCalendar,
  //           link: '/marketing/kol/calendar',
  //         },
  //         // {
  //         //   name: "Integration",
  //         //   icon: FcDatabase,
  //         //   link: "/marketing/meta/integration",
  //         // },
  //       ],
  //     },
  //     {
  //       name: 'Meta Ads',
  //       icon: FaFacebookSquare,
  //       link: '/marketing/meta',
  //       submenu: [
  //         {
  //           name: 'Facebook',
  //           icon: FcStart,
  //           link: '/marketing/meta/facebook',
  //         },
  //         {
  //           name: 'Instagram',
  //           icon: FcDepartment,
  //           link: '/marketing/meta/facebook',
  //         },
  //         {
  //           name: 'Integration',
  //           icon: FcDatabase,
  //           link: '/marketing/meta/integration',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Google Ads',
  //       icon: FcGoogle,
  //       link: '/marketing/google',
  //       submenu: [
  //         {
  //           name: 'Youtube',
  //           icon: FcStart,
  //           link: '/marketing/google/youtube',
  //         },
  //         {
  //           name: 'Search',
  //           icon: FcDepartment,
  //           link: '/marketing/google/search',
  //         },
  //         {
  //           name: 'GDN',
  //           icon: FcDatabase,
  //           link: '/marketing/google/gdn',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Landing Page',
  //       icon: FcFilledFilter,
  //       link: '/marketing/funnels',
  //       submenu: [
  //         {
  //           name: 'Landing Page',
  //           icon: FcFilledFilter,
  //           link: '/marketing/funnels/',
  //         },
  //         {
  //           name: 'Domains',
  //           icon: FcGlobe,
  //           link: '/marketing/funnels/domains',
  //         },
  //       ],
  //     },
  //     // {
  //     //   name: "Setting",
  //     //   icon: FcSettings,
  //     //   link: "/wms/settings",
  //     //
  //     // },
  //   ],
  // },
];
