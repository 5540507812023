import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import useUserStore from "../../Hooks/Zustand/Store";
import useMarketingStore from "../../Hooks/Zustand/MarketingStore";

function MarketingFacebookAddCampaignPage() {
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const steps = [
    { title: "Campaign", description: "Create Campaign" },
    { title: "Ads", description: "Create Ads" },
    { title: "Audience", description: "Select Audience" },
    {
      title: "Budget & Strategies",
      description: "Set your budget & strategies",
    },
    { title: "Publish", description: "Review & publish" },
  ];
  const navigate = useNavigate();

  console.log(marketingState);
  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={1}
        bg={"white"}
        p={5}
        borderRadius={"md"}
        shadow={"base"}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>
                {step.description}
              </StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Heading textAlign="center">Campaign </Heading>

      <Container my="5" shadow="base" bg={"white"} p={5} borderRadius={"md"}>
        <FormControl>
          <FormLabel>Campaign Name</FormLabel>
          <Input
            value={marketingState?.campaign?.campaignName}
            onChange={(e) =>
              marketingState?.setCampaign({ campaignName: e.target.value })
            }
            type="text"
          />
          <FormHelperText>We'll never share your email.</FormHelperText>
        </FormControl>

        <FormControl my={3}>
          <FormLabel>Ad Account</FormLabel>
          <HStack>
            <Select
              onChange={(e) =>
                marketingState?.setCampaign({ adAccount: e.target.value })
              }
              placeholder="Select ad account"
            >
              <option value={globalState.currentCompany}>
                {globalState.currentCompany}
              </option>
            </Select>
            <Button>+</Button>
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel>Link to your Facebook fanpage</FormLabel>
          <Input
            value={marketingState?.campaign?.facebookLink}
            onChange={(e) =>
              marketingState?.setCampaign({ facebookLink: e.target.value })
            }
            type="text"
          />
        </FormControl>

        <FormControl my={3}>
          <FormLabel>Link to your Instagram account</FormLabel>
          <Input
            value={marketingState?.campaign?.instagramLink}
            onChange={(e) =>
              marketingState?.setCampaign({ instagramLink: e.target.value })
            }
            type="text"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Pixel ID for this campaign</FormLabel>
          <Input
            value={marketingState?.campaign?.pixelID}
            onChange={(e) =>
              marketingState?.setCampaign({ pixelID: e.target.value })
            }
            type="text"
          />
        </FormControl>

        <FormControl my={3}>
          <FormLabel>Tags</FormLabel>
          <Input
            value={marketingState?.campaign?.tags}
            onChange={(e) =>
              marketingState?.setCampaign({ tags: e.target.value })
            }
            type="text"
          />
          {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">What do you want to promote?</FormLabel>
          <RadioGroup
            value={marketingState?.campaign?.promotionType}
            onChange={(e) => marketingState?.setCampaign({ promotionType: e })}
            defaultValue="External Website"
          >
            <HStack spacing="24px">
              <Radio value="External Website">External Website</Radio>
              <Radio value="Lead Generation">Lead Generation</Radio>
              <Radio value="Facebook Messanger">Facebook Messenger</Radio>
            </HStack>

            <HStack spacing="24px">
              <Radio value="Local Awareness">Local Awareness</Radio>
              <Radio value="Brand Awarness">Brand Awarness</Radio>
              <Radio value="Video Views">Video Views</Radio>
            </HStack>
          </RadioGroup>
          {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
        </FormControl>

        <Button
          my={3}
          w="full"
          colorScheme="green"
          onClick={() => navigate("/marketing/meta/facebook/add/ads")}
        >
          Next
        </Button>
      </Container>
    </Box>
  );
}

export default MarketingFacebookAddCampaignPage;
