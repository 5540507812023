import {
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
  } from "@chakra-ui/react";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { FcPlus } from "react-icons/fc";
  import { TbReload } from "react-icons/tb";
  import { Link, useNavigate } from "react-router-dom";
  import { addDocumentFirebase, getCollectionFirebase } from "../../Api/firebaseApi";
  import BackButtons from "../../Components/Buttons/BackButtons";
  import DynamicButton from "../../Components/Buttons/DynamicButton";
  import ImageComponent from "../../Components/Image/ImageComponent";
  import useUserStore from "../../Hooks/Zustand/Store";
  
  function ProjectPage() {
    const globalState = useUserStore();
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("")
    const [modalNew, setModalNew] = useState(false)
  
    const navigate = useNavigate();
    const getData = async () => {
      try {
        if (globalState?.currentCompany) {
          const conditions = [
            {
              field: "companyId",
              operator: "==",
              value: globalState?.currentCompany,
            },
            {
              field: "users",
              operator: "array-contains",
              value: globalState?.uid,
            },
          ];
          const data = await getCollectionFirebase("projects", conditions);
          setData(data);
        }
      } catch (error) {
        console.log(`Err code IP-GD: ${error}`);
      }
    };
  
    const handleNewProject = () => {
      setModalNew(true)
    }
  
    const createNewProject = () => {
      const data = {
        name: title,
        companyId: globalState.currentCompany,
        lastUpdated: new Date(),
        owner: [globalState.uid],
        users: [globalState.uid],
      };
      addDocumentFirebase("projects", data, globalState.currentCompany).then((x) => {
        navigate(`/configuration/project/${x}`);
      });
    }
  
    useEffect(() => {
      getData();
      return () => {
        setData();
      };
    }, [globalState?.currentCompany]);
  
    return (
      <Stack p={[1, 1, 5]} spacing={5}>
        <HStack flexDirection={["column", "row", "row"]}>
          <BackButtons />
          <Heading size={"md"}>Projects</Heading>
          <Spacer />
  
          <DynamicButton action={"create"} title="Project" onClick={handleNewProject} />
          <DynamicButton
            action={"custom"}
            icon={TbReload}
            variant="solid"
            title="Configuration"
            color="blue"
            onClick={() => window.location.reload()}
          />
  
        </HStack>
        <SimpleGrid columns={{ base: 1, lg: 4 }} p="2" spacing={4} my={5}>
          {data?.length > 0 && (
            data.map((x, i) => (
              <Stack
                key={i}
                p="5"
                shadow="base"
                minH="100px"
                bg={"white"}
                borderRadius={"md"}
                onClick={() => navigate(`${x.id}`)}
                cursor='pointer'
              >
                <ImageComponent image={x.image} name={x.name} />
                <Spacer />
  
                <Stack>
                  <Text fontWeight={"bold"}>{x.name}</Text>
                  <Text fontSize="3xs">ID: {x.id}</Text>
                </Stack>
  
                <SimpleGrid columns={{ base: 2, lg: 3 }} textAlign="center">
                  <Box shadow="base" borderRadius="md" m="1" p="1">
                    <Text fontSize="xs">Manager</Text>
                    <Text fontWeight={500}>{x?.managers?.length ? x.managers.length : 0}</Text>
                  </Box>
                  <Box shadow="base" borderRadius="md" m="1" p="1">
                    <Text fontSize="xs">Users</Text>
                    <Text fontWeight={500}>{x?.users?.length ? x.users.length : 0}</Text>
                  </Box>
                  <Box shadow="base" borderRadius="md" m="1" p="1">
                    <Text fontSize="xs">Modules</Text>
                    <Text fontWeight={500}>{x?.modules?.length ? x.modules.length : 0}</Text>
                  </Box>
                </SimpleGrid>
                <Flex overflowY="auto">
                  {x.modules?.map((z, index) => (
                    <Badge key={index} colorScheme="green" m="1" borderRadius={'md'}>
                      {z}
                    </Badge>
                  ))}
                </Flex>
                <Text fontSize='2xs' textAlign={'end'}>Created: {moment(x?.createdAt?.seconds * 1000).format('LLL')}</Text>
              </Stack>
            ))
          )}
        </SimpleGrid>
  
  
        <Modal isOpen={modalNew} onClose={() => setModalNew(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* <Lorem count={2} /> */}
              <Input type="text" onChange={(e) => setTitle(e.target.value)} />
            </ModalBody>
  
            <ModalFooter>
              <DynamicButton
                action={"create"}
                onClick={() => createNewProject()}
                title="Save"
                variant={"solid"}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
  
  
  
      </Stack>
    );
  }
  
  export default ProjectPage;
  