import React from 'react';
import MarketingGoogleGdnCampaignPage from '../Pages/Marketing/google/MarketingGoogleGdnCampaignPage';
import MarketingGoogleSearchCampaignPage from '../Pages/Marketing/google/MarketingGoogleSearchCampaignPage';
import MarketingGoogleYoutubeCampaignPage from '../Pages/Marketing/google/MarketingGoogleYoutubeCampaignPage';
import MarketingKolKanbanPage from '../Pages/Marketing/kol/MakretingKolKanbanPage';
import MarketingKolCalendarPage from '../Pages/Marketing/kol/MarketingKolCalendarPage';
import MarketingKolCampaignPage from '../Pages/Marketing/kol/MarketingKolCampaignPage';
import MarketingKolListPage from '../Pages/Marketing/kol/MarketingKolListPage';
import MarketingFacebookAddAdCampaignPage from '../Pages/Marketing/MarketingFacebookAddAdPage';
import MarketingFacebookAddAudiencePage from '../Pages/Marketing/MarketingFacebookAddAudiencePage';
import MarketingFacebookAddBudgetPage from '../Pages/Marketing/MarketingFacebookAddBudgetPage';
import MarketingFacebookAddCampaignPage from '../Pages/Marketing/MarketingFacebookAddCampaignPage';
import MarketingFacebookAddPublishPage from '../Pages/Marketing/MarketingFacebookAddPublishPage';
import MarketingFacebookPage from '../Pages/Marketing/MarketingFacebookPage';
import MarketingFunnelsPage from '../Pages/Marketing/MarketingFunnelsPage';
import MarketingFunnelsViewPage from '../Pages/Marketing/MarketingFunnelsViewPage';
import MarketingMetaIntegration from '../Pages/Marketing/MarketingMetaIntegration';
import MarketingPage from '../Pages/Marketing/MarketingPage';
import MarketingLandingPage from '../Pages/Marketing/MarketingLandingPage';
import MarketingDomainPage from '../Pages/Marketing/landingPage/MarketingDomainPage';
const MarketingRouter = [
  {
    path: '/marketing',
    element: <MarketingPage />,
  },
  {
    path: '/marketing/funnels',
    element: <MarketingFunnelsPage />,
  },
  {
    path: '/marketing/funnels/:id',
    element: <MarketingFunnelsViewPage />,
  },
  {
    path: '/marketing/funnels/domains',
    element: <MarketingDomainPage />,
  },
  // {
  //   path: "/marketing/funnel/create/:funnelId/lp-builder/:pageId",
  //   element: <MarketingLandingPage />,
  // },
  {
    path: '/marketing/funnels/:id',
    element: <MarketingFunnelsViewPage />,
  },
  {
    path: '/marketing/meta/integration',
    element: <MarketingMetaIntegration />,
  },
  {
    path: '/marketing/meta/facebook',
    element: <MarketingFacebookPage />,
  },
  {
    path: '/marketing/meta/facebook/add/campaign',
    element: <MarketingFacebookAddCampaignPage />,
  },
  {
    path: '/marketing/meta/facebook/add/ads',
    element: <MarketingFacebookAddAdCampaignPage />,
  },
  {
    path: '/marketing/meta/facebook/add/audience',
    element: <MarketingFacebookAddAudiencePage />,
  },
  {
    path: '/marketing/meta/facebook/add/budget',
    element: <MarketingFacebookAddBudgetPage />,
  },
  {
    path: '/marketing/meta/facebook/add/publish',
    element: <MarketingFacebookAddPublishPage />,
  },
  {
    path: '/marketing/kol/list',
    element: <MarketingKolListPage />,
  },
  {
    path: '/marketing/kol/campaign',
    element: <MarketingKolCampaignPage />,
  },
  {
    path: '/marketing/kol/campaign/:id',
    element: <MarketingKolKanbanPage />,
  },
  {
    path: '/marketing/kol/calendar',
    element: <MarketingKolCalendarPage />,
  },
  {
    path: '/marketing/google/youtube',
    element: <MarketingGoogleYoutubeCampaignPage />,
  },
  {
    path: '/marketing/google/search',
    element: <MarketingGoogleSearchCampaignPage />,
  },
  {
    path: '/marketing/google/gdn',
    element: <MarketingGoogleGdnCampaignPage />,
  },
];

export default MarketingRouter;
