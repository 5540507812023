import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Flex,
  Spacer,
  VStack,
  useColorModeValue,
  SlideFade,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../Config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import BackButtons from '../../Components/Buttons/BackButtons';

const PatientHistoryPage = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(20); 
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db,'customers');
        const querySnapshot = await getDocs(collectionRef);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (searchTerm) {
          // Jika ada searchTerm, filter data
          const filteredUsers = data.filter((user) =>
            user.displayName && user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setUsers(filteredUsers);
        } else {
          // Jika tidak ada searchTerm, gunakan data lengkap
          setUsers(data);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  console.log(currentUsers,"current")
  const tableBorderColor = useColorModeValue('gray.200', 'gray.600');
  const buttonColor = useColorModeValue('teal.500', 'teal.300');

  return (
    <>
    <BackButtons />
    <Heading size={"md"} fontWeight="bold">
      Patient List
    </Heading>
    <SlideFade in={!isLoading} offsetY="20px">
      <Box p="4" borderRadius="md" boxShadow="md" bg={useColorModeValue('white', 'gray.800')}>
        <Flex mb="4">
          <Input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Flex>
        <Table variant="simple" borderWidth="1px" borderColor={tableBorderColor}>
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Nama</Th>
              <Th>No HP</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentUsers.map((user) => (
              <Tr key={user.id}>
                <Td>{user.email}</Td>
                <Td>{user.displayName}</Td>
                <Td>{user.phoneNumber}</Td>
                <Td>
                  <VStack spacing="2">
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => {
                        navigate(`/patient/patient-history/${user.id}`);
                      }}
                    >
                      Detail
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => {
                        window.open(`https://wa.me/${user.phoneNumber}`, '_blank');
                      }}
                    >
                      Tlfn
                    </Button>
                  </VStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt="4">
          <Spacer />
          {Array.from({ length: Math.ceil(users.length / usersPerPage) }).map((_, index) => (
            <Button
              key={index}
              colorScheme={buttonColor}
              variant="ghost"
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
        </Flex>
      </Box>
    </SlideFade>
    </>
  );
};

export default PatientHistoryPage;
