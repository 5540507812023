import { Container, Flex } from "@chakra-ui/react";
import { Fragment } from "react";
// import FooterComponent from "../Components/Footers/FooterComponent";
import HeaderComponent from "../Components/Header/HeaderComponents";
import SidebarComponentV3 from "../Components/Sidebar/SidebarComponentV3";
import themeConfig from "../Config/themeConfig";
import { useLocation } from "react-router-dom";
import useUserStore from "../Hooks/Zustand/Store";

function Layout({ children }) {
  const {companies} = useUserStore()
  let contentWidth =
    themeConfig.contentWidth === "full" ? "full" : "container.xl";

    const location = useLocation();



  return (
    <Fragment>
      <Flex height="100vh" w={"full"} bgColor={"gray.50"}>
        {/* <SidebarComponentV2 layout={themeConfig.layout} /> */}
        
        {<SidebarComponentV3 layout={themeConfig.layout} />}
        
        <Container maxW={"full"} overflowY={"scroll"} pt={"4"}>
          <HeaderComponent layout={themeConfig.layout} />

          <Container minH={"95vh"} maxW={contentWidth}>
            {children}
          </Container>

          {/* <FooterComponent /> */}
        </Container>
      </Flex>
    </Fragment>
  );
}

export default Layout;
