import { Box, Button, Container, FormControl, FormHelperText, FormLabel, Heading, HStack, Input, Radio, RadioGroup, Select, Step, StepDescription, StepIcon, StepIndicator, StepNumber, Stepper, StepSeparator, StepStatus, StepTitle } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BackButtons from '../../../Components/Buttons/BackButtons'
import useUserStore from '../../../Hooks/Zustand/Store'

function MarketingGoogleYoutubeCampaignPage() {
    const globalState = useUserStore()
    const steps = [
        { title: 'Campaign', description: 'Create Campaign' },
        { title: 'Ads', description: 'Create Ads' },
        { title: 'Audience', description: 'Select Audience' },
        { title: 'Budget & Strategies', description: 'Set your budget & strategies' },
        { title: 'Publish', description: 'Review & publish' },
      ]
    const navigate=useNavigate()
  return (
    <Box>
        <HStack>
            <BackButtons/>
        </HStack>

        <Stepper index={1}>
            {steps.map((step, index) => (
                <Step key={index}>
                <StepIndicator>
                    <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                    />
                </StepIndicator>

                <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
                </Step>
            ))}
        </Stepper>
        <Heading textAlign='center'>Campaign </Heading>
        <Container p='2' mt='5' shadow='base'>
            <FormControl>
                <FormLabel>Campaign Name</FormLabel>
                <Input type='text' />
                <FormHelperText>We'll never share your email.</FormHelperText>
            </FormControl>

            <FormControl>
                <FormLabel>Ad Account</FormLabel>
                <HStack>
                    <Select placeholder='Select ad account'>
                        <option>{globalState.currentCompany}</option>
                    </Select>
                    <Button>+</Button>
                </HStack>
            </FormControl>

            <FormControl>
                <FormLabel>Link to your Facebook fanpage</FormLabel>
                <Input type='text' />
            </FormControl>

            <FormControl>
                <FormLabel>Link to your Instagram account</FormLabel>
                <Input type='text' />
            </FormControl>

            <FormControl>
                <FormLabel>Pixel ID for this campaign</FormLabel>
                <Input type='text' />
            </FormControl>

            <FormControl>
                <FormLabel>Tags</FormLabel>
                <Input type='text' />
                {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>

            <FormControl as='fieldset'>
                <FormLabel as='legend'>What do you want to promote?
                </FormLabel>
                <RadioGroup defaultValue='External Website'>
                    <HStack spacing='24px'>
                    <Radio value='External Website'>External Website</Radio>
                    <Radio value='Nagato'>Lead Generation</Radio>
                    <Radio value='Facebook Messanger'>Facebook Messanger</Radio>
                    </HStack>
                    
                    <HStack spacing='24px'>
                    <Radio value='Local Awareness'>Local Awareness</Radio>
                    <Radio value='Brand Awarness'>Brand Awarness</Radio>
                    <Radio value='Video Views'>Video Views</Radio>
                    </HStack>
                </RadioGroup>
                {/* <FormHelperText>Select only if you're a fan.</FormHelperText> */}
            </FormControl>

            <Button w='full' colorScheme='green'                 
            onClick={()=>navigate('/marketing/meta/facebook/add/ads')}
            >Next</Button>
        </Container>

    </Box>
  )
}

export default MarketingGoogleYoutubeCampaignPage