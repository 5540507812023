import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    HStack,
    Heading,
    Image,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import React, { useState } from 'react'
import DynamicButton from "../../Components/Buttons/DynamicButton";
import BackButtons from "../../Components/Buttons/BackButtons";
import { DeleteIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ title, description, onClick }) => (
    <Box
      p={5}
      borderWidth={1}
      borderRadius="md"
      borderColor="gray.200"
      _hover={{ shadow: "md" }}
      transition="all 0.3s"
      cursor="pointer"
      onClick={onClick}
    >
      <Heading size="md" mb={2}>{title}</Heading>
      <Text color="gray.600">
        {description}
      </Text>
    </Box>
  );

const ProductListPage = () => {
    const modalDelete = useDisclosure();
    const [dataProducts, setDataProducts] = useState("");
    const [searchedDataProduct, setSearchedDataProduct] = useState("");
    const [startIndex, setStartIndex] = useState(0);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const itemsPerPage = 5;

    const handleLoadMore = () => {
        setStartIndex((prev) => prev + itemsPerPage); 
      };
    
      const totalItems = dataProducts?.length || searchedDataProduct?.length || 0;
      const shouldShowLoadMore = totalItems >= startIndex + itemsPerPage;

      const handleDelete = () => {
        // Your delete logic here
        onClose();
      };
  return (
    <>
    <Stack p={[1, 1, 5]}>
      <Stack spacing={4}>
        <HStack gap={5}>
          <BackButtons />
          <Heading size="md" fontWeight="bold">
            Explore Our Products
          </Heading>
          <Spacer />
        </HStack>
        <Stack bg="white" p={5} borderRadius="md" shadow="md">
          <ProductCard
            title="Skin Care Products"
            description="Discover our premium collection of skincare products designed to rejuvenate and nourish your skin."
            onClick={() => navigate("/product/list-product/skincare")}
          />

          <ProductCard
            title="Custom Product Category"
            description="Explore our custom product category tailored to meet your specific needs and preferences."
            onClick={onOpen}
          />
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Box>
            {shouldShowLoadMore && (
              <Button onClick={handleLoadMore} size="sm">
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this product?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} leftIcon={<DeleteIcon />} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  </>
  )
}

export default ProductListPage;