import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    HStack,
    Heading,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import {
    deleteDocumentFirebase,
    deleteFileFirebase,
    getCollectionFirebase,
    updateDocumentFirebase,
  } from "../../Api/firebaseApi";
  import useUserStore from "../../Hooks/Zustand/Store";
  import moment from "moment";
  import { useNavigate } from "react-router-dom";
  import AddButtons from "../../Components/Buttons/AddButtons";
  import { FcPlus } from "react-icons/fc";
  import { DeleteIcon } from "@chakra-ui/icons";
  import { FiEdit, FiEye } from "react-icons/fi";
  import BackButtons from "../../Components/Buttons/BackButtons";
  import DynamicButton from "../../Components/Buttons/DynamicButton";
  import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
  import { db } from "../../Config/firebase";
  
  const ListCabangPage = () => {
    const modalDelete = useDisclosure();
    const globalState = useUserStore();
    const navigate = useNavigate();
    const toast = useToast();
  
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 6;
  
    const [dataProducts, setDataProducts] = useState([]);
    const [dataModal, setDataModal] = useState();
    const [categories, setCategories] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [searchedDataProduct, setSearchedDataProduct] = useState([]);
  
    const getBranch= async () => {
      const collectionRef = collection(db, "branch-deoapp-clinic");
      try {
        const querySnapshot = await getDocs(collectionRef);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDataProducts(data);
        console.log("Data dari koleksi:", data);
      } catch (error) {
        console.error("Gagal mengambil data:", error);
      }
    };
    
    const handleLoadMore = () => {
      setStartIndex((prev) => prev + itemsPerPage); 
    };
  
    const totalItems = dataProducts?.length || searchedDataProduct?.length || 0;
    const shouldShowLoadMore = totalItems >= startIndex + itemsPerPage;
    
    const searchFilterFunction = (text) => {
      if (text || text !== "") {
        const newData = dataProducts.filter((item) => {
          const itemData = item.name
            ? item.name.toUpperCase()
            : "".toUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        console.log(newData,"new")
        setSearchedDataProduct(newData);
        setSearchInput(text);
      } else {
        setSearchedDataProduct(dataProducts);
        setSearchInput(text);
      }
    };
  
    const handleModal = (data) => {
      modalDelete.onOpen();
      setDataModal(data);
    };
  
    console.log(dataModal);
  
    const handleDeletePages = async (categoryId) => {
      try {
        console.log(categoryId,"cateee")
        if (Array.isArray(categoryId.doctor) && categoryId.doctor.length > 0) {
          for (const doctor of categoryId.doctor) {
            const doctorDocRef = doc(db, "doctor-deoapp-clinic", doctor.id);
    
            const doctorDocSnapshot = await getDoc(doctorDocRef);
    
            if (doctorDocSnapshot.exists()) {
              const existingData = doctorDocSnapshot.data();
                console.log(existingData,"exis")
              const indexToRemove = (existingData.branch || []).indexOf(categoryId.name);
    
              if (indexToRemove !== -1) {
                existingData.branch.splice(indexToRemove, 1);
    
                await updateDoc(doctorDocRef, { branch: existingData.branch });
              }
            }
          }
        }

        if (Array.isArray(categoryId.treatment) && categoryId.treatment.length > 0) {
            for (const treatment of categoryId.treatment) {
              const doctorDocRef = doc(db, "treatment-deoapp-clinic", treatment.id);
      
              const doctorDocSnapshot = await getDoc(doctorDocRef);
      
              if (doctorDocSnapshot.exists()) {
                const existingData = doctorDocSnapshot.data();
    
                const indexToRemove = (existingData.branch || []).indexOf(categoryId.name);
      
                if (indexToRemove !== -1) {
                  existingData.branch.splice(indexToRemove, 1);
      
                  await updateDoc(doctorDocRef, { branch: existingData.branch });
                }
              }
            }
          }
    
        await deleteDoc(doc(db, "branch-deoapp-clinic", categoryId.id));
        toast({
          title: "Success",
          description: "Branch Deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        getBranch();
        modalDelete.onClose();
      } catch (error) {
        console.error("Error deleting category:", error);
        toast({
          title: "Error",
          description: "Failed to delete category",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    };
    
  
    const inputStyles = {
      "&::placeholder": {
        color: "gray.500",
      },
    };
  
    useEffect(() => {
      getBranch();
      return () => {};
    }, [globalState.currentProject]);
  
    return (
      <Stack p={[1, 1, 5]}>
        <Stack spacing={4}>
          <HStack gap={5}>
            <BackButtons />
            <Heading size={"md"} fontWeight="bold">
              List Branch
            </Heading>
            <Spacer />
            {/* <HStack> */}
            <Input
              type="text"
              placeholder="Search Branch ..."
              bgColor="white"
              color="black"
              sx={inputStyles}
              w={"15%"}
              fontSize="sm"
              onChange={(e) => searchFilterFunction(e.target.value)}
            />
            <DynamicButton
              action={"create"}
              title={"Add Branch"}
              onClick={() => navigate("/booking/list-branch/create")}
            />
  
            {/* </HStack> */}
          </HStack>
          <Stack bg={"white"} p={5} borderRadius={"md"} shadow={"md"}>
            {searchInput !== "" ? (
              <>
                {searchedDataProduct?.length > 0 ? (
                  <SimpleGrid columns={[1, null, 4]} spacing={3}>
                    {searchedDataProduct.map((product, i) => (
                      <Stack
                        shadow={"base"}
                        gap={3}
                        key={i}
                        bg={"white"}
                        borderRadius={"md"}
                        p={3}
                      >
                        <Stack>
                          <Image
                            src={product?.thumbnail}
                            boxSize={"fit-content"}
                            // w={"fit-content"}
                            objectFit="cover"
                          />
                        </Stack>
                        <Spacer />
                        <Stack align={"center"}>
                          <Box alignSelf={"center"}>
                            <HStack justifyContent={"center"}>
                              <Heading size={"md"}>{product?.name}</Heading>
                            </HStack>
                          </Box>
                          <HStack
                            alignItems={"center"}
                            justify={"space-evenly"}
                            spacing={3}
                          >
                            {/* <HStack spacing={1}>
                              <FiEye /> */}
                              {/* <Text fontSize={12}>{product?.description}</Text> */}
                              {/* <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-treatment/create/${product.id}`)
                                } */}
                              {/* > */}
                                {/* <a href={`/products/article/${product.id}`}> */}
                                {/* View */}
                                {/* </a> */}
                              {/* </Text>
                            </HStack> */}
                            <HStack spacing={1}>
                              <FiEdit />
                              <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-branch/edit/${product.id}`)
                                }
                              >
                                {/* <a href={`/products/article/${product.id}`}> */}
                                Edit
                                {/* </a> */}
                              </Text>
                            </HStack>
  
                            <HStack spacing={1}>
                              <DeleteIcon boxSize={3} />
                              <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() => handleModal(product)}
                              >
                                {/* <a href={`/products/article/${product.id}`}> */}
                                Delete
                                {/* </a> */}
                              </Text>
                            </HStack>
  
                            {/* <Button
                              variant={"ghost"}
                              size={"xs"}
                              w={"fit-content"}
                              onClick={() => handleModal(product)}
                            >
                              <DeleteIcon boxSize={3} />
                            </Button> */}
                          </HStack>
                          <Spacer />
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box bg={"white"} borderRadius={"md"} p={3}>
                    <Center>
                      <Heading size={"md"}>No Branch Added</Heading>
                    </Center>
                  </Box>
                )}
              </>
            ) : (
              <>
                {dataProducts?.length > 0 ? (
                  <SimpleGrid columns={[1, null, 4]} spacing={3}>
                    {dataProducts.map((product, i) => (
                      <Stack
                        shadow={"base"}
                        gap={3}
                        key={i}
                        bg={"white"}
                        borderRadius={"md"}
                        p={3}
                      >
                        <Stack>
                          <Image
                            src={product?.thumbnail}
                            boxSize={"fit-content"}
                            maxHeight={"100px"}
                            // w={"fit-content"}
                            objectFit="contain"
                          />
                        </Stack>
                        <Spacer />
                        <Stack align={"center"}>
                          <Box alignSelf={"center"}>
                            <HStack justifyContent={"center"}>
                              <Heading size={"md"}>{product?.name}</Heading>
                            </HStack>
                          </Box>
                          <HStack
                            alignItems={"center"}
                            justify={"space-evenly"}
                            spacing={3}
                          >
                            {/* <HStack spacing={1}>
                              <FiEye /> */}
                              {/* <Text fontSize={12}>{product?.description}</Text> */}
                              {/* <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-treatment/create/${product.id}`)
                                }
                              > */}
                                {/* <a href={`/products/article/${product.id}`}> */}
                                {/* View */}
                                {/* </a> */}
                              {/* </Text>
                            </HStack> */}
                            <HStack spacing={1}>
                              <FiEdit />
                              <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(`/booking/list-branch/edit/${product.id}`)
                                }
                              >
                                {/* <a href={`/products/article/${product.id}`}> */}
                                Edit
                                {/* </a> */}
                              </Text>
                            </HStack>
  
                            <HStack spacing={1}>
                              <DeleteIcon boxSize={3} />
                              <Text
                                fontSize={12}
                                cursor={"pointer"}
                                onClick={() => handleModal(product)}
                              >
                                {/* <a href={`/products/article/${product.id}`}> */}
                                Delete
                                {/* </a> */}
                              </Text>
                            </HStack>
  
                            {/* <Button
                                variant={"ghost"}
                                size={"xs"}
                                w={"fit-content"}
                                onClick={() => handleModal(product)}
                              >
                                <DeleteIcon boxSize={3} />
                              </Button> */}
                          </HStack>
                          <Spacer />
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box bg={"white"} borderRadius={"md"} p={3}>
                    <Center>
                      <Heading size={"md"}>No Products</Heading>
                    </Center>
                  </Box>
                )}
              </>
            )}
          </Stack>
  
          <Stack alignItems={"center"} justifyContent="center">
            <Box>
              {shouldShowLoadMore && (
                <Button onClick={handleLoadMore} size="sm">
                  Load More
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>
  
        <Modal isOpen={modalDelete.isOpen} onClose={modalDelete.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Branch</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Are you sure want to delete Branch <b>{dataModal?.name}</b>?
              </Text>
            </ModalBody>
  
            <ModalFooter>
              <Button
                colorScheme="red"
                mr={3}
                leftIcon={<DeleteIcon />}
                onClick={() => handleDeletePages(dataModal)}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    );
  };
  
export default ListCabangPage;