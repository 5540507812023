import React from "react";
import DoctorListPage from "../Pages/Doctor/DoctorListPage";
import DoctorCreatePage from "../Pages/Doctor/DoctorCreatePage";
import DoctorEditPage from "../Pages/Doctor/DoctorEditPage";
import DoctorPage from "../Pages/Doctor/DoctorPage";
import DoctorSchudule from "../Pages/Doctor/DoctorSchudule";

const DoctorRouter = [
  {
    path: "/doctor",
    element: <DoctorPage />,
  },  
  {
    path: "/doctor/list-doctor",
    element: <DoctorListPage />,
  },
  {
    path: "/doctor/add-doctor",
    element: <DoctorCreatePage />,
  },
  {
    path: "/doctor/edit-doctor/:id",
    element: <DoctorEditPage />,
  },
  {
    path: "/doctor/doctor-schudle/:id",
    element: <DoctorSchudule />,
  },
];

export default DoctorRouter;
