import React from "react";
import BookingPage from "../Pages/Booking/BookingPage";
import DoctorListPage from "../Pages/Doctor/DoctorListPage";
import DoctorCreatePage from "../Pages/Doctor/DoctorCreatePage";
import DoctorEditPage from "../Pages/Doctor/DoctorEditPage";
import BookingListPage from "../Pages/Booking/BookingListPage";
import AddBookingPage from "../Pages/Booking/AddBookingPage";
import ListCabangPage from "../Pages/Booking/ListCabangPage";
import BrachCreatePage from "../Pages/Booking/BrachCreatePage";
import BranchEditPage from "../Pages/Booking/BranchEditPage";

const BookingRouter = [
  {
    path: "/booking",
    element: <BookingPage />,
  },
  {
    path: "/booking/add-booking",
    element: <AddBookingPage />,
  },
  {
    path: "/booking/list-doctor",
    element: <DoctorListPage />,
  },
  {
    path: "/booking/list-doctor/create",
    element: <DoctorCreatePage />,
  },
  {
    path: "/booking/list-doctor/edit/:id",
    element: <DoctorEditPage />,
  },
  {
    path: "/booking/list-booking",
    element: <BookingListPage />,
  },
  {
    path: "/booking/list-branch",
    element: <ListCabangPage />,
  },
  {
    path: "/booking/list-branch/create",
    element: <BrachCreatePage />,
  },
  {
    path: "/booking/list-branch/edit/:id",
    element: <BranchEditPage />,
  },
];

export default BookingRouter;
