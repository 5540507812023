import {
  Button,
  HStack,
  Input,
  ModalBody,
  ModalHeader,
  Spacer,
  Stack,
  Select,
  Text,
  Box,
  useDisclosure,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  arrayRemoveFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getCollectionFirebaseV2,
  getCollectionWhereFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Api/firebaseApi";
import useUserStore from "../../Hooks/Zustand/Store";
import axios from "axios";
import ApiBackend from "../../Api/ApiBackend";
import DynamicButton from "../../Components/Buttons/DynamicButton";
import _axios from "../../Api/AxiosBarrier";

function HrisUserViewPage(props) {
  const toast = useToast();
  const globalState = useUserStore();

  const apikey = process.env.REACT_APP_PAYMENT_KEY;

  const [data, setData] = useState({});
  const [dataRole, setDataRole] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getData = () => {
    getSingleDocumentFirebase("users", props.uid)
      .then((x) => setData(x))
      .catch((err) => console.log(err.message));
  };

  const getRole = async () => {
    const conditions = [
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      { field: "category", operator: "==", value: "hris" },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };
    const limitValue = 30;

    try {
      const res = await getCollectionFirebase(
        "files",
        conditions,
        sortBy,
        limitValue
      );
      setDataRole(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleAddUser = async () => {
    try {
      if (globalState.roleCompany !== "owner") {
        return displayAccessError();
      }

      const existingUser = await findExistingUser();

      if (existingUser.length > 0) {
        const result = await addExistingUserToCompany(existingUser[0].id);
        await handleUserRole(existingUser[0]);
      } else {
        await createUserAndHandleRoles();
      }

      toastSuccess("Add new Team success");
      props.setModal(false);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
      props.getData("", null, 1);
    }
  };

  const editUser = async () => {
    try {
      setLoading(true);
      await updateUserInDatabase();
      await handleUserRoles();
      props.getData("", null, 1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
      props.setModal(false);
    }
  };

  const displayAccessError = () => {
    return toast({
      title: "Alert!",
      description: "You dont have access to add user.",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
  };

  const findExistingUser = async () => {
    const conditions = [{ field: "email", operator: "==", value: data?.email }];
    const sortBy = null;
    const limitValue = 1;
    return await getCollectionFirebase("users", conditions, sortBy, limitValue);
  };

  const addExistingUserToCompany = async (userId) => {

    const collectionName = "companies";
    const docName = globalState.currentCompany;
    const field = "users";
    const values = [userId];

    
    return await _axios.post("/company-addArrayField", {
      collection: collectionName,
      doc: docName,
      field,
      values,
    });
  };

  const handleUserRole = async (userData) => {
    if (data.role) {
      const resRole = dataRole?.find((x) => x.id === data?.role);
      const getDataRole = await getSingleDocumentFirebase(
        `companies/${globalState.currentCompany}/roles`,
        data?.role
      );

      if (getDataRole && "users" in getDataRole) {
        const collectionRole = `companies/${globalState.currentCompany}/roles`;
        const docRole = data?.role;
        const fieldRole = "users";
        const valueRole = [userData.id];

        const dataObjSecond = {
          collection: collectionRole,
          doc: docRole,
          field: fieldRole,
          values: valueRole,
        };

        const resAdd = await _axios.post("/company-addArrayField", dataObjSecond);

        

      } else {
        const dataUserRole = {
          collection: `companies/${globalState.currentCompany}/roles`,
          doc: data?.role,
          data: {
            title: resRole?.title,
            users: [userData.uid],
            createdAt: new Date(),
            createdBy: globalState?.uid,
            companyId: globalState?.currentCompany,
          },
        };
        const resSet = await _axios.post("/comapny-setDocument", dataUserRole);
      }
    }
  };

  const createUserAndHandleRoles = async () => {
    setLoading(true);
    const baseURL =
      "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net";
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_FUNCTIONS_KEY,
      },
    };
    const dataUser = {
      ...data,
      email: data?.email,
      name: data?.name,
      companyId: globalState?.currentCompany,
    };

    try {
      const newUrl = `${baseURL}/createUser`;
      const res = await axios.post(newUrl, dataUser, options);

      if (res.status === 200) {
        await updateDocumentFirebase("users", res?.data?.data?.uid, dataUser);
        await handleUserRoles(res.data.data);
      }
      toastSuccess("Add new user success");
      props.setModal(false);
    } catch (error) {
      setLoading(false);
      handleErrors(error);
    } finally {
      setLoading(false);
      props.setModal(false);
    }
  };

  const updateUserInDatabase = async () => {
    await setDocumentFirebase(
      "users",
      props.uid,
      data,
      globalState.currentCompany
    );
  };

  const handleUserRoles = async () => {
    if (data.role) {
      const conditions = [
        { field: "users", operator: "array-contains", value: data?.id },
      ];

      const checkRoles = await getCollectionFirebase(
        `companies/${globalState.currentCompany}/roles`,
        conditions
      );

      if (checkRoles?.length > 0) {
        checkRoles.forEach(async (obj) => {
          const collectionRole = `companies/${globalState.currentCompany}/roles`;
          const docRole = obj?.id;
          const fieldRole = "users";
          const valueRole = [data?.id];

          const dataObjSecond = {
            collection: collectionRole,
            doc: docRole,
            field: fieldRole,
            values: valueRole,
          };

          const removeRole = await arrayRemoveFirebase(
            `companies/${globalState.currentCompany}/roles`,
            obj?.id,
            "users",
            [data?.id]
          );
        });
      }

      const resRole = dataRole?.find((x) => x.id === data?.role);

      const getDataRole = await getSingleDocumentFirebase(
        `companies/${globalState.currentCompany}/roles`,
        data?.role
      );

      if (getDataRole && "users" in getDataRole) {
        const collectionRole = `companies/${globalState.currentCompany}/roles`;
        const docRole = data?.role;
        const fieldRole = "users";
        const valueRole = [props?.uid];

        const dataObjSecond = {
          collection: collectionRole,
          doc: docRole,
          field: fieldRole,
          values: valueRole,
        };

        const resAddObj = await _axios.post(
          "/company-addArrayField",
          dataObjSecond
        );
      } else {
        const resRole = dataRole?.find((x) => x.id === data?.role);
        const dataUserRole = {
          collection: `companies/${globalState.currentCompany}/roles`,
          doc: data?.role,
          data: {
            title: resRole?.title,
            users: [data?.id],
            createdAt: new Date(),
            createdBy: globalState?.uid,
            companyId: globalState?.currentCompany,
          },
        };
        const resSet = await _axios.post("/company-setDocument", dataUserRole);
      }
    }
  };

  const deleteUser = async () => {
    if (globalState.roleCompany !== "owner") {
      return toast({
        title: "Alert!",
        description: "You don't have access to delete user",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoadingDelete(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    };

    const data = {
      uid: props.uid,
    };

    try {
      const res = await axios.post(
        "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/accountDelete",
        data,
        config
      );

      console.log(res, "ini ress");

      if (res.status === 200) {
        await deleteUserFromCompany();
        await deleteFromProject();

        await deleteDocumentFirebase("users", props.uid);
      }

      setLoadingDelete(false);

      toast({
        title: "Deoapp",
        status: "success",
        description: "Account deleted",
        duration: 3000,
      });
    } catch (error) {
      console.log(error, "ini error");
      setLoadingDelete(false);
    } finally {
      setLoadingDelete(false);
      props.getData("", null, 1);
    }
  };

  const deleteFromFirestore = async (collection, doc, field, value) => {
    const dataObj = {
      collection: collection,
      doc: doc,
      field: field,
      values: value,
    };

    await _axios.post("/company-removeFromArrayField", dataObj);
  };

  const deleteUserFromCompany = async () => {
    const dataConditions = [
      {
        field: "users",
        operator: "array-contains",
        value: props.uid,
      },
    ];

    const deleteUserFromCompany = await getCollectionFirebase(
      "companies",
      dataConditions
    );

    const updateCompanyPromises = deleteUserFromCompany.map(async (company) => {
      await deleteFromFirestore("companies", company?.id, "owners", [
        props?.uid,
      ]);
      await deleteFromFirestore("companies", company?.id, "users", [
        props?.uid,
      ]);
    });

    await Promise.all(updateCompanyPromises);
  };

  const deleteFromProject = async () => {
    const conditions = [
      { field: "companyId", operator: "==", value: globalState.currentCompany },
      {
        field: "users",
        operator: "array-contains",
        value: props?.uid,
      },
    ];

    const CompanyProject = await getCollectionFirebase("projects", conditions);

    const updateProjectPromises = CompanyProject.map(async (project) => {
      await deleteFromFirestore("projects", project?.id, "owners", [
        props?.uid,
      ]);
      await deleteFromFirestore("projects", project?.id, "users", [props?.uid]);

      const getSubcol = await getCollectionFirebase(
        `projects/${project.Id}/users`
      );

      if (getSubcol && getSubcol.length > 0) {
        await deleteDocumentFirebase(
          `projects/${project.id}/users`,
          props?.uid
        );
      }
    });

    await Promise.all(updateProjectPromises);
  };

  const handleErrors = (error) => {
    console.log("Terjadi kesalahan:", error);
  };

  const toastSuccess = (description) => {
    toast({
      status: "success",
      description,
      duration: 2000,
    });
  };

  useEffect(() => {
    if (props.uid) getData(props.uid);
    getRole();

    return () => {
      setData();
      setDataRole();
    };
  }, []);

  return (
    <Box p="5">
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Name</Text>
          <Input
            placeholder={data?.name ? data.name : "Name"}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </Stack>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Email</Text>
          <Input
            placeholder={data?.email ? data.email : "email"}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Join Date</Text>
          <Input
            type="date"
            placeholder={data?.date ? data.date : "Date"}
            onChange={(e) => setData({ ...data, joinDate: e.target.value })}
          />
        </Stack>
      </HStack>
      <ModalHeader>Employee Attribute</ModalHeader>
      <ModalBody>
        <HStack my={3}>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Date of Birth</Text>
            <Input
              type="date"
              placeholder={data?.dob ? data.dob : "Date of Birth"}
              onChange={(e) => setData({ ...data, dob: e.target.value })}
            />
          </Stack>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Gender</Text>
            <Select
              defaultValue={data?.genre ? data.genre : "Select Gender"}
              onChange={(e) => setData({ ...data, gender: e.target.value })}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Select>
          </Stack>
        </HStack>
        <HStack my={3}>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Religion</Text>
            <Select
              defaultValue={data?.regligion ? data.religion : "Religion"}
              onChange={(e) => setData({ ...data, religion: e.target.value })}
            >
              <option value="Islam">Islam</option>
              <option value="Christian">Christian</option>
              <option value="Catholic">Catholic</option>
              <option value="Buddha">Buddha</option>
              <option value="Hindu">Hindu</option>
              <option value="Konghucu">Konghucu</option>
              <option value="Other">Other</option>
            </Select>
          </Stack>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Phone Number</Text>
            <Input
              type="number"
              placeholder={data?.phone ? data.phone : "phone"}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
          </Stack>
        </HStack>
        <HStack my={3}>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>ID Number</Text>
            <Input
              type="number"
              placeholder={
                data?.idNumber ? data.idNumber : "Input ID Number (KTP)"
              }
              onChange={(e) => setData({ ...data, idNumber: e.target.value })}
            />
          </Stack>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>ID Tax Number</Text>
            <Input
              type="number"
              placeholder={
                data?.taxNumber ? data.taxNumber : "Input ID Number (KTP)"
              }
              onChange={(e) => setData({ ...data, taxNumber: e.target.value })}
            />
          </Stack>
        </HStack>
        <HStack my={3}>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Marital Status</Text>
            <Select
              defaultValue={data?.martial ? data.martial : "Martial Status"}
              placeholder="Select Marital Status"
              onChange={(e) => setData({ ...data, martial: e.target.value })}
            >
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Window/Windower">Widow/Widower</option>
            </Select>
          </Stack>
          <Stack w={"50%"}>
            <Text fontSize={"sm"}>Dependants</Text>
            <Input
              placeholder={
                data?.dependants ? data.dependants : "Input Dependants"
              }
              type="number"
              onChange={(e) => setData({ ...data, dependants: e.target.value })}
            />
          </Stack>
        </HStack>
      </ModalBody>
      {/* <ModalHeader>Position</ModalHeader> */}
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Company</Text>
          <Input
            placeholder=""
            value={
              globalState.companies.find(
                (x) => x.id === globalState.currentCompany
              ).name
            }
            disabled
          />
        </Stack>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Division</Text>
          <Select
            placeholder="Select Division"
            onChange={(e) => setData({ ...data, division: e.target.value })}
          >
            {/* {dataDivision?.map((x, i) => (
                        <option key={i} value={x.id}>{x.name}</option>
                      ))} */}
          </Select>
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Status</Text>
          <Select
            placeholder="Select Status"
            defaultValue={data?.status ? data.status : "Select Status"}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value="Probation">Probation</option>
            <option value="Internship">Internship</option>
            <option value="Contract">Contract</option>
            <option value="Fulltime">Fulltime</option>
            <option value="Non Active">Non Active</option>
          </Select>
        </Stack>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Role</Text>
          <Select
            placeholder="Select Role"
            defaultValue={data?.role ? data.role : "Select Role"}
            onChange={(e) => setData({ ...data, role: e.target.value })}
          >
            {dataRole?.map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>HR Permission Access</Text>
          <Select
            placeholder="Select Status"
            defaultValue={
              data?.hrPermission ? data.hrPermission : "Select Status"
            }
            onChange={(e) => setData({ ...data, hrPermission: e.target.value })}
          >
            <option value="Approve Correction HR">Approve Correction HR</option>
            <option value="Internship">Internship</option>
            <option value="Contract">Contract</option>
            <option value="Fulltime">Fulltime</option>
            <option value="Non Active">Non Active</option>
          </Select>
        </Stack>
      </HStack>

      <Text>Bank</Text>
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Bank Account</Text>
          <Input
            placeholder={data?.bankAccount ? data.bankAccount : "Ex: BCA"}
            onChange={(e) => setData({ ...data, bankAccount: e.target.value })}
          />
        </Stack>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Bank Account Name</Text>
          <Input
            placeholder={
              data?.bankAccountName
                ? data.bankAccountName
                : "Ex: Bank Account Name"
            }
            onChange={(e) =>
              setData({ ...data, bankAccountName: e.target.value })
            }
          />
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={"50%"}>
          <Text fontSize={"sm"}>Bank Account Number</Text>
          <Input
            placeholder={
              data?.bankAccountNumber
                ? data.bankAccountNumber
                : "Ex: Bank Account Number"
            }
            onChange={(e) =>
              setData({ ...data, bankAccountNumber: e.target.value })
            }
          />
        </Stack>
      </HStack>
      <HStack>
        <Spacer />
        {props.uid ? (
          <HStack>
            <DynamicButton
              isLoading={loadingDelete}
              action={"delete"}
              title="Delete Permanent User"
              onClick={() => deleteUser()}
            />
            <DynamicButton
              variant={"solid"}
              isLoading={loading}
              action={"update"}
              title="Update Data User"
              onClick={() => editUser()}
            />
          </HStack>
        ) : (
          <DynamicButton
            variant={"solid"}
            isLoading={loading}
            action={"create"}
            title="Submit"
            onClick={() => handleAddUser()}
          />
        )}
      </HStack>
    </Box>
  );
}

export default HrisUserViewPage;
