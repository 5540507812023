import {
  AspectRatio,
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddButtonComponentMindmap from "../../Components/Buttons/AddButtonComponentMindmap";
import BackButtons from "../../Components/Buttons/BackButtons";
import { Rupiah } from "../../Utils/NumberUtils";
import useUserStore from "../../Hooks/Zustand/Store";
import { getCollectionFirebase } from "../../Api/firebaseApi";

function MarketingFacebookPage() {
  const globalState = useUserStore();
  const [campaignData, setCampaignData] = useState([]);

  const data = [
    {
      id: 1,
      title: "Kodok",
      ctr: 28,
      cpc: 300,
      reach: 1000,
      impression: 30000,
      cpm: 30000,
      cpr: 30000,
      image:
        "https://scontent.fcgk27-2.fna.fbcdn.net/v/t39.35426-6/404306436_1740373206478859_1023210650103708856_n.jpg?stp=dst-jpg_s600x600&_nc_cat=103&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeHMUiYlCbEr28tKQEHArX-OH_IfYgihL_Uf8h9iCKEv9c24FoOfmT44DXekWY_gg9M&_nc_ohc=MqluPEfGYu4AX-2apCl&_nc_ht=scontent.fcgk27-2.fna&oh=00_AfCnNlwp73k0obJCy1aibFppx8rB2eYg2C5Dpwaf4EI4bQ&oe=656E19F6",
    },
    {
      id: 1,
      title: "Kodok",
      ctr: 28,
      cpc: 300,
      reach: 1000,
      impression: 30000,
      cpm: 30000,
      cpr: 30000,
      image:
        "https://scontent.fcgk27-2.fna.fbcdn.net/v/t39.35426-6/404306436_1740373206478859_1023210650103708856_n.jpg?stp=dst-jpg_s600x600&_nc_cat=103&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeHMUiYlCbEr28tKQEHArX-OH_IfYgihL_Uf8h9iCKEv9c24FoOfmT44DXekWY_gg9M&_nc_ohc=MqluPEfGYu4AX-2apCl&_nc_ht=scontent.fcgk27-2.fna&oh=00_AfCnNlwp73k0obJCy1aibFppx8rB2eYg2C5Dpwaf4EI4bQ&oe=656E19F6",
    },
    {
      id: 1,
      title: "Kodok",
      ctr: 28,
      cpc: 300,
      reach: 1000,
      impression: 30000,
      cpm: 30000,
      cpr: 30000,
      video:
        "https://scontent.fcgk27-2.fna.fbcdn.net/v/t42.1790-2/10000000_1045893376536856_4881714478375647099_n.?_nc_cat=105&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeFGkrb9LGZt95EZBD2DGb8q-9sk3iq8JZT72yTeKrwllLoSxLD2dMPE0IndMCCLY3U&_nc_ohc=_IM0qtSvoBMAX_fjKpn&_nc_ht=scontent.fcgk27-2.fna&oh=00_AfAvYurLgV11EMcKA3FgnIPBoCkPF3gSHkAOdro5WxRZIQ&oe=656E9DB7",
    },
  ];

  const handleGetCampaigns = async () => {
    const conditions = [
      {
        field: "companyId",
        operator: "==",
        value: globalState?.currentCompany,
      },
      {
        field: "projectId",
        operator: "==",
        value: globalState?.currentProject,
      },
      { field: "type", operator: "==", value: "ads" },
      { field: "category", operator: "==", value: "facebook" },
    ];
    const res = await getCollectionFirebase("files", conditions);

    console.log(res, "ini res");
    setCampaignData(res);
  };

  useEffect(() => {
    handleGetCampaigns();

    return () => {
      setCampaignData([]);
    };
  }, [globalState?.currentCompany, globalState?.currentProject]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Meta Ads</Heading>
        <Spacer />
        <Link to="add/campaign">
          <Button>+</Button>
        </Link>
      </HStack>
      <SimpleGrid columns={3} spacing={3} my={5}>
        {campaignData.map((campaign, i) => (
          <Box
            shadow="base"
            key={i}
            align={"center"}
            bg={"white"}
            p={3}
            borderRadius={"md"}
          >
            {campaign?.adsCreation?.mediaLink?.type === "video" ? (
              <AspectRatio maxW="560px" maxH="150px" ratio={16 / 9}>
                <iframe
                  title="naruto"
                  src={campaign?.adsCreation?.mediaLink?.link}
                  allowFullScreen
                />
              </AspectRatio>
            ) : (
              <AspectRatio maxW="200px" maxH="200px" ratio={3 / 4}>
                <Image
                  src={campaign?.adsCreation?.mediaLink?.link}
                  alt={campaign?.adsCreation?.adName}
                />
              </AspectRatio>
            )}
            <Text fontWeight={500} textAlign="center" my={3}>
              {campaign?.campaignName}
            </Text>
            <SimpleGrid fontSize={13} spacing={3} columns={3}>
              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>CTR</Text>
                {/* <Text>{x.ctr}%</Text> */}
                <Text>25%</Text>
              </Container>
              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>CPM</Text>
                {/* <Text>IDR{Rupiah(x.cpm)}</Text> */}
                <Text>{Rupiah(30000)}</Text>
              </Container>
              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>CPR</Text>
                {/* <Text>{x.cpr}</Text> */}
                <Text>20000</Text>
              </Container>

              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>CPC</Text>
                {/* <Text>{x.cpc}%</Text> */}
                <Text>280%</Text>
              </Container>
              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>Reach</Text>
                {/* <Text>{x.reach}</Text> */}
                <Text>2000</Text>
              </Container>
              <Container
                border={"1px"}
                borderColor={"gray.300"}
                bgColor="white"
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight={500}>Impression</Text>
                {/* <Text>{x.impression}</Text> */}
                <Text>40000</Text>
              </Container>
            </SimpleGrid>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default MarketingFacebookPage;
