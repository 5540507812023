import { Box, Heading, HStack, Input, SimpleGrid, Spacer, Stack, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { DndProvider } from 'react-dnd';
import KanbanColumnsComponent from '../../../Components/Columns/KanbanColumnsComponent'
import { HTML5Backend } from "react-dnd-html5-backend";
import BackButtons from '../../../Components/Buttons/BackButtons';
import { useParams } from 'react-router-dom';

function MarketingKolKanbanPage() {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const param = useParams()

  return (
    <Box>
        <HStack>
            <BackButtons/>
            <Heading>KOL Campaign {param.id}</Heading>
            <Spacer/>
            <Input type='text' maxW='sm' size='sm' bgColor='white'/>
        </HStack>
        <DndProvider backend={HTML5Backend}>
          {isDesktop ? (
            <SimpleGrid columns={{ base: 1, md: 7 }}>
              <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Request"}
              />
               <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Quotation"}
              />
               <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Down Payment"}
              />
               <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Brief & Schedule"}
              />
               <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Publish"}
              />
               <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Review"}
              />
              <KanbanColumnsComponent
                kanbanData={[]}
                allowedDropEffect="move"
                filterData={[]}
                column={"Done"}
              />
            </SimpleGrid>
          ) : (
            <Stack h={"50vh"} alignItems="center" justifyContent={"center"} spacing={5}>
              <Heading textAlign={"center"} size="md">Desktop View Only</Heading>

              {/* <DynamicButton title={"New card"} variant="solid" action="create" onClick={() => {
                handleNewCard();
              }} /> */}
            </Stack>
          )}
        </DndProvider>
    </Box>
  )
}

export default MarketingKolKanbanPage