import React from "react";
import WarehouseAddressPage from "../Pages/Warehouses/WarehouseAddressPage";
import WarehousePage from "../Pages/Warehouses/WarehousePage";

const WarehouseRouter = [
  {
    path: "/wms",
    element: <WarehousePage />,
  },
  {
    path: "/wms/address",
    element: <WarehouseAddressPage />,
  },
];

export default WarehouseRouter;
