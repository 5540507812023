import {
  Box,
  Checkbox,
  HStack,
  Spacer,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  useToast,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  addDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from "../../Api/firebaseApi";
import DynamicButton from "../../Components/Buttons/DynamicButton";
import { data } from "../../Components/Sidebar/DataMenu";
import useUserStore from "../../Hooks/Zustand/Store";

function HrisRoleAddPage(props) {
  const [select, setSelect] = useState({});
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const globalState = useUserStore();
  const toast = useToast();

  const dataTabs = useMemo(() => {
    return data.map((x, i) => <Tab key={i}>{x.name}</Tab>);
  }, []);

  const handleCheck = (id, baseRole) => {
    const allPermissions = ["read", "create", "update", "delete"];
    const hasUncheckedPermissions = allPermissions.some(
      (permission) => !id[`${baseRole}-${permission}`]
    );
    if (hasUncheckedPermissions) {
      setSelect({ ...select, [baseRole]: false });
    }
    setRole({ ...role, ...id });
  };

  const handleCheckCRUD = (link, checked) => {
    setSelect({ ...select, [link]: checked });
    const crudPermissions = ["read", "create", "update", "delete"];
    const crudData = {};
    crudPermissions.forEach((permission) => {
      crudData[`${link}-${permission}`] = checked;
    });
    handleCheck(crudData, link);
  };

  const handleCheckAll = (submenu, checked) => {
    const permissions = ["read", "create", "update", "delete"];
    const updatedRole = { ...role };
    submenu?.forEach((sub) => {
      permissions.forEach((permission) => {
        updatedRole[`${sub.link}-${permission}`] = checked;
      });
      setSelect({ ...select, [sub.link]: checked });
    });
    setRole(updatedRole);
  };

    // const handleEdit = async () => {
    //   setLoading(true);

    //   role.type = "role";
    //   role.category = "hris";
    //   role.title = role.name;

    //   try {
    //     const res = await setDocumentFirebase(
    //       "files",
    //       props?.roleId,
    //       role,
    //       globalState?.currentCompany
    //     );

    //     toast({
    //       title: "Deoapp.com",
    //       description: "success edit role",
    //       status: "success",
    //       position: "top-right",
    //       isClosable: true,
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLoading(false);
    //     props.onClose();
    //     props.getData();
    //   }
    // };

  const handleSave = async () => {
    setLoading(true);
    try {
      role.type = "role";
      role.category = "hris";
      role.title = role.name;

      if (!props.roleId) {
        await addDocumentFirebase("files", role, globalState.currentCompany);
        toast({
          title: "Deoapp.com",
          description: "success add role",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      } else {
        await setDocumentFirebase(
          "files",
          props.roleId,
          role,
          globalState.currentCompany
        );
        await addDocumentFirebase("files", role, globalState.currentCompany);
        toast({
          title: "Deoapp.com",
          description: "success add role",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
      }
      // setLoading(false);
      // window.location.reload();
    } catch (error) {
      console.log(error, "ini error");
    }
    setLoading(false);
    props.onClose();
    props.getData();
  };
console.log(role,"role")
  const getData = async () => {
    try {
      const roleData = await getSingleDocumentFirebase("files", props.roleId);
      setRole(roleData);
    } catch (error) {
      console.log(error, "ini errro");
    }
  };

  useEffect(() => {
    if (props.roleId) {
      getData();
    }
  }, [props.roleId]);

  return (
    <Box shadow="base" p="2" w="full">
      <Text>Role Name</Text>
      <Input
        type="text"
        mb="2"
        placeholder={role.title || ""}
        onChange={(e) => setRole({ ...role, name: e.target.value })}
      />

      <Tabs
        variant="soft-rounded"
        colorScheme="green"
        orientation="vertical"
        shadow="base"
        p="2"
        mb="2"
      >
        <TabList>{dataTabs}</TabList>
        <TabPanels>
          {data.map((x, i) => (
            <TabPanel key={i}>
              {x.submenu?.map((y, j) => (
                <Box key={j} mt="2" shadow="base" p="2">
                  <HStack>
                    <Text fontSize="sm" textAlign="center">
                      {y.name}
                    </Text>
                    <Checkbox
                      onChange={(e) =>
                        handleCheckAll(y.submenu, e.target.checked)
                      }
                    ></Checkbox>
                  </HStack>
                  {y.submenu &&
                    y.submenu.map((z, k) => (
                      <HStack key={k} fontSize="sm">
                        <Text fontSize="sm">{z.name}</Text>
                        <Spacer />
                        <Checkbox
                          size="sm"
                          id={z.name}
                          isChecked={select[z.link] || false}
                          onChange={(e) =>
                            handleCheckCRUD(z.link, e.target.checked)
                          }
                        ></Checkbox>
                        {["read", "create", "update", "delete"].map(
                          (permission) => (
                            <Checkbox
                              size="sm"
                              key={permission}
                              id={z.name}
                              isChecked={
                                role[`${z.link}-${permission}`] || false
                              }
                              onChange={(e) =>
                                handleCheck(
                                  {
                                    [`${z.link}-${permission}`]:
                                      e.target.checked,
                                  },
                                  z.link
                                )
                              }
                            >
                              {permission?.charAt(0).toUpperCase() +
                                permission?.slice(1)}
                            </Checkbox>
                          )
                        )}
                      </HStack>
                    ))}
                </Box>
              ))}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <Stack pt={2} alignItems="flex-end" justifyContent="flex-end">
        {props?.type === "edit" ? (
          <DynamicButton
            action={"update"}
            title="Update"
            isLoading={loading}
            onClick={() => handleSave()}
            variant="solid"
          />
        ) : (
          <DynamicButton
            action={"create"}
            title="Save"
            isLoading={loading}
            onClick={() => handleSave()}
            variant="solid"
          />
        )}
      </Stack>
    </Box>
  );
}

export default HrisRoleAddPage;
