import React from "react";
import PatientPage from "../Pages/Patient/PatientPage";
import PatientHistoryPage from "../Pages/Patient/PatientHistoryPage";
import PatientHistoryDetailPage from "../Pages/Patient/PatientHistoryDetailPage";

const PatientRouter = [
  {
    path: "/patient",
    element: <PatientPage />,
  },  
  {
    path: "/patient/patient-history",
    element: <PatientHistoryPage />,
  },
  {
    path: "/patient/patient-history/:id",
    element: <PatientHistoryDetailPage />,
  },
];

export default PatientRouter;
