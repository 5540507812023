import React, { useEffect, useRef, useState } from 'react';
import CryptoJS from 'crypto-js';
import 'grapesjs/dist/css/grapes.min.css';
import './grapesJs.css';
import {
  Box,
  Heading,
  HStack,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
// import { blockData, templateData } from "./editorComponents/FunnelsBlocks";

// import grapesjs from "grapesjs";
import grapesjs from 'grapesjs';
import presetPlugin from 'grapesjs-preset-webpage';
import pluginGrapesjsBlocksBasic from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import pluginExport from 'grapesjs-plugin-export';
// import fontPlugin from "@silexlabs/grapesjs-fonts";
import pluginCountdown from 'grapesjs-component-countdown';
import stylePlugin from 'grapesjs-style-bg';
import customCodePlugin from 'grapesjs-custom-code';
import pluginTyped from 'grapesjs-typed';

import useUserStore from '../../Hooks/Zustand/Store';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import { uploadAws } from '../../Api/AwsApi';
// import { blockData, templateData } from "./TemplateData";

function MarketingLandingPage() {
  const editorRef = useRef(null);
  const globalState = useUserStore();
  const toast = useToast();

  const { state } = useLocation();
  const param = useParams();

  // console.log(param);
  // console.log(globalState);

  const getDataStorage = async (editor) => {
    const conditions = [];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 5;

    try {
      // const res = await getCollectionFirebase(
      //   `funnels/${param.funnelId}/page/${param.pageId}/html`,
      //   conditions,
      //   sortBy,
      //   limitValue
      // );
      const res = await getSingleDocumentFirebase('websites', param?.pageId);

      if (res?.hasOwnProperty('html') && res?.hasOwnProperty('css')) {
        editor.setComponents(res?.html);
        editor.setStyle(res?.css);
      } else {
        console.log('No data found in storage');
      }
    } catch (error) {
      console.log(error, 'Error retrieving data from storage');
      toast({
        title: 'Error',
        description: 'Failed to retrieve data from storage',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const editor = grapesjs.init({
      container: '#gjs',
      plugins: [
        presetPlugin,
        pluginGrapesjsBlocksBasic,
        gjsForms,
        pluginExport,
        pluginCountdown,
        // fontPlugin,
        stylePlugin,
        customCodePlugin,
        pluginTyped,
      ],
      pluginsOpts: {
        [presetPlugin]: {},
        [pluginGrapesjsBlocksBasic]: {},
        [gjsForms]: {},
        [pluginExport]: {},
        // [fontPlugin]: {
        //   api_key: "AIzaSyAdJTYSLPlKz4w5Iqyy-JAF2o8uQKd1FKc",
        // },
        [pluginCountdown]: {},
        [stylePlugin]: {},
        [customCodePlugin]: {},
        [pluginTyped]: {},
      },
    });

    editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
      buttons: [
        {
          id: 'get-storage-button',
          active: false,
          label: 'Get Storage',
          command(editor) {
            getDataStorage(editor);
          },
        },
      ],
    });

    // const templateBlocks = blockData;
    // const templateType = templateData;

    // templateType?.forEach((x) =>
    //   editor.Components.addType(x.title, { ...x.data })
    // );

    // templateBlocks?.forEach((block) => {
    //   editor.BlockManager.add(block.id, {
    //     label: block.label,
    //     content: block.content,
    //     category: block.category,
    //     media: block.media,
    //   });
    // });

    if (state.message) {
      const airesult = state?.message;
      const tamplateFull = airesult?.reduce((result, obj) => {
        return result + obj?.htmlContent;
      }, '');
      const templateBlocksFull = tamplateFull;

      editor.BlockManager.add('tamplate UI Full', {
        label: 'tamplate UI Full',
        content: templateBlocksFull,
        category: 'Your AI Blocks',
        media: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="header"><path d="M13 2H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm0 11H3V3h10v10z"></path><path d="M4 4h8v3H4z"></path></svg>`,
      });
    }

    editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });
    editor.Panels.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        // {
        //   id: "create-button",
        //   active: false,
        //   label: "<u>Fonts</u>",
        //   command(editor) {
        //     editor.runCommand("open-fonts");
        //   },
        // },
        {
          id: 'save-button',
          active: false,
          className: 'btn-toggle-borders',
          label: '<button>Save</button>',

          async command(editor) {
            const pageDetail = await getSingleDocumentFirebase(
              'websites',
              param?.pageId
            );

            if (editor) {
              const htmlContent = editor.getHtml();
              const cssContent = editor.getCss();

              const blob = new Blob([htmlContent], { type: 'text/html' });
              const file = new File([blob], 'index.html', {
                type: 'text/html',
              });

              const cssBlob = new Blob([cssContent], { type: 'text/css' });
              const cssFile = new File([cssBlob], 'style.css', {
                type: 'text/css',
              });

              //dicheck md5 buat di header pas request ke aws
              const md5Digest = CryptoJS.MD5(htmlContent);
              const md5DigestCss = CryptoJS.MD5(cssContent);

              const dataUpload = {
                file: file,
                folder: `${pageDetail?.domainName}/${pageDetail?.domainPath}`,
              };

              const dataUploadCss = {
                file: cssFile,
                folder: `${pageDetail?.domainName}/${pageDetail?.domainPath}`,
              };

              try {
                const res = await uploadAws(dataUpload, md5Digest);
                const resCss = await uploadAws(dataUploadCss, md5DigestCss);

                if (res?.status && resCss?.status) {
                  const res = await getSingleDocumentFirebase(
                    `websites`,
                    param?.pageId
                  );

                  if (res?.html === '' && res?.css === '') {
                    const updateRes = await updateDocumentFirebase(
                      `websites`,
                      param?.pageId,
                      { html: editor.getHtml(), css: editor.getCss() }
                    );
                  } else {
                    const response = await setDocumentFirebase(
                      `websites`,
                      param?.pageId,
                      {
                        html: editor.getHtml(),
                        css: editor.getCss(),
                        projectId: globalState?.currentProject,
                        companyId: globalState?.currentCompany,
                      },
                      globalState.currentCompany
                    );

                    console.log(response);
                  }

                  toast({
                    title: 'Success',
                    description: 'Data successfully saved',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                }
              } catch (error) {
                console.log(error);
                console.log(error.message);
                toast({
                  title: 'Error',
                  description: 'Failed to save data',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              }
            }
          },
        },
      ],
    });

    return () => {
      editor.destroy();
    };
  }, []);

  return (
    <Stack p={[1, 1, 5]} w="full" h="100vh" spacing={5}>
      {/* <HStack>
        <BackButtons />
        <Spacer />
        <Heading textTransform={"capitalize"} size="md">
          {state.title} - {state.title_page}
        </Heading>
      </HStack> */}
      <div style={{ paddingBottom: 50 }}>
        <div className="panel__top">
          <div className="panel__basic-actions" />
        </div>
        <div id="gjs" />
      </div>
    </Stack>
  );
}

export default MarketingLandingPage;
