import React from "react";
import SkincareListPage from "../Pages/Product/SkincareListPage";
import ProductPage from "../Pages/Product/ProductPage";
import SkincareCreatePage from "../Pages/Product/SkincareCreatePage";
import SkincareEditPage from "../Pages/Product/SkincareEditPage";
import TreatmentListPage from "../Pages/Product/TreatmentListPage";
import TreatmentCreatePage from "../Pages/Product/TreatmentCreatePage";
import TreatmentEditPage from "../Pages/Product/TreatmentEditPage";
import ProductListPage from "../Pages/Product/ProductListPage";

const ProductRouter = [
  {
    path: "/product",
    element: <ProductPage />,
  },  
  {
    path: "/product/list-product",
    element: <ProductListPage />,
  },
  {
    path: "/product/list-product/skincare",
    element: <SkincareListPage />,
  },
  {
    path: "/product/list-product/skincare/add-skincare",
    element: <SkincareCreatePage />,
  },
  {
    path: "/product/list-product/skincare/edit-skincare/:id",
    element: <SkincareEditPage />,
  },
  {
    path: "/product/list-treatment",
    element: <TreatmentListPage />,
  },
  {
    path: "/product/list-treatment/create",
    element: <TreatmentCreatePage />,
  },
  {
    path: "/product/list-treatment/edit/:id",
    element: <TreatmentEditPage />,
  },
];

export default ProductRouter;
