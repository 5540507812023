import axios from "axios";

export const ApiAccountingToken = async (globalState, value) => {

  try {
    const { data } = await axios.post(
      `https://asia-southeast2-anggaran-v2.cloudfunctions.net/authProd/api/select-company`,
      {
        select_company: value,
      },
      {
        headers: {
          Authorization: `Bearer ${globalState?.anggaranData?.token}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error, "ini error");
    throw error;
  }
};
