import {
  AbsoluteCenter,
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtons";
import useMarketingStore from "../../Hooks/Zustand/MarketingStore";
import { addDocumentFirebase } from "../../Api/firebaseApi";
import useUserStore from "../../Hooks/Zustand/Store";

function MarketingFacebookAddAdPublishPage() {
  const toast = useToast();
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const [loading, setLoading] = useState(false);

  const steps = [
    { title: "Campaign", description: "Create Campaign" },
    { title: "Ads", description: "Create Ads" },
    { title: "Audience", description: "Select Audience" },
    {
      title: "Budget & Strategies",
      description: "Set your budget & strategies",
    },
    { title: "Publish", description: "Review & publish" },
  ];

  const navigate = useNavigate();

  const handlePublish = async () => {
    setLoading(true);
    const data = {
      campaignName: marketingState?.campaign?.campaignName,
      adAccount: marketingState?.campaign?.adAccount,
      facebookLink: marketingState?.campaign?.facebookLink,
      instagramLink: marketingState?.campaign?.instagramLink,
      pixelId: marketingState?.campaign?.pixelID,
      promotionType: marketingState?.campaign?.promotionType,
      tags: marketingState?.campaign?.tags,
      adsCreation: marketingState?.adsCreation,
      budget: marketingState?.budgeting,
      targeting: marketingState?.targeting,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      type: "ads",
      category: "facebook",
    };
    try {
      const res = await addDocumentFirebase(
        "files",
        data,
        globalState?.currentCompany
      );

      console.log(res);
      toast({
        title: "success",
        description: "Success save campaign, preparing to publish",
        duration: 3000,
        status: "success",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: `An error occured: ${error}`,
        duration: 3000,
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box mb="5">
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={5}
        bg={"white"}
        p={5}
        borderRadius={"md"}
        shadow={"base"}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box>
        <Heading textAlign="center">Publish</Heading>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2 }}
          my={5}
          bg={"white"}
          p={5}
        >
          <Container
            alignSelf="start"
            mt="5"
            shadow="base"
            bg={"white"}
            p={5}
            borderRadius={"md"}
          >
            <Text>
              Campaign Name : {marketingState?.campaign?.campaignName}
            </Text>
            <Text>Ad Name: {marketingState?.adsCreation?.adName}</Text>
            <Text>Facebook Link: {marketingState?.campaign?.facebookLink}</Text>
            <Text>
              Instagram Link: {marketingState?.campaign?.instagramLink}
            </Text>
            <Text>Pixel ID: {marketingState?.campaign?.pixelID}</Text>
            <Text>Promotion:{marketingState?.campaign?.promotionType}</Text>
            <Text>Language: {marketingState?.adsCreation?.language}</Text>
            <Text>
              Locations: {marketingState?.adsCreation?.location?.city}
            </Text>
            <Text>Audience: {marketingState?.targeting?.customAudience}</Text>
            <Text>
              Age: {marketingState?.targeting?.ageStart} -{" "}
              {marketingState?.targeting?.ageEnd}
            </Text>
            <Text>Sex: {marketingState?.targeting?.sex}</Text>
            <Text>Devices: {marketingState?.targeting?.devices}</Text>
            <Button
              mt={5}
              w="full"
              isLoading={loading}
              colorScheme="green"
              onClick={() => handlePublish()}
            >
              Publish
            </Button>
          </Container>

          <Container alignSelf="start">
            <Box borderRadius={"md"} shadow={"base"} overflow={"hidden"}>
              <HStack p={2}>
                <Image
                  boxSize={70}
                  borderRadius="full"
                  src={
                    marketingState?.adsCreation?.businessLogo === ""
                      ? "https://scontent.fcgk27-1.fna.fbcdn.net/v/t39.35426-6/406579295_373908135085268_3861333469265015122_n.jpg?stp=dst-jpg_s60x60&_nc_cat=111&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeEGH6IsRTw35Ad7h4t2JwB8ghaTzGOdgDSCFpPMY52ANFNzsBwy2alRNLPxf8ekL8o&_nc_ohc=pD4zGzi6ogwAX_ajZJ3&_nc_ht=scontent.fcgk27-1.fna&oh=00_AfCaiom984i5RcBxdWjQVZyarMFX4z-KUvB-Qb5QCUehrg&oe=656ED39A"
                      : marketingState?.adsCreation?.businessLogo
                  }
                  alt="your branad name"
                />
                <Box>
                  <Heading size="md">
                    {marketingState?.adsCreation?.businessName === ""
                      ? "Your Business Name"
                      : marketingState?.adsCreation?.businessName}
                  </Heading>
                  <Text>Sponsored</Text>
                </Box>
              </HStack>
              <Text mt="5" p={2}>
                {marketingState?.adsCreation?.businessName === ""
                  ? "Your Primary Text"
                  : marketingState?.adsCreation?.primaryText}
                {/* 𝟱 𝗝𝗮𝗺 𝗣𝗿𝗮𝗸𝘁𝗲𝗸 𝗶𝗺𝗽𝗼𝗿𝘁 & 𝗥𝗶𝘀𝗲𝘁 𝗣𝗿𝗼𝗱𝘂𝗸 𝗟𝗮𝗿𝗶𝘀 berdasarkan data dari
                Tokopedia/Shopee dengan Omset 100 Juta per bulan😱 . Kamu akan
                di bimbing dari 0 hingga mendapatkan 1 Produk potensi CUAN 100
                Juta Pertama Hanya dari 1 Produk Saja😍 . Yuk Catat tanggal &
                waktunya */}
              </Text>
              <Center bgColor="F2F2F2">
                {marketingState?.adsCreation?.mediaLink === "" ? (
                  <Image
                    w={300}
                    src={
                      "https://scontent.fcgk27-2.fna.fbcdn.net/v/t39.35426-6/404306436_1740373206478859_1023210650103708856_n.jpg?stp=dst-jpg_s600x600&_nc_cat=103&ccb=1-7&_nc_sid=c53f8f&_nc_eui2=AeHMUiYlCbEr28tKQEHArX-OH_IfYgihL_Uf8h9iCKEv9c24FoOfmT44DXekWY_gg9M&_nc_ohc=MqluPEfGYu4AX_RZvsl&_nc_ht=scontent.fcgk27-2.fna&oh=00_AfDmN-pk_W0TfxDM5YdpQTLZ1RgpJNBrJiiEY4ONoVSmGw&oe=65701436"
                    }
                    alt="ads Image"
                  />
                ) : (
                  <>
                    {marketingState?.adsCreation?.mediaLink?.type ===
                    "video" ? (
                      <Box align={"center"}>
                        {/* <AspectRatio> */}
                        <iframe
                          width={"100%"}
                          src={marketingState?.adsCreation?.mediaLink?.link}
                          allowFullScreen
                        />
                        {/* </AspectRatio> */}
                      </Box>
                    ) : (
                      <Image
                        w={300}
                        src={marketingState?.adsCreation?.mediaLink?.link}
                        alt="ads Image"
                      />
                    )}
                  </>
                )}
              </Center>
              <HStack
                bgColor="white"
                p="2"
                borderTop={"1px"}
                borderColor={"gray.300"}
              >
                <Box>
                  <Text fontSize="xs">
                    {" "}
                    {marketingState?.adsCreation?.websiteUrl === ""
                      ? "Your Website URL"
                      : marketingState?.adsCreation?.websiteUrl}
                    {/* www.kodok.com */}
                  </Text>
                  <Text>
                    {marketingState?.adsCreation?.headline === ""
                      ? "Your headline"
                      : marketingState?.adsCreation?.headline}
                    {/* Dapatkan Tiketnya segera */}
                  </Text>
                  <Text fontSize="sm">
                    {marketingState?.adsCreation?.description === ""
                      ? "Your description"
                      : marketingState?.adsCreation?.description}
                    {/* Pendaftaran Terbatas */}
                  </Text>
                </Box>
                <Spacer />
                <Button>
                  {marketingState?.adsCreation?.cta === ""
                    ? "Your CTA Button"
                    : marketingState?.adsCreation?.cta}
                  {/* Learn More */}
                </Button>
              </HStack>
            </Box>
          </Container>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default MarketingFacebookAddAdPublishPage;
